nn.registerModule('reviews.List', async (element, options) => {
  const listPromise = import(/* webpackChunkName: "reviews" */ './list');
  const collectionPromise = import(/* webpackChunkName: "reviews" */ './collection');
  const paginationPromise = import(/* webpackChunkName: "reviews" */ './pagination');
  const ReviewCollection = (await (collectionPromise)).default;
  const Pagination = (await (paginationPromise)).default;
  (await (listPromise)).default(element, options, ReviewCollection, Pagination);
});

nn.registerModule('reviews.Dialoglink', async (element) => {
  const listPromise = import(/* webpackChunkName: "reviews" */ './list');
  const collectionPromise = import(/* webpackChunkName: "reviews" */ './collection');
  const paginationPromise = import(/* webpackChunkName: "reviews" */ './pagination');
  const ReviewCollection = (await (collectionPromise)).default;
  const Pagination = (await (paginationPromise)).default;
  const List = (await (listPromise)).default;
  const dialogPromise = import(/* webpackChunkName: "reviews" */ './dialoglink');
  (await (dialogPromise)).default(element, List, ReviewCollection, Pagination)
});