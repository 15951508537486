import { getMenuItems, createMoreMenu, createSubMenu, setMoreMenu } from "./menu-more";
import { addDropdownToHeader, addDropdownEventListeners } from "./menu-dropdown";
import { setMobileMenu } from "./menu-mobile";
import { addSearchButton, addSearchEventListeners } from "./search";
import { skipLinks } from "./skip-links";
import { setAdvisorLoginLink } from "./advisor";

window.app = window.app || {};
window.app.header = window.app.header || {};

window.app.header.Index = function (element) {
  const { menu, menuItems } = getMenuItems(element);
  const search = element.querySelector('.c-header__search__form');
  let mobileSearchButton, searchButton;
  if (menuItems.length > 0) {
    const { subMenu, subMenuList } = createSubMenu();
    const dropdown = addDropdownToHeader(element);
    const menuItemMore = createMoreMenu(menu, subMenu);
    setMoreMenu(menu, menuItems, menuItemMore, subMenuList);
    addDropdownEventListeners(menuItems, menuItemMore, dropdown);
    window.addEventListener("resize", () => {
      setMoreMenu(menu, menuItems, menuItemMore, subMenuList);
    })
  }
  mobileSearchButton = setMobileMenu(element, menu, search);

  if (search) {
    searchButton = addSearchButton(element);
    addSearchEventListeners(element, searchButton, mobileSearchButton);
  }

  if (document.body.classList.contains('advisor')) {
    setAdvisorLoginLink(element);
  }
  skipLinks();
};
