import Dialog from '../dialog/dialog.js';
import scrapeContent from '../utility/content-scraper.js';

export default class AjaxDialogLink {
  constructor (element, config = {}) {
    this.element = element;
    this.element.addEventListener('click', event => this._onClick(event));
    this.dialog = new Dialog({themes: ['m']});
    this.contentURL = this.element.getAttribute('href');
    if (this.contentURL === '#' && config.href) {
      this.contentURL = config.href;
    }
  }

  async _onClick (event) {
    event.preventDefault();
    this.dialog.setContent(`<div class="u-textalign-center u-margin-bottom">
      <div class="c-spinner u-inline-block">Bezig met laden...</div>
    </div>`);
    this.dialog.show();
    try {
      this.dialog.setContent(await scrapeContent(this.contentURL));
    } catch (err) {
      window.console.error(err);
      this.dialog.setContent('<h2>[Foutmelding]</h2>');
    }
  }
}
