import Dialog from '@nn/psdl-modules/src/dialog/dialog.js';
import scrapeContent from '@nn/psdl-modules/src/utility/content-scraper.js';

/**
 * This component is used to ask for confirmation before logging out.
 * @param {element} form element
 *   The component's form element.
 */

nn.component.ServiceMenu = class {
  constructor(element) {
    this.element = element;
    this.userName = '';
    this.confirmDialogURL = this.element.dataset.confirmUrl;
    this.logoutLink = this.element.querySelector('.logout');
    this.logoutLink.addEventListener('click', () => this.onLogout(event));
    this.dialog = new Dialog({
      themes: ['m'],
      closeOnGlassClick: true,
    });
  }

  onLogoutOk(event) {
    event.preventDefault();
    window.location.assign(this.logoutLink.href);
  }

  onLogoutCancel(event) {
    event.preventDefault();
    this.dialog.hide();
  }

  async onLogout(event) {
    event.preventDefault();
    const content = document.createDocumentFragment();
    content.append(...await scrapeContent(this.confirmDialogURL, false));
    content
      .querySelector('.button-ok')
      .addEventListener('click', event => this.onLogoutOk(event));

    content
      .querySelector('.button-cancel')
      .addEventListener('click', event => this.onLogoutCancel(event));

    this.dialog.setContent(content);
    this.dialog.show();
  }
}
