import Sidepanel from '../sidepanel/sidepanel.js';
import {disownRemoteOverlay, buildRemoteOverlayHTML} from './helpers.js';

export default class BlueriqApi {
  constructor () {}

  startLoading (scope, loader) {
    var html;
    // loader is a jQuery object from which the content has to be shown as a ‘custom’ spinner
    if (loader instanceof jQuery) {
      html = loader[0].outerHTML;
      //TODO change content of content container in blueriq
      html = $(html).find('.spinner-content').html();
    }

    if (this.ajaxLoader) {
      this.ajaxLoader.destroy();
      delete this.ajaxloader;
    }

    var options = {
      dialogContent : html,
      delay : '400'
    };

    this.ajaxLoader = new window.app.Loadingoverlay(options);
    this.ajaxLoader.show();

    window.radio('blueriq.state-change').broadcast({'scope': scope, 'state': 'start'});
  }

  completeLoading (scope) {
    var eventData = {'scope': scope};
    $('body').trigger('NN:Blueriq:completeLoading', eventData);
    var event = document.createEvent('Event');
    event.initEvent('NN:Blueriq:completeLoading', true, true);
    event.data = eventData;
    document.body.dispatchEvent(event);
    window.dispatchEvent(event);
    window.radio('blueriq.state-change').broadcast({'scope': scope, 'state': 'completed'});
    if (this.ajaxLoader) {
      this.ajaxLoader.hide();
    }
  }

  successLoading () {
    if (this.ajaxLoader) {
      this.ajaxLoader.hide();
    }
  }

  setPageTitle (scope, title) {
    document.title = title;
  }

  showSidepanel (scope, html, closeHandler) {
    if (this.sidepanel) {
      this.sidepanel.hide();
    }

    this.sidepanel = new Sidepanel({
      closeHandler,
    });

    this.sidepanel.setContent(buildRemoteOverlayHTML(scope, html), true);
    this.sidepanel.show();
  }

  hideSidepanel () {
    this.sidepanel.hide();
    disownRemoteOverlay();
  }

  // setBlueriqSessionId (scope, sessionId) {
  // };
}
