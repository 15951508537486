require("./_technical-component.scss");

require("./charactercount/js/charactercount.js");
require("./iframe/js/Iframe.js");
require("./iban/js/IbanCopy.js");
require("./iban/js/IbanConverter.js");
require("./collapser/js/Collapser.js");
require("./responsive/js/Responsive.js");
require("./dateresponsive/js/Dateresponsive.js");
require("./formatters/js/BsnFormatter.js");
require("./formatters/js/DotsDigitsFormatter.js");
require("./formatters/js/IbanFormatter.js");
require("./formatters/js/InitialsFormatter.js");
require("./formatters/js/LastnameFormatter.js");
require("./formatters/js/ZipcodeFormatter.js");
require("./formatters/js/WordsFormatter.js");
require("./formatters/js/CharsNumbersFormatter.js");
require("./formatters/js/PriceFormatter.js");
require("./fullwidthcentered/js/FullWidthCentered.js");
require("./stepindicatorlistener/js/StepIndicatorListener.js");
require("./styleenhancer/js/Styleenhancer.js");
require("./bustframe/js/bustframe.js");
require("./decorateparent/js/decorateparent.js");
require("./feedforward/js/feedforward.js");
require("./prefillm2/js/PrefillM2.js");
require("./requestaddress/js/RequestAddress.js");
require("./libloader/js/LibLoader.js");
require("./libloader/js/initialize-libloader.js");
require("./stickypanel/js/StickyPanel.js");
require("./stickypanel/js/StickyPanelTop.js");
require("./service-menu/js/ServiceMenu.js");
require("./text-field-autosuggest/js/TextFieldAutosuggest.js");
require("./blueriqelement/js/BlueriqElement.js");
require("./hide/js/Hide.js");
require("./saml/js/initialize-saml.js");
require("./saml/js/saml.js");
