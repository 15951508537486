(function(window) {
  'use strict';

  window.app = window.app || {};
  window.app.helper = window.app.helper || {};

  /**
	 * Scroll To helper method.
	 *
	 * This helper scrolls the page to the target element using a scroll
	 * animation with a particular duration.
	 *
	 * @param {object:Element} element: component element to scroll to.
	 * @param {number} duration: duration of the animation in ms.
	 */
  window.app.helper.scrollto = function(element, duration) {
    var timeStart = window.performance.now();
    var timeEnd = timeStart + duration;
    var scrollStart = window.pageYOffset;

    // The maximum scroll position is document height - window height.
    var scrollEnd = Math.min(
      element.getBoundingClientRect().top + window.pageYOffset,
      document.documentElement.scrollHeight - window.innerHeight
    );

    /**
		 * This is the step method, it runs every step of the animation.
		 * This setup uses a closure rather than Function.prototype.bind,
		 * because the code needs at least 5 values. Making those into
		 * parameters seems messy, and repeatedly binding seems silly as well.
		 */
    (function step(timeNow) {
      // Determine progress, this the domain of the easing function.
      var timeRatio = (timeNow - timeStart) / (timeEnd - timeStart);

      // Then clamp to [0,1] inclusive.
      timeRatio = Math.max(Math.min(timeRatio, 1), 0);

      // Easing uses the sine function from x >= -0.5 pi, x <= 0.5 pi.
      var scrollRatio = (Math.sin((timeRatio - 0.5) * Math.PI) + 1) / 2;

      // Clamp the output ratio to [0,1] inclusive.
      scrollRatio = Math.max(Math.min(scrollRatio, 1), 0);

      window.scroll(0, scrollStart + scrollRatio * (scrollEnd - scrollStart));

      if (timeNow < timeEnd) {
        window.requestAnimationFrame(step);
      }
    }(timeStart));
  };
}(window));