import template from "./template/sidepanel.tpl.html";
import { setContent, appendNodes } from "../utility/html-update-helpers";
import scrapeContent from "../utility/content-scraper";
import * as a11y from "../utility/a11y";
import { KEY_ESCAPE } from "../utility/keyboardKeys";
import scrollHelper from "../utility/scroll.helper";

export default class SidePanel {
  constructor(options) {
    this.options = options || {};
    const tmp = document.createElement("div");
    tmp.innerHTML = template;
    this.element = tmp.removeChild(tmp.firstElementChild);
    this.titleElement = this.element.querySelector(
      '[data-role="sidepanel-title"]'
    );
    this.contentElement = this.element.querySelector(
      '[data-role="sidepanel-content"]'
    );

    this.closeButtons = [
      ...this.element.querySelectorAll('[data-role="sidepanel-close"]')
    ];
    this.closeButtons.forEach(btn => this.addToggleButton(btn, true));

    this.element.addEventListener(
      "transitionend",
      this._transitionEndHandler.bind(this)
    );

    if (this.options.titleElement) {
      const titleSourceElement = document.querySelector(
        this.options.titleElement
      );
      if (titleSourceElement) {
        this.appendTitle([...titleSourceElement.childNodes], false);
      }
    } else if (this.options.title) {
      this.setTitle(this.options.title);
    }

    if (this.options.contentElement) {
      const contentSourceElement = document.querySelector(
        this.options.contentElement
      );
      if (contentSourceElement) {
        this.appendContent([...contentSourceElement.childNodes], false);
      }
    } else if (this.options.content) {
      this.setContent(this.options.content);
    }

    this.element.addEventListener("keydown", evt => {
      if (evt.keyCode === KEY_ESCAPE) {
        this.hide();
      }
    });
  }

  /**
   * Sets side panel title.
   *
   * @param {String|Node} content
   * @returns {Element}, the title element
   */
  setTitle(content) {
    return setContent(this.titleElement, content, true);
  }

  /**
   * Sets side panel content.
   *
   * @param {String|Node} content
   * @returns {Element}, the content element
   */
  setContent(content, replaceTitle = false) {
    setContent(this.contentElement, content, true);

    const heading = this.contentElement.querySelector("h1,h2,h3,h4");
    if (replaceTitle && heading) {
      heading.parentNode.removeChild(heading);
      this.setTitle(heading.innerHTML);
    }

    return this.contentElement;
  }

  /**
   * Append nodes to the title element.
   *
   * @param {Array|DOMNodeList} nodes
   * @returns {Element}, the title element
   */
  appendTitle(nodes, applyBlueriqFix = true) {
    return appendNodes(this.titleElement, nodes, true, applyBlueriqFix);
  }

  /**
   * Append nodes to the content element.
   *
   * @param {Array|DOMNodeList} nodes
   * @returns {Element}, the content element
   */
  appendContent(nodes, applyBlueriqFix = true) {
    return appendNodes(this.contentElement, nodes, true, applyBlueriqFix);
  }

  /**
   * Returns whether the sidepanel is currently open.
   * @returns {Boolean}
   */
  isOpen() {
    return this.element.classList.contains("c-sidepanel__is-open");
  }

  /**
   * Shows the sidepanel.
   *
   * @returns {Sidepanel}, the sidepanel instance (used for chaining).
   */
  show() {
    document.body.append(this.element);
    a11y.a11yDialog(this.element);

    setTimeout(() => {
      if (scrollHelper.isBodyOverflowing()) {
        document.body.style.paddingRight = `${scrollHelper.getScrollbarWidth()}px`;
      }
      this.element.classList.add("c-sidepanel__is-open");
      document.body.classList.add("is-modal-open");
    }, 0);
    return this;
  }

  /**
   * Hides the sidepanel.
   *
   * @returns {Sidepanel}, the sidepanel instance (used for chaining).
   */
  hide() {
    document.body.style.paddingRight = null;
    this.element.classList.remove("c-sidepanel__is-open");
    document.body.classList.remove("is-modal-open");
    a11y.a11yDialogRemove(this.element);
    return this;
  }

  /**
   * Returns whether the sidepanel is currently attached to the DOM.
   * @returns {Boolean}
   */
  isAttached() {
    return Boolean(this.element.parentNode);
  }

  /**
   * The transition handler that fires as soon as the glass is done animating.
   *
   * @returns {undefined}
   */
  _transitionEndHandler() {
    if (!this.isOpen()) {
      this.element.remove();
    }
  }

  /**
   * Utility method to a add click listener to the element provided that
   * triggers either the show or hide method on the sidepanel instance.
   *
   * @param {EventTarget} target, the event target to attach the listener to,
   * such as an HTML Element;
   * @param {Boolean} hide, if truthy, the sidepanel is hidden, if falsy, it's
   * shown.
   * @returns {Sidepanel}, the sidepanel instance (used for chaining).
   */
  addToggleButton(target, hide) {
    target.addEventListener("click", event => {
      event.preventDefault();
      if (hide) {
        if (this.options.closeHandler) {
          this.options.closeHandler(event);
        } else {
          this.hide();
        }
      } else {
        this.show();
      }
    });
    return this;
  }

  static fromShowButton(element, config) {
    return new SidePanel(config).addToggleButton(element);
  }

  static fromAjaxButton(clickButton, config) {
    clickButton.addEventListener("click", event => {
      event.preventDefault();

      scrapeContent(event.target.getAttribute("href"))
        .then(htmlResponse => {
          const sidePanel = new SidePanel(config);
          sidePanel.setContent(htmlResponse, true);
          sidePanel.show();
          return sidePanel;
        })
        .catch(error => {
          error(error);
        });
    });

    return this;
  }
}
