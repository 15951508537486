(function (window, $, nn) {

  nn.component.TextFieldAutosuggest = function (element) {
    this.element = $(element);

    this.init();
  };

  nn.component.TextFieldAutosuggest.prototype = {
    init: function () {
      this.getAndSetOptions();
      this.getAndSetElements();
      this.bindEvents();
    },
    getAndSetOptions: function () {
      this.useCache = true;
      this.cache = {};
      this.minimalInputLength = 3;
      this.suggestionUrl = this.element.data('suggestion-url');
      this.viewAllText = this.element.data('view-all-text');
      this.suggestionLimit = 10;
      this.submittingForm = false;

      this.handleInputDebounced = nn.helpers.debounce($.proxy(this.handleInput, this), 200);

    },
    getAndSetElements: function () {
      this.element.attr("autocomplete", "off");
      this.wrapperElement = this.element.parent('.text-field-autosuggest-wrapper');
      if (this.wrapperElement.length < 1) {
        this.wrapperElement = this.element.wrap('<span class="text-field-autosuggest-wrapper"></span>').parent();
      }
      this.suggestionContainer = this.wrapperElement.find('.text-field-autosuggest-container');
      if (this.suggestionContainer.length < 1) {
        this.suggestionContainer = $('<span class="text-field-autosuggest-container"></span>').appendTo(this.wrapperElement);
      }
    },
    bindEvents: function () {
      this.element.on('keyup', $.proxy(this.onFieldKeyUp, this));
      this.element.on('keydown', $.proxy(this.onFieldKeydown, this));
      this.element.on('blur', $.proxy(this.hideSuggestions, this));

      this.suggestionContainer.on('click mousedown', '.text-field-autosuggest-option, .text-field-autosuggest-viewall', $.proxy(this.onOptionClick, this));
      this.suggestionContainer.on('mouseenter', '.text-field-autosuggest-option', $.proxy(this.focusByHoverEvent, this));

    },
    isDisabled: function () {
      return this.element.data('autosuggest-disabled');
    },
    onFieldKeyUp: function (evt) {
      if (this.isDisabled()) {
        return;
      }

      var valueMayChange = true;

      if (evt.keyCode === 38) { // Key up -> Focus previous suggestion
        try {
          evt.preventDefault();
        } catch (E) {
          // Empty block
        } // Attempt to prevent random jQuery errors in IE <= 8
        if (!this.suggestionsActive) {
          this.showSuggestions();
        }
        this.focusPrevious();
        valueMayChange = false;
      } else if (evt.keyCode === 40) { // Key down -> Focus next suggestion
        try {
          evt.preventDefault();
        } catch (E) {
          // Empty block
        } // Attempt to prevent random jQuery errors in IE <= 8
        if (!this.suggestionsActive) {
          this.showSuggestions();
        }
        this.focusNext();
        valueMayChange = false;
      } else if (this.suggestionsActive) {

        if (evt.keyCode === 37 || evt.keyCode === 39) { // Left / right arrows
          // Do nothing
          valueMayChange = false;
        } else if (evt.keyCode === 13) { // Enter -> Submit form
          this.element.closest('form').submit();
          this.submittingForm = true;
          this.hideSuggestions();
          valueMayChange = false;
        } else if (evt.keyCode === 27) { // Escape -> Hide suggestions
          this.hideSuggestions();
          valueMayChange = false;
        }

      }

      if (valueMayChange) {
        // Handle any given input
        this.handleInputDebounced();
      }
    },
    handleInput: function () {
      var value = this.element.val();

      if (value.length < this.minimalInputLength) {

        this.currentValue = value;
        this.clearSuggestions();
        this.hideSuggestions();

      } else if (value !== this.currentValue) {
        this.currentValue = value;
        this.updateSuggestions();
      } else {
        this.showSuggestions();
      }
    },
    onFieldKeydown: function (evt) {
      if (evt.keyCode === 38 || evt.keyCode === 40) { // Key up/down
        try {
          evt.preventDefault();
        } catch (E) {
          // Empty block
        } // Attempt to prevent random jQuery errors in IE <= 8
      } else if (evt.keyCode === 13) { // Enter key
        this.submittingForm = true;
        if (this.suggestionsActive) {
          this.hideSuggestions();
        }
      }
    },
    updateSuggestions: function () {
      if (this.useCache && this.cache[this.currentValue]) {
        this.processResult(this.cache[this.currentValue]);
      } else if (!this.fetchingSuggestions) {

        this.fetchingSuggestions = true;

        $.ajax({
          'url': this.suggestionUrl,
          'type': 'get',
          'data': {
            'q': this.currentValue,
            'limit': this.suggestionLimit
          },
          'cache': false,
          'dataType': 'json'

        })
          .done($.proxy(this.onFetchSuggestionDoneHandler, this))
          .fail($.proxy(this.onFetchSuggestionFailHandler, this))
          .always($.proxy(this.onFetchSuggestionFinishHandler, this));
      }
    },
    onFetchSuggestionDoneHandler: function (data) {
      this.processResult(data);
    },
    onFetchSuggestionFailHandler: function (jqXHR, textStatus, errorThrown) {
      var errorObj = {
        message: 'Failed to fetch suggestions for query "' + this.currentValue + '"',
        url: this.url,
        originalError: errorThrown
      };
      $(window).trigger('TextFieldAutosuggest:Error', errorObj);
    },
    onFetchSuggestionFinishHandler: function () {
      this.fetchingSuggestions = false;
    },
    processResult: function (result) {
      var output = '',
        queryLength = this.currentValue.length,
        label,
        value;

      if (result.suggestions.length < 1) {

        this.clearSuggestions();
        this.hideSuggestions();

      } else {

        for (var i = 0, l = result.suggestions.length; i < l; i++) {
          label = result.suggestions[i].label;
          value = result.suggestions[i].value;

          output += '<li class="text-field-autosuggest-option" data-value="' + value + '">' +
            '<span class="bold">' + label.substr(0, queryLength) + '</span>' +
            (label.length > queryLength ? label.substr(queryLength) : '') +
            '</li>';
        }

        output += '<li class="text-field-autosuggest-viewall" data-value="' + this.currentValue + '">' +
          this.viewAllText.replace('{{keyword}}', this.currentValue) + '</li>';

        output = '<ul class="text-field-autosuggest-optionlist">' + output + '</ul>';

        if (this.submittingForm) {
          this.hideSuggestions();
          this.submittingForm = false;
        } else {
          this.showSuggestions();
        }

        this.suggestionContainer.html(output);

        this.options = this.suggestionContainer.find('.text-field-autosuggest-option');

        this.focusedIndex = null;
      }
    },
    showSuggestions: function () {
      this.suggestionContainer.addClass('text-field-autosuggest-container--active');
      this.suggestionsActive = true;
    },
    hideSuggestions: function () {
      this.suggestionContainer.removeClass('text-field-autosuggest-container--active');
      this.suggestionsActive = false;
    },
    clearSuggestions: function () {
      this.suggestionContainer.html('');
    },
    focusNext: function () {
      if (this.focusedIndex === null || this.focusedIndex >= this.options.length - 1) {
        this.focusedIndex = 0;
      } else {
        this.focusedIndex++;
      }

      this.focusByIndex(this.focusedIndex);
      this.pickCurrentSuggestion();
    },
    focusPrevious: function () {
      if (this.focusedIndex === null || this.focusedIndex === 0) {
        this.focusedIndex = this.options.length - 1;
      } else {
        this.focusedIndex--;
      }

      this.focusByIndex(this.focusedIndex);
      this.pickCurrentSuggestion();
    },
    focusByIndex: function (index) {
      this.blurFocusedOptions();
      this.focusedIndex = index;
      this.options.eq(index).addClass('text-field-autosuggest-option--focus');
    },
    focusByHoverEvent: function (evt) {
      this.focusByIndex(this.options.index(evt.target));
    },
    blurFocusedOptions: function () {
      this.suggestionContainer.find('.text-field-autosuggest-option--focus').removeClass('text-field-autosuggest-option--focus');
    },
    onOptionClick: function (evt) {
      this.setValue($(evt.currentTarget).data('value'));
      this.element.closest('form').submit();
    },
    pickCurrentSuggestion: function () {
      this.setValue(this.options.eq(this.focusedIndex).data('value'));
    },
    setValue: function (value) {
      this.element.val(value).change();
      setTimeout($.proxy(this.setFieldFocus, this), 10);
    },
    setFieldFocus: function () {
      this.element.focus();
    }
  };
})(window, jQuery, nn);