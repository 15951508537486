import './_c-masthead.scss';
import moduleconfig from "../u-moduleconfig/moduleconfig.js";

window.app = window.app || {};
window.app.masthead = window.app.masthead || {};
window.app.masthead.Index = function (element) {
  import(/* webpackChunkName: "modules" */ './masthead').then(module => {
    const Masthead = module.default;
    return new Masthead(element, moduleconfig({element}));
  });
};