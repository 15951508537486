// This is a third party script used with the call-me-now mechanism.

/* jshint ignore:start*/
function InitializeFreecall(container, subscription, phone, language, country, check, options) {
  return FreecallFactory.InitializeFreecall(container, subscription, phone, language, country, check, options);
}
var FreecallObjects = Array();
function HexEncode(s) {
  var r = '';
  var j = 0;
  var str = String(s);
  str = str.replace('action=', '_action=');
  for (let i = 0; i < str.length; i++) {
    j = str.charCodeAt(i);
    r += j.toString(16);
  }
  return (r);
}
var FreecallFactory = {
  lastData: null,
  remoteAddr: "",
  HexEncode,
  GetCurrentPage: function() {
    var s = window.location.protocol + '//' + window.location.host;
    if (window.location.port !== '') {
      s = s + ':' + window.location.port;
    }
    s = s + window.location.pathname;
    return HexEncode(s);
  },
  AddFreecall: function(containerId, subscription, language, country, options) {
    var i = 0;
    while ((FreecallObjects[i]) && (i < FreecallObjects.length)){
      i++;
    }
    FreecallObjects[i] = new this.Freecall(containerId, subscription, language, country, i, options);
    return i;
  },
  InitializeFreecall: function(container, subscription, phone, language, country, check, options) {
    var i = 0;
    i = this.AddFreecall(container, subscription, language, country, options);
    FreecallObjects[i].Initialize(check);

    // Moved StartCall to here as we have only one field now
    FreecallObjects[i].StartCall();

    return FreecallObjects[i];
  },
  Freecall: function(containerId, subscription, language, country, id, options) {
    var FApp = "https://secure.4tele.com/scripts/freecall/v13/freecall.dll";
    var Self = this;
    var FReference = "";
    var FreecallIDs = {
      status: "freecallstatus",
      freecallnumber: "freecallnumber",
      freecallstart: "freecallstart",
      freecallinput: "freecallinput",
      freecalllocation: "freecalllocation"
    };
    function SendRequest(url) {
      var script = document.createElement("script");
      if (url.indexOf("?") < 0) {
        script.src = url + "?requeststamp=" + (new Date).getTime();
      } else {
        script.src = url + "&requeststamp=" + (new Date).getTime();
      }
      if (this.lastData) {
        document.body.removeChild(this.lastData);
      }
      this.lastData = script;
      document.body.appendChild(script);
    }

    var References = {
      FId: id,
      FSubscription: subscription,
      FTemplates: language,
      FContainerDiv: containerId,
      FContainerObj: null,
      FCountry: country,
      FStatusObj: null,
      FStartObj: null,
      FPhoneObj: null,
      FOriginalHtml: "",
      FLastPhone: "",
      FLastStatusValue: -1,
      FOptions: options,
      FCheckMode: 0
    };

    function CheckObjects() {
      References.FContainerObj = document.getElementById(References.FContainerDiv);
      if (References.FContainerDiv === FreecallIDs.status) {
        References.FStatusObj = References.FContainerObj;
      } else {
        References.FStatusObj = document.getElementById(FreecallIDs.status);
      }
      References.FPhoneObj = document.getElementById(FreecallIDs.freecallnumber);
      References.FStartObj = document.getElementById(FreecallIDs.freecallstart);

      // This doesn't work anymore, this click is bound on the button which initialized the current click
      //ael("click", StartCall, References.FStartObj);
    }
    function ShowObject(obj, show) {
      if (obj) {
        if (show === 1) {
          obj.style.display = "block";
        } else {
          obj.style.display = "none";
        }
      }
    }
    function Status(show) {
      try {
        ShowObject(document.getElementById(FreecallIDs.freecallinput), show * -1);
        ShowObject(document.getElementById(FreecallIDs.status), show);
      } catch (e) {
        //empty
      }
    }
    function GetUrlData() {
      return HexEncode(window.location.search);
    }
    function UpdateStatusText(reply) {
      var s = reply.message.data;
      Status(1);
      References.FStatusObj.innerHTML = s;
    }
    function ProcessStatusReply(reply) {
      if (References.FLastStatusValue !== reply.status.value) {
        References.FLastStatusValue = reply.status.value;
        UpdateStatusText(reply);
      }
      if (reply.status.finished === "0") {
        setTimeout(GetState, reply.nextdelay);
      }
    }
    function GetCustomerData() {
      var d = "";
      try {
        if (typeof References.FOptions.CustomerDataFunction === "function") {
          d = References.FOptions.CustomerDataFunction();
        }
        if (References.FOptions.CustomerData) {
          if (d !== "") {
            d = d + ",";
          }
          d = d + References.FOptions.CustomerData;
        }
        d = HexEncode(d);
      } catch (e) {
        //empty
      }
      return d;
    }
    function GetState() {
      var url = FApp + "?action=getstate&ref=" + FReference + "&templates=" + References.FTemplates + "&callback=OnStatusReply&localid=" + References.FId;
      SendRequest(url);
    }
    function PrepareNumber(num) {
      var s = num;
      var r = '';
      var c = '';
      for (let i = 0; i < s.length; i++) {
        c = s.substr(i, 1);
        if ((c >= '0') && (c <= '9')) {
          r = r + c;
        }
      }
      return r;
    }
    function StartCall() {
      if (Self.CheckInput(References)) {
        References.FOriginalHtml = References.FStatusObj.innerHTML;
        References.FOriginalHtml = References.FOriginalHtml.replace(/^\s+|\s+$/g, "");
        References.FLastStatusValue = -1;
        References.FFreecallReference = "";
        References.FLastPhone = PrepareNumber(References.FPhoneObj.value);
        var enc_q = GetUrlData();
        var enc_h = FreecallFactory.GetCurrentPage();
        var enc_d = GetCustomerData();
        var url = FApp + "?action=startcall&subscrid=" + References.FSubscription + "&telnr=" + References.FLastPhone + "&templates=" + References.FTemplates + "&cc=" + References.FCountry + "&callback=OnStartReply&localid=" + References.FId + "&enc_d=" + enc_d;
        var encurl = url + '&enc_h=' + enc_h + '&enc_q=' + enc_q;
        SendRequest(encurl);
      }
    }
    function Initialize() {
      CheckObjects();
      References.FOriginalHtml = References.FStatusObj.innerHTML;
      References.FOriginalHtml = References.FOriginalHtml.replace(/^\s+|\s+$/g, "");
    }
    function HtmlEncode(txt) {
      txt = txt.replace(/&/g, '&amp;');
      txt = txt.replace(/</g, '&lt;');
      txt = txt.replace(/>/g, '&gt;');
      txt = txt.replace(/'/g, '&#39;');
      txt = txt.replace(/"/g, '&#34;');
      return txt;
    }
    this.OnStartReply = function(reply) {
      FReference = reply.reference;
      this.OnStatusReply(reply);
    };
    this.OnAvailableReply = function(reply) {
      reply.message.data = this.FilterText(reply.message.data);
      this.remoteAddr = reply.remoteaddr;
      this.UpdateStatus(reply.status.value);
      ProcessStatusReply(reply);
      this.ProcessLocation(window.locationinfo, window.allowed);
    };
    this.GetCustomerData = function(nam) {
      var i = 0;
      var val = "";
      while (i < References.FOptions.CustomerData.length) {
        if (References.FOptions.CustomerData[i].indexOf(nam) === 0) {
          val = References.FOptions.CustomerData[i];
        }
        i++;
      }
      if (val !== "") {
        val = val.substring(nam.length + 1, val.length);
      }
      return val;
    };
    this.Initialize = function() {
      Initialize();
    };
    this.FilterText = function(txt) {
      return HtmlEncode(txt);
    };
    this.CheckInput = function (references) {

      var fieldset = document.getElementById(references.FContainerDiv);
      var formID = fieldset.parentNode.id;
      return window.CMN.references[formID].maySubmit;
    };
    this.OnStatusReply = function(reply) {
      reply.message.data = this.decodeHTMLEntities(this.FilterText(reply.message.data));
      ProcessStatusReply(reply);
    };
    this.decodeHTMLEntities = function (data) {
      return data
        .replace(/&amp;/g, '&')
        .replace(/&#60;/g, '<')
        .replace(/&#45;/g, '-')
        .replace(/&#39;/g, '\'')
        .replace(/&#34;/g, '"')
        .replace(/&#62;/g, '>');
    };
    // Added this function because we don't want to bind the startCall to the button, we want to execute it immediately.
    this.StartCall = function() {
      StartCall();
    }
  }
};

// Make them all global as these are called from a head script
module.exports = global.FreecallFactory = FreecallFactory;
module.exports = global.FreecallObjects = FreecallObjects;
module.exports = global.InitializeFreecall = InitializeFreecall;

/* jshint ignore:end */