/* ----------------------~  S T I C K Y  - E L E M E N T  ~----------------------- */

/**
 * Initializes a new sticky panel component.
 *
 * @param {element} The component's root element.
 */

nn.component.StickyElement = function (element) {
  this.element = $(element);
  // Initialize
  this.init();
};

$.extend(nn.component.StickyElement.prototype, {
  init: function () {
    this.getAndSetElements();
    this.getAndSetOptions();
    this.bindEvents();

    this.onScroll();
  },
  getAndSetOptions: function () {

    // make element sticky;
    this.element.css(this.elementPosition, '0');
    this.element.css({
      'position': 'fixed',
      'left' : 0,
      'right' : 0,
      'z-index': 1201
    });

    // this.onScrollListenerDebounced = nn.helpers.debounce($.proxy(this.onScroll, this), 200);

  },
  getAndSetElements: function () {
    this.elementPosition = this.element.data('sticky-position');
    // this.originalTop = this.element.offset().top;
    // this.originalWidth = this.element.width();
    // We are going to rip our element from the page flow. Therefore, we'll need a wrapper of the same height so the page won't jump around.
    // this.wrapperElement = this.element.wrap('<div class="block-sticky-wrapper"></div>').parent('.block-sticky-wrapper');
    // this.wrapperElement.css({height: this.element.height() + 31 + 'px', position: 'relative'});
  },
  bindEvents: function () {
    $(window).on('resize', this.onScrollListenerDebounced);
    $(window).on('scroll', this.onScrollListenerDebounced);
    $(window).on('orientationchange', this.onScrollListenerDebounced);

  },
  onScroll: function () { // This will be called on every scroll (debounce:10ms), so keep it SUPER light! DO NOT calculate in here!
    // this.setSticky();
  },
  setSticky: function () {

    var containerTop = this.wrapperElement.offset().top;
    var scrollTop = $(window).scrollTop();
    var t = scrollTop - containerTop;
    t = Math.max(t, 0);

    if (nn.settings.currentView !== "desktop") {
      t = 0;
    }

    // this.element.css({
    // 	left: null,
    // 	top: t, // needs to be set
    // 	width: this.originalWidth + 'px'
    // });

    if (0 < t && !this.element.hasClass('is-sticky')) {
      this.element.addClass('is-sticky');
      this.triggerStickyChangeEvent();
    } else if (t === 0 && this.element.hasClass('is-sticky')) {
      this.element.removeClass('is-sticky');
      this.triggerStickyChangeEvent();
    }

  },
  triggerStickyChangeEvent: function () {
    this.element.trigger('StickyPanelTop:StickyChange', {sticky: this.element.hasClass('is-sticky')});
  }
});