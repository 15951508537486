
export default function toggler(root, config, console) {

  if (!config.fieldId) {
    console.warn("No field id is specified for the toggler function");
    return;
  }

  const fieldElement = document.getElementById(config.fieldId);
  if (!fieldElement) {
    console.warn('Specified field id is not found on the page: ', config.fieldId);
    return;
  }

  let hideClass = 'hidden';
  if (config.hideClass) {
    hideClass = config.hideClass;
  }

  fieldElement.addEventListener('focus', () => {
    root.classList.remove(hideClass);
  });

  fieldElement.addEventListener('blur', () => {
    root.classList.add(hideClass);
  });
}