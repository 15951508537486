import toggler from './toggler.js';
import moduleconfig from '../u-moduleconfig/moduleconfig';


window.app = window.app || {};
window.app.Toggler = window.app.Toggler || {};
window.app.Toggler.Index = function (root) {

  const config = moduleconfig({element: root});
  toggler(root, config, window.console);
};




//		Toggler