(function(window) {
  'use strict';

  window.app = window.app || {};
  window.app.smoothscroll = window.app.smoothscroll || {};

  /**
	 * Smooth scroll component initialization method.
	 *
	 * The smooth scroll component smoothly scrolls to the element targeted by
	 * the href of the element.
	 *
	 * Example:
	 * <a
	 *     data-module="smoothscroll"
	 *     data-config="{'duration': 500}"
	 *     href="#bar"
	 * >foo</a>
	 * ...other html...
	 * <p id="bar">bar</p>
	 *
	 * @param {object:Element} element: component element.
	 */
  window.app.smoothscroll.Index = function (element) {
    var config = window.app.helper.moduleconfig({
      element: element,
      defaults: {
        duration: 500
      }
    });

    element.addEventListener('click', click.bind(undefined, config));
  };

  /**
	 * This function is called when we're done with the scroll animation.
	 *
	 * @param {object:Element} target: target element we're scrolling to.
	 */
  function scrollComplete(target) {
    window.location.hash = target.id;
  }

  /**
	 * The click handler for the element.
	 *
	 * @param {object} config: scroll animation configuration options.
	 * @param {object:Event} event: the click event that triggered this handler.
	 */
  function click(config, event) {
    event.preventDefault();
    var href = event.target.getAttribute('href');
    if (typeof href !== 'string') {
      return;
    }

    var target = document.getElementById(href.slice(1));
    if (target) {
      window.app.helper.scrollto(target, config.duration);
      setTimeout(scrollComplete.bind(undefined, target), config.duration);
    }
  }
}(window));