(function (window, $, nn) {

  nn.component.ToggleResponsive = function (element) {

    this.COOKIE_NAME = 'is-responsive'; // constant

    this.responsiveClass = 'is-responsive'; // default
    this.traversalelement = $('body'); // default

    this.element = $(element);

    this.toMobileTekst = this.element.find('.to-mobile');
    this.toDesktopTekst = this.element.find('.to-desktop');

    this.init();
  }

  nn.component.ToggleResponsive.prototype = {

    init: function () {

      if (!nn.helpers.Util.isResponsivePage()) {
        $('.visible-mobile-special').hide();
        return;
      }

      if (this.element.data('traversalelement')) {
        this.traversalelement = $(this.element.data('traversalelement'));
      }

      if (this.element.data('responsive-class')) {
        this.RESP_CLASS = this.element.data('responsive-class');
      }

      var cookie = nn.helpers.Cookie.get(this.COOKIE_NAME);
      this.toggleResponsive(cookie === 'false');
      this.element.on('click', $.proxy(this.handleClick, this));
    },

    isResponsiveEnabled: function () {

      if (this.traversalelement.hasClass(this.responsiveClass)) {
        return true;
      }

      return false;
    },

    handleClick: function (e) {
      e.preventDefault();

      this.toggleResponsive(this.isResponsiveEnabled());
    },

    toggleResponsive: function (isResponsive) {

      if (isResponsive) {
        this.disable();
      } else {
        this.enable();
      }
    },

    enable: function () {
      this.traversalelement.addClass(this.responsiveClass);
      nn.helpers.Cookie.set(this.COOKIE_NAME, 'true', 365 * 60 * 60);
    },

    disable: function () {
      this.traversalelement.removeClass(this.responsiveClass);
      nn.helpers.Cookie.set(this.COOKIE_NAME, 'false', 365 * 60 * 60);
    }
  }

})(window, jQuery, nn);