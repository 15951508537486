import moment from 'moment';

(function (window, $, nn) {

  nn.component.Datepicker = function (element) {
    this.element = $(element);
    this.init();
  };

  nn.component.Datepicker.prototype = {
    init: function () {
      this.getAndSetOptions();
      this.getAndSetElements();
      this.bindEvents();
    },
    getAndSetOptions: function () {

      this.FORMAT_SYS = 'YYYY-MM-DD';
      this.FORMAT_DUTCH = 'DD-MM-YYYY';
      this.FORMAT_DUTCH_READABLE = 'D MMM YYYY';
      this.FORMAT_SYS_MONTH_FIRST = 'YYYY-MM-01';

      this.DATEPICKER_WIDTH = 300; // Datepicker width constant

      this.scrollDuration = 300; // The number of ms to take to do programmatic scrolls
      this.adjacentMonths = 6; // The number of months to render next to one being rendered at any time
      this.yearpickerMonths = 24; // The number of months from which a year selector shoud be displayed
      this.disabledWeekDays = []; // Container for disabled days of week. 0 = Monday, 6 = Sunday.

      // set the time to zero
      this.now = moment({hour: 0, minute: 0, second: 0, millisecond: 0});
      this.listStart = moment(this.now).subtract(50, 'years');
      this.listEnd = moment(this.now).add(50, 'years');
      this.dateFocus = this.now.clone();
      this.selected = null;

      this.updateOnListScrollDebounced = nn.helpers.debounce(this.updateOnListScroll, 100);
    },
    getAndSetElements: function () {

      var element = '<div class="datepicker-dialog"></div>',
        headerPanel = [
          '<div class="datepicker-header">',
          '<div class="datepicker-header-title">',
          '<h3 class="heading-accent datepicker-currentmonth-title" data-role="current-month"></h3>',
          '</div>',
          '<div class="datepicker-header-select">',
          '<select name="" id="" data-role="year" data-component="Select"></select>',
          '</div>',
          '</div>'
        ].join(''),
        daysPanel = [
          '<table class="datepicker-weekdays"><tr>',
          '<td class="datepicker-weekday">ma</td>',
          '<td class="datepicker-weekday">di</td>',
          '<td class="datepicker-weekday">wo</td>',
          '<td class="datepicker-weekday">do</td>',
          '<td class="datepicker-weekday">vr</td>',
          '<td class="datepicker-weekday">za</td>',
          '<td class="datepicker-weekday">zo</td>',
          '</tr></table>',
        ].join(''),
        scrollPanel = '<div class="datepicker-list"></div>';

      this.element = $(element);
      this.calendarHeaderPanel = $(headerPanel).appendTo(this.element);
      this.calendarDaysPanel = $(daysPanel).appendTo(this.element);
      this.calendarScrollPanel = $(scrollPanel).appendTo(this.element);


      // we passen glass zelf aan als dat nodig is
      this.dialog = new nn.component.Dialog({
        theme: 'datepicker',
        enableGlass: false,
        reposition: false,
        responsive: false
      });
      this.dialog.content.html(this.element);
      this.arrow = $('<div class="datepicker-arrow"></div>').appendTo(this.dialog.window);

      this.yearSelector = this.element.find('[data-role="year"]');
      this.pickerMonthTitle = this.element.find('[data-role="current-month"]');
    },
    bindEvents: function () {
      this.element.on('click', $.proxy(this.onDateClickHandler, this));
      this.calendarScrollPanel.on('scroll', $.proxy(this.updateOnListScrollDebounced, this));
      this.yearSelector.on('change', $.proxy(this.onYearChangeHandler, this));
      var self = this;
      $(window).on('dialog-open', function (event, dialogElement) {
        if (self.dialog.root.get(0) === dialogElement) {
          self.updateOnListScrollDebounced();
        }
      });
    },
    monthRenderHandler: function () {
      this.moveToDate(this.dateFocus);
    },
    onDateClickHandler: function (evt) {
      var dateCell = $(evt.target),
        dateString = dateCell.data('datepicker-date');

      if (dateCell.hasClass('datepicker-date--inactive') || !dateString) {
        return;
      }

      var chosenDate = nn.helpers.dateToMoment(dateString);

      if (moment.isMoment(chosenDate) && chosenDate.isValid()) {
        this.dateFocus = this.selected = chosenDate;
        this.element.trigger('Datepicker:Datepicked', {
          'moment': this.dateFocus
        });
        this.hide();
      }
    },
    onYearChangeHandler: function (evt) {
      var destination = this.now.year(evt.target.value).clone();
      this.moveToDate(destination);
    },
    updateOnListScroll: function () {
      var newMonthIndex = Math.round(this.calendarScrollPanel.scrollTop() / this.monthWrapperHeight),
        visibleMonth = this.calendarScrollPanel.find('[data-datepicker-month]').eq(newMonthIndex);

      if (visibleMonth.length > 0) {
        this.updateVisibleMonth(visibleMonth);
      }
    },
    updateVisibleMonth: function (newMonthElement) {
      var newMonthDate = moment(newMonthElement.attr('data-datepicker-month')),
        monthTitle = newMonthElement.find('.datepicker-month-title').text();

      this.pickerMonthTitle.text(monthTitle);
      this.yearSelector.val(newMonthDate.year()).trigger('update');
      this.renderMonth(newMonthDate);
    },
    /**
     *
     * @param {moment|string} date
     * @returns {void}
     */
    moveToDate: function (date) {
      var currentMonth = nn.helpers.dateToMoment(date),
        monthContainer;

      if (currentMonth.isBefore(this.listStart)) {
        currentMonth = this.listStart.clone().date(1);
      }

      if (currentMonth.isAfter(this.listEnd)) {
        currentMonth = this.listEnd.clone().date(1);
      }

      monthContainer = this.calendarScrollPanel.find('[data-datepicker-month="' + currentMonth.format(this.FORMAT_SYS_MONTH_FIRST) + '"]');
      if (monthContainer.length > 0) {
        var scrollTop = monthContainer.index() * this.monthWrapperHeight;

        setTimeout($.proxy(function () {
          this.calendarScrollPanel.animate({'scrollTop': scrollTop + 'px'}, this.scrollDuration, function () {
          });
        }, this), 0);
      }
    },
    /**
     * This will select the date in the dialog
     *
     * @param {type} date
     * @returns {undefined}
     */
    selectDateInCalendar: function (date) {
      var selectedDate = nn.helpers.dateToMoment(date),
        newDateElement = this.calendarScrollPanel.find('[data-datepicker-date="' + selectedDate.format(this.FORMAT_SYS) + '"]');

      this.calendarScrollPanel.find('.datepicker-date--chosen').removeClass('datepicker-date--chosen');

      if (!newDateElement.hasClass('datepicker-date--inactive')) {
        newDateElement.addClass('datepicker-date--chosen');
      }
    },
    render: function () {

      this.element.one('Datepicker:Monthrendered', $.proxy(this.monthRenderHandler, this));
      this.renderRange(this.listStart, this.listEnd);
      this.renderMonth(this.dateFocus);
      this.updateOnListScrollDebounced();
      this.rendered = true;
    },
    renderRange: function (listStart, listEnd) {
      this.yearSelector.html('');
      this.calendarScrollPanel.html('');

      var monthHtml,
        now,
        lastSelectYear;

      listStart = listStart.clone().date(1);
      listEnd = listEnd.clone().add(1, 'months').date(1).subtract(1, 'days');

      for (now = moment(listStart); !moment(now).isAfter(listEnd); now.add(1, 'month')) {
        monthHtml = '<div class="datepicker-month-wrapper" data-datepicker-month="' + now.format(this.FORMAT_SYS_MONTH_FIRST) + '">'
          + '<h3 class="datepicker-month-title heading-accent">' + now.format('MMMM YYYY') + '</h3>'
          + '</div>';

        this.calendarScrollPanel.append(monthHtml);

        ///////////////////////////////////////////////////////////////

        if (typeof lastSelectYear === 'undefined' || lastSelectYear !== now.year()) {
          this.yearSelector.append('<option value="' + now.year() + '"' + (now.isSame(this.now, 'year') ? ' selected' : '') + '>' + now.year() + '</option>');
          lastSelectYear = now.year();
        }
      }

      this.yearSelector.trigger('update');

      this.monthWrapperHeight = this.calendarScrollPanel.find('.datepicker-month-wrapper:first').height();

      var monthsRendered = listEnd.diff(listStart, 'months');
      if (monthsRendered < this.yearpickerMonths) {
        this.yearSelector.parent().addClass('hidden'); // This parent thing should be fixed in control/select
      }
    },
    renderMonth: function (month, isAdjacent) {
      var currentMonth = nn.helpers.dateToMoment(month),
        monthContainer = this.calendarScrollPanel.find('[data-datepicker-month="' + currentMonth.format('YYYY-MM-01') + '"]');

      if (!monthContainer.hasClass('datepicker-month--rendered') && !monthContainer.hasClass('datepicker-month--rendering')) {
        monthContainer.addClass('datepicker-month--rendering');
        setTimeout($.proxy(function () {
          monthContainer.append(this.getMonthHTML(currentMonth));
          monthContainer
            .removeClass('datepicker-month--rendering')
            .addClass('datepicker-month--rendered');

          this.element.trigger('Datepicker:Monthrendered', {
            'monthContainer': monthContainer,
            'monthDate': currentMonth
          });

          if (!isAdjacent) {
            this.renderAdjacentMonths(currentMonth);
          }
        }, this), 5);
      } else if (!isAdjacent) {
        this.renderAdjacentMonths(currentMonth);
      }
    },
    renderAdjacentMonths: function (month) {
      var currentMonth = nn.helpers.dateToMoment(month);

      for (var i = 1; i <= this.adjacentMonths; i++) {
        this.renderMonth(moment(currentMonth).subtract(i, 'months'), true);
        this.renderMonth(moment(currentMonth).add(i, 'months'), true);
      }
    },
    getMonthHTML: function (month) {
      var currentMonth = nn.helpers.dateToMoment(month).date(1),
        nextMonth = moment(currentMonth).add(1, 'month'),
        startPadDays = currentMonth.weekday(),
        endPadDays = (6 - moment(nextMonth).subtract(1, 'day').weekday());

      var monthHTML = '<table class="datepicker-month"><tr>' +
        this.getEmptyDatesHTML(startPadDays);

      for (; currentMonth.isBefore(nextMonth); currentMonth.add(1, 'day')) {
        if (currentMonth.weekday() === 0) {
          monthHTML += '<tr>';
        }

        monthHTML += this.getDateHTML(currentMonth);

        if (currentMonth.weekday() === 6) {
          monthHTML += '</tr>';
        }
      }

      if (endPadDays > 0) {
        monthHTML += this.getEmptyDatesHTML(endPadDays) + '</tr>';
      }

      monthHTML += '</table>';

      return monthHTML;
    },
    getEmptyDatesHTML: function (days) {
      var out = '';
      for (days > 0; days > 0; days--) {
        out += this.getDateHTML(null);
      }

      return out;
    },
    getDateHTML: function (date) {

      if (!date) {
        return '<td class="datepicker-date--inactive">&nbsp;</td>';
      }
      var currentDay = nn.helpers.dateToMoment(date),
        disabledWeekDaysString = ':' + this.disabledWeekDays.join(':') + ':',
        weekDayNeedle = ':' + currentDay.weekday() + ':',
        isToday = moment().isSame(currentDay, 'day'),
        isChosen = currentDay.isSame(this.selected, 'day'),
        isInactive = false,
        dayNotation = ' data-datepicker-date="' + currentDay.format(this.FORMAT_SYS) + '"',
        dayClass = '',
        classAttr = [];

      if (0 < this.disabledWeekDays.length && -1 !== disabledWeekDaysString.indexOf(weekDayNeedle)) {
        isInactive = true;
      }

      if (currentDay.isBefore(this.listStart) || currentDay.isAfter(this.listEnd)) {
        isInactive = true;
      }

      if (isToday) {
        classAttr.push('datepicker-date--today');
      }

      if (isChosen) {
        classAttr.push('datepicker-date--chosen');
      }

      if (isInactive) {
        classAttr.push('datepicker-date--inactive');
      }

      if (classAttr.length > 0) {
        dayClass = ' class="' + classAttr.join(' ') + '"';
      }

      return [
        '<td',
        dayNotation,
        dayClass,
        '>',
        currentDay.date(),
        '</td>'
      ].join('');
    },
    repositionDialog: function (datepickerIcon) {

      if (nn.helpers.isTouchDevice() && this.dialog.visible) {
        //				this.hide();
      } else if (!nn.helpers.isTouchDevice() && this.dialog.visible) {
        this.showDialog(datepickerIcon, false);
      }
    },
    // External API methods
    show: function (datepickerIcon) {

      this.render();
      nn.initializeComponents(this.element);
      this.showDialog(datepickerIcon);
      this.updateOnListScroll();
      this.monthWrapperHeight = this.calendarScrollPanel.find('.datepicker-month-wrapper:first').height();

      this.isOpen = true;
    },
    hide: function () {
      nn.globals.glass.hide();
      this.dialog.hide();

      this.isOpen = false;
    },
    setField: function (field) {
      this.dateField = field;
    },
    setOptions: function (options) {
      if (options.now) {
        this.now = nn.helpers.dateToMoment(options.now);
        this.dateFocus = this.now;
      }
      if (options.dateStart) {
        this.listStart = nn.helpers.dateToMoment(options.dateStart);
      }
      if (options.dateEnd) {
        this.listEnd = nn.helpers.dateToMoment(options.dateEnd);
      }
      if (options.dateFocus) {
        this.dateFocus = nn.helpers.dateToMoment(options.dateFocus);
      }
      if (options.dateSelected) {
        this.selected = nn.helpers.dateToMoment(options.dateSelected);
        this.dateFocus = nn.helpers.dateToMoment(this.selected);
      }

      if (options.disabledWeekDays) {
        this.disabledWeekDays = options.disabledWeekDays;
      }

      if (this.now.isBefore(this.listStart)) {
        this.now = this.listStart.clone();
      }

      if (this.now.isAfter(this.listEnd)) {
        this.now = this.listEnd.clone();
      }
    },
    showDialog: function (datepickerIcon, fadeOverride) {

      nn.globals.glass.show({clear: true});
      var iconOffset = datepickerIcon.offset(),
        pageOffset,
        left = 0,
        fade = true;

      if (fadeOverride) {
        fade = fadeOverride;
      }

      if ($('#page').length) {
        pageOffset = $('#page').offset();
      } else {
        pageOffset = $('body').offset();
      }

      left = this.computeLeftDesktopPosition(datepickerIcon);
      var top = iconOffset.top - 30,
        fieldElementHeight = 40,
        arrowHeight = 10,
        spaceLeft = $(window).width() - left;

      var arrowLeft = 'auto';
      if (spaceLeft > this.DATEPICKER_WIDTH) {
        this.dialog.root.removeClass('dialog--datepicker-pos-bottom');
        this.dialog.show(left, top, {fade: fade});
        this.arrow.css('left', arrowLeft);
      } else {

        this.dialog.root.addClass('dialog--datepicker-pos-bottom');
        left = this.computeLeftMobilePosition(this.dateField.element, datepickerIcon, pageOffset);
        top = this.dateField.element.offset().top + fieldElementHeight + arrowHeight;

        if (this.dateField.element.width() < this.DATEPICKER_WIDTH) {
          top = datepickerIcon.offset().top + datepickerIcon.height() + arrowHeight;
          arrowLeft = (datepickerIcon.offset().left - left) - 2;
        }
        this.arrow.css('left', arrowLeft);
        this.dialog.show(left, top, {fade: fade});
      }
    },
    computeLeftDesktopPosition: function (datepickerIcon) {
      var arrowWidth = 15,
        iconOffset = datepickerIcon.offset();

      return (iconOffset.left + datepickerIcon.width() + arrowWidth);
    },
    computeLeftMobilePosition: function (fieldElement) {

      var leftPosFieldElement = fieldElement.offset().left,
        viewportWidth = $(window).width(),
        spaceLeft = (viewportWidth - this.DATEPICKER_WIDTH) / 2;

      if (viewportWidth < 610) {
        return Math.min(leftPosFieldElement, spaceLeft);
      }
      return leftPosFieldElement;


    }
  };
})(window, jQuery, nn);
