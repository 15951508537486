import http from '../app-utilities/http';
import loadStyleSheet from '../utility/loadStyleSheet';
import loadScript from '../utility/loadscript';
import { createTag } from '../utility/createTag';

/**
 * The instruction file declaring what and how needs to be loaded for the module to work.
 * @typedef {Object} Instruction
 * @property {(String| { url: string ,[key: string]: string })[]} css - The stylesheet urls or link attributes to load.
 * @property {(String| { url: string ,[key: string]: string })[]} links - The stylesheet urls or link attributes to load.
 * @property {(String| { url: string ,[key: string]: string })[]} scripts - The script urls or script attributes to load.
 */

/**
 * Loads the instruction object and creates the appropriated tags
 * @param {Instruction} instruction The instruction object to load
 */
const loadInstruction = async (instruction) => {
  const links = [...(instruction.css ? instruction.css : []), ...(instruction.links ? instruction.links : [])];
  if (links.length > 0) {
    await links.reduce((previousPromise, nextCss) => {
      return previousPromise.then(() => {
        if (typeof(nextCss) === 'string') {
          return loadStyleSheet(nextCss);
        }
        const { url, ...attributes } = nextCss;
        return createTag('link', url, attributes, { allowBlockedDomains: true });
      });
    }, Promise.resolve());
  }

  if (instruction.scripts && instruction.scripts.length > 0) {
    await instruction.scripts.reduce((previousPromise, nextScript) => {
      return previousPromise.then(() => {
        if (typeof(nextScript) === 'string') {
          return loadScript(nextScript);
        }
        const { url, ...attributes } = nextScript;
        return createTag('script', url, attributes);
      });
    }, Promise.resolve());
  }
}
/**
 * Fetches and loads the instructions
 * @param {string} instructionUrl The url where to fetch the instructions object
 * @returns {Promise<boolean>} Returns true if it the instruction was loaded successfully
 */
const load = async (instructionUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!instructionUrl) {
        return;
      }
      const instruction = await getInstructionBy(instructionUrl);
      await loadInstruction(instruction);
      resolve();
    } catch (err) {
      reject(err);
    }
  });
}
/**
 * Fetchs the instruction object from the url by using a cache bust parameter
 * @param {string} instructionUrl The url from where to fetch the data from
 * @returns {Promise<Instruction>}
 */
const getInstructionBy = async (instructionUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      const instruction = await http.get(instructionUrl + '?now=' + new Date().getTime());
      resolve(instruction);
      return instruction;
    } catch (err) {
      reject(err);
    }
  });
}

export default {
  load,
};
