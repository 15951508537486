import { lineStyles } from './chartStyles.js';

export default class Chart {

  constructor (element) {
    this.element = element;
    if (!window.nn.helpers.libLoader) {
      window.nn.handlers.init.libLoader()
    }
    window.nn.helpers.libLoader.load('//www.google.com/jsapi', this.chartsApiLoad.bind(this));
  }

  chartsApiLoad () {
    if (typeof window.google.visualization === 'undefined') {
      window.google.load('visualization', '1', {
        'packages': ['corechart'],
        'callback': this.initGraph.bind(this),
        'language': 'nl',
        'locale': 'nl_NL'
      });
    } else {
      this.initGraph();
    }
  }

  initGraph () {
    this.setOptions();
    this.bindEvents();
    this.drawChart();
  }

  setOptions () {
    this.graphType = 'line';
    this.graphLabels = [];
    this.graphData = [];
    this.defaultSliceStyles = [
      "graph-type-46", "graph-type-67", "graph-type-56", "graph-type-47", "graph-type-52",
      "graph-type-57", "graph-type-48", "graph-type-53", "graph-type-49"
    ];
    this.graphOptions = {
      annotations: {},
      hAxis: {},
      vAxis: { gridlines: {} },
      fontName: 'Nitti Grotesk NN Text, Verdana',
      series: [],
      slices: [],
      legend: { position: 'none' },
      curveType: 'function',
      enableInteractivity: false,
      bar: {},
      isStacked: false,
      chartArea: { left: '25%', top: 10, width: '100%', height: '60%' }
    };
    this.dataTable = new window.google.visualization.DataTable();

    if (this.element.dataset.chartConfig) {
      this.processConfig(this.element.dataset.chartConfig);
    }
  }

  processConfig (config) {
    config = Object.assign({
      'legend': false,
      'graphType': 'line',
      'curveType': 'function',
      'tooltip': true,
      'columnStacked': false,
      'yAxisPrefix': '',
      'yAxisSuffix': '',
      'yAxisFormat': '',
      'referenceLine': '',
      'referenceAnnotation': '',
      'showTextEvery': '',
    }, JSON.parse(config));

    this.graphLabels = config.labels || [];
    this.graphOptions.hAxis.showTextEvery = config.showTextEvery || null;
    this.aspectRatio = config.aspectRatio || null;
    this.graphType = config.graphType || null;

    if (typeof config.legend === 'object') {
      this.graphOptions.legend = config.legend;
    } else if (config.legend) {
      this.graphOptions.legend = { position: 'right' };
    }

    this.graphOptions.curveType = config.curveType || null;
    this.graphOptions.enableInteractivity = config.tooltip || null;
    this.graphOptions.isStacked = config.columnStacked || false;
    if (config.columnStacked) {
      this.graphOptions.bar.groupWidth = "100%";
    }
    this.graphOptions.vAxis = config.vAxis || { format: '#' };
    this.graphOptions.vAxis.format = config.yAxisFormat || '#';
    this.graphOptions.vAxis.format = config.yAxisPrefix + ' ' + this.graphOptions.vAxis.format || null;

    // We need to escape the yAxisSuffix: otherwise a %-character
    // leads to unwanted behaviour (y-Axis value multiplied by 100)
    this.graphOptions.vAxis.format = this.graphOptions.vAxis.format + (config.yAxisSuffix && config.yAxisSuffix === '%' ? ' \'' + config.yAxisSuffix + '\'' : config.yAxisSuffix);
    this.yAxisSuffix = config.yAxisSuffix || null;
    this.yAxisPrefix = config.yAxisPrefix || null;

    if (document.body.getBoundingClientRect().width >= 500) {
      this.graphOptions.chartArea.left = '15%';
    }

    this.dataTable.addColumn('string', 'X');

    if (config.graphType === 'pie') {
      // PIE Chart
      this.element.classList.add('c-chart--pie');
      this.graphOptions.chartArea = {
        top: 12,
        left: 12,
        width: "85%",
        height: "85%"
      };
      this.sliceStyles = config.sliceStyles || this.defaultSliceStyles;
      this.addSliceStyles(this.sliceStyles);

    } else if (config.graphType === 'line') {
      // LINE Chart
      this.element.classList.add('c-chart--line');
      this.aspectRatio = this.aspectRatio || 1 / 0.39;

    } else if (config.graphType === 'column') {
      // COLUMN Chart
      this.element.classList.add('c-chart--column');
      this.aspectRatio = this.aspectRatio || 1 / 0.9;

      // FIXME, these defaults are important for WIA:
      // https://www.nn.nl/Zakelijk/Inkomensverzekeringen/Inkomen-bij-arbeidsongeschiktheid.htm
      this.graphOptions.chartArea = config.chartArea || { top: 0, left: 0, width: "85%", height: "85%" };
      // --end

      this.referenceLine = config.referenceLine || null;
      this.referenceAnnotation = config.referenceAnnotation || null;
      this.graphOptions.vAxis.viewWindowMode = 'maximized';
      this.graphOptions.annotations.alwaysOutside = false;
    }

    for (let i = 0, c = config.data.length; i < c; i++) {
      this.dataTable.addColumn('number', config.data[i].name);
      // if we have annotation add a column
      if (config.data[i].annotations) {
        this.dataTable.addColumn({ type: 'string', role: 'annotation' });
      }

      this.addLineStyle(lineStyles[config.data[i].lineStyle]);
      this.addPoints(config.data[i]);
    }
    this.dataTable.addRows(this.graphData);
  }

  bindEvents () {
    window.addEventListener('resize', () => this.drawChart());
  }

  drawChart () {
    if (this.element.offsetParent === null) {
      return;
    } // Don't bother rendering if this instance is not visible

    if (this.graphType === 'line') {
      // LINE Chart
      const dataView = new window.google.visualization.DataView(this.dataTable);
      dataView.setColumns([{
        calc (data, row) {
          return data.getFormattedValue(row, 0);
        }, type: 'string'
      }, 1]);

      if (this.yAxisSuffix === '%') {
        const formatter = new google.visualization.NumberFormat({ 'fractionDigits': 2, 'suffix': '%' });
        formatter.format(this.dataTable, 1); // Apply formatter to second column
      }

      this.setAspectRatio();
      this.chart = new window.google.visualization.LineChart(this.element);

    } else if (this.graphType === 'pie') {
      // PIE Chart
      this.chart = new window.google.visualization.PieChart(this.element);

    } else if (this.graphType === 'column') {
      // COLUMN Chart
      if (this.yAxisPrefix === '€') {
        this.formatMultipleColumns('#.##', ',', '.', '€');
      }

      this.setAspectRatio();
      this.chart = new window.google.visualization.ColumnChart(this.element);
      this.graphOptions.bar = { groupWidth: "100%", gap: 25 };
    } else {
      this.chart = null;
    }

    if (this.referenceLine) {
      window.google.visualization.events.addListener(this.chart, 'ready', () => this.addReferenceline());
    }
    if (this.graphOptions.isStacked) {
      google.visualization.events.addListener(this.chart, 'ready', () => this.addPixel());
    }

    if (this.chart) {
      this.chart.draw(this.dataTable, this.graphOptions);
    } else {
      window.console.log('Graphtype not set.');
    }
  }

  formatMultipleColumns (format, decimalSymbol, groupingSymbol, prefix) {
    const formatter = new google.visualization.NumberFormat({
      'format': format,
      'decimalSymbol': decimalSymbol,
      'groupingSymbol': groupingSymbol,
      'prefix': prefix
    });
    const range = [...Array(this.dataTable.getNumberOfColumns()).keys()]
    range.forEach(col => {
      formatter.format(this.dataTable, col);
    });
  }

  setAspectRatio () {
    if (this.aspectRatio) {
      const { width } = this.element.getBoundingClientRect();
      this.element.style.height = `${width / this.aspectRatio}px`;
    }
  }

  addPoints (lineData) {
    const { points, annotations } = lineData;
    for (let i = 0; i < points.length; i++) {
      // if row does not exist we should first append the label to the row
      if (!this.graphData[i]) {
        this.graphData[i] = [this.graphLabels[i]];
      }

      this.graphData[i].push(points[i]);
      if (annotations) {
        this.graphData[i].push(annotations[i]);
      }
    }
  }

  addLineStyle (lineStyle) {
    this.graphOptions.series.push(lineStyle);
  }

  addSliceStyles (sliceStyles) {
    for (let i = 0; i < sliceStyles.length; i++) {
      this.graphOptions.slices.push(lineStyles[sliceStyles[i]]);
    }
  }

  addReferenceline () {
    const cli = this.chart.getChartLayoutInterface();

    const reference = document.createElement('div');
    reference.classList.add('c-chart-overlay-reference');
    reference.style.top = `${Math.floor(cli.getYLocation(this.referenceLine))}px`;
    const overlaymarker = document.createElement('span');
    overlaymarker.classList.add('c-chart-overlay-label');
    overlaymarker.textContent = this.referenceAnnotation;
    reference.appendChild(overlaymarker);
    this.overlaymarker = overlaymarker;
    this.element.appendChild(reference);
  }

  addPixel () {
    // this is a hack for vertical white lines in firefox
    [...this.element.querySelectorAll('rect')]
      .forEach(element => {
        element.style.width = `${element.getBoundingClientRect().width + 0.5}px`;
      });
  }
}
