const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export function set(name, value, days) {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * MILLISECONDS_IN_DAY);
    expires = '; expires=' + date.toGMTString();
  }

  document.cookie = name + '=' + value + expires + '; path=/';
}

export function get(name) {
  const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
  if (match) {
    return match[1];
  }
  return null;
}

export function remove(name) {
  set(name, '', -1);
}

export default { set, get, remove };
