(function (window, $, nn) {

  nn.component.EqualHeights = function (element) {
    this.element = $(element);
    this.init();
  };

  nn.component.EqualHeights.prototype = {
    init: function () {
      this.getAndSetOptions();
      this.getAndSetElements();
      this.bindEvents();
      this.getDevice();
    },
    getAndSetOptions: function () {
      this.element.addClass("component-equalheights");
    },
    getAndSetElements: function () {
      this.blockElement = $(this.element).data("element");
      this.blockCta = $(this.element).data("element-cta");
      this.blockException = $(this.element).data("element-equal");
      this.equalCap = "2";
      // short versions;
      this.items = this.element.find(this.blockElement);
      this.amountEquals = this.element.find(this.blockException).size();
    },
    bindEvents: function () {
      $(window).on('load', nn.helpers.debounce($.proxy(this.getDevice, this), 50));
      $(window).on('resize', $.proxy(this.onWindowResize, this));

    },
    getDevice: function () {
      if (nn.settings.currentView !== 'desktop') {
        this.destructElement();
      } else {
        this.constructElement();
      }
    },
    destructElement: function () {
      $(this.blockCta).removeClass("absolute");
      this.items.css({'padding-bottom': '15px', 'min-height': '0'});
    },
    constructElement: function () {
      var _this = this;
      this.element.find('.col-one-whole').each(function () {
        if ($(this).find(_this.blockCta).length > 0) {
          _this.blockCtaHeight = $(_this.blockCta).outerHeight();
          $(this).find(_this.blockCta).addClass("absolute");
          $(this).find(_this.blockElement).css({"padding-bottom": _this.blockCtaHeight});
        } else {
          $(this).find('p:last-child, ul:last-child').css({'margin-bottom': '0'});
        }
      });
      this.equalHeights();
    },
    equalHeights: function () {
      // reset height
      this.items.css('min-height', '0');

      var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = [];

      this.element.find('.col-one-whole').each(function () {
        var $el = $(this);
        var topPosition = $el.position().top;
        var currentDiv;

        if (currentRowStart !== topPosition) {

          for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].find('.block').css({'min-height': currentTallest});
          }
          rowDivs.length = 0;
          currentRowStart = topPosition;
          currentTallest = parseInt($el.find('.block').outerHeight());
          rowDivs.push($el);

        } else {
          rowDivs.push($el);
          currentTallest = (currentTallest < parseInt($el.find('.block').outerHeight())) ? (parseInt($el.find('.block').outerHeight())) : (currentTallest);

        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
          rowDivs[currentDiv].find('.block').css({'min-height': currentTallest});
        }

      });
    },
    onWindowResize: function () {
      this.getDevice();
    }
  };
})(window, jQuery, nn);