/**
 *
 * Dots 'n' Digits Formatter
 *
 * @param {HTMLNode} element
 * @returns {void}
 */
nn.component.DotsDigitsFormatter = function (element) {
  this.element = $(element);
  this.init();
  this.cmd = false;
};

/**
 *
 * @type type
 */
nn.component.DotsDigitsFormatter.prototype = {
  init : function () {
    this.element
      .on('keydown', $.proxy(this.onKeydownHandler, this));
  },
  onKeydownHandler : function (e) {
    var kc_backspace = 8,
      kc_tab = 9,			// key = tab
      kc_delete = 46,		// key = delete
      kc_period = 190,	// key = period
      kc_c = 67,			// key = c
      kc_v = 86,			// key = v
      kc_cmd = 91,		// key = cmd
      kc_ctrl = 17;		// key = ctrl

    // check if ctrl or cmd is pressed
    if (e.keyCode === kc_cmd || e.keyCode === kc_ctrl) {
      this.cmd = true;
    }

    if( e.keyCode === kc_backspace
			|| e.keyCode === kc_tab
			|| e.keyCode === kc_delete
			|| e.keyCode === kc_period
			|| e.keyCode === kc_cmd
			|| nn.helpers.isArrowKeyCode(e)
			|| nn.helpers.isNumericKeyCode(e)
			|| (this.cmd === true && e.keyCode === kc_v)
			|| (this.cmd === true && e.keyCode === kc_c)) {
      // valid characters or valid key combinations (ctrl-c ctrl-v cmd-c cmd-v)
    } else {
      // invalid character
      e.preventDefault();

    }
  }
};