/**
 * Events - Retrieve event names for current browser
 */
(function(window) {
  'use strict';

  var eventNames = {};

  window.app = window.app || {};
  window.app.Events = function(event) {
    return eventNames[event] || getEventName(event);
  };

  function getEventName(event) {
    var eventName = false;

    if (event === 'transitionEnd') {
      var el = document.createElement('div'),
        transEndEventNames = {
          WebkitTransition: 'webkitTransitionEnd',
          MozTransition: 'transitionend',
          OTransition: 'oTransitionEnd otransitionend',
          transition: 'transitionend'
        };

      for (var name in transEndEventNames) {
        if (el.style[name] !== undefined) {
          eventName = eventNames[event] = transEndEventNames[name];
        }
      }
    }

    return eventName;
  }

}(window));