/**
 * Dialog - Overlay window
 *
 * Example usage:

// Create the new dialog
const dialog = new Dialog(<creation options>);

// Set its HTML contents to the raw string provided.
dialog.setContent(<html string>);

// Show the dialog using the options provided.
dialog.show(<position options>);

options.left // Left offset in pixels
options.top // Top offset in pixels

options.arrow.side // Arrow position to the dialog. Can be top, right, bottom, left
options.arrow.top // Arrow.position from the top of the dialog. Applies to options.arrow.pos right and left
options.arrow.left // Arrow.position from the left of the dialog. Applies to options.arrow.pos top and bottom

// Append existing nodes content
dialog.appendContent(<DOM nodes>);

dialog.hide();

 */

import template from "./template/dialog.tpl.html";
import { determineSide, updateArrowConfig } from "./dialog.helpers.js";
import {setContent, appendNodes} from "../utility/html-update-helpers";
import scrollHelper from "../utility/scroll.helper";
import * as a11y from "../utility/a11y";
import stickyService from "../utility/stickyService";
import {KEY_ESCAPE} from "../utility/keyboardKeys";

const MODAL_OPEN = 'is-modal-open';
const CONTEXT_MODAL_OPEN = 'is-modal-open--context';
const STATE_CLOSED = "closed";
const STATE_CLOSING = "closing";
const STATE_OPENED = "opened";
const STATE_OPENING = "opening";

export default class Dialog{
  constructor(settings = {}){
    this.settings = Object.assign({
      stateChangeHandler: undefined,
      closeOnBlueriqStateChange: false,
      locked: false,
      closeOnGlassClick: false,
      closeHandler: undefined,
      themes: [],
    }, settings);

    const dialogTemplate = this.createDialogTemplate(this.settings);
    this.contentElement = dialogTemplate.querySelector('[data-role="content"]');
    this.closeBtnElement = dialogTemplate.querySelector('[data-role="close-btn"]');
    this.arrowElement = dialogTemplate.querySelector('[data-role="arrow"]');
    this.arrowTipElement = dialogTemplate.querySelector('[data-role="arrow-tip"]');
    this.dialogElement = dialogTemplate.querySelector('[data-role="dialog"]');
    this.glassElement = dialogTemplate.querySelector('[data-role="glass"]');

    this.glassElement.remove();

    this.glassElement.dataset.state = STATE_CLOSED;

    this.glassElement.addEventListener('click', this._modalClickHandler.bind(this));
    this.glassElement.addEventListener('transitionend', this._transitionEndHandler.bind(this));
    this.glassElement.addEventListener('keydown',(evt) => {
      if (evt.keyCode === KEY_ESCAPE && this.glassElement.dataset.state === STATE_OPENED) {
        this.glassElement.click();
      }
    });

    if (this.settings.closeOnBlueriqStateChange) {
      window.radio('blueriq.state-change').subscribe(() => this.onBlueriqStateChange());
    }
  }

  createDialogTemplate (settings) {
    const div = document.createElement('div');

    const replacedTemplate = template.replace('{{closebutton}}', (!settings.locked) ?
      '<a href="#" data-role="close-btn" role="button" aria-label="icoon om dialoog te sluiten" class="c-modal__close-btn c-icon c-icon--close--thin"></a>' :
      ''
    );
    div.innerHTML = replacedTemplate;
    const modal = div.querySelector('.c-modal');
    settings.themes.forEach(theme => modal.classList.add('c-modal--' + theme));
    return div;
  }

  /**
	 * Updates dialog state and broadcasts the update.
	 */
  setState(state) {
    this.glassElement.dataset.state = state;

    const update = {
      element: this.dialogElement,
      state: this.glassElement.dataset.state
    };

    if (typeof this.settings.stateChangeHandler === 'function') {
      this.settings.stateChangeHandler(update);
    }

    window.radio('modal.state-change').broadcast(update);
  }

  /**
	 * Show the dialog.
	 * @param {Object} dialog positioning options
	 * @returns {undefined}
	 */
  show(options, initiator) {
    options = options || {};

    if (!this.isAttached()) {
      this.dialogElement.classList.remove('c-modal__dialog--positioned');
      this.dialogElement.removeAttribute('style');
      this.glassElement.removeAttribute('data-arrow-position');
      this.glassElement.removeAttribute('data-arrow-align');
      document.body.appendChild(this.glassElement);
    }
    window.requestAnimationFrame(() => {
      this.setState(STATE_OPENING);
      this.reposition(options, initiator);
      nn.initializeComponents(this.contentElement);
    });

    const hasGlassBackground = this.glassElement.classList.contains('c-modal--context');
    if(!hasGlassBackground){
      a11y.a11yDialog(this.dialogElement);
    }
  }

  /**
   * Repositions the dialog
   * @param {Object} dialog positioning options
   * @returns {undefined}
	 */
  reposition(options, initiator) {
    options = options || {};
    const initialOptions = { ...options };
    if (options.arrow) {
      const newSide = determineSide(this.dialogElement, options.side, options.arrow);

      if (newSide !== options.side) {
        initialOptions.side = newSide;
        updateArrowConfig(initiator, initialOptions);
      }
    }

    this.arrowElement.removeAttribute('style');

    if (initialOptions.arrow && initialOptions.arrow.x) {
      document.body.classList.add(CONTEXT_MODAL_OPEN);
      this._positionDialogByArrowPoint(initialOptions);
    } else if (initialOptions.left && initialOptions.top) {
      document.body.classList.add(CONTEXT_MODAL_OPEN);
      this._positionDialogByPos(initialOptions);
    } else {
      if(scrollHelper.isBodyOverflowing()) {
        document.body.style.paddingRight = `${scrollHelper.getScrollbarWidth()}px`;
      }
      document.body.classList.add(MODAL_OPEN);
    }
  }

  /**
	 * Hide the dialog.
	 * @returns {undefined}
	 */
  hide() {
    document.body.style.paddingRight = null;
    document.body.classList.remove(MODAL_OPEN);
    document.body.classList.remove(CONTEXT_MODAL_OPEN);
    a11y.a11yDialogRemove(this.dialogElement);
    this.setState(STATE_CLOSING);
  }

  onBlueriqStateChange() {
    if (this.isOpen() || this.isOpening()) {
      this.hide();
    }
  }

  /**
	 * Returns whether the dialog is currently attached to the DOM.
	 * @returns {Boolean}
	 */
  isAttached(){
    return Boolean(this.glassElement.parentNode);
  }

  /**
	 * Returns whether the dialog is currently closing.
	 * @returns {Boolean}
	 */
  isClosing() {
    return this.glassElement.dataset.state === STATE_CLOSING;
  }

  /**
	 * Returns whether the dialog is currently open.
	 * @returns {Boolean}
	 */
  isOpen() {
    return this.glassElement.dataset.state === STATE_OPENED;
  }

  /**
	 * Returns whether the dialog is currently opening.
	 * @returns {Boolean}
	 */
  isOpening() {
    return this.glassElement.dataset.state === STATE_OPENING;
  }

  /**
	 * Returns whether the dialog is currently closed.
	 * @returns {Boolean}
	 */
  isClosed() {
    return this.glassElement.dataset.state === STATE_CLOSED;
  }

  /**
	 * Sets dialog content.
	 *
	 * @param {String} htmlContent
	 * @returns {undefined}
	 */
  setContent(content) {
    setContent(this.contentElement, content, this.isAttached());

    const heading = this.contentElement.querySelector('h1,h2,h3,h4');
    if (heading) {
      heading.setAttribute('aria-labelledby', 'modalDialogTitle');
    }

    return this.contentElement;
  }

  /**
	 * Append nodes to the dialog.
	 *
	 * @param {Array|DOMNodeList} nodes
	 * @returns {undefined}
	 */
  appendContent(nodes, applyBlueriqFix=true) {
    appendNodes(this.contentElement, nodes, this.isAttached(), applyBlueriqFix);
  }

  /**
	 * Position the dialog by "pointing to" something, then calculating the
	 * position relative to the tip of that arrow. The following options can be
	 * provided:
     *
	 * options.arrow.side; the side of the dialog the arrow appears on, one of:
	 * 'left', 'right', 'top', 'bottom'
	 *
	 * options.arrow.align; the alignment of the arrow, one of:
	 * 'left', 'right', 'top', 'bottom', 'middle'
	 * Align 'left' and 'right' only make sense with side 'top' and 'bottom'.
	 * Likewise, align 'top' and 'bottom' only makes sense with side set to
	 * 'left' or 'right'.
     *
	 * options.arrow.x, options.arrow.y: the [x,y] coordinate to point to.
	 *
	 * Obligatory Unicode art displaying the outcome of the different side;align
	 * combinations.
	 *
	 *      ╱╲                            ╱╲                            ╱╲
	 *  ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
	 *  ┃   top;left                   top;middle                 top;right  ┃
	 *  ┃                                                                    ┃
	 * ╱┃          ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓          ┃╲
     * ╲┃left;top  ┃Dialog contents go here                      ╳┃ right;top┃╱
	 *  ┃          ┃                                              ┃          ┃
	 *  ┃          ┃                                              ┃          ┃
	 *  ┃          ┃                                              ┃          ┃
	 *  ┃          ┃                                              ┃          ┃
	 * ╱┃          ┃                                              ┃          ┃╲
     * ╲┃left;top  ┃                                              ┃ right;top┃╱
	 *  ┃          ┃                                              ┃          ┃
	 *  ┃          ┃                                              ┃          ┃
	 *  ┃          ┃                                              ┃          ┃
	 *  ┃          ┃                                              ┃          ┃
	 * ╱┃          ┃                                              ┃          ┃╲
     * ╲┃left;top  ┃                                              ┃ right;top┃╱
	 *  ┃          ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛          ┃
	 *  ┃                                                                    ┃
	 *  ┃   bottom;left              bottom;middle             bottom;right  ┃
	 *  ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
	 *      ╲╱                            ╲╱                            ╲╱
	 *
	 *
	 * @param {Object} options
	 * @returns {undefined}
	 */
  _positionDialogByArrowPoint(options) {
    const side = options.arrow.side;
    const align = options.arrow.align;

    this.dialogElement.classList.add('c-modal__dialog--positioned');
    this.glassElement.dataset.arrowPosition = side;

    const pageWidth = document.body.scrollWidth;

    const dialogRect = this.dialogElement.getBoundingClientRect();
    const dialogHeight = dialogRect.height;
    const dialogWidth = dialogRect.width;

    let dialogTop = 0;
    let dialogLeft = 0;

    const arrowRect = this.arrowElement.getBoundingClientRect();
    const arrowHeight = arrowRect.height;
    const arrowWidth = arrowRect.width;

    const arrowTopMin = 0;
    const arrowTopMax = dialogHeight - arrowHeight;
    const arrowLeftMin = 0;
    const arrowLeftMax = dialogWidth - arrowWidth;
    let arrowTop = '';
    let arrowLeft = '';

    const arrowTipRect = this.arrowTipElement.getBoundingClientRect();

    if (side === 'left') {
      dialogLeft = options.arrow.x + arrowTipRect.width;
    } else if (side === 'right') {
      dialogLeft = options.arrow.x - arrowTipRect.width - dialogWidth;
    } else if (side === 'top') {
      dialogTop = options.arrow.y + arrowTipRect.height;
    } else if (side === 'bottom') {
      dialogTop = options.arrow.y - arrowTipRect.height - dialogHeight;
    }

    if (side === 'left' || side === 'right') {
      if (!align || align === 'top') {
        arrowTop = arrowTopMin;
      } else if (align === 'middle') {
        arrowTop = (dialogHeight / 2 - arrowHeight / 2);
      } else if (align === 'bottom') {
        arrowTop = arrowTopMax;
      }

      dialogTop = options.arrow.y - arrowHeight / 2 - arrowTop;
    }

    if (side === 'top' || side === 'bottom') {
      if (!align || align === 'left') {
        arrowLeft = arrowLeftMin;
      } else if (align === 'middle') {
        arrowLeft = (dialogWidth / 2 - arrowWidth / 2);
      } else if (align === 'right') {
        arrowLeft = arrowLeftMax;
      }

      dialogLeft = options.arrow.x - arrowWidth / 2 - arrowLeft;
    }

    const dialogLeftFinal = Math.min(pageWidth - Math.min(dialogWidth, pageWidth), Math.max(0, dialogLeft));
    if (dialogLeftFinal !== dialogLeft && (side === 'top' || side === 'bottom')) {
      arrowLeft = options.arrow.x - dialogLeftFinal - arrowWidth / 2;
      arrowLeft = Math.min(arrowLeftMax, Math.max(arrowLeftMin, arrowLeft));
    }

    Object.assign(this.arrowElement.style, {
      top: typeof arrowTop === 'number' ? `${arrowTop}px` : '',
      left: typeof arrowLeft === 'number' ? `${arrowLeft}px` : ''
    });

    this._positionDialogByPos({left: dialogLeftFinal, top: dialogTop});
  }

  /**
	 * Position the dialog based on "left" and "top" coordinates. It also adds scroll offset
	 *
	 * @param {Object} options
	 * @returns {undefined}
	 */
  _positionDialogByPos(options) {
    let posLeft = 'auto';
    if (typeof options.left === 'number' || String(options.left).endsWith('px')) {
      posLeft = Math.max(0, ~~parseInt(options.left)) + 'px';
    }

    this.dialogElement.classList.add('c-modal__dialog--positioned');
    this.dialogElement.style.left = posLeft;
    this.dialogElement.style.top = `${options.top}px`;
    this._hideDialogWhenOverflowingHeader(options.top);
  }

  _hideDialogWhenOverflowingHeader(top) {
    this.dialogElement.style.visibility = (top <= stickyService.getTopOffset()) ? 'hidden' : 'visible';
  }

  /**
	 * The transition handler that fires as soon as the glass is done animating.
	 *
	 * @returns {undefined}
	 */
  _transitionEndHandler() {
    if (this.glassElement.dataset.state === STATE_OPENING) {
      this.setState(STATE_OPENED);
    } else if (this.glassElement.dataset.state === STATE_CLOSING) {
      this.glassElement.remove();
      this.setState(STATE_CLOSED);
    }
  }

  /**
	 * Click handler that closes the dialog.
	 *
	 * @returns {undefined}
	 */
  _modalClickHandler(evt) {
    // Locked modals can only be closed programmatically by calling hide()..
    if (this.settings.locked) {
      return;
    }

    const role = evt.target.dataset.role;
    const glassClicked = role && role.includes('glass');
    const closeClicked = role && role.includes('close-btn');

    if (this.settings.closeOnGlassClick && glassClicked || closeClicked) {
      evt.preventDefault();
      if (this.settings.closeHandler) {
        this.settings.closeHandler(evt);
      } else {
        this.hide();
      }
    }
  }
}
