nn.component.Dateresponsive = function (element) {

  var self = this;
  this.$element = $(element);
  this.id = this.$element.attr('id');

  // Don't activate state-changing abilities on a non-responsive page
  if (!nn.helpers.Util.isResponsivePage()) {
    return;
  }

  // Prepare both HTML states
  self.prepareSmallState();

  this.isSyncedToMobile = false;
  this.isSyncedToDesktop = false;

  $(window).on('resize', function () {
    self.toggleState();
  });

  self.toggleState();
};

/**
 * Prepare HTML small state
 */
nn.component.Dateresponsive.prototype.prepareSmallState = function () {
  this.insertSmallStateHTML();
};

/**
 * Build HTML, and insert it into the DOM
 */
nn.component.Dateresponsive.prototype.insertSmallStateHTML = function () {

  // Common html templates
  var html = {

    'all': '<div class="field grid grid--block-beta mandatory visible-mobile" data-state="small">\n\
					<label class="grid-unit field-label" for="value_birthdate">Geboortedatum</label>\n\
					<div class="grid-unit field-group">\n\
						[day][month][year][error]\n\
					</div>\n\
				</div>',

    'template': '<div class="field">\n\
						<label class="hidden-mobile" for="field-select-date-[key]-[id]">[label]</label>\n\
						<select name="[id]" id="field-select-date-[key]-[id]" class="select-field" data-component="SelectField">\n\
							<option>[label]</option>\n\
							[options]\n\
						</select>\n\
					</div>',
    'option': '<option value="[value]">[label]</option>',
    'errorTemplate': '<p class="error-message">[text]</p>'
  };

  // Day template
  var dayOptions = '';
  var i;
  for (i = 1; i <= 31; i++) {
    dayOptions += html.option
      .replace(/\[value\]/g, ('0' + i).slice(-2))
      .replace(/\[label\]/g, i);
  }
  html.day = html.template
    .replace(/\[key\]/g, this.id)
    .replace(/\[id\]/g, 'day')
    .replace(/\[label\]/g, 'Dag')
    .replace(/\[options\]/g, dayOptions);

  // Month template
  var monthOptions = '',
    months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
  $.each(months, function (i, month) {
    monthOptions += html.option
      .replace(/\[value\]/g, ('0' + (i + 1)).slice(-2))
      .replace(/\[label\]/g, month);
  });
  html.month = html.template
    .replace(/\[key\]/g, this.id)
    .replace(/\[id\]/g, 'month')
    .replace(/\[label\]/g, 'Maand')
    .replace(/\[options\]/g, monthOptions);

  // Year template
  var yearOptions = [],
    currentYear = (new Date()).getFullYear();
  for (i = currentYear; i >= currentYear - 100; i--) {
    yearOptions += html.option
      .replace(/\[value\]/g, i)
      .replace(/\[label\]/g, i);
  }
  html.year = html.template
    .replace(/\[key\]/g, this.id)
    .replace(/\[id\]/g, 'year')
    .replace(/\[label\]/g, 'Jaar')
    .replace(/\[options\]/g, yearOptions);

  // Error template
  var $error = []; //$('[data-component="date-picker"] .error-message', this.$element);

  if ($error.length) {
    html.error = html.errorTemplate
      .replace(/\[text\]/g, $error.text());
  } else {
    html.error = '';
  }

  // Combine day, month, year templates
  html.all = html.all
    .replace(/\[day\]/, html.day)
    .replace(/\[month\]/, html.month)
    .replace(/\[year\]/, html.year)
    .replace(/\[error\]/, html.error);

  // Insert combined HTML into DOM
  var $parentField = this.$element.closest('.field');
  var $nextElement = $parentField.addClass('hidden-mobile').after(html.all).next();

  this.$smallState = $nextElement;

  nn.initializeComponents($nextElement);
};

nn.component.Dateresponsive.prototype.toggleState = function () {
  if ($(window).width() <= nn.settings.mobileBreakPoint && this.isSyncedToMobile === false) {
    this.syncSmallStateDateWithLargeStateDate();
    this.isSyncedToMobile = true;
    this.isSyncedToDesktop = false;
  }

  if ($(window).width() > nn.settings.mobileBreakPoint && this.isSyncedToDesktop === false) {
    this.syncLargeStateDateWithSmallStateDate();
    this.isSyncedToDesktop = true;
    this.isSyncedToMobile = false;
  }
}

/**
 * Sync large state date with small state date.
 */
nn.component.Dateresponsive.prototype.syncLargeStateDateWithSmallStateDate = function () {

  // Define date format
  var dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[012])[- /.](19|20)\d\d$/;

  // Grab selected date
  var dateString =
    this.$smallState.find('select[name*="day"]').val() + '-' +
    this.$smallState.find('select[name*="month"]').val() + '-' +
    this.$smallState.find('select[name*="year"]').val();

  // If valid, duplicate date into the large state
  if (dateString.match(dateFormat)) {
    this.$element.val(dateString);
    this.$element.change();
  }
};

/**
 * Sync small state date with large state date.
 */
nn.component.Dateresponsive.prototype.syncSmallStateDateWithLargeStateDate = function () {

  // Define date format
  var dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[012])[- /.](19|20)\d\d$/;

  // Get the date-picker's hidden field with the date
  var $datePickerHiddenField = this.$element,
    dateString = $datePickerHiddenField.val();

  if (dateString.match(dateFormat)) {

    var date = dateString.split('-'),
      day = parseInt(date[0], 10),
      month = parseInt(date[1], 10),
      year = parseInt(date[2], 10);

    this.$smallState.find('select[name*="day"]').val(day < 10 ? '0' + day : day).change();
    this.$smallState.find('select[name*="month"]').val(month < 10 ? '0' + month : month).change();
    this.$smallState.find('select[name*="year"]').val(year).change();
  }
};