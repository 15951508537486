import templateLayout from './template/layout.tpl.html';
import moment from "moment";
import Dialog from '../dialog/dialog';
import ElementHelper from '../utility/element.helper';
import { default as dateHelpers, DATE_FORMAT } from './date.helpers';

export class DatepickerDialog {

  constructor(config, currentDate, datePickedCallback) {
    this.config = config;
    this.selectedDate = dateHelpers.asMomentDate(currentDate);
    this.datePickedCallback = datePickedCallback;

    this.setupDialog(config);
    this.setupSelectorsAndListeners();

    this.disableOfflimitsMonths();
    this.renderMonth();
    if (this.selectedDate) {
      this.setSelectedDate(this.selectedDate);
    }
  }

  setupDialog(config) {
    this.dialog = new Dialog({
      themes: ["datepicker"],
      closeOnGlassClick: true,
    });

    const layout = document.createElement('div');
    layout.innerHTML = templateLayout;

    this.dialogContent = this.dialog.setContent(layout);
    const monthSelector = this.dialogContent.querySelector('.c-datepicker__select-month');
    const yearSelector = this.dialogContent.querySelector('.c-datepicker__select-year');

    const createOption = (value, innerHTML) => {
      const option = document.createElement('option');
      option.value = value;
      option.innerHTML = innerHTML;
      return option;
    };
    dateHelpers.textualMonthsLong
      .forEach((month, index) => monthSelector.appendChild(createOption(index, month)));

    [...Array(config.dateEnd.year() - config.dateStart.year() + 1)]
      .map((_, i) => i + config.dateStart.year())
      .forEach(year => yearSelector.appendChild(createOption(year, year)));
  }

  setupSelectorsAndListeners() {
    // Selectors
    this.dialogContainerWeeks = this.dialogContent.getElementsByClassName('c-datepicker__weeks')[0];
    this.selectYear = this.dialogContent.getElementsByClassName('c-datepicker__select-year')[0];
    this.selectMonth = this.dialogContent.getElementsByClassName('c-datepicker__select-month')[0];

    // set these before adding a change event so they don't fire an event to the original input on initializing
    ElementHelper.setWithChange(this.selectYear, this.config.dateFocus.year());
    ElementHelper.setWithChange(this.selectMonth, this.config.dateFocus.month());

    // Event listeners
    this.dialogContainerWeeks.addEventListener('click', this.pickDate.bind(this));
    this.selectYear.addEventListener('change', this.changeYear.bind(this));
    this.selectMonth.addEventListener('change', this.changeMonth.bind(this));
  }

  // *** Events ***
  pickDate(evt) {
    evt.preventDefault();
    const date = evt.target.getAttribute('data-date') || evt.target.parentNode.getAttribute('data-date');
    if (date) {
      this.notifySelectedDate(date);
      this.dialog.hide();
    }
  }

  changeMonth(evt) {
    evt.preventDefault();

    this.renderMonth();
    if (this.selectedDate && (this.selectedDate.month() !== parseInt(evt.target.value, 10))) {

      // use moments feature that ensures a change of month doesn't bubble up to next month
      this.selectedDate.month(parseInt(evt.target.value, 10));
      this.notifySelectedDate(this.selectedDate.format(DATE_FORMAT));
    }
  }

  changeYear(evt) {
    evt.preventDefault();

    const newYear = evt.target.value;
    const shouldNotify = this.selectedDate && this.selectedDate.year() !== parseInt(evt.target.value, 10);

    this.disableOfflimitsMonths();
    this.renderMonth();

    if (shouldNotify) {
      this.selectedDate.year(parseInt(newYear, 10));
      this.notifySelectedDate(this.selectedDate);
    }
  }

  // *** Selected Date ***
  setSelectedDate(date) {
    this.selectedDate = dateHelpers.asMomentDate(date);


    if (this.config.dateEnd && this.selectedDate > this.config.dateEnd) {
      this.selectedDate = dateHelpers.asMomentDate(this.config.dateEnd);
    }

    if (this.config.dateStart && this.selectedDate < this.config.dateStart) {
      this.selectedDate = dateHelpers.asMomentDate(this.config.dateStart);
    }
    this.highlightDate(this.selectedDate);
  }

  notifySelectedDate(date) {
    this.setSelectedDate(date);

    const dateString = this.selectedDate ? this.selectedDate.format(DATE_FORMAT) : '';
    this.datePickedCallback(dateString);
  }

  // *** Render actions ***
  renderMonth() {
    this.dialogContainerWeeks.innerHTML = '';
    const year = parseInt(this.selectYear.value);
    const month = parseInt(this.selectMonth.value);
    const weeks = DatepickerDialog.getMonthData(year, month, this.config);

    weeks.forEach(week => {
      const weekRow = document.createElement('tr');
      week.forEach(day => {
        const dayCell = document.createElement('td');
        let innerHTML = '';
        if (day.before || day.after) {
          innerHTML = '<div class="c-datepicker__date">&nbsp;</div>';
        } else if (day.disabled) {
          innerHTML = `<div class="c-datepicker__date c-datepicker__date--inactive ${(day.today) ? 'c-datepicker__date--today' : ''}">${day.date}</div>`;
        } else {
          innerHTML = `<div class="c-datepicker__date ${(day.today) ? 'c-datepicker__date--today' : ''}" data-date="${day.format}">${day.date}</div>`;
        }
        dayCell.innerHTML = innerHTML;
        weekRow.appendChild(dayCell);
      });
      this.dialogContainerWeeks.appendChild(weekRow);
    });

    this.highlightDate(this.selectedDate);
  }

  disableOfflimitsMonths() {
    const visibleYear = parseInt(this.selectYear.value, 10),
      firstMonth = this.config.dateStart.month(),
      lastMonth = this.config.dateEnd.month();

    // Reset all months
    Array.from(this.selectMonth.children).forEach(child => {
      child.removeAttribute('disabled');
    });

    // The first available year. Disable months before the first selectable one
    if (visibleYear === this.config.dateStart.year()) {
      Array.from(this.selectMonth.children).forEach(child => {
        if (child.value < firstMonth) {
          child.setAttribute('disabled', 'disabled');
        }
      });

      // If currently selected month just got hidden, raise to the first available month
      if (this.selectMonth.value < firstMonth) {
        ElementHelper.setWithChange(this.selectMonth, firstMonth);
      }
    }

    // The last available year. Disable months after the last selectable one
    if (visibleYear === this.config.dateEnd.year()) {
      Array.from(this.selectMonth.children).forEach(child => {
        if (child.value > lastMonth) {
          child.setAttribute('disabled', 'disabled');
        }
      });

      // If currently selected month just got hidden, lower to the last available month
      if (this.selectMonth.value > lastMonth) {
        ElementHelper.setWithChange(this.selectMonth, lastMonth);
        this.selectMonth.value = lastMonth;
      }
    }
  }

  render(icon) {
    const iconRect = icon.getBoundingClientRect();

    this.dialog.show({
      side: 'bottom',
      followArrow: true,
      arrow: {
        side: 'top',
        align: 'middle',
        x: iconRect.left + (iconRect.width / 2),
        y: iconRect.bottom + (iconRect.height / 2)
      }
    }, icon);
  }

  highlightDate(date) {
    let dateString = date;
    if (typeof date !== 'string') {
      const dateObject = dateHelpers.asMomentDate(date);
      dateString = dateObject ? dateObject.format(DATE_FORMAT) : '';
    }
    const chosenClass = 'c-datepicker__date--chosen';

    const currentChosen = this.dialogContainerWeeks.getElementsByClassName(chosenClass);
    if (currentChosen.length > 0) {
      currentChosen[0].classList.remove(chosenClass);
    }

    if (!dateString) {
      return;
    }

    const newChosen = this.dialogContainerWeeks.querySelectorAll('*[data-date="' + dateString + '"]');
    if (newChosen.length > 0) {
      newChosen[0].classList.add(chosenClass);
    }
  }

  static getMonthData(year, month, config) {
    const results = [];

    // find out first and last days of the month
    const firstDate = moment({ year: year, month: month, date: 1 });
    const lastDate = moment({ year: year, month: month, date: firstDate.daysInMonth() });

    // calculate first monday and last sunday
    const firstMonday = DatepickerDialog.getFirstMonday(firstDate);
    const lastSunday = DatepickerDialog.getLastSunday(lastDate);

    // iterate days starting from first monday
    const iterator = moment(firstMonday);
    let i = 0;

    let week;
    // ..until last sunday
    while (iterator.isSameOrBefore(lastSunday, 'd')) {
      if (i++ % 7 === 0) {
        // start new week when monday
        week = [];
        results.push(week);
      }

      // push day to week
      week.push({
        date: iterator.date(),
        format: iterator.format(DATE_FORMAT),
        before: iterator.isBefore(firstDate), // add indicator if before current month
        after: iterator.isAfter(lastDate), // add indicator if after current month,
        today: iterator.isSame(moment(), 'd'), // add indicator if today
        disabled: config.disabledWeekDays.indexOf(iterator.day()) > -1 || iterator.isBefore(config.dateStart) || iterator.isAfter(config.dateEnd)
      });

      // iterate to next day
      iterator.date(iterator.date() + 1);
    }

    return results;
  }

  static fixMonday(day) {
    day = day || 7;
    return --day;
  }

  static getFirstMonday(firstDate) {
    const offset = DatepickerDialog.fixMonday(firstDate.day());
    const result = moment(firstDate);

    result.date(firstDate.date() - offset);

    return result;
  }

  static getLastSunday(lastDate) {
    const offset = 6 - DatepickerDialog.fixMonday(lastDate.day());
    const result = moment(lastDate);

    result.date(lastDate.date() + offset);

    return result;
  }
}

export default DatepickerDialog;
