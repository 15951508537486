/**
 * Zipcode Formatter
 *
 * @param {HTMLNode} element
 * @returns {void}
 */
nn.component.ZipcodeFormatter = function (element) {
  this.element = $(element);
  this.init();
};

/**
 *
 * @type type
 */
nn.component.ZipcodeFormatter.prototype = {
  init: function () {
    this.element
      .on('change', $.proxy(this.onChangeHandler, this))
      .on('keydown', $.proxy(this.onKeydownHandler, this))
      .addClass('uppercase');
  },
  onChangeHandler: function () {
    var v = this.element.val();
    this.element.val(v.replace(/[^0-9A-Za-z]/g, "").toUpperCase());
  },
  onKeydownHandler: function (e) {
    var kc_backspace = 8,
      kc_tab = 9,
      kc_delete = 46,
      kc_decimalpoint = 110,
      kc_period = 190;

    if (e.keyCode === kc_backspace
      || e.keyCode === kc_tab
      || e.keyCode === kc_delete
      || nn.helpers.isArrowKeyCode(e)
      || nn.helpers.isNumericKeyCode(e)
      || nn.helpers.isAlphaNumericKeyCode(e)
      || e.keyCode === kc_decimalpoint
      || e.keyCode === kc_period) {
      // valid character
    } else {
      // invalid character
      e.preventDefault();
    }
  }
};