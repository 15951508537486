import { createTag } from "./createTag";

/**
 * Creates a script tag in the document
 * @param {string} url Url of the script
 * @param {Boolean | undefined} async If set the script tag will have the `async` property set to this
 * @param {Boolean | undefined} forceLoad If the script already exists, it will remove it and load it again
 * @returns {Promise<Boolean>}
 */
function loadScript(url, async, forceLoad) {
  const attributes = {
    ...(async ? { async } : {}),
  }
  const options = {
    ...(forceLoad ? { forceLoad } : {}),
  }
  return createTag('script', url, attributes, options);
}

export default loadScript;
