import { refreshAuthSession } from '../../app-utilities/forgeRockUtils';


(function(window, $, nn) {

  nn.component.SessionMonitor = function(element) {
    this.element = $(element);
    this.timeout = null;
    this.sessionTimeout = parseInt(this.element.data('session-timeout'), 10); //Session timeout in seconds
    this.sessionTimeoutCountdown = parseInt(this.element.data('session-timeout-countdown'), 10); //Session timeout in seconds
    this.sessionRefreshInterval = parseInt(this.element.data('session-refresh-interval'), 10) || 480; // Session token refresh interval in seconds
    this.sessionResetUrl = this.element.data('session-reset-url');
    this.sessionLogoutUrl = this.element.data('data-session-logout-url') || '/logout';
    this.sessionResetElements = '#remote,.close-button,.button--continue-session,input[type="submit"],input[type="button"]';
    this.sessionTimeIdle = this.sessionTimeoutCountdown / 60;
    this.timerID = null;
    this.dialog = null;
    this.debugLogKey = 'DebugSessionMonitor';
    this.debugLog = false;
    this.init();
  };

  nn.component.SessionMonitor.prototype = {
    init: function() {
      if (nn.isCmsModePreview()) {
        return;
      }

      this.dialog = new nn.component.Dialog({ locked: true, glassLocked: true });
      this.dialog.content.html(this.element.html());
      this.dialog.content.find('span.time-idle').html(this.sessionTimeIdle);
      nn.initializeComponents(this.dialog.content);

      this.checkDebugLogging();
      this.setNextSessionRefreshTime();
      this.resetLogoutAndWarningTime();

      this.countdownElement = this.dialog.content.find('span.countdown');
      this.timerID = setInterval($.proxy(this.countingDown, this), 1000);

      this.bindResetElements();
    },
    bindResetElements: function () {
      var self = this;

      $(window).on('Iframe:Page:Loaded', function(e, obj) {

        var $iframe = $(obj.element);
        $iframe.contents().find("body").on('mousedown', $.proxy(self.resetTimer, self));
        $iframe.contents().find("iframe").contents().find('body').on('mousedown', $.proxy(self.resetTimer, self));
      });

      $(window).on('Session:Refresh', $.proxy(self.resetTimer, self));
      $(window).on('mousedown', $.proxy(self.resetTimer, self));
      $(window).on('keydown', $.proxy(self.resetTimer, self));
    },
    setNextSessionRefreshTime() {
      this.nextSessionRefreshTime = new Date();
      this.nextSessionRefreshTime.setSeconds(this.nextSessionRefreshTime.getSeconds() + this.sessionRefreshInterval);
      this.logDebug('Next session refresh after', this.nextSessionRefreshTime.toLocaleTimeString());
    },
    resetLogoutAndWarningTime() {
      this.logoutTime = new Date();
      this.logoutTime.setSeconds(this.logoutTime.getSeconds() + this.sessionTimeout);
      this.logDebug('Timer reset, auto logout at', this.logoutTime.toLocaleTimeString());

      this.showWarningTime = new Date();
      this.showWarningTime.setSeconds(this.showWarningTime.getSeconds() + this.sessionTimeoutCountdown);
      this.logDebug('Timer reset, popup will show at', this.showWarningTime.toLocaleTimeString());
    },
    resetTimer: function (ev, immediate) {
      if (this.dialog.visible && immediate !== true) {
        // Don't auto refresh if the dialog is already being shown. User should control action to perform through dialog.
        return;
      }

      this.resetLogoutAndWarningTime();

      if (immediate || new Date() > this.nextSessionRefreshTime) {
        this.logDebug('Session is being refreshed')
        this.setNextSessionRefreshTime();

        $.ajax({
          type: "GET",
          url: this.sessionResetUrl
        });
        refreshAuthSession();
        this.dialog.hide();
        this.logDebug('Refresh done, next session refresh after', this.nextSessionRefreshTime.toLocaleTimeString());
      }
    },
    countingDown : function () {
      if (new Date() > this.logoutTime) {
        clearInterval(this.timerID);

        var self = this;
        $.ajax({
          type: "GET",
          url: this.sessionLogoutUrl
        }).done(function() {
          self.reloadPage();
        }).fail(function() {
          self.reloadPage();
        });
      } else if (new Date() > this.showWarningTime) {
        if (!this.dialog.visible) {
          this.dialog.show();
        }
        this.updateCountDown();
      }
    },
    updateCountDown: function() {
      var countdownTimer = Math.floor((this.logoutTime - new Date()) / 1000),
        sessionTimeoutInMinutes = Math.floor(countdownTimer / 60),
        countDownMinutesInSeconds = (sessionTimeoutInMinutes * 60),
        countDownRestSeconds = (countdownTimer - countDownMinutesInSeconds),
        countDownDescription;

      if (sessionTimeoutInMinutes < 1) {
        countDownDescription = countdownTimer + ' sec.'
      } else {
        countDownDescription = sessionTimeoutInMinutes+' min. '+ countDownRestSeconds + ' sec.'
      }

      this.countdownElement.html(countDownDescription);
    },
    reloadPage: function () {
      if (/^(?:\/nn|\/web2)?\/mijn\.nn/.test(window.location.pathname)) {
        window.location.href = '/mijn.nn.htm';
      } else {
        window.location.reload(true);
      }
    },
    checkDebugLogging() {
      this.isDebugActive = sessionStorage.getItem(this.debugLogKey) === 'true';
      if (this.isDebugActive) {
        this.logDebug('Logging is active. To turn it off type the following in the console and press enter: sessionStorage.setItem(\'DebugSessionMonitor\', \'false\')')
      }
    },
    logDebug() {
      if (this.isDebugActive) {
        window.console.log('SessionMonitor DEBUG:', ...arguments);
      }
    }
  };

  nn.component.SessionMonitorContinue = function(element) {
    this.element = $(element);
    this.init();
  };

  nn.component.SessionMonitorContinue.prototype = {
    init: function() {
      this.element.on('click', function(e) {
        e.preventDefault();
        $(window).trigger('Session:Refresh', true);
      });
    }
  };

})(window, jQuery, nn);