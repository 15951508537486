/** ************************************************************
 *
 *                         H E L P E R S
 *
 *  ************************************************************ */

import cookie from '@nn/psdl-utility/src/cookie.js';
import moment from 'moment';

/**
 * @class Cookie
 * @description
 *    Static helper class to create, read and erase cookies.
 */
nn.helpers.Cookie = cookie;

nn.helpers.Util = {
  isResponsivePage: function () {
    return nn.helpers.isResponsivePage();
  }
};

/**
 * Check if given value is an int
 */
nn.helpers.isInt = function (n) {
  return typeof n === 'number' && (n % 1 === 0);
}

/**
 * Check if the device is an iPad
 */
nn.helpers.isiPad = function () {
  return (navigator.platform.indexOf("iPad") !== -1);
}

/**
 * Check if the device is an iPod or an iPhone
 */
nn.helpers.isiPhone = function () {
  return (navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1);
}

nn.helpers.isAndroid = function () {
  return (navigator.userAgent.match(/Android/i));
}

/**
 * Check if the page is responsive
 */
nn.helpers.isResponsivePage = function () {
  return $('body').hasClass('is-responsive');
}

/**
 * Check if the viewing device is a Touch device
 */
nn.helpers.isTouchDevice = function () {
  return !!('ontouchstart' in window) // works on most browsers
    || !!('onmsgesturechange' in window) // works on ie10
    && !navigator.userAgent.match(/Trident.*rv:11\./); // IE11 check. (should equal false)
}

nn.helpers.isEdge = function () {
  return navigator.userAgent.match('Windows NT 10.0'); // Edge check
}


/**
 * Check if the it is a valid IBAN number
 */
nn.helpers.isIbanNumber = function (number) {
  return /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/.test(number);
}

/**
 * Check if the viewing device is a Touch device
 */
nn.helpers.isBankAccountNumber = function (number) {
  if (number === null || number === '' || number === undefined) {
    return false;
  }

  if (/\D/.test(number)) {
    return false;
  }

  return !(number.length > 7 && !nn.helpers.elfProef(number));

}

/**
 * Check if the it is a valid IBAN number
 */
nn.helpers.isZipCode = function (zipcode) {
  return /[1-9]{1}[0-9]{3}\s?[a-zA-Z]{2}/.test(zipcode);
}

/**
 * Check if the given number is a valid bank account number
 */
nn.helpers.elfProef = function (bankrekeningnummer) {
  // verwijder alle tekens die geen cijfers zijn
  var som = 0, aantal_tekens, getal;

  bankrekeningnummer = bankrekeningnummer.replace(/\D/, "");
  aantal_tekens = bankrekeningnummer.length;
  // loop door de 9 cijfers met de 11 proef formule
  for (var i = 1; i < 10; i++) {
    getal = bankrekeningnummer.charAt(i - 1);
    som += getal * (10 - i);
  }
  // geef resultaat van check terug
  return som % 11 === 0 && aantal_tekens === 9;
};

/**
 * @param event {jq event}
 */
nn.helpers.isArrowKeyCode = function (event) {
  var kc_arrows_start = 37;
  var kc_arrows_end = 40;
  return (event.keyCode >= kc_arrows_start && event.keyCode <= kc_arrows_end);
};

/**
 * @param event {jq event}
 */
nn.helpers.isAlphaNumericKeyCode = function (event) {
  var kc_alpha_start = 65;
  var kc_alpha_end = 90;
  return (event.keyCode >= kc_alpha_start && event.keyCode <= kc_alpha_end);
};

/**
 * @param event {jq event}
 */
nn.helpers.isNumericKeyCode = function (event) {

  var kc_numpad_0 = 96,
    kc_numpad_9 = 105,
    kc_num_0 = 48,
    kc_num_9 = 57;

  return (event.keyCode >= kc_num_0 && event.keyCode <= kc_num_9)
    || (event.keyCode >= kc_numpad_0 && event.keyCode <= kc_numpad_9);
};

nn.helpers.ComputeMonthPercFromYear = function (jaarPercConst) {
  return Math.pow((1 + (jaarPercConst / 100)), (1 / 12)) - 1;
};

nn.helpers.ComputeProfit = function (saldo, maandinleg, maanden, rendement) {
  return (saldo * (Math.pow((1 + rendement), maanden))) + (maandinleg * ((Math.pow((1 + rendement), maanden)) - 1)) / rendement;
};

/**
 *
 * @param {String} variable
 * @param {String} query
 * @returns {unresolved}
 */
nn.helpers.getQueryVariable = function (variable, queryString) {

  if (!queryString) {
    queryString = window.location.search.substring(1);
  }
  var vars = queryString.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
};

/**
 * Get all the query parameters from the url (address bar)
 * If a query is given it will get the parameters from the given query
 *
 * @param {String} query
 * @returns {Object}
 */
nn.helpers.getAllQueryVariables = function (query) {
  if (!query) {
    query = window.location.search.substring(1);
  }
  var vars = query.split('&'),
    queryParameters = {};

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[1]) {
      queryParameters[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }

  return queryParameters;
};

/**
 *
 * @param {Function} func
 * @param {int} wait
 * @param {boolean} immediate
 * @returns {Function}
 */
nn.helpers.debounce = function (func, wait, immediate) {

  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};

nn.helpers.isNumeric = function (n) {
  return (!isNaN(parseFloat(n)) && isFinite(n));
};

/**
 * This helper is needed for older advisor applications.
 * Hiding the right (service) column
 *
 * @type type
 */
nn.helpers.gridcontrol = {
  expandColumn: function (column) {
    column = $(column);
    if (!column.hasClass('grid-unit')) {
      column = column.parents('.grid-unit');
    }
    var grid = column.parent('[class*="grid--"]'),
      siblings = column.siblings('.grid-unit'),
      gridFixSet = false;
    if (siblings.length > 0 && !grid.hasClass('grid--alpha')) {
      var gridClassList = grid.attr('class').split(/\s+/);
      for (var i = gridClassList.length - 1; i >= 0; i--) {
        if (gridClassList[i].indexOf('grid--') === 0) {
          grid.attr('data-gridcontrol-original-grid', gridClassList[i]);
          grid.removeClass(gridClassList[i]).addClass('grid--alpha');
          gridFixSet = true;
          break;
        }
      }
      if (gridFixSet) {
        siblings.attr('data-gridcontrol-hidden', true).hide();
      }
    }
  },
  collapseColumn: function (column) {
    column = $(column);
    if (!column.hasClass('grid-unit')) {
      column = column.parents('.grid-unit');
    }
    var grid = column.parent('[data-gridcontrol-original-grid]'),
      siblings = column.siblings('.grid-unit').filter('[data-gridcontrol-hidden]');

    if (grid.length > 0 && siblings.length > 0) {
      grid.addClass(grid.attr('data-gridcontrol-original-grid'))
        .removeAttr('data-gridcontrol-original-grid')
        .removeClass('grid--alpha');
      siblings.removeAttr('data-gridcontrol-hidden').show();
    }

  }
};

nn.helpers.hasDomReference = function (element) {
  var $el = $(element),
    id = $el.prop('id');
  if (!id) {
    id = parseInt(Math.random() * Date.now(), 10) + '-ref-check';
    $el.prop('id', id);
  }

  var foundElement = document.getElementById(id);

  if (!foundElement) {
    foundElement = window.top.document.getElementById(id)
  }

  if (!foundElement) {
    return false;
  }

  return foundElement === element;
};

/**
 * convert a given date string to a moment obj.
 * If no valid date string is given NULL is returned
 *
 * @param {string} dateString
 * @returns {moment}
 */
nn.helpers.dateToMoment = function (dateString) {

  var results,
    momentObj;

  if (!dateString) {
    return null;
  }

  if (moment.isMoment(dateString)) {
    return dateString.clone();
  }

  // Check formatting
  // Format: DDMMYYYY /
  // Example: 20102014
  // Translate to: Mon Oct 20 2014 00:00:00 GMT+0200

  if (/^([0][1-9]|[1|2][0-9]|[3][0|1])([0][1-9]|[1][0-2])([0-9]{4})$/.test(dateString)) {
    momentObj = moment(dateString, 'DDMMYYYY');
    if (momentObj.isValid()) {
      return momentObj;
    }
  }

  // Format: DD-MM-YYYY /
  // Example: 20-10-2014
  // Translate to: 20-10-2014
  // Check if format is DD [ -/.] MM [ -/.] YYYY or D [ -/.] M [ -/.] YYYY
  results = dateString.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[ -/.]([0]?[1-9]|[1][0-2])[ -/.]([0-9]{4})$/);

  if (results) {
    momentObj = moment([results[1], results[2], results[3]].join('-'), 'D-M-YYYY');
    if (momentObj.isValid()) {
      return momentObj;
    }
  }

  // Format: DD-MM-YYYY /
  // Example: 20 feb 2014
  // Translate to: 20-feb-2014
  // Check if format is DD [ -/.]? MMM [ -/.]? YYYY or D [ -/.]? MMM [ -/.]? YYYY
  results = dateString.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[ -/.]?([a-zA-Z]{3})[ -/.]?([0-9]{4})$/);
  if (results) {
    momentObj = moment([results[1], results[2], results[3]].join('-'), 'D-MMM-YYYY');
    if (momentObj.isValid()) {
      return momentObj;
    }
  }

  // Format: D-MMMM-YYYY /
  // Example: 16 februari 2014
  // Translate to: 20-10-2014
  // Check if format is DD [ -/.]? MMMM [ -/.]? YYYY
  results = dateString.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[ -/.]?([a-zA-Z]+)[ -/.]?([0-9]{4})$/);
  if (results) {
    momentObj = moment([results[1], results[2], results[3]].join('-'), 'D-MMMM-YYYY');
    if (momentObj.isValid()) {
      return momentObj;
    }
  }

  // Format: YYYY-MM-DD /
  // Example: 2014-10-20
  // Translate to: 20-10-2014
  // Check if format is DD [ -/.] MM [ -/.] YYYY or D [ -/.] M [ -/.] YYYY
  results = dateString.match(/^([0-9]{4})[ -/.]([0]?[1-9]|[1][0-2])[ -/.]([0]?[1-9]|[1|2][0-9]|[3][0|1])$/);

  if (results) {
    momentObj = moment([results[1], results[2], results[3]].join('-'), 'YYYY-MM-DD');
    if (momentObj.isValid()) {
      return momentObj;
    }
  }

  return null;
};
