import * as a11y from '../utility/a11y';

const FOCUS_CLASS = 'c-focus-element--on';

class FocusOnElement {
  constructor(element, config) {
    this.element = element;
    this.config = config || {};
    this.elementToFocus = document.querySelector(this.config.focusElement);

    const alreadyPresentFocusedElement = document.querySelector('.c-focus-element--on');
    if (alreadyPresentFocusedElement) {
      this.initBlueriq(alreadyPresentFocusedElement);
    } else {
      this.init();
    }
  }

  initBlueriq(focusedElement) {
    this.overlay = document.querySelector('.c-focus-element-overlay');
    a11y.a11yDialog(focusedElement);
    this.focusBackOnFocusedElement(focusedElement);
    setTimeout(() => focusedElement.focus());
  }

  init() {
    if (this.elementToFocus) {
      this.elementToFocus.classList.add('c-focus-element');
      [...this.elementToFocus.querySelectorAll('[data-clear-focus]')].forEach(button => {
        button.addEventListener('click', this.removeA11y.bind(this));
      });
      this.element.addEventListener('click', () => {
        this.overlay = this.createNewOverlay();
        this.elementToFocus.classList.add(FOCUS_CLASS);
        a11y.a11yDialog(this.elementToFocus);
        this.focusBackOnFocusedElement(this.elementToFocus);
      });
    }
  }

  focusBackOnFocusedElement(elementToFocus) {
    this.overlay.addEventListener('click', () => {
      elementToFocus.focus();
    });
  }

  createNewOverlay() {
    const overlay = document.createElement('div');
    overlay.classList.add('c-focus-element-overlay');
    document.body.appendChild(overlay);
    return overlay;
  }

  removeA11y() {
    document.body.removeChild(this.overlay);
    this.elementToFocus.classList.remove(FOCUS_CLASS);
    a11y.a11yDialogRemove(this.elementToFocus);
  }
}

export default FocusOnElement;
