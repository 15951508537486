export function pfcUrl(p, f, c) {
  const pfc = encodeURIComponent(`p${p}f${f}c${c}`);
  return `/nn_doorlink_${pfc}.htm`;
}

export function buildInitialValues(type, data) {
  const innerXml = jsonToXml(data, false);
  return `<aquimaParameters action="${type}">${innerXml}</aquimaParameters>`;
}

export function parseInitialValues(initialValues) {
  var iv = xmlToJson(parseXml(initialValues));
  var parsedInitialValues = {};
  if (iv.aquimaParameters) {
    for (var name in iv.aquimaParameters) {
      if ('#text' !== name && '@attributes' !== name) {
        parsedInitialValues[name] = iv.aquimaParameters[name]['#text'];
      }
    }
  }
  return parsedInitialValues;
}

export function parseApplicationData(data) {
  function fixProps(obj, parent, ancestors) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && ancestors.indexOf(obj[key]) === -1) {
        if (typeof obj[key]['#text'] !== 'undefined') {
          obj[key] = obj[key]['#text'];
        } else if (typeof obj[key]['@attributes'] !== 'undefined') {
          obj[key] = obj[key]['@attributes'];
        } else {
          // Ancestors are tracked to avoid following cyclic references.
          ancestors.push(obj[key]);
          obj[key] = fixProps(obj[key], obj, ancestors);
          ancestors.pop();
        }
      }
    }
    return obj;
  }
  return fixProps(xmlToJson(parseXml(data)), undefined, []);
}

export function getRedirectUrl(settings) {
  let seperator = (settings.redirectUrl.indexOf('?') === -1) ? '?' : '&';

  var initialValues = buildInitialValues(settings.profile, {"ticketId": settings.ticketId});
  return settings.redirectUrl + seperator + 'initialValues=' + encodeURIComponent(initialValues);
}

export function render(root, data, template) {
  root.innerHTML = template(data);
  window.nn.initializeComponents(root);

  return Promise.resolve(root);
}

export function jsonToXml(data, addProlog) {
  if (typeof data !== 'object') {
    return data;
  }

  let XML = [];
  if (addProlog) {
    XML.push('<?xml version="1.0" encoding="UTF-8"?>');
  }

  return [...XML, ...Object
    .keys(data)
    .filter((key) => {
      return (data.hasOwnProperty(key) && data[key]);
    })
    .map((key) => {
      return `<${key}>${jsonToXml(data[key], false)}</${key}>`;
    })].join('');

}

export function xmlToJson(xml) {
  // Create the return object
  var obj = {};
  if (xml.nodeType === 1) { // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) { // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof (obj[nodeName]) === "undefined") {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof (obj[nodeName].push) === "undefined") {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}

export function parseXml(xmlStr) {
  return (new window.DOMParser()).parseFromString(xmlStr, "application/xml");
}

export function getKeyByValue (object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function error () {
  if (arguments[0] && arguments[0].message === 'TEST_FAKE') {
    return;
  }
  window.console.error.apply(console, arguments);
}

export const createVueChildContainer = (el) => el.appendChild(document.createElement('div'));

export const getParentVueInstance = (el) => {
  if (!el) {
    return;
  } else if (el.__vue__) {
    return el.__vue__;
  }
  return getParentVueInstance(el.parentElement);
};

export const getElAndParent = (root) => ({
  el: createVueChildContainer(root),
  parent: getParentVueInstance(root),
});
