nn.component.RadioButton = function (element) {

  // This component has been modified to follow branding conform style/modules/form/c-form-radio
  // Can probably be marked for deprecation
  // Only the IAF handlers have been left here for legacy purposes

  this.element = $(element); // the input
  this.input = this.element.find('input[type="radio"]');
  this.label = this.element.find('label');

  // Change classes to style/modules/form/c-form-radio
  this.input.addClass('c-form-radio');
  this.label.removeClass('field-label');

  // Translate gender classes
  if (this.element.hasClass('field--gender-selection-female')) {
    this.label.addClass('c-form-label--radio-gender');
    this.label.addClass('c-form-label--radio-gender-female');
  }

  if (this.element.hasClass('field--gender-selection-male')) {
    this.label.addClass('c-form-label--radio-gender');
    this.label.addClass('c-form-label--radio-gender-male');
  }

  // Translate payment classes
  if (this.element.hasClass('field--payment-selection-ideal')) {
    this.label.addClass('c-form-label--radio-ideal');
  }

  this.iafPrefillPersonalisation();
};

nn.component.RadioButton.prototype = {
  iafPrefillPersonalisation: function () {

    if (typeof window.FormValidation === 'undefined') {
      return;
    }

    var $containerElement = this.element.closest('[data-iaf-prefill-value]'),
      iafCurrentValue = $containerElement.data('iaf-current-value'),
      iafPrefillValue = $containerElement.data('iaf-prefill-value');

    if (typeof iafCurrentValue !== 'undefined' && iafCurrentValue.toString().length === 0) {
      iafCurrentValue = iafPrefillValue;
    }


    if (typeof iafCurrentValue === "undefined") {
      return;
    }

    var $checkedElement = $containerElement.find('input[name="' + this.input.prop('name') + '"]');
    if (iafCurrentValue.toString().length > 0
      && $checkedElement.filter(':checked').length === 0) {

      if (this.input.val() === iafCurrentValue) {
        this.input.prop('checked', true);
        var $element = $(this.input);
        $containerElement.closest('.nn_form')
          .on('IAF_ClientsideFrameworkLoaded', function () {
            window.FormValidation.changeFragment($element, false, true);
          });
      }
    }
  }
};
