import debounce from 'lodash/debounce';

(function ($, window) {

  window.app = window.app || {};
  window.app.premiumoverview = window.app.premiumoverview || {};

  window.app.premiumoverview.Sticky = function (element) {

    //CONST
    var $STICKYCONTAINER = $(element);
    var $TOGGLESWITCH = $('.c-premium-overview__detailtoggle');
    var $STICKTO = $STICKYCONTAINER.parent();
    var $INNERWRAPPER = $STICKYCONTAINER.find('.c-premium-overview__innerwrapper');
    var STICKUNTILWIDTH = 640;

    //LET
    var stickybehaviour = false;
    var stickyFixed = false;
    var viewportHeight;
    var sticktoHeight;
    var premiumoverviewHeight;
    var scrollTop;

    // We only want sticky behavour until given screen size
    var displayCheckDebounced = debounce(displayCheck, 100);

    function displayCheck() {
      var premiumWidth = $(window).outerWidth();

      // Our screen size has been changed so we need the new sizes
      viewportHeight = $(window).height();
      premiumoverviewHeight = $INNERWRAPPER.outerHeight();

      $STICKYCONTAINER.css({height: premiumoverviewHeight + 'px', position: 'relative'});
      if (STICKUNTILWIDTH < premiumWidth) {
        startStickyness();
      } else {
        stopStickyness();
      }
    }

    //Calculate if the container to stick to is above our sticky element or not
    //keep this as light as possible
    var calculateStickyDebounced = debounce(calculateSticky, 80);

    function calculateSticky() {
      scrollTop = $(window).scrollTop();
      sticktoHeight = $STICKTO.height();
      var sticktoTop = parseInt($STICKTO.offset().top, 10);
      // this calculates the bottom
      var shouldFitInViewPortValue = (sticktoTop + sticktoHeight) - scrollTop;
      //this calculates if the top would still be visible if not unset
      var topOffContainervisible = (sticktoTop + premiumoverviewHeight) - scrollTop;

      if ((viewportHeight < shouldFitInViewPortValue) && (viewportHeight > topOffContainervisible)) {
        // sticky should follow the bottom
        stickyFixed = false;
        setSticky();
      } else {
        unsetSticky();
      }
    }

    // Give the container a fixed position
    function setSticky() {
      //only touch dom when nessesary
      if (!stickyFixed) {
        $INNERWRAPPER.css({
          position: 'fixed',
          top: 'auto',
          bottom: 0,
          zIndex: 1000,
          width: $STICKYCONTAINER.width() + 'px'
        });
        stickyFixed = true;
      }
    }

    function unsetSticky() {
      //only touch dom when nessesary
      if (stickyFixed) {
        $INNERWRAPPER.css({
          position: 'relative',
          top: 'auto',
          bottom: 'auto',
          zIndex: '100',
          width: '100%',
        });
        stickyFixed = false;
      }
    }

    //check focused element is not hidden behind our sticky div
    function focusVisible(event) {
      if ((event.which === 9) && stickyFixed) {
        var $focusedElement = $(document.activeElement);
        var focusOffset = parseInt($focusedElement.offset().top, 10);

        scrollTop = $(window).scrollTop();
        var focusElVisible = (focusOffset + premiumoverviewHeight) - scrollTop;

        //check if the element is not inside our sticky container
        if ($focusedElement.parents('.c-premium-overview').length < 1) {
          //is the focused element behind our stickyfooter then scroll to top of the focused element
          if (viewportHeight < focusElVisible) {
            $('body').scrollTop(focusOffset);

          }
        }
      }
    }

    function stopStickyness() {
      // Remove events listners
      $STICKTO.off('click');
      $STICKTO.off('keyup');
      $(window).off('scroll');

      $STICKYCONTAINER.css({height: 'auto', position: 'relative'});
      $INNERWRAPPER.css({
        position: 'relative',
        top: 'auto',
        bottom: 'auto',
        zIndex: '100',
        width: '100%',
      });
      stickybehaviour = false;
    }

    function startStickyness() {
      calculateSticky();

      if (!stickybehaviour) {
        // Take height-changing collapsers into account. A global height-change event would help
        $STICKTO.on('click', _heightHandler);
        $STICKTO.on('keyup', focusVisible);
        $(window).on('scroll', calculateStickyDebounced);
      }
      stickybehaviour = true;
    }

    // When we have click in our containing container it could be an accordion
    // Collapsers animate for 600s, but can be configured. 800ms is a wild guess here.
    function _heightHandler() {
      setTimeout(function () {
        displayCheck();
      }, 800);
    }

    /**
		 * Events
		 */

    // For smoother opening of details set height of wrapper to auto
    $TOGGLESWITCH.on('click', function () {
      //recalculate the height of our wrapper
      $STICKYCONTAINER.css({height: 'auto'});
    });

    window.addEventListener('resize', displayCheckDebounced, true);
    window.addEventListener('orientationchange', displayCheckDebounced, true);

    displayCheck();
  };


}(jQuery, window));
