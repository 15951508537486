let hasSessionStorage = true;
try {
  hasSessionStorage = !!window.sessionStorage;
} catch(e) {
  // needed because IE Edge has a bug where calling window.sessionStorage throws an error instead of giving an undefined
  // if the sessionStorage is not available. Once this error is thrown it stops all other scripts from loading, so
  // catching it here helps to allow the rest to continue.
  hasSessionStorage = false;
}

if (hasSessionStorage) {
  window.app.sessionStorage = {
    key: sessionStorage.key.bind(sessionStorage),
    clear: sessionStorage.clear.bind(sessionStorage),
    removeItem: sessionStorage.removeItem.bind(sessionStorage),

    getItem: function (key) {
      return JSON.parse(sessionStorage.getItem(key));
    },

    setItem: function (key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  };
}