const readByContentType = async (response) => {
  const contentType = response.headers.get("content-type");

  if(contentType.indexOf('application/json') !== -1) {
    return await response.json();
  }

  return await response.text();
};

async function post(url, body, props) {
  let opts = {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    ...props
  };

  return await call(url, opts);
}

async function get(url, props) {
  let opts = {
    method: 'GET',
    credentials: 'same-origin',
    ...props
  };

  return await call(url, opts);
}

async function call(url, opts) {
  try {
    const response = await fetch(url, opts);
    if (response.ok) {
      return await readByContentType(response);
    }
    throw response
  } catch (e) {
    throw e
  }
}

export default {post, get};