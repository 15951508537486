import { publish } from '../utility/pubsub.js';
import { getAdvisorMobileLoginLink } from "./advisor";

function setMobileMenu(element, menu, search) {
  const body = document.querySelector('body');
  const loginElement = element.querySelector('.c-header__login');
  const loginLink = element.querySelector('.c-header__login-link');
  let loginItems, mobileLoginLink, mobileLoginSpan;
  if (loginElement) {
    if (body.classList.contains('advisor')) {
      loginItems = getAdvisorMobileLoginLink(element, loginLink);
    } else {
      loginItems = getLoginItems(loginLink);
    }
    if (loginItems) {
      mobileLoginLink = loginItems.link;
      mobileLoginSpan = loginItems.span;
    }
  }
  createMobileMenu(element, menu, mobileLoginLink, mobileLoginSpan);
  createMobileButtons(element, mobileLoginLink, mobileLoginSpan, search, menu);
  return element.querySelector('.c-header__search-button');
}

function getLoginItems(element) {
  const link = element.cloneNode(true);
  const span = link.firstElementChild;
  return { link, span };
}

function createMobileMenu(element, menu, loginLink, loginSpan) {
  const
    mobileElement = document.createElement('div'),
    mobileScroll = document.createElement('div'),
    mobileWrapper = document.createElement('div'),
    mobileMask = document.createElement('div'),
    menuSwitch = element.querySelector(`.c-header__switch`),
    menuUserItems = element.querySelector(`.c-header__user-items`),
    menuLogin = element.querySelector('.c-header__login');

  mobileElement.classList.add('c-header__mobile');
  mobileScroll.classList.add('c-header__mobile-scroll');
  mobileWrapper.classList.add('c-header__mobile__wrapper');
  mobileMask.classList.add('c-header__mobile-mask');

  mobileWrapper.appendChild(mobileMask);
  mobileWrapper.appendChild(mobileElement);
  mobileElement.appendChild(mobileScroll);

  if (menuSwitch) {
    const mobileSwitchItems = menuSwitch.cloneNode(true);
    mobileSwitchItems.classList.remove(`c-header__switch`);
    mobileSwitchItems.classList.add(`c-header__switch--mobile`);// ie11 doesn't support classlist.add() with multiple classes
    mobileSwitchItems.classList.add('u-clear');
    mobileScroll.appendChild(mobileSwitchItems);
  }

  if (menuLogin) {
    const mobileUserItems = document.createElement('ul');
    mobileUserItems.classList.add('c-header__mobile-user');

    if (menuUserItems) {
      [...menuUserItems.cloneNode(true).children].forEach((item) => {
        item.className = '';
        item.classList.add('c-header__mobile-user__item');
        let userItem = item.querySelector('a') || item.querySelector('span');
        if (userItem && userItem.classList.contains('c-header__user-account')) {
          userItem.className = '';
          userItem.classList.add('c-header__mobile-user__link');
          item.appendChild(loginSpan.cloneNode(true));
          item.appendChild(userItem);
        }
        mobileUserItems.appendChild(item);
      });
    } else {
      const item = document.createElement('li');
      item.classList.add('c-header__mobile-user__item');
      item.appendChild(loginLink.cloneNode(true));
      mobileUserItems.appendChild(item);
    }
    mobileScroll.appendChild(mobileUserItems);
  }

  if (menu) {
    const mobileMenu = menu.cloneNode(true);
    mobileMenu.className = '';
    mobileMenu.classList.add('c-header__mobile-menu');
    [...mobileMenu.children].forEach((item) => {
      if (item.classList.contains('c-header__login') || item.classList.contains('c-header__menu-item--more')) {
        item.parentNode.removeChild(item);
      } else {
        item.className = '';
        item.classList.add('c-header__mobile-menu-item');
        const linkItem = item.querySelector('a');
        linkItem.className = '';
        linkItem.className = 'c-header__mobile-menu-link';
        mobileMenu.appendChild(item);
      }
    });
    mobileScroll.appendChild(mobileMenu);
  }
  const mobileWrapperHtml = mobileWrapper.outerHTML;
  element.insertAdjacentHTML('afterend', mobileWrapperHtml);
}

function createMobileButtons(element, loginLink, loginSpan, search, menu) {
  const
    userButtonClass = 'c-header__user-button',
    searchButtonClass = 'c-header__search-button',
    menuButtonClass = 'c-header__mobile-button',
    mobileButtons = document.createElement('ul');

  let buttons = [];

  mobileButtons.classList.add('c-header__buttons');

  if (loginLink) {
    buttons.push(userButtonClass);
  }
  if (search) {
    buttons.push(searchButtonClass);
  }
  if (menu) {
    buttons.push(menuButtonClass);
  }

  buttons.forEach((b) => {
    let
      button,
      buttonItem = document.createElement('li');

    buttonItem.classList.add('c-header__buttons-item');
    if (b === userButtonClass) {
      button = loginLink.cloneNode(false);
      button.className = '';
      button.className = userButtonClass;
      button.appendChild(loginSpan.cloneNode(true));
    } else {
      let span = document.createElement('span');
      button = document.createElement('a');
      if (b === searchButtonClass) {
        button.href = '#search';
        button.classList.add(searchButtonClass);
        span.classList.add('c-icon'); // ie11 doesn't support classlist.add() with multiple classes
        span.classList.add('c-icon--pict-search');
        button.appendChild(span)
      }
      if (b === menuButtonClass) {
        const screenReader = document.createElement('span');
        let publishIsDone;
        screenReader.classList.add('u-screen-reader');
        screenReader.innerHTML = 'menu';
        span.classList.add('c-icon'); // ie11 doesn't support classlist.add() with multiple classes
        span.classList.add('c-icon--menu');
        button.href = '#menu';
        button.classList.add(menuButtonClass);
        button.addEventListener('click', () => {
          const body = document.querySelector('body');
          body.classList.add('is-menu-open');
          if (!publishIsDone) {
            publish('header:state:menupdate');
            publishIsDone = true;
          }
          let mobileMask = document.querySelector('.c-header__mobile-mask');
          mobileMask.addEventListener('click', () => {
            body.classList.remove('is-menu-open');
          });
        })
        button.appendChild(span);
        button.appendChild(screenReader);
      }
    }
    buttonItem.appendChild(button);
    mobileButtons.appendChild(buttonItem);
    element.appendChild(mobileButtons);
  });
}

export { setMobileMenu };