function addSearchButton(element) {
  const searchButton = document.createElement('a');
  const searchSpan = document.createElement('span');
  const search = element.querySelector('.c-header__search');
  const searchForm = element.querySelector('.c-header__search__form');
  search.classList.add('c-header__search--toggle');
  searchButton.href = '#';
  searchButton.classList.add('c-header__search__toggle');// ie11 doesn't support classlist.add() with multiple classes
  searchButton.classList.add('c-icon');// ie11 doesn't support classlist.add() with multiple classes
  searchButton.classList.add('c-icon--pict-search');
  searchSpan.classList.add('u-screen-reader');
  searchSpan.innerHTML = 'toggle search';
  searchButton.appendChild(searchSpan);
  searchForm.appendChild(searchButton);
  return searchButton;
}

function addSearchEventListeners(element, searchButton, mobileSearchButton) {
  const clickButtons = [searchButton, mobileSearchButton];

  clickButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      toggleSearch(element, searchButton);
    })
  })

  document.body.addEventListener('keydown', (e) => {
    if (e.key === "Escape" && !searchButton.classList.contains('c-icon--pict-search')) {
      toggleSearch(element, searchButton);
    }
  })
}

function toggleSearch(element, searchButton) {
  if (searchButton.classList.contains('c-icon--pict-search')) {
    searchButton.classList.remove('c-icon--pict-search');
    searchButton.classList.add('c-icon--close--thin');// ie11 doesn't support classlist.add() with multiple classes
    searchButton.classList.add('is-active');// ie11 doesn't support classlist.add() with multiple classes
    element.classList.add('is-search-open');// ie11 doesn't support classlist.add() with multiple classes
    element.querySelector('input[type=text]').focus();
  } else {
    searchButton.classList.remove('c-icon--close--thin');// ie11 doesn't support classlist.remove() with multiple classes
    searchButton.classList.remove('is-active');
    searchButton.classList.add('c-icon--pict-search');
    element.classList.remove('is-search-open');
  }
}

export { addSearchButton, addSearchEventListeners }