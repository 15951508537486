import { asArray } from '@nn/psdl-utility/src/object-utils';
import throttle from 'lodash/throttle';
import defaultsDeep from 'lodash/defaultsDeep';
import Dialog from '../dialog/dialog.js';
import { updateArrowConfig } from '../dialog/dialog.helpers.js';

export default class Contextdialog {
  constructor(element, config) {
    this.config = defaultsDeep(config, {
      theme: 'light',
      side: 'right',
      followArrow: true,
      arrow: {
        side: 'left',
        align: 'middle',
        x: 0,
        y: 0,
      }
    });

    this.contentElement = this.config.contentElement;
    if (!this.config.contentElement) {
      this.contentElement = element.querySelector('[data-role="context-dialog-content"]');
    }

    this.button = this.config.button;
    if (!this.config.button) {
      this.button = element.querySelector('[data-role="context-dialog-link"]');
    }

    this.dialog = new Dialog({
      themes: [...asArray(this.config.theme), 'context'],
      closeOnBlueriqStateChange: true,
      closeOnGlassClick: true,
    });

    if (this.contentElement && this.contentElement.childNodes.length) {
      this.dialog.appendContent(this.contentElement.childNodes);
    }

    this.button.addEventListener('click', this.clickHandler.bind(this));
    const repositionHandler = throttle(this.reposition.bind(this), 50, {
      leading: true,
      trailing: true
    });
    window.addEventListener('resize', repositionHandler);
    window.addEventListener('scroll', repositionHandler);
  }

  clickHandler(event) {
    event.preventDefault();
    this.show();
  }

  reposition() {
    if (this.dialog.isOpen() || this.dialog.isOpening()) {
      const initialConfig = { ...this.config };
      updateArrowConfig(this.button, initialConfig);
      this.dialog.reposition(initialConfig, this.button);
    }
  }

  show() {
    const initialConfig = { ...this.config };
    updateArrowConfig(this.button, initialConfig)
    this.dialog.show(initialConfig, this.button);
  }

  hide() {
    this.dialog.hide();
  }

  /**
	 * @param {HTMLNode}, element
	 * @param {Object}, config
	 * @returns {Object}, config
	 */
}

