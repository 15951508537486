
nn.registerModule('notificationlist', (element) => {
  function setupCollapser(element) {
    var data = {
      "component": "Collapser",
      "traversalmethod": "parents",
      "traversalselector": ".c-notification-list__item",
      "contentselector": ".c-notification-list__item__content",
      "animatecollapse": "true",
      "preventContainerActiveClass": "true",
      "initialOpen": "false",
      "collapserNostorage": "true"
    };
    setElementData(element, data);
  }

  // The class to indentify the collapser elements
  var collapserSelector = '.c-notification-list__item__heading__actions__content-toggle';

  // The class to indentify the main notification list
  var listSelector = '.c-notification-list__list';

  // Find and loop through the elements, and set them up with necessary data-* attributes
  var collapserElements = element.querySelectorAll(collapserSelector);
  [].forEach.call(collapserElements, setupCollapser);

  // Kick off the Collapsers
  // Only kick off within the main list,
  // otherwise we'd create a data-component / data-module initialisation loop
  nn.initializeComponents(element.querySelector(listSelector));
});

function setElementData(element, data) {
  var dataset = element.dataset,
    attr,
    key;

  for (key in data) {
    if (data.hasOwnProperty(key)) {
      if (dataset) {
        dataset[key] = data[key];
        window.console.info("dataset set "+key+": "+data[key]);
      } else {
        attr = "data-" + key.replace(/^./, function (str) {
          return str.toLowerCase();
        })
          .replace(/([A-Z])/g, function (str) {
            return "-" + str.toLowerCase();
          });
        element.setAttribute(attr, data[key]);
        window.console.info("attribute set "+attr+": "+data[key]);
      }
    }
  }
}
