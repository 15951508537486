(function(window, $, nn) {
  nn.component.Masthead = function(element) {
    this.element = $(element);

    this.init();
  };

  nn.component.Masthead.prototype = {
    init: function() {
      this.getAndSetElements();
      this.bindEvents();
      this.onWindowResize();
    },
    getAndSetElements: function() {
      this.imageWrapper = $(".masthead-wrapper--image", this.element);
      this.image = $(".masthead-image", this.element);
      this.imageFallback = $("img:not([data-media])", this.image);
      this.imageSources = $("source,img[data-media]", this.image);

      this.prepareImageSources();

      // rvv: temp fix for padding-right on title element (to avoid hitting the sticker)
      if (this.element.find(".sticker-clean").length > 0) {
        this.element.addClass("has-sticker");
      }
    },
    bindEvents: function() {
      if (
        window.matchMedia &&
        window.matchMedia(
          "(max-device-width: " + nn.settings.tabletBreakPoint + "px)"
        ).matches
      ) {
        // do nothing with these kind of devices
      } else {
        $(window).on("resize", $.proxy(this.onWindowResize, this));
      }
      $(window).on("orientationchange", $.proxy(this.onWindowResize, this));

      $(window).on("CMS:EditModeChange", $.proxy(this.onEditModeChange, this));
    },
    onEditModeChange: function() {
      if (nn.isCmsModeEdit()) {
        this.revertImageSources();
        this.onWindowResize();
        this.setBg("none");
        this.image.show();
      } else {
        this.prepareImageSources();
        this.onWindowResize();
        this.image.hide();
      }
    },
    prepareImageSources: function() {
      if (!nn.isCmsModeEdit()) {
        this.imageSources.each($.proxy(this.prepareImageSource, this));
      }
    },
    revertImageSources: function() {
      if (nn.isCmsModeEdit()) {
        this.imageSources.each($.proxy(this.revertImageSource, this));
      }
    },
    prepareImageSource: function(i, el) {
      var $el = $(el);
      // check to support fallback, before cms update
      if ($el.attr("src") !== "#") {
        $el.attr("data-src", $el.attr("src")).attr("src", "#");
      }
    },
    revertImageSource: function(i, el) {
      var $el = $(el);
      $el.attr("src", $el.attr("data-src")).removeAttr("data-src");
    },
    onWindowResize: function() {
      if (nn.isCmsModeEdit()) {
        this.imageWrapper.show().css({ position: "relative" });
        this.image.css({ height: "auto" });

        // Don't change anything in preview mode
        return;
      }
      if (navigator.userAgent.indexOf("Android") < 0) {
        this.viewportWidth = parseInt($("body").width(), 10);
      } else {
        this.viewportWidth = screen.width;
      }

      this.centerImageWrapper();
      this.updateImageWrapperBg();
    },
    centerImageWrapper: function() {
      var notResponsivePageWidth = 990,
        pageWidth = $("#page").width(),
        viewportWidth =
          this.viewportWidth < 320 ? 320 : Math.min(this.viewportWidth, 1280), // maximum viewport width that can be used is 1280
        leftPosition = Math.floor((pageWidth - viewportWidth) / 2);

      if (notResponsivePageWidth >= this.viewportWidth) {
        leftPosition = 0;
      }

      if (
        !nn.helpers.isResponsivePage() &&
        notResponsivePageWidth >= this.viewportWidth
      ) {
        viewportWidth = notResponsivePageWidth;
      }
      this.imageWrapper.css({
        left: leftPosition
      });
    },
    updateImageWrapperBg: function() {
      this.image.hide();
      this.imageFallback.hide();
      this.imageSources.each($.proxy(this.applyImagePolyFill, this));
    },
    applyImagePolyFill: function(i, el) {
      var $el = $(el),
        thisImage = $el.attr("data-src"),
        substrValue = 1;

      if (thisImage) {
        substrValue = thisImage.length;
      }

      var currentImage = this.imageWrapper
        .css("background-image")
        .replace('url("', "")
        .replace('")', "")
        .substr(-substrValue);

      var mediaQuery = window.matchMedia($el.attr("data-media"));

      if (mediaQuery.matches) {
        if (!this.imageWrapper.is(":visible")) {
          this.imageWrapper.show();
        }

        if (currentImage !== thisImage) {
          this.setBg(thisImage);
        }

        return false;
      }

      if (this.imageWrapper.is(":visible")) {
        // hide wrapper when no mobile image is found
        this.imageWrapper.hide();
      }
    },
    setBg: function(bg) {
      var bgVal = bg === "none" ? "none" : "url('" + bg + "')";
      this.imageWrapper.css({ "background-image": bgVal });
    }
  };
})(window, jQuery, nn);
