import { offset } from '../utility/element.helper';
import stickyService from '../utility/stickyService.js';

const stickyClass = 'c-main-navigation__sticky';
const stickyClassMobile = 'c-main-navigation__sticky--mobile';
const stickyClassSlideDown = 'c-main-navigation__sticky--slide-down';
const stickyClassSlideUp = 'c-main-navigation__sticky--slide-up';
const mainMenuSelector = '.c-main-navigation__main';
const fixedBannerSelector = '.c-main-navigation__banner';

export const scroll = {
  isScrollingUp() {
    return window.pageYOffset < this.previousPageYOffset;
  },
  isBelow(threshold) {
    return window.pageYOffset >= threshold;
  },
  isAbove(threshold) {
    return window.pageYOffset <= threshold;
  },
  rememberPageYOffset() {
    this.previousPageYOffset = window.pageYOffset;
  }
};

const stickyMenu = {
  init(element) {
    this.element = element;
    this.mainMenuElement = element.querySelector(mainMenuSelector);
    this.disabled = !this.mainMenuElement || this.hasFixedBanner();
    if (this.disabled) {
      return;
    }

    stickyService.registerTopModule(this);
    this.stickyOffset = offset(this.mainMenuElement).top;
    this.checkStickiness();

    this.checkStickinessHandler = this.checkStickiness.bind(this);

    if (!this.bound) {
      // This is a singleton, bind events only once
      window.addEventListener('scroll', this.checkStickinessHandler);
      window.addEventListener('resize', this.checkStickinessHandler);
      this.bound = true;
    }

    // Ugly fix to remove stickiness when Blueriq element scrollIntoView is found
    // This is a Blueriq module and therefore not adjustable by us
    if (window.radio) {
      window.radio('blueriq.state-change').subscribe(() => {
        if (document.querySelector('.scrollIntoView')) {
          this.disable();
        }
      });
    }
  },

  getTopOffset() {
    const rect = this.mainMenuElement.getBoundingClientRect();
    const isSlidingUp = this.hasMenuClass(stickyClassSlideUp) && rect.bottom > 0 && rect.bottom < this.mainMenuElement.offsetHeight;

    if (this.disabled || !this.hasMenuClass(stickyClass) || rect.bottom === 0 || isSlidingUp) {
      return 0;
    }

    return this.mainMenuElement.offsetHeight;
  },
  clearGlobalEvents() {
    window.removeEventListener('scroll', this.checkStickinessHandler);
    window.removeEventListener('resize', this.checkStickinessHandler);
  },

  checkStickiness() {
    if (this.disabled) {
      return;
    }

    this.toggleSticky();
    this.toggleStickyMobile();
    scroll.rememberPageYOffset();
  },

  hasMenuClass(className) {
    return this.mainMenuElement.classList.contains(className);
  },

  addMenuClass(className) {
    if (!this.hasMenuClass(className)) {
      this.mainMenuElement.classList.add(className);
    }
  },

  removeMenuClass(className) {
    if (this.hasMenuClass(className)) {
      this.mainMenuElement.classList.remove(className);
    }
  },

  toggleSticky() {
    const topOfMainMenu = offset(this.mainMenuElement).top;
    if (scroll.isBelow(topOfMainMenu) && !this.hasMenuClass(stickyClass)) {
      this.addMenuClass(stickyClass);
    } else if (scroll.isAbove(this.stickyOffset)) {
      this.removeMenuClass(stickyClass);
    }
  },

  toggleStickyMobile() {
    if (scroll.isBelow(offset(this.element).bottom)) {
      this.addMenuClass(stickyClassMobile);
    } else if (scroll.isAbove(this.stickyOffset)) {
      this.removeMenuClass(stickyClassMobile);
      this.removeMenuClass(stickyClassSlideDown);
      this.removeMenuClass(stickyClassSlideUp);
    }

    if (!this.hasMenuClass(stickyClassMobile)) {
      return;
    }

    if (scroll.isScrollingUp()) {
      this.addMenuClass(stickyClassSlideDown);
      this.removeMenuClass(stickyClassSlideUp);
    } else {
      this.removeMenuClass(stickyClassSlideDown);
      this.addMenuClass(stickyClassSlideUp);
    }
  },

  disable() {
    if (this.disabled) {
      return;
    }
    this.disabled = true;
    this.removeMenuClass(stickyClass);
    this.removeMenuClass(stickyClassMobile);
    this.removeMenuClass(stickyClassSlideDown);
    this.removeMenuClass(stickyClassSlideUp);
    this.clearGlobalEvents();
  },

  hasFixedBanner() {
    return !!this.element.querySelector(fixedBannerSelector);
  }
};

export default stickyMenu;