export function causeEvent(element, eventType) {
  if (element.fireEvent) {
    element.fireEvent('on' + eventType);
  } else {
    const eventObject = document.createEvent('Events');
    eventObject.initEvent(eventType, true, false);
    element.dispatchEvent(eventObject);
  }
}

export function moveChildNodes(fromElement, toElement) {
  while (fromElement.hasChildNodes()) {
    toElement.appendChild(fromElement.firstChild);
  }
}

export function offset(element) {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft, bottom: rect.bottom + scrollTop, right: rect.right + scrollLeft}
}

export function setWithChange(element, value) {
  if (element.value !== value) {
    element.value = value;
    causeEvent(element, 'change');
  }
}

export default {
  setWithChange,
  causeEvent,
  moveChildNodes,
  offset
};