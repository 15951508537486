(function ($, nn) {
  nn.component.Range = function (element) {
    this.$element = $(element);
    this.init();
  };

  nn.component.Range.prototype = {
    init () {
      this.getAndSetOptions();
      this.getAndSetElements();
      this.bindEvents();
    },

    getAndSetOptions () {
      this.rangesliderOptions = {
        polyfill: false,
        rangeClass: 'rangeslider',
        fillClass: 'rangeslider-fill',
        handleClass: 'rangeslider-handle'
      };
      this.showLimits = this.$element.attr('data-range-show-limits');
      this.showCurrentValue = !!this.$element.data('range-show-currentvalue');
      this.formattedValue = this.$element.attr('data-formatted-value'); // merge conflicts. Remove after sprint 45.
      this.nameAttr = this.$element.attr('name');
      this.unitPrefix = this.$element.attr('data-range-unit-prefix');
      this.unitSuffix = this.$element.attr('data-range-unit-suffix');
      this.customLabelMax = this.$element.attr('data-range-label-max');
      this.customLabelMin = this.$element.attr('data-range-label-min');
      this.decimals = this.$element.attr('data-decimals') || 0;
    },

    getAndSetElements () {
      if (this.formattedValue) {
        this.$element.val(this.parse(this.formattedValue, true));
      }

      if (this.showLimits || this.showCurrentValue) {
        this.$wrapperElement = this.$element.wrap('<div class="range-wrapper" />').parent('.range-wrapper');
        this.$currentValueElement = this.$element.parent().siblings('input.range-valuedisplay');

        if (this.showLimits === 'true') {
          this.$minElement = $('<span class="range-min" />');
          this.$maxElement = $('<span class="range-max" />');
          if (this.customLabelMin) {
            this.$minElement.text(this.customLabelMin);
          } else {
            this.$minElement.text(this.getLimitText(this.$element.attr('min')));
          }
          if (this.customLabelMax) {
            this.$maxElement.text(this.customLabelMax);
          } else {
            this.$maxElement.text(this.getLimitText(this.$element.attr('max')));
          }
          this.$minElement.appendTo(this.$wrapperElement);
          this.$maxElement.appendTo(this.$wrapperElement);

        }
        if (this.showCurrentValue) {
          this.$wrapperElement.addClass('range-wrapper--valueshown');
          this.currentValueWrapper = $('<span class="range-valuedisplay-wrapper" />').appendTo(this.$wrapperElement);

          if (!this.$currentValueElement.length) {
            if (this.$element.is(":disabled")) {
              this.$currentValueElement = $('<input type="text" class="text-field range-valuedisplay" name="' + this.nameAttr + '-dummy" data-component="TextField" disabled >');

            } else {
              this.$currentValueElement = $('<input type="text" class="text-field range-valuedisplay" name="' + this.nameAttr + '-dummy" data-component="TextField" >');
            }

            // if we have a name add it to the element that holds the current value
            if (this.nameAttr !== undefined) {
              this.$currentValueElement.attr('name', this.nameAttr + '_currentvalue');
            }
          }
          this.$currentValueElement
            .appendTo(this.currentValueWrapper)
            .val(this.formatNumber(this.$element.val()));


          if (!!this.unitPrefix && this.unitPrefix !== '') {
            this.currentValuePrefix = $('<span class="range-valuedisplay-prefix">' + this.unitPrefix + '</span>').insertBefore(this.$currentValueElement);
            this.$currentValueElement.addClass("range-valuedisplay-prefix-input");
          }
          if (!!this.unitSuffix && this.unitSuffix !== '') {
            this.currentValueSuffix = $('<span class="range-valuedisplay-suffix">' + this.unitSuffix + '</span>').insertAfter(this.$currentValueElement);
          }
        }
      }
      this.$element.rangeslider(this.rangesliderOptions);
    },

    bindEvents () {
      if (this.showCurrentValue) {
        this.$element.on('input', this.onChange.bind(this));
        this.$currentValueElement.on('blur', this.onBlurTextInput.bind(this));
        this.$currentValueElement.on('change', this.onSetValueChange.bind(this));
        this.$currentValueElement.on('keydown', this.onSetValueKeydown.bind(this));
      }
    },

    getLimitText (text) {
      text = this.formatNumber(text);

      if (!!this.unitPrefix && this.unitPrefix !== '') {
        text = this.unitPrefix + ' ' + text;
      }
      if (!!this.unitSuffix && this.unitSuffix !== '') {
        text = text + ' ' + this.unitSuffix;
      }

      return text;
    },

    formatNumber (number) {
      this.formatter = this.formatter || new Intl.NumberFormat('nl-NL', { maximumFractionDigits: this.decimals, minimumFractionDigits: this.decimals });
      return this.formatter.format(number);
    },

    onChange () {
      this.$currentValueElement.val(this.formatNumber(this.$element.val()));
    },

    onBlurTextInput () {
      this.$currentValueElement.val(this.formatNumber(this.parse(this.$currentValueElement.val())));
    },

    onSetValueChange () {
      var entry = this.$currentValueElement.val();
      if (this.isValidEntry(entry)) {
        this.$element
          .val(this.parse(entry))
          .change();
      } else {
        this.$currentValueElement.val(this.formatNumber(this.$element.val()));
      }
    },

    onSetValueKeydown (evt) {
      var isNumeric = nn.helpers.isNumericKeyCode(evt),
        isArrow = nn.helpers.isArrowKeyCode(evt),
        kc_backspace = 8,
        kc_tab = 9,
        kc_delete = 46,
        kc_dot = 188,
        kc_comma = 190;

      if (evt.keyCode !== kc_backspace
        && evt.keyCode !== kc_tab
        && evt.keyCode !== kc_delete
        && evt.keyCode !== kc_dot
        && evt.keyCode !== kc_comma
        && !isNumeric
        && !isArrow) {
        evt.preventDefault();
      }
    },

    isValidEntry (value) {
      var parsedValue = this.parse(value);
      var maxInt = parseInt(this.$element.attr('max'), 10);
      var minInt = parseInt(this.$element.attr('min'), 10);
      return nn.helpers.isNumeric(parsedValue)
        && parsedValue <= maxInt
        && parsedValue >= minInt;
    },

    parse (numberString, setup) {
      if (this.decimals) {
        if (!setup) {
          numberString = numberString.replaceAll('.', '').replace(',','.');
        }
        return parseFloat(numberString);
      }
      return parseInt(numberString.replaceAll('.',''), 10);
    },
  };

})(jQuery, nn);
