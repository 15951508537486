export default function (params) {

  let elementConfig;
  if (params.element) {
    elementConfig = params.element.getAttribute('data-config');
    if (typeof elementConfig === 'string' && elementConfig !== '') {
      try {
        elementConfig = JSON.parse(elementConfig.replace(/'/g, '"'));
      } catch (e) {
        throw new Error('Cannot parse data-config: ' + e.message);
      }
    }
  }

  const instanceConfig = params.options || {};

  const defaults = params.defaults || {};

  return Object.assign({}, defaults, elementConfig, instanceConfig);
}
