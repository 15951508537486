const mainNavSelector = '.c-main-navigation';
const mainNavClass = 'c-main-navigation';
const PortalDropDownFallbackTitle = "Particulier";

const menuItemsSelectors = {
  appendFirst: [mainNavSelector + '__logo'],
  appendLast: [mainNavSelector + '__co-branding', mainNavSelector + '__links', mainNavSelector + '__login', mainNavSelector + '__profile']
};

const portalMenu = {
  mainNavElement: null,
  mainMenuElement: null,
  portalMenuElement: null,

  init(element) {
    this.mainNavElement = element;
    this.mainMenuElement = element.querySelector(mainNavSelector + '__main');
    if (!this.mainMenuElement) {
      return;
    }

    this.portalMenuElement = element.querySelector(mainNavSelector + '__portal > ul');

    if (!this.portalMenuElement) {
      this.createNewMenu();
    } else {
      this.createPortalDropDown();
    }
    this.addMenuItems();
  },

  getAllPortalLinks() {
    let allPortalLinks = '';
    [...this.portalMenuElement.querySelectorAll(mainNavSelector + '__portal-menu > ul li')].forEach(item => {
      allPortalLinks = allPortalLinks + item.innerHTML;
    });
    return allPortalLinks;
  },

  getDropdownTemplate() {
    const activePortalMenuItem = this.portalMenuElement.querySelector(mainNavSelector + '__active');
    const activePortalMenuItemText = activePortalMenuItem ? activePortalMenuItem.innerHTML : PortalDropDownFallbackTitle;
    const parentElement = document.createElement('li');
    parentElement.classList.add(mainNavClass + '__portal__dropdown');
    parentElement.innerHTML = '<button class="c-main-navigation__toggler" data-toggle="submenu">' + activePortalMenuItemText + '</button>';

    return parentElement;
  },

  createPortalDropDown() {
    const div = document.createElement('div');
    div.classList.add(mainNavClass + '__submenu');
    div.innerHTML = this.getAllPortalLinks();

    const element = this.getDropdownTemplate();
    element.appendChild(div);
    this.portalMenuElement.appendChild(element);
  },

  getNodeToAdd(selector) {
    const element = this.mainMenuElement.querySelector(selector);
    if (element) {
      return element;
    }
    return this.mainMenuElement.querySelector("li" + selector, "[style*='display:none']");
  },

  addMenuItems() {
    menuItemsSelectors.appendFirst.forEach((selector) => {
      const nodeToAppend = this.getNodeToAdd(selector)
      if(nodeToAppend){
        this.portalMenuElement.insertBefore(nodeToAppend.cloneNode(true), this.portalMenuElement.childNodes[0]);
      }
    });

    menuItemsSelectors.appendLast.forEach((selector) => {
      const nodeToAppend = this.getNodeToAdd(selector)
      if(nodeToAppend){
        this.portalMenuElement.appendChild(nodeToAppend.cloneNode(true));
      }
    });
  },
  createNewMenu() {
    this.mainNavElement.classList.add(mainNavClass + '--custom-height');
    const portalMenuNav = document.createElement('nav');
    portalMenuNav.classList.add(mainNavClass + '__portal');
    portalMenuNav.classList.add(mainNavClass + '__portal' + '--custom-height');
    this.portalMenuElement = document.createElement('ul');
    this.portalMenuElement.classList.add(mainNavClass + '__portal--user-logo');
    portalMenuNav.appendChild(this.portalMenuElement);
    const header = this.mainNavElement.querySelector('header');
    header.insertBefore(portalMenuNav, this.mainMenuElement);
  }
};

export default portalMenu;
