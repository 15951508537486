const stickyService = {
  stickyModules: {
    top: [],
  },
  registerTopModule(module) {
    if(!module.getTopOffset || typeof module.getTopOffset !== 'function') {
      throw new Error('Unable to register TopModule: module missing required function getTopOffset');
    }
    this.stickyModules.top.push(module)
  },
  getTopOffset() {
    return this.stickyModules.top.reduce((offset, module) => offset + module.getTopOffset() || 0, 0)
  }
};

window.app = window.app || {};
window.app.stickyService = stickyService;

export default stickyService;