/**
 * @handler initializeObjects
 * @description
 *    This method is needed for some IAF forms. They require this method to initialize some
 *    logic on following form steps
 */

nn.handlers.initializeObjects = function (context) {
  nn.initializeComponents(context);
}
;