import uniqueId from "lodash/uniqueId";
import moment from "moment";

const Details = function(element, options) {
  // Our main container
  var $element = $(element);

  var rawData;
  var processedData = {};

  // Flag to indicate if JSON data is processed
  var processed = false;

  // Section container
  var $sections;

  var config = $element.data("config");
  if (typeof config === "string" && config !== "") {
    config = JSON.parse(config.replace(/'/g, '"'));
  }

  config = $.extend({}, config, options);

  // Data which needs to be posted in order to get Dossier Details
  var postData = {
    GetDossierMetaData_Request: {
      UsernameRequester: "",
      DossierId: config.DossierId || 0
    }
  };

  // Unique id to recognize this specific object instance when subcribers receive its signals
  var publisherId;

  // Callback to use when rendering is done. Default to empty noop
  var detailsRenderedCallback = function() {};

  // Set the callback to run after rendering
  function onDetailsRendered(callback) {
    if (typeof callback === "function") {
      detailsRenderedCallback = callback;
    }
  }

  // Render Dossier Details
  function renderApp() {
    $element.html(generateLayoutTemplate(getTitle(), getContent()));
    renderItems();
    detailsRenderedCallback();
  }

  // Return Dossier Details Title ("Header" in JSON)
  function getTitle() {
    return processed ? processedData.Header : "";
  }

  // Return Dossier Details Items ("Section" in JSON)
  function getItems() {
    return processed ? processedData.Section : [];
  }

  function getContent() {
    return processed ? processedData.Content : "";
  }

  // Render Dossier Detail Items
  function renderItems() {
    $sections = $element.find('[data-role="details-section"]');
    $sections.html(generateSectionTemplate(getItems()));
  }

  function handleJSON(data) {
    setData(data);
    renderApp();
  }

  function showError() {
    processedData = {
      Header: "Foutmelding",
      Content: "De gegevens zijn niet (meer) beschikbaar."
    };
    processed = true;
    renderApp();
  }

  // Store received (JSON) data
  function setData(data) {
    rawData = data;

    if (rawData.GetDossierMetaData_Response.Result.Status !== "OK") {
      showError();
      return;
    }

    processedData =
      rawData.GetDossierMetaData_Response.MetaData.DossierMetaData;
    processed = true;
  }

  // Return Publisher ID
  function getPublisherId() {
    if (!publisherId) {
      publisherId = uniqueId("pubsubid");
    }
    return publisherId;
  }

  // Check if we have a data URL, then kick off the Dossier Details
  if (typeof config.src === "string") {
    var testmode =
      typeof config.testmode !== "undefined" && config.testmode === true;

    $.ajax({
      type: testmode ? "GET" : "POST",
      url: config.src,
      data: JSON.stringify(postData),
      dataType: "json"
    })
      .done(handleJSON)
      .fail(showError);
  }

  // Public Method(s)
  return {
    getPublisherId: getPublisherId,
    onDetailsRendered: onDetailsRendered
  };
};

const generateLayoutTemplate = (title, content) => {
  return `
    <div class="js-dialogsummary">
      ${title ? `<h1 data-role="details-title" class="c-h1">${title}</h1>` : ""}
      <div data-role="details-section"></div>
      ${content ? `<p>${content}</p>` : ""}
    </div>`;
};

const generatTableTemplate = rows => {
  let result = "";
  rows.map(row => {
    if (row.Type === "subheader") {
      result += `<tr class="c-table__body__row">
                  <td class="c-table__body__hcell" colspan="2"><strong>${row.Name}</strong></td>
                </tr>`;
    } else {
      result += `<tr class="c-table__body__row">
                  <td class="c-table__body__hcell u-width-40p">${row.Name}</td>
                  <td class="c-table__body__cell">`;
      if (row.Type === "cur") {
        result += `&euro; ${row.Value}`;
      } else if (row.Type === "date") {
        result += moment(row.Value,"DD-MM-YYYY").format("D MMMM YYYY");
      } else {
        result += row.Value;
      }
      result += `</td></tr>`;
    }
  });
  return result;
};

const generateSectionTemplate = items => {
  if (items) {
    return items.map(item => {
      return `<table class="c-table">
        <thead class="c-table__header">
            <tr class="c-table__header__row">
                <th class="c-table__header__cell" colspan="2">
                  ${item.Header}
                </th>
            </tr>
        </thead>
        <tbody class="c-table__body">
           ${generatTableTemplate(item.Rows)}
        </tbody>
    </table>`;
    });
  }
};

export default Details;