nn.component.LicensePlate = function (element) {

  if (!element) {
    return;
  }

  this.element = $(element);

  this.getAndSetOptions();
  this.bindEvents();
};

nn.component.LicensePlate.prototype = {
  getAndSetOptions: function () {
    this.changeAllowed = false;
    this.testRegExp = /^([A-Z]|\d){1,3}-([A-Z]|\d){1,3}-([A-Z]|\d){1,3}$/;
  },
  bindEvents: function () {
    this.element.on('keydown', $.proxy(this.onKeyDownHandler, this));
    this.element.bind('input', $.proxy(this.onInput, this));
  },
  isValid: function () {
    var val = this.element.val().toUpperCase();
    return (val.length === 8 && this.testRegExp.test(val));
  },
  onInput: function () {
    var val = this.element.val().toUpperCase().replace(/[^A-Z0-9]+/g, '');

    if (val.length > 1 && val.length < 7) {
      val = val.match(/([A-Z]+)|([0-9]+)/g).join("-").replace(/([a-z]{2})([a-z]{2,})/gi, '$1-$2').replace(/(\d{2})(\d{2,})/gi, '$1-$2');

      this.element.val(val);

      if (this.isValid()) {
        this.changeAllowed = true;
        this.element.trigger("change");
      }
    } else if (val.length === 8) {
      if (this.isValid()) {
        this.element.trigger("change");
      }
    }
  },
  onKeyDownHandler: function (e) {
    var kc_backspace = 8,
      kc_tab = 9,
      kc_delete = 46,
      kc_dash = 189;

    if ((e.keyCode === kc_backspace
      || e.keyCode === kc_tab
      || e.keyCode === kc_delete
      || nn.helpers.isArrowKeyCode(e)
      || e.keyCode === kc_dash
      || nn.helpers.isAlphaNumericKeyCode(e)
      || nn.helpers.isNumericKeyCode(e)
    )) {
      // valid character
    } else {
      // invalid character
      e.preventDefault();
    }

    if (!this.validLength()
      && (e.keyCode === kc_backspace
        || e.keyCode === kc_tab
        || e.keyCode === kc_delete
        || nn.helpers.isArrowKeyCode(e))) {
      // valid length
    } else if (!this.validLength()) {
      e.preventDefault();
    }

  },
  validLength: function () {
    var rawValue = this.element.val().replace(/-/g, '');
    return rawValue.length < 6;
  }
}