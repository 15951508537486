import moduleconfig from "../u-moduleconfig/moduleconfig";

window.app = window.app || {};
window.app.form = window.app.form || {};

window.app.form.inputfile = function (element, options) {

  // Our main container/element
  const $element = $(element);

  // Parse and/or arrange any given config parameters
  const config = moduleconfig({
    "options": options,
    "element": element,
    "defaults": {
      "accept": "",
      "max-filesize": 0,
      "errormimetype": "Dit bestandstype wordt niet ondersteund",
      "errorfilesizebefore": "De bestandsgrootte mag maximaal",
      "errorfilesizeafter": "zijn"
    }
  });

  // Error container provided by GX/XC
  const errorContainer = $element.closest('.field').find($('[id^=error_]')).first();

  // The accepted MIME types for this field. Defaults to empty string (all MIMEs accepted)
  const acceptedMimetypes = (config.accept !== '') ? ',' + config.accept : config.accept;

  // Check for the various File API support.
  const fileApiSupported = (window.File && window.FileReader && window.FileList && window.Blob);

  // Field change listener
  function onChange(evt) {
    checkAllowedFileTypes(evt.target);
    checkAllowedFilesize(evt.target);
  }

  // Set change listener on input
  $element.on('change', onChange);


  // Check the field for unallowed file size
  function checkAllowedFilesize(input) {
    if (fileApiSupported !== '') {
      let illegalFile = false;
      for (let i = input.files.length - 1; i >= 0; i--) {
        if (config["max-filesize"] > 0 && input.files[i].size > config["max-filesize"]) {
          illegalFile = true;
        }
      }
      if (illegalFile) {
        $element.addClass('c-form-text--unallowed-filesize');
        errorContainer.text(config.errorfilesizebefore + ' ' + bytesToSize(config["max-filesize"]) + ' ' + config.errorfilesizeafter);
      } else {
        $element.removeClass('c-form-text--unallowed-filesize');
      }
    }
  }

  // Check the field for unallowed file types
  function checkAllowedFileTypes(input) {
    if (fileApiSupported && acceptedMimetypes !== '') {
      let illegalFile = false;
      //when files[i].type cannot be determend we will allow the case
      for (let i = input.files.length - 1; i >= 0; i--) {
        if (acceptedMimetypes !== '' && acceptedMimetypes.indexOf(',' + input.files[i].type) === -1) {
          illegalFile = true;
        }
      }
      //TODO: this should get a js-cms-fileuploadtroep name
      if (illegalFile) {
        $element.addClass('c-form-text--unallowed-filetype');
        errorContainer.text(config.errormimetype);
      } else {
        $element.removeClass('c-form-text--unallowed-filetype');
      }
    }
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Bytes';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  return {
    "getFilesize": window.app.form.inputfile.getFilesize
  };

};

window.app.form.inputfile.getInputFilesize = function (input) {
  // TODO: Check if this really is a file input
  // TODO: Where do we place public helpers like this one?
  let totalFilesize = 0;
  for (let i = input.files.length - 1; i >= 0; i--) {
    totalFilesize += input.files[i].size;
  }

  return totalFilesize;
};
