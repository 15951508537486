(function (window, $, nn) {
  /**
   *
   */
  nn.component.Iframe = function (element, options) {
    this.element = $(element);
    this.autoResize = true;
    this.resizeOnInit = true;
    this.iframeSizeTimeOut = 1000;
    this.initialized = false;
    this.panelId = this.element.parents('.tabpanel-panel').attr('id');

    if (options && options.autoResize) {
      this.autoResize = options.autoResize;
    }

    if (options && options.resizeOnInit) {
      this.resizeOnInit = options.resizeOnInit;
    }

    this.init();
    this.bindEvents();
  };

  /**
   *
   */
  nn.component.Iframe.prototype = {
    init: function () {
      if (typeof this.element.data('auto-resize') !== 'undefined') {
        var autoResize = parseInt(this.element.data('auto-resize'), 10);
        this.autoResize = autoResize === 1;
      }

      this.enableNestedResize();
    },
    bindEvents: function () {
      this.element.on('load', $.proxy(this.onLoadHandler, this));
    },
    onLoadHandler: function () {
      var self = this;

      setTimeout(function () {
        $(window).trigger('Iframe:Page:Loaded', {
          element: self.element.get(0),
        });

        if (self.autoResize) {
          self.enableAutoResize();
        }

        if (self.resizeOnInit) {
          self.resizeFrameHeight();
        }
      }, 0);
    },
    resizeFrameHeight: function () {
      try {
        var iframeHeight,
          contents = this.element.contents();

        if (!this.element.attr('src')) {
          return;
        }

        contents.find('body').css('height', 'auto');
        iframeHeight = parseInt(contents.find('body').outerHeight(true), 10);
        this.element.height(iframeHeight);

        if (this.element.attr('height')) {
          this.element.removeAttr('height');
        }
      } catch (e) {
        // IFrame in a dialog that will loose it's reference causes an error
      }
    },
    resizeNestedFrameHeights: function () {
      try {
        var reference = nn.helpers.hasDomReference(this.element.get(0));
        if (!reference) {
          this.disableAutoResize();
          this.disableNestedResize();
          return;
        }

        var secIframeMinHeight = '1300px',
          secIframe = null,
          isIE11 = !!(
            navigator.userAgent.match(/Trident/) &&
            !navigator.userAgent.match(/MSIE/)
          ),
          secIframeBody = null;

        if (isIE11) {
          secIframe = $(
            this.element[0].contentDocument.getElementsByTagName('iframe')[0],
          );
          secIframeBody = $(
            secIframe[0].contentDocument.getElementsByTagName('body')[0],
          );
        } else {
          secIframe = this.element.contents().find('iframe');
          secIframeBody = secIframe.contents().find('body');
        }

        //exit function when iframe is found and is hidden
        if (secIframe.is(':hidden')) {
          return;
        }

        if (secIframeBody.find('#iframe-end').length === 0) {
          secIframeBody.css('height', 'auto');
          secIframeBody.append('<br style="clear: both;" id="iframe-end" />');
        }
        secIframeMinHeight = parseInt(secIframeBody.outerHeight(true), 10);

        if (secIframe.attr('height')) {
          secIframe.removeAttr('height');
        }
        secIframe.height(secIframeMinHeight);
      } catch (e) {
        //console.log('[IFRAME]: no content for nested iframe found', e.message);
      }
    },
    enableNestedResize: function () {
      var timeOut = this.iframeSizeTimeOut;
      this.nestedIframeSizeTimeOutId = setInterval(
        $.proxy(this.resizeNestedFrameHeights, this),
        timeOut,
      );
    },
    enableAutoResize: function () {
      var timeOut = this.iframeSizeTimeOut;

      if (!this.inizialized) {
        this.inizialized = true;
        timeOut = 0;
      }

      this.iframeSizeTimeOutId = setInterval(
        $.proxy(this.resizeFrameHeight, this),
        timeOut,
      );
    },
    disableAutoResize: function () {
      if (this.iframeSizeTimeOutId) {
        clearInterval(this.iframeSizeTimeOutId);
      }
    },
    disableNestedResize: function () {
      if (this.nestedIframeSizeTimeOutId) {
        clearInterval(this.nestedIframeSizeTimeOutId);
      }
    },
  };
})(window, jQuery, nn);
