nn.registerModule('downloadlink', (element, options) => {
  // Parse and/or arrange any given config parameters
  element.config = window.app.helper.moduleconfig({
    "options": options,
    "element": element,
    "defaults": {
      timeout: 20000,
      cookiePollInterval: 100,
      cookieValue: ''
    }
  });

  var anchor = element.querySelector('[data-role="anchor"]');
  anchor.addEventListener('click', handleClick.bind(undefined, element, anchor));
});

// Release the anchor in the component. This effectively resets the component.
function release(element) {
  // We're no longer busy
  element.classList.remove('is-busy');

  // Clear the timer
  clearTimeout(element.timeoutTimer);

  // Clear the cookie, if relevant
  if (element.config.cookieName) {
    clearInterval(element.cookieInterval);
    clearCookie(element);
  }
}

// Clears the cookie (in case it was left over from somewhere)
function clearCookie(element){
  document.cookie = element.config.cookieName+'=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// Check if the name=value cookie combination specified in the component config exists. If yes, we release the anchor.
function checkCookie(element) {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    if (cookies[i].trim() === (element.config.cookieName+'='+element.config.cookieValue)) {
      release(element);
      return;
    }
  }
}

function handleClick(element, anchor, event) {
  // Ignore repeated clicks if we're busy
  if (element.classList.contains('is-busy')) {
    event.preventDefault();
    return;
  }

  // We're busy
  element.classList.add('is-busy');

  // Timeout if the download takes too long
  element.timeoutTimer = setTimeout(release.bind(undefined, element), element.config.timeout);

  // Poll for the cookie, if relevant
  if (element.config.cookieName) {
    element.cookieInterval = setInterval(checkCookie.bind(undefined, element), element.config.cookiePollInterval);
    clearCookie(element);
  }
}
