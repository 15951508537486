nn.handlers.init.Glossary = function () {
  $('a.glossary').each(function () {
    new nn.component.Glossary(this);
  });

  $(window)
    .on('Aquima:Element:Refresh', function (e, element) {
      $('a.glossary', element).each(function () {
        new nn.component.Glossary(this);
      });
    });
};

nn.handlers.init.Lightbox = function () {
  $('a.lightbox:not([data-component="AjaxDialogLink"])').each(function () {
    new nn.component.AjaxDialogLink(this);
  });

  $(window)
    .on('Aquima:Element:Refresh', function (e, element) {
      $('a.lightbox:not([data-component="AjaxDialogLink"])', element).each(function () {
        new nn.component.AjaxDialogLink(this);
      });
    });
};

nn.handlers.init.Responsiveness = function () {
  if ($('body').hasClass('is-responsive')) {
    $('html').addClass('is-responsive');
  }
}

nn.handlers.init.Collapser = function () {
  $(window)
    .on('Collapser:refresh', function () {
      new nn.component.Collapser(this);
    });
};

nn.handlers.init.Editmode = function () {

  var $body = $('body');

  if (!$body.hasClass(nn.globals.cms.previewModeClass)) {
    // Only run this thing when in preview mode.
    // This prevents the observer from running in production.
    return;
  }

  var updateCmsModes = function () {
    var bodyHasPreview = $body.hasClass(nn.globals.cms.previewModeClass);
    var bodyhasEdit = $body.hasClass(nn.globals.cms.editModeClass);

    if (!nn.isCmsModeEdit() && bodyhasEdit) {
      nn.globals.cms.isModeEdit = true;
      $body.trigger('CMS:EditModeChange');
    } else if (nn.isCmsModeEdit() && !bodyhasEdit) {
      nn.globals.cms.isModeEdit = false;
      $body.trigger('CMS:EditModeChange');
    }

    if (!nn.isCmsModePreview() && bodyHasPreview) {
      nn.globals.cms.isModePreview = true;
      $body.trigger('CMS:PreviewModeChange');
    } else if (nn.isCmsModePreview() && !bodyHasPreview) {
      nn.globals.cms.isModePreview = false;
      $body.trigger('CMS:PreviewModeChange');
    }
  }

  updateCmsModes();

  if (typeof window.MutationObserver === 'function') {
    var observer = new MutationObserver(function (mutations) {
      var i = 0,
        il = mutations.length,
        m = null;
      while (i < il) {
        m = mutations[i];
        if (m.type === 'attributes' && m.attributeName === 'class') {

          updateCmsModes();

          break;
        }
        i += 1;
      }
    });

    observer.observe($body[0], {
      attributes: true,
      childList: false,
      characterData: false,
      attributeOldValue: true
    });
  }
};
