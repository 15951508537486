window.app = window.app || {};
window.app.notificationCenter = window.app.notificationCenter || {};
window.app.notificationCenter.List = async function (root) {

  const NotificationCenterPromise = import(/* webpackChunkName: "customer" */ './notificationCenter.js');
  const NotificationCenter = (await NotificationCenterPromise).default;

  const config = {
    feed: root.getAttribute('data-feed'),
    actionFeed: root.getAttribute('data-action-feed'),
    origin: root.getAttribute('data-origin'),
  };

  return new NotificationCenter(root, config).init();
};
