// if data is Array we return data, if not we return ar array of one object [data]
function asArray(data) {
  if (!data) {
    // data was empty, so return undefined
    return;
  }

  if (Array.isArray(data)) {
    return data;
  }

  return [data];
}

function extend(defaults, options) {
  var extended = {};
  var prop;
  for (prop in defaults) {
    if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
      extended[prop] = defaults[prop];
    }
  }
  for (prop in options) {
    if (Object.prototype.hasOwnProperty.call(options, prop)) {
      extended[prop] = options[prop];
    }
  }
  return extended;
}

function filterEmptyProperties(data) {
  let filteredData = {};

  forEach(data, (value, key) => {
    if (null !== value && undefined !== value && '' !== value) {
      filteredData[key] = value;
    }
  });

  return filteredData;
}

function flattenDeep(array) {
  return Array.isArray(array)
    ? array.reduce((a, b) => a.concat(flattenDeep(b)), [])
    : [array];
}

function forEach(object, fn) {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      fn(object[key], key);
    }
  }
}

function get(obj, path, defaultValue) {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj,
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

function isObject(objectToCheck) {
  return objectToCheck && {}.toString.call(objectToCheck) === '[object Object]';
}

function isEmpty(obj) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
}

export {
  forEach,
  asArray,
  extend,
  filterEmptyProperties,
  flattenDeep,
  get,
  isEmpty,
  isFunction,
  isObject,
};
