nn.component.IbanFormatter = function (element) {
  this.element = $(element);
  this.converterUrl = this.element.data('converter');
  this.init();
};

nn.component.IbanFormatter.prototype = {
  init: function () {
    this.element
      .on('change', $.proxy(this.onChangeEvent, this))
      .addClass('uppercase');

    if (!nn.helpers.isAndroid()) {
      this.element.on('keydown', $.proxy(this.onKeyDownHandler, this));
    }

  },

  onChangeEvent: function () {

    var v = this.element.val();
    this.element.val(v.replace(/[^a-zA-Z0-9]/g, "").toUpperCase());
  },
  onKeyDownHandler: function (e) {

    var kc_backspace = 8,
      kc_tab = 9,
      kc_delete = 46;

    if (e.keyCode === kc_backspace
      || e.keyCode === kc_tab
      || nn.helpers.isArrowKeyCode(e)
      || nn.helpers.isAlphaNumericKeyCode(e)
      || nn.helpers.isNumericKeyCode(e)
      || e.keyCode === kc_delete) {
      // valid character
    } else {
      // invalid character
      e.preventDefault();
    }
  }
};
