/**
 * @class Dialog
 * @description Provides a generic dialog.
 *
 * @example
 *    var dialog = new nn.component.Dialog();
 *    dialog.content.html('<p>Hello world</p>');
 *    dialog.show();
 */

/**
 * @constructor
 *
 * @property {boolean} visible = false
 *    Keeps track of the dialog's visibility.
 */
nn.component.Dialog = function (options) {
  options = $.extend({
    enableShadows: true,
    enableGlass: true,
    glassLocked: false,
    locked: false,
    theme: null,
    reposition: null,
    responsive: true
  }, options);

  this.defaultZindex = 1100;
  this.importantZindex = 1500;
  this.reposition = true;
  this.inFront = false;
  this.visible = false;
  this.attached = false;
  this.options = options;
  this.borderFix = false;
  this.mayOpen = true;
  this.oversizedDialogTop = null;
  this.currentWidth = parseInt($(window).width(), 10);

  // Create the dialog and attach it to the document.
  this.window = $('<div class="dialog"></div>');
  this.closeButton = $('<div class="close-button"></div>').appendTo(this.window);
  this.content = $('<div class="content"></div>').appendTo(this.window);

  this.root = this.window;

  if (this.options.reposition !== null) {
    this.reposition = !!this.options.reposition;
  }

  if (this.options.theme !== null) {
    this.root.addClass('dialog--' + this.options.theme);
  }

  if (this.options.locked === true) {
    this.closeButton.hide();
  }

  this.bindEvents();
};

/**
 * @method attach
 * @description
 *    Attaches the dialog to the body element to ensure the DOM
 *    is available to HTML scripts upon document load.
 */

nn.component.Dialog.prototype = {
  bindEvents: function () {

    if ((window.matchMedia && window.matchMedia('(max-device-width:' + nn.settings.tabletBreakPoint + 'px)').matches) || nn.helpers.isAndroid()) {
      // do nothing with these kind of devices
    } else if (this.reposition) {
      $(window).on('resize', $.proxy(this.repositionDialogHandler, this));
    }

    // and the special thanks goes to NOKIA LUMIA :(
    if (this.reposition && navigator.userAgent.match(/Windows Phone/i)) {
      $(window).on('resize', nn.helpers.debounce($.proxy(this.repositionDialogOnWindowsMobile, this), 500));
    }

    $(window).on('orientationchange', $.proxy(this.closeButtonDialogHandler, this));

    this.window.on('click', $.proxy(this.closeUpperDialogsHandler, this));
    this.window.on('dialogs.hide', $.proxy(this.closeThisDialogsHandler, this));

    this.closeButton.on('click', $.proxy(this.closeButtonDialogHandler, this));
  },
  repositionDialogOnWindowsMobile: function () {
    var newWidth = parseInt($(window).width(), 10);
    if (this.currentWidth !== newWidth) {
      this.repositionDialogHandler();
      this.currentWidth = newWidth;
    }
  },
  repositionDialogHandler: function () {
    if (this.visible) {
      this.show(null, null, {fade: false, animate: false});
    }
  },
  closeThisDialogsHandler: function (event, level) {
    if (this.options.locked === true) {
      return;
    }

    if (this.level >= level) {
      this.hide();
    }
  },
  closeUpperDialogsHandler: function (event) {
    // Redirect the window's click event to the dialogs.hide handler.
    event.stopPropagation();

    if (this.options.locked === true) {
      return;
    }

    nn.handlers.dialogs.hide({
      level: this.level + 1
    });
  },
  closeButtonDialogHandler: function (event) {
    event.stopPropagation();
    if (this.options.locked === true) {
      return;
    }

    nn.handlers.dialogs.hide({
      level: this.level
    });
  },
  attach: function () {

    if (this.options.relatedto) {
      this.root.prependTo($(this.options.relatedto));
    } else {
      this.root.appendTo('body');
    }

    this.root.hide(0);
    this.attached = true;
  },
  /**
   * @method detach
   * @description
   *    Remove the dialog from the body object, making it a loose and
   *    anonymous node again. The DOM will not be accessible.
   */

  detach: function () {
    this.root.detach();
    this.attached = false;
  },
  defineLevel: function () {
    if (this.level === undefined || this.level === null) {
      this.level = nn.references.dialogs.length;
      nn.references.dialogs.push(this);
    }
  },
  /**
   * @method show
   * @description Shows the dialog.
   *
   * @param {number} [left]
   * @param {number} [top]
   *    Place the dialog at these coordinates.
   *
   * @example
   *    dialog.show(200, 10);
   */
  show: function (left, top) {
    // If no coordinates are specified, calculate how
    // to position the dialog in the viewport's center.

    if (this.attached === false) {
      this.attach();
    }

    if (!top) {
      this.root
        .css('position', '')
        .css('right', '')
        .css('bottom', '')
        .css('visibility', 'hidden')
        .css('display', 'block')
        .css('left', 0)
        .css('top', 0);

      var windowWidth = $(window).width();
      var rootWidth = this.root.get(0).offsetWidth;
      var scrollTop = parseInt($(window).scrollTop(), 10);
      left = Math.round((windowWidth - rootWidth) / 2);

      if (this.root.height() > $(window).height()) {
        top = $(window).scrollTop() + 20;
      } else {

        top = Math.round(($(window).height() / 2) - (this.root.height() / 2)) + scrollTop;
        top = Math.max(top, 20);
      }
    }

    if (this.options.enableGlass) {
      nn.globals.glass.show();
    }

    if (this.options.glassLocked) {
      nn.globals.glass.lock();
    }

    var bottom;
    if ($('html').hasClass('is-responsive')
      && $('body').width() < nn.settings.mobileBreakPoint
      && this.options.responsive === true) {
      this.root
        .css('visibility', 'visible')
        .css('display', 'none')
        .css('margin', '0')
        .css('left', '0')
        .css('right', '0')
        .css('top', '20px')
        .css('position', 'fixed')
        .css('bottom', '0');
      $('body').addClass('noscroll');
    } else if (this.options.slideUp) {
      bottom = $(this.options.relatedto).height();
      this.root.css({
        "visibility": "visible",
        "left": 0,
        "top": 'auto',
        'bottom': "-300px",
        "right": 0,
        "max-width": 'none',
        'width': 'auto',
        'position': 'absolute'
      });
      $('body').removeClass('noscroll');
    } else {
      this.root
        .css('visibility', 'visible')
        .css('display', 'none')
        .css('left', left + 'px')
        .css('margin-right', left + 'px')
        .css('top', top + 'px')
        .css('bottom', 'auto');
      $('body').removeClass('noscroll');
    }

    this.root.show(0, this.showComplete.bind(this, !this.visible, bottom));
    this.defineLevel();
    this.visible = true;
  },

  /**
   * @method showComplete
   * @description Show animation complete callback
   *
   * @param {boolean} triggerOpen
   *     If true, trigger dialog-open event.
   * @param {number} bottom
   *     Value to change bottom css value to if option slideUp is set.
   */
  showComplete: function (triggerOpen, bottom) {
    var self = this;

    // HACK to enable scrolling after reopening a dialog on at least IOS devices
    // TODO fixme
    if (nn.helpers.isTouchDevice()) {
      self.content.hide();
    }

    setTimeout(function () {
      if (nn.helpers.isTouchDevice()) {
        self.content.show();
      }

      self.root.stop();

      if (self.options.slideUp) {
        if (nn.helpers.isTouchDevice()) {
          self.root.css({'bottom': bottom});
        } else {
          self.root.animate({'bottom': bottom});
        }
      }

      if (triggerOpen) {
        $(window).trigger('dialog-open', self.root);
      }
    }, 0);
  },

  /**
   * @method hide
   * @description Hides the dialog.
   *
   * @param {object} options
   *    @param {boolean} fade = false
   *       If true, fades the dialog out. If false,
   *       hides it immediately.
   */

  hide: function (options) {
    options = $.extend({
      fade: false
    }, options);

    var self = this;
    this.visible = false;
    this.oversizedDialogTop = null;

    if (this.options.glassLocked) {
      nn.globals.glass.unlock();
      nn.globals.glass.hide();
    }

    if (this.inFront === true) {
      this.inFront = false;
      nn.globals.glass.bringToBack();
    }

    if (options.fade) {
      setTimeout(function () {
        self.root.fadeOut(nn.settings.fadeDuration, function () {

          $(window).trigger('dialog-close', this.root);

          self.root.css('zIndex', self.defaultZindex);
          self.detach();
        });
      }, 0);
    } else {

      $(window).trigger('dialog-close', this.root);

      this.root.hide();
      this.detach();
    }

  },
  bringToFront: function () {
    this.inFront = true;
    this.root.css('zIndex', this.importantZindex);
  }
};


/**
 * @class AjaxLoader
 * @description
 *    adds a ajax loader/spinner on top of the glass
 */

nn.component.AjaxLoader = function () {
  this.loader = $('<div class="ajax-loader"></div>');
  this.visible = false;
}

nn.component.AjaxLoader.prototype = {
  show: function (options) {

    if (this.visible) {
      return;
    }

    nn.globals.glass.lock();
    nn.globals.glass.show(options);
    this.loader.appendTo('body').fadeTo(nn.settings.fadeDuration, 1);
    this.visible = true;
  },
  hide: function () {
    this.loader.remove();
    nn.globals.glass.unlock();
    this.visible = false;
  }
}

/** @constructor */

nn.component.IframeDialog = function (href) {

  this.href = href
  this.dialog = new nn.component.Dialog({"theme": 'iframe'});
};

nn.component.IframeDialog.prototype = {
  show: function () {
    var self = this,
      iframe = $('<iframe border="0" frameBorder="0" src="' + this.href + '" scrolling="no" class="iframe--dialog"></iframe>');

    self.dialog.attach();
    self.dialog.content.html(iframe);
    self.dialog.show();

    // do something with the size of the iframe
    this.bindIframeEvents();
  },
  bindIframeEvents: function () {
    var self = this,
      $iframe = self.dialog.content.find('.iframe--dialog');
    $iframe.on('load', $.proxy(this.resizeFrameToContents, this));
    $iframe.on('load', $.proxy(this.bindCloseEvent, this));

    if (nn.helpers.browser.safari || nn.helpers.browser.opera) {
      var src = $iframe.attr('src');
      $iframe.attr('src', '');
      $iframe.attr('src', src);
    }
  },
  bindCloseEvent: function () {

    var $iframe = this.dialog.content.find('.iframe--dialog');
    $iframe.contents().find('.button--dialog-close').on('click', function (e) {
      e.preventDefault();
      nn.handlers.dialogs.hide({level: 0});
    });
  },
  resizeFrameToContents: function () {

    var $iframe = this.dialog.content.find('.iframe--dialog'),
      self = this;

    setTimeout(function () {

      $iframe.contents().find('body').css('height', 'auto');
      var iframeHeight = parseInt($iframe.contents().find('body').outerHeight(true), 10);
      $iframe.height(iframeHeight);

      self.dialog.content.height(iframeHeight);
      self.dialog.show(null, null, {fade: false});

    }, 0);
  }
};

nn.component.IframeDialogLink = function (element) {

  var self = this;
  this.dialog = new nn.component.IframeDialog(element.href);

  $(element).on('click', function (e) {
    e.preventDefault();
    self.dialog.show();
  });
};


/**
 * @class DialogCloser
 * @description
 *    This class will be applied to html element that contain the class .dialog-closer.
 *    It will trigger the click function of the close button of the given dialog
 *
 * @param {HTMLnode} element
 */
nn.component.DialogCloser = function (element, dialog) {
  this.element = $(element);
  this.dialog = dialog;

  this.element.on('click', function (e) {
    e.preventDefault();

    if (this.dialog) {
      this.dialog.closeButton.trigger('click');
    } else {
      nn.handlers.dialogs.hide();
    }

  })
}

/**
 * @handler dialogs.hide
 * @description
 *    Sends the 'hide' event to all dialogs,
 *    hides the glass pane and updates the
 *    level counter.
 *
 * @param {object} [options]
 *    @param {number} [level = 0]
 *       Hides all dialogs on a specific
 *       level or higher.
 */

nn.handlers.dialogs.hide = function (options) {
  options = $.extend({
    level: 0
  }, options);

  var level = options.level;

  $('.dialog, .custom-select-box, .custom-dropdown-navigation, .photopanel').trigger('dialogs.hide', level);

  var closeGlass = true;
  for (var i = 0; i < nn.references.dialogs.length; i++) {
    if (nn.references.dialogs[i].visible) {
      closeGlass = false;
    }
  }

  if (level === 0 || closeGlass || $('.dialog--slidepanel').length > 0) {
    $('body').removeClass('noscroll');
    nn.globals.glass.hide();
  }


  nn.globals.dialogLevel = level;

};
