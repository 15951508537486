var dialogOpenClass = 'is-open';
var rowSelectedClass = 'is-selected';

nn.registerModule('tabularselector', (element) => {
  // The element that holds the currently selected value and toggles the dialog.
  var valueElement = element.querySelector('.c-tabular-selector__value');

  // The dialog that contains the selectable values
  var dialogElement = element.querySelector('.c-tabular-selector__dialog');

  // fix for selectable rows:
  Array.prototype.slice.call(dialogElement.querySelectorAll('.c-tabular-selector__label'))
    .filter(function (labelElement) {
      return !labelElement.textContent;
    })
    .forEach(function (labelElement) {
      labelElement.innerHTML = '&nbsp;';
    });

  // Add listeners
  element.addEventListener('blur', closeDialog.bind(undefined, element));
  dialogElement.addEventListener('change', handleToggleClick.bind(undefined, element, valueElement));
  valueElement.addEventListener('click', toggleDisplay.bind(undefined, element));

  // Update if there are prechecked inputs.
  var input = element.querySelector('input[type="radio"]:checked');
  if (input) {
    updateSelector(element, valueElement, input);
  }
});

// Toggles the selection dialog state.
function toggleDisplay(element) {
  if (element.isDialogOpen) {
    closeDialog(element);
  } else {
    openDialog(element);
  }
}

// Capture handler for body clicks. Clicks outside the element close the dialog.
function handleBodyClick(element, event) {
  for (var target = event.target; target; target = target.parentElement) {
    if (target === element) {
      return;
    }
  }

  closeDialog(element);
}

// Open the selection dialog if it's closed.
// Clicks outside the element will cause the dialog to close.
function openDialog(element) {
  if (!element.isDialogOpen) {
    element.classList.add(dialogOpenClass);
    element.isDialogOpen = true;
    element.ownerDocument.body.addEventListener('click', handleBodyClick.bind(undefined, element), true);
  }
}

// Close the selection dialog if it's open.
// If so, we no longer need to listen to clicks outside the element.
function closeDialog(element) {
  if (element.isDialogOpen) {
    element.classList.remove(dialogOpenClass);
    element.focus();
    element.isDialogOpen = false;
    element.ownerDocument.body.removeEventListener('click', handleBodyClick.bind(undefined, element), true);
  }
}

// Handles a click on the value/toggle element.
function handleToggleClick(element, valueElement, event){
  updateSelector(element, valueElement, event.target);
}

// Updates the selector with element 'element', and toggle 'valueElement',
// selecting the row associated with the 'input' element.
function updateSelector(element, valueElement, input) {
  // Unselect all currently selected rows.
  var rows = element.getElementsByClassName(rowSelectedClass);
  if (rows) {
    for (var r = 0; r < rows.length; r++) {
      rows[r].classList.remove(rowSelectedClass);
    }
  }

  // Select the closest row
  var row = input.parentElement.parentElement;
  row.classList.add(rowSelectedClass);

  // Update the value text according to the labels in this row.
  var labels = row.querySelectorAll('.c-tabular-selector__label');
  if (labels) {
    var valueText = '<table class="c-tabular-selector__value-table"><tr>';

    for (var l = 0; l < labels.length; l++) {
      // valueText += '<td>'+labels[l].textContent+'</td>';
      valueText += '<td>'+labels[l].textContent.replace(/(<.*>)/, '')+'</td>';
    }

    valueText += '</tr></table>';
    valueElement.innerHTML = valueText;
  }

  // Done.
  closeDialog(element);
}
