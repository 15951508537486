export const colors = {
  'nn_dark_orange': '#e64415',
  'nn_medium_orange': '#ea650d',
  'nn_light_orange': '#ee7f00',
  'nn_fifty_light_orange': '#eac98e',
  'nn_extra_dark_grey': '#414141',
  'nn_dark_grey': '#666666',
  'nn_medium_grey': '#999999',
  'nn_light_grey': '#cac7c7',
  'nn_extra_light_grey': '#e5ded9',
  'nn_ultra_light_grey': '#f1edeb',
  'nn_dark_blue': '#5389c2',
  'nn_medium_blue': '#7caad6',
  'nn_light_blue': '#a4ccea',
  'nn_dark_green': '#5b9853',
  'nn_medium_green': '#96b439',
  'nn_light_green': '#c8cd2e',
  'nn_dark_purple': '#706cb0',
  'nn_medium_purple': '#9c91c6',
  'nn_light_purple': '#c6bfe0'
};

export const lineStyles = {
  // DEFAULT (Straight line)

  // NN Medium Orange
  "graph-type-1": {
    "lineWidth": 2,
    "color": colors.nn_medium_orange
  },
  // NN Medium Blue
  "graph-type-2": {
    "lineWidth": 2,
    "color": colors.nn_medium_blue
  },
  // NN Dark Grey
  "graph-type-3": {
    "lineWidth": 2,
    "color": colors.nn_dark_grey
  },
  // NN Medium Grey
  "graph-type-4": {
    "lineWidth": 2,
    "color": colors.nn_medium_grey
  },
  // NN Medium Purple
  "graph-type-5": {
    "lineWidth": 2,
    "color": colors.nn_medium_purple
  },
  // NN 50% light orange
  "graph-type-6": {
    "lineWidth": 2,
    "color": colors.nn_light_orange
  },
  // NN Light Blue
  "graph-type-7": {
    "lineWidth": 2,
    "color": colors.nn_light_blue
  },
  // NN Light Grey
  "graph-type-8": {
    "lineWidth": 2,
    "color": colors.nn_light_grey
  },
  // NN Light Green
  "graph-type-9": {
    "lineWidth": 2,
    "color": colors.nn_light_green
  },
  // NN Light Purple
  "graph-type-10": {
    "lineWidth": 2,
    "color": colors.nn_light_purple
  },
  // NN Dark Orange
  "graph-type-11": {
    "lineWidth": 2,
    "color": colors.nn_dark_orange
  },
  // NN Dark Blue
  "graph-type-12": {
    "lineWidth": 2,
    "color": colors.nn_dark_blue
  },
  // NN Medium Grey
  "graph-type-13": {
    "lineWidth": 2,
    "color": colors.nn_medium_grey
  },
  // NN Dark Green
  "graph-type-14": {
    "lineWidth": 2,
    "color": colors.nn_dark_green
  },
  // NN Dark Purple
  "graph-type-15": {
    "lineWidth": 2,
    "color": colors.nn_dark_purple
  },
  // NN fifty light orange
  "graph-type-61": {
    "lineWidth": 2,
    "color": colors.nn_fifty_light_orange
  },
  // NN Medium Greene
  "graph-type-62": {
    "lineWidth": 2,
    "color": colors.nn_medium_green
  },


  // STRIPED LINES

  // NN Medium Orange
  "graph-type-16": {
    "lineWidth": 2,
    "color": colors.nn_medium_orange,
    "lineDashStyle": [20, 5]
  },
  // NN Medium Blue
  "graph-type-17": {
    "lineWidth": 2,
    "color": colors.nn_medium_blue,
    "lineDashStyle": [20, 5]
  },
  // NN Dark Grey
  "graph-type-18": {
    "lineWidth": 2,
    "color": colors.nn_dark_grey,
    "lineDashStyle": [20, 5]
  },
  // NN Medium Grey
  "graph-type-19": {
    "lineWidth": 2,
    "color": colors.nn_medium_grey,
    "lineDashStyle": [20, 5]
  },
  // NN Medium Purple
  "graph-type-20": {
    "lineWidth": 2,
    "color": colors.nn_medium_purple,
    "lineDashStyle": [20, 5]
  },
  // NN 50% light orange
  "graph-type-21": {
    "lineWidth": 2,
    "color": colors.nn_light_orange,
    "lineDashStyle": [20, 5]
  },
  // NN Light Blue
  "graph-type-22": {
    "lineWidth": 2,
    "color": colors.nn_light_blue,
    "lineDashStyle": [20, 5]
  },
  // NN Light Grey
  "graph-type-23": {
    "lineWidth": 2,
    "color": colors.nn_light_grey,
    "lineDashStyle": [20, 5]
  },
  // NN Light Green
  "graph-type-24": {
    "lineWidth": 2,
    "color": colors.nn_light_green,
    "lineDashStyle": [20, 5]
  },
  // NN Light Purple
  "graph-type-25": {
    "lineWidth": 2,
    "color": colors.nn_light_purple,
    "lineDashStyle": [20, 5]
  },
  // NN Dark Orange
  "graph-type-26": {
    "lineWidth": 2,
    "color": colors.nn_dark_orange,
    "lineDashStyle": [20, 5]
  },
  // NN Dark Blue
  "graph-type-27": {
    "lineWidth": 2,
    "color": colors.nn_dark_blue,
    "lineDashStyle": [20, 5]
  },
  // NN Medium Grey
  "graph-type-28": {
    "lineWidth": 2,
    "color": colors.nn_medium_grey,
    "lineDashStyle": [20, 5]
  },
  // NN Dark Green
  "graph-type-29": {
    "lineWidth": 2,
    "color": colors.nn_dark_green,
    "lineDashStyle": [20, 5]
  },
  // NN Dark Purple
  "graph-type-30": {
    "lineWidth": 2,
    "color": colors.nn_dark_purple,
    "lineDashStyle": [20, 5]
  },
  // NN fifty light orange
  "graph-type-63": {
    "lineWidth": 2,
    "color": colors.nn_fifty_light_orange,
    "lineDashStyle": [20, 5]
  },
  // NN Medium Greene
  "graph-type-64": {
    "lineWidth": 2,
    "color": colors.nn_medium_green,
    "lineDashStyle": [20, 5]
  },

  // DOT

  // NN Medium Orange
  "graph-type-31": {
    "lineWidth": 7,
    "color": colors.nn_medium_orange
  },
  // NN Medium Blue
  "graph-type-32": {
    "lineWidth": 7,
    "color": colors.nn_medium_blue
  },
  // NN Dark Grey
  "graph-type-33": {
    "lineWidth": 7,
    "color": colors.nn_dark_grey
  },
  // NN Medium Grey
  "graph-type-34": {
    "lineWidth": 7,
    "color": colors.nn_medium_grey
  },
  // NN Medium Purple
  "graph-type-35": {
    "lineWidth": 7,
    "color": colors.nn_medium_purple
  },
  // NN light orange
  "graph-type-36": {
    "lineWidth": 7,
    "color": colors.nn_light_orange
  },
  // NN Light Blue
  "graph-type-37": {
    "lineWidth": 7,
    "color": colors.nn_light_blue
  },
  // NN Light Grey
  "graph-type-38": {
    "lineWidth": 7,
    "color": colors.nn_light_grey
  },
  // NN Light Green
  "graph-type-39": {
    "lineWidth": 7,
    "color": colors.nn_light_green
  },
  // NN Light Purple
  "graph-type-40": {
    "lineWidth": 7,
    "color": colors.nn_light_purple
  },
  // NN Dark Orange
  "graph-type-41": {
    "lineWidth": 7,
    "color": colors.nn_dark_orange
  },
  // NN Dark Blue
  "graph-type-42": {
    "lineWidth": 7,
    "color": colors.nn_dark_blue
  },
  // NN Medium Grey
  "graph-type-43": {
    "lineWidth": 7,
    "color": colors.nn_medium_grey
  },
  // NN Dark Green
  "graph-type-44": {
    "lineWidth": 7,
    "color": colors.nn_dark_green
  },
  // NN Dark Purple
  "graph-type-45": {
    "lineWidth": 7,
    "color": colors.nn_dark_purple
  },
  // NN fifty light orange
  "graph-type-65": {
    "lineWidth": 7,
    "color": colors.nn_fifty_light_orange
  },
  // NN Medium Green
  "graph-type-66": {
    "lineWidth": 7,
    "color": colors.nn_medium_green
  },

  // SQUARE

  // NN Medium Orange
  "graph-type-46": {
    "color": colors.nn_medium_orange
  },
  // NN Medium Blue
  "graph-type-47": {
    "color": colors.nn_medium_blue
  },
  // NN Dark Grey
  "graph-type-48": {
    "color": colors.nn_dark_grey
  },
  // NN Medium Grey
  "graph-type-49": {
    "color": colors.nn_medium_grey
  },
  // NN Medium Purple
  "graph-type-50": {
    "color": colors.nn_medium_purple
  },
  // NN light orange
  "graph-type-51": {
    "color": colors.nn_light_orange
  },
  // NN Light Blue
  "graph-type-52": {
    "color": colors.nn_light_blue
  },
  // NN Light Grey
  "graph-type-53": {
    "color": colors.nn_light_grey
  },
  // NN Light Green
  "graph-type-54": {
    "color": colors.nn_light_green
  },
  // NN Light Purple
  "graph-type-55": {
    "color": colors.nn_light_purple
  },
  // NN Dark Orange
  "graph-type-56": {
    "color": colors.nn_dark_orange
  },
  // NN Dark Blue
  "graph-type-57": {
    "color": colors.nn_dark_blue
  },
  // NN Medium Grey
  "graph-type-58": {
    "color": colors.nn_medium_grey
  },
  // NN Dark Green
  "graph-type-59": {
    "color": colors.nn_dark_green
  },
  // NN Dark Purple
  "graph-type-60": {
    "color": colors.nn_dark_purple
  },
  // NN fifty light orange
  "graph-type-67": {
    "color": colors.nn_fifty_light_orange
  },
  // NN Medium Green
  "graph-type-68": {
    "color": colors.nn_medium_green
  }
};
