import $ from 'jquery';
import './tabbed.scss';

/**
 * @class Tabbed
 * @description
 *		Creates tabs to switch between parts of differtent blocks
 *
 *
 * @usage
 *		place the data-component="Tabbed" on the
 *		containing element. Within this element there should be a title element
 *		in order to work.
 *
 *		- The block title : data-role="tabbed-title"
 *
 *		By default when in accordion state all tabs are closed (advisor wish)
 *		To enable deeplinking to accordion tabs set deeplinkacc to true
 *
 */


window.nn.registerModule('tabbed', function (element) {
  const tabbed = new Tabbed(element);
  tabbed.init();
  return tabbed;
});

nn.component = nn.component || {};
nn.component.Tabbed = window.app.tabbed.Index;

class Tabbed {
  constructor(element) {
    this.element = $(element);
  }
  init() {
    this.elementId = this.element.find('> .uniqueid').text() || this.element.attr('id');
    this.tabHeaderListAnchors = [];
    this.lastSelectedTab = null;
    this.tabPanels = this.element.find('div.tab');
    this.initialLoad = true;
    this.tabNoStorage = (this.element.attr('data-tabbed-nostorage') === "true");
    this.tabNoHash = (this.element.attr('data-tabbed-nohash') === "true");
    this.deeplinkAcc = (this.element.attr('data-tabbed-deeplinkacc') === "true");
    this.selectedTabIndex = this.element.attr('data-selected-tab-index');

    this.constructTabAccordeonHTML();
    this.bindEvents();
    this.viewportChangeHandler();

    if (this.selectedTabIndex) {
      this.selectTab(this.selectedTabIndex);
    }
  }
  constructTabAccordeonHTML() {

    this.tabPanels.hide();

    this.tabJustify = this.element.attr('data-justify');
    this.tabHeader = $('<div class="tab-header"></div>');
    this.tabHeaderList = $('<ul class="tab-list"></ul>');
    this.tabPanels.each($.proxy(this.constructTab, this));
    this.tabHeaderList.appendTo(this.tabHeader);
    this.element.prepend(this.tabHeader);

    if (this.tabJustify === 'distribute') {
      this.element.addClass('tabbed-component--items-' + this.tabPanels.length);
    }

    this.tabHeaderListAnchors = this.tabHeaderList.find('.tab > a');

    $(window).trigger('tabbed:state:rendered', this.element);
  }
  bindEvents() {
    if (this.editMode) {
      $(window).one('resize', $.proxy(this.viewportChangeHandler, this));
    } else {
      this.element.on('click', '.tab-list a', $.proxy(this.tabListClickHandler, this));
      this.element.on('click', '.tab-button > a', $.proxy(this.tabListClickHandler, this));
      $(window).on('hashchange', $.proxy(this.hashChangeHandler, this));
      $(window).on('resize', nn.helpers.debounce($.proxy(this.viewportChangeHandler, this), 500));
    }
  }
  viewportChangeHandler() {

    // iOS fires resize events upon toggling the address bar
    var bodyWidth = $('body').width();
    if (typeof this.lastBodyWidth !== 'undefined' && bodyWidth === this.lastBodyWidth) {
      return;
    }
    this.lastBodyWidth = bodyWidth;

    // in mobile always show accordion
    if ("mobile" === nn.settings.currentView) {
      this.toggleMode('accordion');
      return;
    }

    // switch to display-tab-header mode to measure widths
    this.element.addClass('tabbed-component--display-tab-header');

    var tabElementWidth = this.element.width();
    var tabListElement = this.element.find('.tab-list');
    var tabListElementWidth = tabListElement.width();

    this.element.removeClass('tabbed-component--display-tab-header');

    // if the tab-header is too wide, switch to accordion mode
    if (tabListElementWidth > tabElementWidth) {
      this.toggleMode('accordion');
    } else {
      this.toggleMode('tabs');
      if ("mobile" !== nn.settings.currentView) {
        this.toggleOrSelectTab(this.getDeeplinkTabIndexFromHash() || this.getDeeplinkTabIndexFromStorage());
      }
    }
  }
  constructTab(index, tabPanelElement) {

    var tabPanel = $(tabPanelElement),
      tabPanelTitle = tabPanel.find('> [data-role="tabbed-title"]:eq(0)');
    if (tabPanelTitle.length === 0) {
      // if there is no title do not render. Strange to have tab header link with no text
      return;
    }

    var tabHash = '#tab:' + this.elementId + '-' + index,
      tabHeaderTitle = tabPanelTitle.html(),
      tabHeaderItem = $('<li class="tab"></li>'),
      tabHeaderItemLink = $('<a href="' + tabHash + '" data-tab-index="' + index + '">' + tabHeaderTitle + '</a>'),
      tabButtonItem = $('<div class="tab-button"><a href="' + tabHash + '" data-tab-index="' + index + '">' + tabHeaderTitle + '</a></div>');


    tabHeaderItemLink.appendTo(tabHeaderItem);
    tabHeaderItem.appendTo(this.tabHeaderList);
    tabPanelTitle.addClass('tabbed-title');
    tabPanel.attr('data-tab-index', index);
    tabPanel.before(tabButtonItem);
  }
  hashChangeHandler() {
    //ie triggers hashchange after replacestate. links in the same document to #tab wil not work
    if (window.location.hash.indexOf('#tab:') === -1) {
      this.toggleOrSelectTab(this.getDeeplinkTabIndexFromHash() || this.getDeeplinkTabIndexFromStorage());
    }
  }
  getDeeplinkTabIndexFromHash() {
    if (window.location.hash.indexOf('#tab:') !== -1) {
      // starts with index
      var deeplinkTabInfo = window.location.hash.replace('#tab:', '').split('-');
      var targetTab = deeplinkTabInfo.pop();
      var deeplinkId = deeplinkTabInfo.join('-');

      if (this.elementId === deeplinkId) {
        if (this.lastSelectedTab === null) {
          var self = this;
          setTimeout(function () {
            self.scrollToTab(targetTab);
          }, 500)
        }
        return parseInt(targetTab, 10);
      }
    } else if (window.location.hash.indexOf('#') !== -1) {
      // no #tab selected but perhaps there is link somewhere nested in an tab panel. Find it and return the index
      return this.getTabPanelByNestedLink();
    }

    //changed this for accordion state from 0 to null
    return null;
  }
  getDeeplinkTabIndexFromStorage() {
    var index = 0;

    if (!this.tabNoStorage && this.elementId && nn.globals.storage.get('tabbed.' + this.elementId + '.index') !== null) {
      index = nn.globals.storage.get('tabbed.' + this.elementId + '.index');
    }

    return index;
  }
  getTabPanelByNestedLink() {

    var possibleNestedAnchorHref = window.location.hash.replace('#', ''),
      tabToOpen = null;

    this.element.find('> .tab [id], a[name]').each(function (index, anchorElement) {
      var el = $(anchorElement);
      var attr = el.attr('id') || el.attr('name');
      if (attr === possibleNestedAnchorHref) {
        tabToOpen = el.closest('.tab');
      }
    });

    if (tabToOpen !== null) {
      return parseInt(tabToOpen.data('tab-index'), 10);
    }

    return 0;
  }
  scrollToTab(tabIndex) {
    var target_offset;
    var target_top;

    //get the top offset of the target accordion tab
    if (this.element.hasClass('accordion')) {
      target_offset = this.element.find('.tab-button:eq(' + tabIndex + ')').offset();
      target_top = target_offset.top - 15;
      //get the top offset of the target anchor
    } else {
      target_offset = this.element.offset();
      target_top = target_offset.top - 10;
    }
    $('html, body').animate({ scrollTop: target_top });

  }
  tabListClickHandler(event) {
    var clickedTabLink = $(event.target);
    var clickedTabIndex = parseInt(clickedTabLink.data('tab-index'), 10);

    if (this.tabNoStorage) {
      event.preventDefault();
    } else if (!this.tabNoHash && window.history.replaceState) {
      window.history.replaceState({}, '', '#tab:' + this.elementId + '-' + clickedTabIndex);
    } else if (this.tabNoHash) {
      event.preventDefault();
      if (this.elementId) {
        nn.globals.storage.set('tabbed.' + this.elementId + '.index', clickedTabIndex);
      }
    }
    this.toggleOrSelectTab(clickedTabIndex);
  }
  toggleOrSelectTab(clickedTabIndex, forceOpen) {

    if (this.element.hasClass('accordion')) {
      this.toggleTab(clickedTabIndex, forceOpen);
    } else {
      this.selectTab(clickedTabIndex);

    }
  }
  selectTab(tabIndex) {
    // header list
    this.tabHeaderListAnchors.each(function () {
      $(this).parent().removeClass('active');
    });

    var tabHeaderListItem = this.tabHeaderListAnchors.filter('[data-tab-index="' + tabIndex + '"]');

    if (tabHeaderListItem.length === 0) {
      tabIndex = 0;
    }

    if ("mobile" !== nn.settings.currentView) {
      tabHeaderListItem.parent().addClass('active');
    }
    // content panels
    this.tabPanels.each(function () {
      $(this).removeClass('active');
    });

    if ("mobile" !== nn.settings.currentView) {
      this.tabPanels.filter('[data-tab-index="' + tabIndex + '"]').addClass('active');
    }
    this.element.find('[data-render-role="child"]').trigger('RenderTree:Rerender');

    this.lastSelectedTab = tabIndex;

  }
  toggleTab(tabIndex, reset) {
    if (reset === true) {
      this.element.find('.tab-button').removeClass('active');
      this.tabPanels.removeClass('active');
    }

    var selectedTabButton = this.element.find('.tab-button > a[data-tab-index="' + tabIndex + '"]');
    var selectedTabPanel = this.tabPanels.filter('[data-tab-index="' + tabIndex + '"]');

    if (selectedTabPanel.hasClass('active')) {
      selectedTabPanel.removeClass('active');
      selectedTabButton.parent().removeClass('active');
    } else {
      selectedTabPanel.addClass('active');
      selectedTabButton.parent().addClass('active');

      this.lastSelectedTab = tabIndex;

      this.element.find('[data-render-role="child"]').trigger('RenderTree:Rerender');
    }

  }
  toggleMode(mode) {
    if (mode === 'accordion' && !this.element.hasClass('accordion')) {
      this.element.addClass('accordion');
      this.unselectAccordion();
      if (this.deeplinkAcc === true) {
        this.toggleOrSelectTab(this.getDeeplinkTabIndexFromHash());
      }
    } else if (mode === 'tabs' && this.element.hasClass('accordion')) {
      this.element.removeClass('accordion');
      if (this.lastSelectedTab !== null) {
        this.toggleOrSelectTab(0, true);
      }
    }
  }
  unselectAccordion() {
    // content panels
    this.element.find('.tab-button').removeClass('active');
    this.tabPanels.removeClass('active');

    this.tabPanels.each(function () {
      $(this).removeClass('active');
    });

    this.element.find('[data-render-role="child"]').trigger('RenderTree:Rerender');
  }
}
