
nn.component.Glossary = function(element) {
  this.element = $(element);

  this.dialog = new nn.component.Dialog({theme: 'ajax'});
  this.ajaxLoader = new nn.component.AjaxLoader();

  this.arrow = $('<div class="tooltip-arrow"></div>').appendTo(this.dialog.window);

  this.element.on('click', $.proxy(this.clickHandler, this));
  $(window).on('resize', $.proxy(this.windowRezised, this));
};

nn.component.Glossary.prototype = {
  clickHandler: function(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dialog.visible) {
      this.dialog.hide();
    } else {
      this.show();
    }
  },
  show: function() {

    this.ajaxLoader.show({clear: true});

    $.ajax({
      url: this.element.attr('href'),
      timeout: nn.settings.ajaxTimeout
    })
      .done($.proxy(this.glossaryContentLoadDone, this))
      .fail($.proxy(this.glossaryContentLoadFail, this));

  },
  glossaryContentLoadDone: function(data) {

    this.dialog.attach();
    this.dialog.content.html(data);
    nn.initializeComponents(this.dialog.content);
    this.dialog.content.find('.dialog-closer').each(function() {
      new nn.component.DialogCloser(this, this.dialog);
    });

    this.reposition(true);
    this.ajaxLoader.hide();
  },
  glossaryContentLoadFail: function() {

    var dialog = new nn.component.Dialog();
    dialog.content.html('<h2>[Foutmelding]</h2>');
    dialog.show();
    this.ajaxLoader.hide();
  },
  windowRezised: function() {
    if (this.dialog.visible) {
      this.reposition(false);
    }
  },
  reposition: function(fadeBool) {
    var iconOffset = this.element.offset(),
      pageOffset = $('#page').offset(),
      left = this.computeLeftPosition(iconOffset, pageOffset),
      arrowLeft = iconOffset.left - left;

    this.arrow.css('left', arrowLeft - 3 + 'px');
    this.dialog.show(left, iconOffset.top + 26, {fade: fadeBool});
  },
  computeLeftPosition: function(iconOffset, pageOffset) {

    var left = iconOffset.left - $(this.dialog.root).outerWidth() + 16 + 16;

    if ($(window).width() <= nn.settings.mobileBreakPoint) { // check if mobile
      left = pageOffset.left + 10;
    } else if (left < pageOffset.left + 30) {
      left = pageOffset.left + 30;
    }

    return left;
  }
};