window.nn.registerModule('fieldchecklist', (element, options) => {
  new FieldChecklist(element, options);
});

export default class FieldChecklist {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.field = document.querySelector(this.options.field);
    this.depFields = {};

    this.field.addEventListener('input', () => this.update());

    this.options.rules.map(rule => {
      if (rule.notEquals) {
        this.addDepField(rule.notEquals);
      }
      if (rule.equals) {
        this.addDepField(rule.equals);
      }
    });

    this.update();
  }

  addDepField(fieldSelector) {
    this.depFields[fieldSelector] = document.querySelector(fieldSelector);
    this.depFields[fieldSelector].addEventListener('input', () => this.update());
  }

  update() {
    const results = this.options.rules.map(rule => {
      let checked;

      if (rule.regex) {
        checked = new RegExp(rule.regex).test(this.field.value);
      }
      if (rule.notEquals) {
        checked = this.field.value !== this.depFields[rule.notEquals].value;
      }
      if (rule.equals) {
        checked = this.field.value === this.depFields[rule.equals].value;
      }

      return {
        checked,
        text: rule.text
      }
    });

    const ul = document.createElement('ul');
    ['c-list-icon', 'c-list-icon--medium'].forEach(c => ul.classList.add(c));
    results.forEach(result => {
      const listIcon = document.createElement('li');
      const listIconText = document.createElement('span');
      const cIcon = document.createElement('span');

      listIcon.classList.add('c-list-icon__item');
      listIconText.classList.add('c-list-icon__text');
      ['c-icon', 'c-list-icon__icon'].forEach(c => cIcon.classList.add(c));
      if (result.checked) {
        ['c-icon--check', 'u-color--medium-green'].forEach(c => cIcon.classList.add(c))
      } else {
        ['c-icon--bullet', 'u-color--medium-grey'].forEach(c => cIcon.classList.add(c))
      }

      listIcon.appendChild(listIconText);
      listIconText.appendChild(cIcon);
      listIconText.appendChild(document.createTextNode(result.text));
      ul.appendChild(listIcon);
    });
    this.element.innerHTML = ul.outerHTML;
  }
}