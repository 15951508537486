let scriptSource = document.currentScript;
if (!scriptSource) {
  // Must be IE or an older browser, we'll have to dig further for it
  // A pretty reliable way is to find the last running script as they are loaded synchronously
  // NOTE: careful if we're ever in an environment where the main nn script is being loaded dynamically
  const scripts = document.getElementsByTagName('script');
  scriptSource = scripts[scripts.length - 1];
}

let scriptSourcePath = scriptSource.getAttribute('src');
scriptSourcePath = scriptSourcePath.substring(0, scriptSourcePath.lastIndexOf('/'));

if (/^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(scriptSourcePath)) {
  // A URL is considered absolute if it begins with "<scheme>://" or "//"
  __webpack_public_path__ = scriptSourcePath + '/';
} else {
  if (!window.location.origin) {
    // polyfill window.location.origin for browsers not supporting this
    window.location.origin = window.location.protocol + '//' + window.location.host + (window.location.port ? (':' + window.location.port) : '');
  }

  let origin = window.location.origin;

  if (scriptSourcePath.indexOf('/') !== 0) {
    if (origin !== window.location.href) {
      // relative path and on a different url than the origin
      origin = window.location.href;

      // remove whats behind last /
      origin = origin.substring(0, origin.lastIndexOf('/'));
    }
  } else {
    // remove first / to prevent double // inside public path
    scriptSourcePath = scriptSourcePath.slice(1);
  }

  __webpack_public_path__ = origin + '/' + scriptSourcePath + '/';
}

global['__webpack_public_path__'] = __webpack_public_path__;
