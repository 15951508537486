
(function(window, $, nn) {

  nn.component.DecorateParent = function(element) {

    this.element = $(element);
    this.init();
  }

  nn.component.DecorateParent.prototype = {
    init : function() {
      var parentClass = this.element.attr("data-parent-class");
      if (parentClass !== '') {
        this.element.parent().addClass(parentClass);
      }
    }
  }

})(window, jQuery, nn);