/* ----------------------~  S T I C K Y  P A N E L  ~----------------------- */

/**
 * Initializes a new sticky panel component.
 *
 * @param {element} The component's root element.
 */

nn.component.StickyPanel = function(element) {
  this.element = $(element);

  this.init();
};

$.extend(nn.component.StickyPanel.prototype, {
  init: function() {
    this.getAndSetOptions();
    this.getAndSetElements();
    this.bindEvents();

    this.onScroll();
  },
  getAndSetOptions: function() {
    this.onResizeListenerDebounced = nn.helpers.debounce(
      $.proxy(this.onScroll, this),
      200
    );
    if (nn.helpers.isiPhone() || nn.helpers.isiPad()) {
      this.onScrollListenerDebounced = nn.helpers.debounce(
        $.proxy(this.onScroll, this),
        200
      );
    } else {
      this.onScrollListenerDebounced = nn.helpers.debounce(
        $.proxy(this.onScroll, this),
        10
      );
    }
    this.onClickListenerDebounced = nn.helpers.debounce(
      $.proxy(this.onClick, this),
      200
    );
    this.onOrientationchangeListenerDebounced = nn.helpers.debounce(
      $.proxy(this.onScroll, this),
      50
    );
  },
  getAndSetElements: function() {
    // We are going to rip our element from the page flow. Therefore, we'll need a wrapper of the same height so the page won't jump around.
    this.wrapperElement = this.element.parent('.sticky-wrapper');
    // If no container element is present, create it
    if (this.wrapperElement.length < 1) {
      this.wrapperElement = this.element
        .wrap('<div class="sticky-wrapper"></div>')
        .parent('.sticky-wrapper');
      this.wrapperElement.addClass('sticky-phantom');
    }
    this.ceilingElement = this.wrapperElement.parent();
    // },

    this.wrapperElement.css({
      height: this.element.outerHeight() + 'px',
      position: 'relative'
    });
    // Since we have a wrapper, we can assume it's size and position.
    this.element.css({ position: 'absolute', width: '100%' });
    // Force the wrapper height, just to be sure
    this.wrapperElement.css({ height: this.element.outerHeight() + 'px' });

    this.ceilingElement.css({ overflow: 'hidden', 'padding-bottom': '0' });
  },
  bindEvents: function() {
    $(window).on('resize', this.onResizeListenerDebounced);
    $(window).on('scroll', this.onScrollListenerDebounced);
    $(window).on(
      'orientationchange',
      this.onOrientationchangeListenerDebounced
    );
    $(window).on('click', this.onClickListenerDebounced); // Take height-changing collapsers into account. A global height-change event would help.
  },
  onScroll: function() {
    // This will be called on every scroll (debounce:10ms), so keep it SUPER light! DO NOT calculate in here!

    if (nn.isCmsModePreview()) {
      this.unsetSticky();
      return;
    }

    if (nn.settings.currentView !== 'desktop') {
      this.unsetSticky();
    } else {
      var viewportHeight = $(window).height(),
        scrollTop = $(window).scrollTop(),
        containerTop = parseInt(this.ceilingElement.offset().top, 10),
        containerHeight = this.ceilingElement.height(),
        shouldFitInViewPortValue = containerTop + containerHeight - scrollTop;

      if (viewportHeight < shouldFitInViewPortValue) {
        // sticky should follow the bottom
        this.setSticky();
      } else {
        this.unsetSticky();
      }
    }
  },
  onClick: function() {
    var self = this;
    setTimeout(function() {
      // Collapsers animate for 600s, but can be configured. 1000ms is a wild guess here.
      self.onScroll();
    }, 1000);
  },
  setSticky: function() {
    var containerTop = parseInt(this.ceilingElement.offset().top, 10),
      panelTop = this.element.offset().top,
      bottom = 0;

    if (containerTop > panelTop) {
      bottom = panelTop - containerTop;
    }

    this.element.css({
      position: 'fixed',
      top: 'auto',
      bottom: bottom,
      zIndex: 400, //NOTE: z-index reduced from 1000 to 400 (FRONTEND-1791)
      width: this.wrapperElement.width() + 'px'
    });
  },
  unsetSticky: function() {
    this.wrapperElement.css({
      height: this.element.outerHeight() + 'px',
      position: 'relative'
    });
    this.element.css({
      position: 'relative',
      top: 'auto',
      bottom: 'auto',
      zIndex: '100',
      width: '100%'
    });
  }
});
