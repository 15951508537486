/**
 *
 *
 * script for xperiencentral
 * Events called by experiencentralscript:
 *
 * window.elementUpdated
 * after placing an element this event is triggered
 *

 *
 */
(function (window, $, nn) {

  nn.handlers.init.XcHandlers = function () {
    if (nn.isCmsModePreview()) {
      window.elementUpdated = function () {
        nn.initializeComponents();
      };

      $(window).on('CMS:EditModeChange', function () {

        if (nn.isCmsModeEdit()) {
          $('a').on('click', function (e) {
            e.preventDefault();
            return false;
          });
        } else {
          $('a').unbind('click');
        }

      });
    }
  }


})(window, jQuery, nn);
