/**
 *
 * @param {HTMLNode} element
 * @returns {void}
 */
nn.component.WordsFormatter = function (element) {
  this.element = $(element);
  this.init();
};

nn.component.WordsFormatter.prototype = {
  init : function () {
    this.element
      .on('keydown', $.proxy(this.onKeyDownHandler, this))
      .on('blur', $.proxy(this.onBlurHandler, this));
  },
  onKeyDownHandler : function (e) {

    var kc_backspace = 8,
      kc_tab = 9,
      kc_delete = 46,
      kc_space = 32;

    if(		e.keyCode === kc_backspace
			||	e.keyCode === kc_tab
			||	e.keyCode === kc_delete
			||	e.keyCode === kc_space
			||	nn.helpers.isArrowKeyCode(e)
			||	nn.helpers.isAlphaNumericKeyCode(e)) {

      // valid character
    } else {
      // invalid character
      e.preventDefault();
      this.stripIllegalChars();
    }
  },
  onBlurHandler: function () {
    this.stripIllegalChars();
  },
  stripIllegalChars: function () {
    var v = this.element.val();
    this.element.val(v.replace(/[^A-Za-z\s]/g, ""));
  }
};