import AjaxDialogLink from '../ajaxdialoglink/ajaxdialoglink.js';

function setAdvisorLoginLink(element) {
  [...element.querySelectorAll('.c-header__login-link')]
    .forEach(el => {
    // FIXME: hardcoded URLs are blasphemy, either remove the dialog
    // functionality and just use an anchor with an href, or update the href.
      el.href = '/va/JIT-login.htm';
      new AjaxDialogLink(el);
    });
}

function getAdvisorMobileLoginLink(element, loginElement){
  if (loginElement) {
    const link = loginElement.cloneNode(true);
    const span = link.firstElementChild;
    return { link, span };
  } else if (element.querySelector('.c-header__user-account')) {
    const link = document.createElement('a');
    const span = document.querySelector('.c-header__login-icon');
    link.setAttribute('href', element.querySelector('.c-header__user-account').getAttribute('href'))
    return { link, span };
  }
}

export { setAdvisorLoginLink, getAdvisorMobileLoginLink };

