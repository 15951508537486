import Dialog from "../dialog/dialog.js";

export default class CMSContentMultimedia {
  constructor(element, data) {
    this.element = element;
    this.data = data;

    this.element.innerHTML = this._getMultimediaContentTemplate();

    this.dialog = new Dialog({ themes: ["l"] });
    if (this.data.video_source) {
      this.dialog.setContent(this._getDialogContentTemplateWithVideo());
      this.dialogButtons = [
        ...this.element.querySelectorAll('[data-role="dialog-open"]')
      ];
      this.dialogButtons.forEach(button => {
        button.addEventListener("click", event => {
          event.preventDefault();
          this.dialog.show();
        });
      });
    }
  }

  _getMultimediaContentTemplate() {
    return `<div class="c-cmscontent c-cmscontent--multimedia">
              ${this.data.poster ? `<a href="#" class="c-cmscontent__poster" data-role="dialog-open">
                      <svg class="c-cmscontent__poster__play" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <circle cx="254.6915" cy="254.6915" r="239.9915" fill="currentColor"/>
                          <path fill="#fff" d="M203.93 404.31c0 3.073 1.286 5.275 3.911 6.579 3.099 1.329 5.74 1.091 7.924-0.661l142.427-143.053c3.963-3.963 5.926-8.136 5.926-12.53s-1.964-8.56-5.926-12.513l-141.749-142.418c-2.218-2.21-4.851-2.642-7.917-1.329-3.048 0.906-4.597 3.082-4.597 6.612z"/>
                      </svg>
                      <img class="c-cmscontent__poster__still" src="${this.data.poster}" />
                  </a>` : ''}
              ${this.data.linktext ? `<a href="#" class="c-link" data-role="dialog-open">${this.data.linktext}</a>`:''}
            </div>`;
  }

  _getDialogContentTemplateWithVideo() {
    return `<div class="c-cmscontent__dialog">
              ${this.data.title ? `<h2>${this.data.title}</h2>` : ""}
            <div class="u-embed-container">
              <iframe width="560" height="315" src="//${this.data.video_source}?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>
            </div>
            </div>`;
  }
}
