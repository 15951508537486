(function (window) {

  'use strict';

  var isOpenClass = 'is-open';
  var isClosedClass = 'is-closed';
  var isOpeningClass = 'is-opening';
  var isClosingClass = 'is-closing';

  window.app = window.app || {};
  window.app.funnelviewport = window.app.funnelviewport || {};

  window.app.funnelviewport.Index = function (element) {
    var openBtn = element.querySelector('[data-role="viewport-toggle-open"]');
    var closeBtn = element.querySelector('[data-role="viewport-toggle-close"]');

    openBtn.addEventListener('click', handlClick);
    closeBtn.addEventListener('click', handlClick);

    var isOpen = window.app.localStorage.getItem('funnel-viewport');

    if (isOpen) {
      element.classList.add(isOpenClass);
    }

    function handlClick(event) {
      event.preventDefault();
      initTransition();
    }

    /**
		 * init the transition of opening or closing;
		 * @returns {undefined}
		 */
    function initTransition() {
      if (element.classList.contains(isOpenClass)) {
        // if the panel is open init closing behavior
        // open is based on the 'is-open' class
        element.classList.add(isClosingClass);
        element.classList.remove(isOpenClass);
      } else if (isClosed()) {
        // if the panel is closed init open behavior
        element.classList.add(isOpeningClass);
        element.classList.remove(isClosingClass);
      }

      // set timeout. Time is defined same as in the css file
      setTimeout(finishTransition, 200);
    }

    function finishTransition() {

      // check if the panel is opening or closing
      if (element.classList.contains(isOpeningClass)) {
        // is the element opening?
        // then modify class to is-open
        element.classList.remove(isOpeningClass);
        element.classList.add(isOpenClass);

      } else if (element.classList.contains(isClosingClass)) {
        // is the element closing?
        // then modify class to is-closed
        element.classList.remove(isClosingClass);
        element.classList.add(isClosedClass);
      }

      window.app.localStorage.setItem('funnel-viewport', element.classList.contains(isOpenClass));
    }

    // check if the panel is closed
    // initialy the panel doesn't have any state class. But still is closed
    function isClosed() {
      if (element.classList.contains(isClosedClass)) {
        return true;
      }

      // check when there is no state class. Then the panel is closed
      if (!element.classList.contains(isClosingClass) && !element.classList.contains(isOpeningClass) && !element.classList.contains(isOpenClass)) {

        return true;
      }

      return false;
    }

  };

})(window);
