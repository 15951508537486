/**
 * Glass - Viewport overlay
 */
(function (window, radio) {
  'use strict';

  window.app = window.app || {};
  window.app.Glass = function () {

    // States should be a module dependency. Attaching at runtime for now.
    var states = {
        "CLOSED": "closed",
        "CLOSING": "closing",
        "OPENED": "opened",
        "OPENING": "opening"
      },
      theme = '',
      glassElement = buildGlassElement();

    glassElement.addEventListener('click', clickHandler);
    if(radio) {
      radio('dialog.state-change').subscribe(dialogBroadcastHandler);
      radio('blueriq.state-change').subscribe(blueriqStateChangeHandler);
    }

    if (instance) {
      return instance;
    }
    return {
      show: show,
      hide: hide
    };


    // public methods
    function show() {
      attach();
      setTimeout(function () {
        glassElement.setAttribute('data-state', states.OPENING);
        if(radio) {
          radio('glass.state-change').broadcast({element: glassElement, state: states.OPENING});
        }
      }, 0);
    }

    function hide() {
      glassElement.setAttribute('data-state', states.CLOSING);
    }

    // private methods
    /**
		 * Build the Glass div. Give it its initial state of closed
		 *
		 * @returns {glassL#4.Glass.buildGlassElement.glassElement|.window.app.Glass.buildGlassElement.glassElement}
		 */
    function buildGlassElement() {
      var glassElement = window.document.createElement('div');
      glassElement.setAttribute('data-state', states.CLOSED);
      glassElement.classList.add('o-glass');
      return glassElement;
    }

    /**
		 * Attach the glass to the DOM (to the body). Every time when glass is attached. Reapply the "transitionEnd" Listener. It get's lost after detaching
		 *
		 * @returns {void}
		 */
    function attach() {

      if (!isAttached()) {

        var glassFragment = window.document.createDocumentFragment();
        glassFragment.appendChild(glassElement);
        window.document.body.appendChild(glassFragment);
      }

      glassElement.addEventListener('transitionend', transitionEndHandler);

    }

    /**
		 * Remove the glass from the DOM
		 *
		 * @returns {undefined}
		 */
    function remove() {
      if (isAttached()) {
        glassElement.parentNode.removeChild(glassElement);
      }
    }

    /**
		 *
		 * @returns {Boolean}
		 */
    function isAttached() {
      return !!glassElement.parentNode;
    }

    /**
		 *
		 * Apply theming to the glass
		 *
		 * @param {Object} options
		 * @returns {void}
		 */
    function applyTheme(options) {
      if (options.theme) {
        theme = 'o-glass--' + options.theme;
        glassElement.classList.add(theme);
      }
    }

    /**
		 * Remove theming
		 *
		 * @returns {undefined}
		 */
    function removeTheme() {
      if (theme) {
        glassElement.classList.remove(theme);
        theme = null;
      }
    }

    // handlers
    function dialogBroadcastHandler(dialogInfo) {
      if (states.CLOSING === dialogInfo.state) {
        hide();
      }
      if (states.OPENING === dialogInfo.state) {
        applyTheme(dialogInfo);
        show();
      }
    }

    function blueriqStateChangeHandler(stateInfo) {
      if (isAttached()) {
        window.console.log(stateInfo);
        hide();
      }
    }

    function transitionEndHandler() {

      // FIXME Transitions are not always fired upon showing. Therefore, this function isn't always called.

      var state = glassElement.getAttribute('data-state');
      if (states.OPENING === state) {
        glassElement.setAttribute('data-state', states.OPENED);

        if(radio) {
          radio('glass.state-change').broadcast({element: glassElement, state: states.OPENED});
        }
      } else if (states.CLOSING === state) {
        glassElement.setAttribute('data-state', states.CLOSED);
        remove();
        removeTheme();

        if(radio) {
          radio('glass.state-change').broadcast({element: glassElement, state: states.CLOSED});
        }
      }
    }

    function clickHandler(evt) {
      evt.preventDefault();
      var state = glassElement.getAttribute('data-state');

      if (states.OPENED === state || states.OPENING === state) { // should only be OPENED, see transitionEndHandler
        hide();
        if(radio) {
          radio('glass.state-change').broadcast({element: glassElement, state: states.CLOSING});
        }
      }
    }

  };

  // There are a million ways to make this cleaner, but this works right now.
  var instance;
  window.document.addEventListener('DOMContentLoaded', function () {
    instance = new window.app.Glass();
  }, false);

}(window, window.radio));