window.app = window.app || {};
window.app.form = window.app.form || {};

function setInputType(input, type) {
  input.type = type;
}

// Handles a mousedown/touchstart on the toggle button and
// toggles the type of 'input' between text and password.
function handleToggleClick(input, event) {
  event.preventDefault();

  // NOTE: once we've received an event we only listen to events of that type.
  // This prevents conflicts between mousedown and touchstart.
  if (!input.toggleEventType) {
    input.toggleEventType = event.type;
  } else if (input.toggleEventType !== event.type) {
    return;
  }

  input.type = (input.type.toLowerCase() === 'text') ? 'password' : 'text';
}

// Updates the visibility of the toggle button, based on the presence of a password.
function updateInput(event) {
  if (event.target.value.length) {
    event.target.classList.add('is-nonempty');
  } else {
    event.target.classList.remove('is-nonempty');
  }
}

// Password module with visibility toggle.
// Password visibility is toggled by switching input type from password to text and back.
window.app.form.password = function (element) {
  const toggle = element.querySelector('[data-role="toggle"]');
  const input = element.querySelector('[data-role="password"]');

  toggle.addEventListener('mousedown', handleToggleClick.bind(undefined, input));
  toggle.addEventListener('touchstart', handleToggleClick.bind(undefined, input));
  input.addEventListener('blur', setInputType.bind(undefined, input, 'password'));
  input.addEventListener('keyup', updateInput);
  input.addEventListener('focus', updateInput);
};
