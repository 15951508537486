import numberformat from '../u-formatters/number-format';

const formatteramount = function (element) {
  this._rootElement = element;
  const decimalSeparator = this.getDecimalSeparator();
  if (decimalSeparator === ',') {
    this._rootElement.placeholder = '0,00';
  } else {
    this._rootElement.placeholder = '0.00';
  }

  this._bindEvents(element);
};

formatteramount.prototype = {
  _bindEvents: function (element) {
    element.addEventListener('blur', this._onNumberInputFieldBlur.bind(this));
    element.addEventListener('focus', this._onRootElementFocus.bind(this));
  },
  _onRootElementFocus: function () {
    var value = this._cleanValue(event.target.value);
    if(value){
      value = value.replace(/\./g, '');

      if (value.indexOf(',') === -1) {
        value += `,00`;
      }

      event.target.value = value;
    }
  },
  _onNumberInputFieldBlur: function (event) {
    var value = numberformat(this._cleanValue(event.target.value), 2, ',', '.');
    if( value ){
      event.target.value = value;
    }
  },
  _cleanValue: function (value) {
    value = value.trim()
      .replace(',--', ',00')
      .replace(',-', ',00');

    const notAPositiveDecimal = /[^.,\d]/;
    const notADigit = /^\D*$/;

    return (!notAPositiveDecimal.exec(value) && !notADigit.exec(value)) ? value : false;
  },
  getDecimalSeparator() {
    var n = 1.1;
    return n.toLocaleString().substring(1, 2);
  },
};

window.app = window.app || {};
window.app.form = window.app.form || {};
window.app.form.formatteramount = formatteramount;

export default formatteramount;
