import { log } from '@nn/psdl-utility/src/console';

window.nn.registerModule('signInCallbackApp', (el, config) => {
  return window.nn.oauth2.handleAuthorizationCodeFlowCallback(config.key);
});

window.nn.registerModule('signOutApp', (el, config) => {
  return window.nn.oauth2.cleanupSessionState(config.key);
});

window.nn = window.nn || {};
if (window.oauth2) {
  log('window.oauth2 namespace found! Using this on window.nn.oauth2.')
  window.nn.oauth2 = window.oauth2;
}
