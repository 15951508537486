nn.component.Button = function (element) {
  this.element = $(element);
  this.init();
};

nn.component.Button.prototype = {
  init: function () {
    if (this.element[0].nodeName === 'INPUT') {
      this.applyInputStyling();
      this.bindStatusEvents();
    } else {
      this.applyAnchorStyling();
    }

  },
  applyInputStyling: function () {

    if (this.element.hasClass('button--back')) {
      var wrapper = this.element.wrap('<label class="button--back-wrapper" />').parent('.button--back-wrapper');
      var disabledAttr = this.element.prop('disabled');

      if (disabledAttr === true || this.element.hasClass('disabled')) {
        wrapper.addClass('disabled');
      }
    }

    this.applyDisabledStateInput();

  },
  applyAnchorStyling: function () {
    if (this.element.hasClass("disabled") === true) {
      this.element.on('click', $.proxy(this.preventClick, this));
    }
  },
  applyDisabledStateInput: function () {

    var self = this,
      $form = this.element.closest('form.nn_form');


    if ($form.length > 0) { // FIX FOR IE AND OTHER BROWSERS.

      $form.on('IAF_ClientsideFrameworkLoaded', function () {
        if (self.element.prop('disabled') === true) {
          self.element.trigger('disable');
        }
      });
    } else if (this.element.prop('disabled') === true) {
      self.element.trigger('disable');
    }

    if (this.element.hasClass('one_click_submit')) {
      this.element.on('click', function () {

        var $btn = $(this);
        $form.on('IAFBeforeSubmit', function () {
          $btn.trigger('disable');
        });
      });
    }
  },
  preventClick: function (e) {
    e.preventDefault();
  },
  bindStatusEvents: function () {

    var $btn = this.element,
      $parent = this.element.parent();

    this.element.on('disable', function () {

      $btn.attr('disabled', true).addClass('disabled');
      if ($parent.hasClass('button--back-wrapper')
        || $parent.hasClass('button--submit-back-wrapper')
        || $parent.hasClass('button--submit-wrapper')) {
        $parent.addClass('disabled');
      }
    }).on('enable', function () {

      $btn.attr('disabled', false).removeClass('disabled');
      if ($parent.hasClass('button--back-wrapper')
        || $parent.hasClass('button--submit-back-wrapper')
        || $parent.hasClass('button--submit-wrapper')) {
        $parent.removeClass('disabled');
      }
    });
  }
};