import throttle from 'lodash/throttle'
import { findAncestor } from '@nn/psdl-utility/src/dom-helpers'
import stickyService from '../utility/stickyService.js';

export default class StickyTable {
  constructor(tableElement, config) {
    this.config = config || {};

    this.getElements(tableElement);
    this.getClientRects();

    this.initializeStickyHeader();
    this.initializeStickyColumn();

    window.addEventListener('resize', throttle(() => {
      this.resetElements();
      this.resetStickyColumnStyles();
      this.getClientRects();
      this.initializeStickyColumn();
    }), 50);
  }

  initializeStickyColumn() {
    if (this.config.stickyColumn) {
      this.tableElement.classList.add('c-table--stickycolumn');
      this.setStickyColumnStyles();
    }
  }

  initializeStickyHeader() {
    if (this.config.stickyHeader) {
      this.tableElement.classList.add('c-table--stickyheader');

      window.addEventListener('scroll', throttle(() => {
        this.setStickyHeaderStyles();
      }), 50);

      this.scrollableContainer.addEventListener('scroll', throttle(() => {
        this.moveHeaderWhileScrollingHorizontally();
      }), 50);
    }
  }

  getElements(tableElement) {
    this.tableElement = tableElement;
    this.tableHeader = tableElement.querySelector('.c-table__header');
    this.scrollableContainer = findAncestor(tableElement, '.c-scrollablecontainer');
    this.scrollableWrapper = findAncestor(tableElement, '.c-scrollablewrapper');
    this.tableBody = tableElement.querySelector('.c-table__body')
    this.headerRow = tableElement.querySelector('.c-table__header__row');
    this.secondHeaderCell = this.tableElement.querySelector('thead tr:first-child th:nth-child(2)');
  }

  getClientRects() {
    this.tableHeaderHeight = this.tableHeader.getBoundingClientRect().height;
    this.widthFirstColumn = this.tableElement.rows[0].cells[0].getBoundingClientRect().width;
    this.scrollableWrapperLeft = this.scrollableWrapper.getBoundingClientRect().left;
    this.scrollableContainerWidth = this.scrollableContainer.getBoundingClientRect().width;
  }

  setStickyColumnStyles() {
    this.scrollableContainer.classList.add('webkitOverflowScrollingAuto');
    this.scrollableContainer.style.marginLeft = this.widthFirstColumn + 'px';

    for (var i = 0; i < this.tableElement.rows.length; i++) {
      let row = this.tableElement.rows[i];
      row.cells[0].classList.add("sticky-first-cell");
      row.cells[0].style.width = this.widthFirstColumn + 'px';
    }
  }

  resetStickyColumnStyles() {
    this.scrollableContainer.style.marginLeft = '0px';

    for (var i = 0; i < this.tableElement.rows.length; i++) {
      let row = this.tableElement.rows[i];
      row.cells[0].classList.remove('sticky-first-cell');
      row.cells[0].style.removeProperty('width');
    }
  }

  setStickyHeaderStyles() {
    const tableClientRect = this.tableElement.getBoundingClientRect();
    let yPositionTableHeader = stickyService.getTopOffset();

    if (tableClientRect.top < yPositionTableHeader && tableClientRect.bottom - this.tableHeaderHeight > yPositionTableHeader) {
      if (!this.shadowHeader) {
        this.shadowHeader = document.createElement('div');
        this.tableElement.parentNode.appendChild(this.shadowHeader);

        if (this.config.stickyHeader && this.config.stickyColumn) {
          let marginLeft = parseInt(this.scrollableContainer.style.marginLeft.substring(0, this.scrollableContainer.style.marginLeft.indexOf('px')));
          this.shadowHeader.style.left = this.scrollableWrapperLeft + 'px';
          this.secondHeaderCell.style.marginLeft = marginLeft + 'px';
        }
        if (!this._elementsFixedWidths) {
          this.setElementsFixedWidths(tableClientRect);
        }
        const shadowTable = this.tableElement.cloneNode(true);
        shadowTable.querySelector('tbody').remove();
        this.shadowHeader.appendChild(shadowTable);
        this.shadowHeader.classList.add('c-table-shadowHeader');

        this.shadowHeader.style.width = this.scrollableContainerWidth + 'px';
        this.shadowHeader.style.height = this.tableHeader.getBoundingClientRect().height + 'px';
        this.shadowHeader.setAttribute('aria-hidden', 'true');
      }
      this.shadowHeader.style.top = yPositionTableHeader + 'px';
    } else if (this.shadowHeader) {
      this.shadowHeader.remove();
      this.shadowHeader = null;
    }
  }

  setElementsFixedWidths(tableClientRect) {
    this.tableElement.style.width = tableClientRect.width + 'px';
    this.tableElement.style.height = tableClientRect.height + 'px';
    const bodyCells = this.tableElement.querySelectorAll('tbody tr:first-child td');
    const headerCells = this.tableElement.querySelectorAll('thead tr:first-child th');
    [...bodyCells].forEach((cell, index) => {
      const width = cell.getBoundingClientRect().width;
      headerCells[index].style.width = width + 'px';
      bodyCells[index].style.width = width + 'px';
    })
    this._elementsFixedWidths = true;
  }

  resetElements() {
    if (this.shadowHeader) {
      this.shadowHeader.remove();
      this.shadowHeader = null;
    }
    if (this._elementsFixedWidths) {
      this.tableElement.style.removeProperty('width');
      this.tableElement.style.removeProperty('height');
      const bodyCells = this.tableElement.querySelectorAll('tbody tr:first-child td');
      const headerCells = this.tableElement.querySelectorAll('thead tr:first-child th');
      [...bodyCells].forEach((cell, index) => {
        headerCells[index].style.removeProperty('width');
        bodyCells[index].style.removeProperty('width');
      })
      this._elementsFixedWidths = false;
    }
  }

  moveHeaderWhileScrollingHorizontally() {
    if (this.shadowHeader) {
      if (this.scrollableContainer.scrollLeft > 0) {
        this.shadowHeader.querySelector('table thead tr').style.marginLeft = -this.scrollableContainer.scrollLeft + 'px';
      } else {
        this.shadowHeader.querySelector('table thead tr').style.marginLeft = '0px';
      }
    }
  }
}