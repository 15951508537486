import AjaxDialogLink from './ajaxdialoglink';
import moduleconfig from '../u-moduleconfig/moduleconfig';

window.app.AjaxDialogLink = window.app.AjaxDialogLink || {};
window.app.AjaxDialogLink.Index = function (element) {
  return new AjaxDialogLink(element, moduleconfig({element: element}));
}

// Legacy modals
nn.component.AjaxDialogLink = window.app.AjaxDialogLink.Index;
nn.component.AjaxDialogSmallLink = window.app.AjaxDialogLink.Index;
