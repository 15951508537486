const SEQUENCE = ['light', 'medium', 'dark'];

export default class ColoredText{
  constructor(element){
    this.element = element;

    const walker = document.createTreeWalker(this.element, NodeFilter.SHOW_TEXT, null, false);
    const textNodes = [];
    while (walker.nextNode()) {
      textNodes.push(walker.currentNode);
    }

    const wordNodes = [];
    textNodes.forEach(node => {
      const tmp = document.createElement('div');
      tmp.innerHTML = node.nodeValue
        .split(/\s+/)
        .map(piece => {
          if (piece) {
            return `<span>${piece}</span>`;
          }
          return '';

        }).join(' ');

      const fragment = document.createDocumentFragment();
      fragment.append(...tmp.childNodes);
      wordNodes.push(...[...fragment.childNodes].filter(child => child.tagName));
      node.parentNode.replaceChild(fragment, node);
    });

    wordNodes.forEach((node, index) => {
      node.className = SEQUENCE[Math.floor(index / wordNodes.length * SEQUENCE.length)];
    });

    this.element.classList.add('colored');
  }
}