async function bootstrapLazy(lazyPromise, root, config) {
  return (await lazyPromise).default(root, config)
}

window.nn.registerModule('psdl-eherkenning-couple', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "psdl-eherkenning-couple" */ './bootstrapCoupleEherkenning'), root, config);
});

window.nn.registerModule('psdl-eherkenning-callback', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "psdl-eherkenning-couple" */ './loginCallbackEherkenning'), root, config);
});
