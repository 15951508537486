nn.component.DateField = function (element) {
  var self = this;

  this.months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
  this.monthsShort = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

  this.selectedDay = undefined;
  this.selectedMonth = undefined;
  this.selectedYear = undefined;

  this.currentDay = undefined;
  this.currentMonth = undefined;
  this.currentYear = undefined;

  this.startDay = 1;
  this.startMonth = 0;
  this.startYear = 1900;
  this.minimumYear = 1900;

  this.endDay = 31;
  this.endMonth = 11;
  this.endYear = 2100;
  this.maximumYear = 2100;

  this.$element = $(element);
  this.$input = $(element); // $('input[type="text"]:first', element);
  this.$inputHidden = $('#' + this.$input.attr('id').replace('-dummy', '')); //$('input[type="hidden"]:first', element);
  this.$native = $('<input type="date" class="date-field" placeholder="' + this.$input.attr('placeholder') + '" />');
  this.$datePickerToggle = this.$element.data('date-picker-toggle');
  this.$readonly = this.$element.attr('readonly');

  this.updateInput = function () {
    var value = self.$inputHidden.val();

    if (value) {
      self.$input.closest('.custom-text-field').addClass('value');

      // Try to convert hidden input value to date object
      var date = self.convertToDate(value);

      if (date) {
        // Update visible input field with formatted date
        self.$input.val(self.formatDate(date.getDate(), date.getMonth() + 1, date.getFullYear()))
          .trigger('refresh');
      } else {
        // Update visible input field with invalid input
        self.$input.val(value)
          .trigger('refresh');
      }
    }
  };

  this.$inputHidden.on('update', this.updateInput);
  this.$inputHidden.trigger('update');

  var date, elm;

  if (this.$element.attr('data-date-type') === 'date of birth') {
    date = new Date();

    this.endDay = date.getDate();
    this.endMonth = date.getMonth();
    this.endYear = date.getFullYear();
    this.maximumYear = date.getFullYear();
  }

  if (this.$element.attr('data-start-date')) {
    elm = this.$element.attr('data-start-date').split('-');
    date = new Date(parseInt(elm[0], 10), parseInt(elm[1], 10) - 1, parseInt(elm[2], 10));

    this.startDay = date.getDate();
    this.startMonth = date.getMonth();
    this.startYear = date.getFullYear();
    this.minimumYear = date.getFullYear();
  }

  if (this.$element.attr('data-end-date')) {
    elm = this.$element.attr('data-end-date').split('-');
    date = new Date(parseInt(elm[0], 10), parseInt(elm[1], 10) - 1, parseInt(elm[2], 10));

    this.endDay = date.getDate();
    this.endMonth = date.getMonth();
    this.endYear = date.getFullYear();
    this.maximumYear = date.getFullYear();
  }

  this.blurTimeout = undefined;

  this.$input.focus(function () {
    if (self.blurTimeout !== undefined) {
      clearTimeout(self.blurTimeout);
      self.blurTimeout = undefined;
    }

    // show date picker by default when data-attribute "data-date-picker" is set to true
    if (self.$datePickerToggle && !self.$element.data('end-date')) {
      self.updateCalendar();
      self.showPicker();
    }
  });

  // extra on click event in order for it to work in Aquima on return
  if (self.$datePickerToggle) {
    this.$input.on('click', function () {
      self.updateCalendar();
      self.showPicker();
    });
  }

  this.$input.keydown(function (e) {

    if (self.pickerDialog.visible && e.keyCode === 27) {
      self.pickerDialog.hide();
    }

    if (self.$datePickerToggle === true && e.keyCode !== 9) {
      self.updateCalendar();
      self.showPicker();
      e.preventDefault();
    }

    if (e.keyCode === 9) {
      $('.date-picker-suggestions', element).remove();
      self.$element.removeClass('is-suggested');
    }

  });

  this.$input.on('blur', function () {
    if (self.blurTimeout === undefined) {
      self.blurTimeout = setTimeout(function () {
        $('.date-picker-suggestions', element).remove();
        self.$element.removeClass('is-suggested');
      }, 250);
    }
  });

  if (this.$element.attr('data-date-type') !== 'date of birth') {
    this.$input.keyup(function () {
      self.getSuggestions(this.value);
    });
  }

  if (nn.helpers.isiPhone() && !self.$datePickerToggle && !this.$readonly) {

    this.$input.after(this.$native);
    this.$input.hide();

    this.$native.on('blur', function () {

      var d = this.value.split('-');
      self.$inputHidden
        .val([d[2], d[1], d[0]].join('-'))
        .trigger('update');

      self.$input.trigger('change');
    });

    var v = this.$inputHidden.val();
    var res = v.match(/^(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[012])[- /.](19\d\d|20\d\d)$/);
    if (res) {
      this.$native.val([res[3], res[2], res[1]].join('-'));
    }
  } else {

    // Create picker
    this.createPicker();
    // Create picker icon
    this.$pickerIcon = $('<div class="date-picker-icon"/>');
    // add hidden-mobile class accept in case of data-start-date attribute
    if (!this.$element.data('start-date')) {
      this.$pickerIcon.addClass('hidden-mobile');
    }


    // Place after input
    if (!this.$readonly || this.$datePickerToggle) {
      this.$element.after(this.$pickerIcon);
    }

    this.$pickerIcon.on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();

      self.updateForwardBackBox();
      self.updateDayBox();
      self.updateMonthBox();
      self.updateYearBox();
      self.updateCalendar();

      self.showPicker();
    });
    if (!this.$readonly) {
      this.prepareMobileState();
    }
  }

  // On change visible input field
  this.$input.on('change', function () {
    // Try to convert visible input value to date object
    var date = self.convertToDate(this.value);


    if (date) {
      // Reformat visible input field
      this.value = self.formatDate(date.getDate(), date.getMonth() + 1, date.getFullYear());

      // Set values
      self.selectedDay = date.getDate();
      self.selectedMonth = date.getMonth();
      self.selectedYear = date.getFullYear();

      self.currentDay = date.getDate();
      self.currentMonth = date.getMonth();
      self.currentYear = date.getFullYear();

      self.updateDayBox();
      self.updateMonthBox();
      self.updateYearBox();

      // Update hidden input field
      self.$inputHidden
        .val(self.formatHiddenDate(date))
        .trigger('change');
    } else {
      // Unset values
      self.selectedDay = undefined;
      self.selectedMonth = undefined;
      self.selectedYear = undefined;

      // Pass invalid visible input value to hidden field
      self.$inputHidden
        .val(this.value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;"))
        .trigger('change');

    }
  }).trigger('change');

  // Find parent grid-unit and add grid-unit--datefield
  this.updateContainingGridUnit();
};

nn.component.DateField.prototype = {
  updateContainingGridUnit: function () {
    var self = this, parentGrid = this.$element.closest('.grid-unit, .data-table-cell');
    if (parentGrid) {
      parentGrid.addClass('grid-unit--datefield');

      if (this.$pickerIcon && this.$pickerIcon.is(':visible')) {
        parentGrid.addClass('grid-unit--datefield-picker');
      }

      this.$element.parents('.field').on('IAF_ShowFormFragment', function () {
        if (self.$pickerIcon && self.$pickerIcon.is(':visible')) {
          parentGrid.addClass('grid-unit--datefield-picker');
        }
      });
    }
  },
  prepareMobileState: function () {
    var self = this,
      html = {
        'all': '<div class="field--date-mobile visible-mobile">[day][month][year][error]</div>',
        'template': '<div class="field">\n\
							<label class="hidden-mobile" for="field-select-date-[key]-[id]">[label]</label>\n\
							<select id="field-select-date-[key]-[id]" class="[id]-select-mobile select-field" data-component="' + (nn.helpers.isAndroid() ? 'Select' : 'SelectField') + '" data-visible-options="13">\n\
								<option>[label]</option>\n\
								[options]\n\
							</select>\n\
						</div>',
        'option': '<option value="[value]"[selected]>[label]</option>',
        'errorTemplate': '<p class="error-message">[text]</p>'
      };

    var v = this.$inputHidden.val();
    var res = v.match(/^(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[012])[- /.](19\d\d|20\d\d)$/);
    if (res) {
      self.currentDay = parseInt(res[1], 10);
      self.currentMonth = parseInt(res[2], 10) - 1;
      self.currentYear = parseInt(res[3]);
    }

    // Day template
    var dayOptions = '';
    var i;
    for (i = 1; i <= 31; i++) {

      var selectedDay = '';
      if (self.currentDay === i) {
        selectedDay = ' selected="selected" ';
      }

      dayOptions += html.option
        .replace(/\[selected\]/g, selectedDay)
        .replace(/\[value\]/g, ('0' + i).slice(-2))
        .replace(/\[label\]/g, i);
    }
    html.day = html.template
      .replace(/\[key\]/g, this.id)
      .replace(/\[id\]/g, 'day')
      .replace(/\[label\]/g, 'Dag')
      .replace(/\[options\]/g, dayOptions);

    // Month template
    var monthOptions = '',
      months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
    $.each(months, function (i, month) {

      var selectedMonth = '';
      if (self.currentMonth === i) {
        selectedMonth = ' selected="selected" ';
      }

      monthOptions += html.option
        .replace(/\[selected\]/g, selectedMonth)
        .replace(/\[value\]/g, ('0' + (i + 1)).slice(-2))
        .replace(/\[label\]/g, month);
    });
    html.month = html.template
      .replace(/\[key\]/g, this.id)
      .replace(/\[id\]/g, 'month')
      .replace(/\[label\]/g, 'Maand')
      .replace(/\[options\]/g, monthOptions);

    // Year template
    var yearOptions = [],
      currentYear = this.maximumYear;
    for (i = currentYear; i >= currentYear - 100; i--) {

      var selectedYear = '';
      if (self.currentYear === i) {
        selectedYear = ' selected="selected" ';
      }

      yearOptions += html.option
        .replace(/\[selected\]/g, selectedYear)
        .replace(/\[value\]/g, i)
        .replace(/\[label\]/g, i);
    }
    html.year = html.template
      .replace(/\[key\]/g, this.id)
      .replace(/\[id\]/g, 'year')
      .replace(/\[label\]/g, 'Jaar')
      .replace(/\[options\]/g, yearOptions);

    // Error template
    var $error = []; //$('[data-component="date-picker"] .error-message', this.$element);

    if ($error.length) {
      html.error = html.errorTemplate
        .replace(/\[text\]/g, $error.text());
    } else {
      html.error = '';
    }

    // Combine day, month, year templates
    html.all = html.all
      .replace(/\[day\]/, html.day)
      .replace(/\[month\]/, html.month)
      .replace(/\[year\]/, html.year)
      .replace(/\[error\]/, html.error);

    // Insert combined HTML into DOM
    if (!this.$element.data('start-date')) {
      this.$element.addClass('hidden-mobile').before(html.all);
    }
    var $parent = this.$element.closest('.grid-unit,.data-table-cell').addClass('field-group');

    this.$smallState = $parent.find('.field--date-mobile');
    nn.initializeComponents(this.$smallState);

    this.$smallState
      .find('select')
      .on('change', function () {

        // Define date format
        var dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[012])[- /.](19|20)\d\d$/;

        // Grab selected date
        var dateString =
          self.$smallState.find('select.day-select-mobile').val() + '-' +
          self.$smallState.find('select.month-select-mobile').val() + '-' +
          self.$smallState.find('select.year-select-mobile').val();

        // If valid, duplicate date into the large state
        if (dateString.match(dateFormat)) {
          self.$inputHidden
            .val(dateString)
            .trigger('update')
            .trigger('change');
        }
      });

    this.$inputHidden
      .on('change', function () {

        var dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[-/.](0?[1-9]|1[012])[- /.](19|20)\d\d$/;
        // Get the date-picker's hidden field with the date
        var dateString = this.value;

        if (dateString.match(dateFormat)) {

          var date = dateString.split('-'),
            day = parseInt(date[0], 10),
            month = parseInt(date[1], 10),
            year = parseInt(date[2], 10);

          self.$smallState.find('select.day-select-mobile').val(day < 10 ? '0' + day : day);
          self.$smallState.find('select.month-select-mobile').val(month < 10 ? '0' + month : month);
          self.$smallState.find('select.year-select-mobile').val(year);

          self.$smallState.find('select');

          self.selectedDay = self.currentDay = day;
          self.selectedMonth = self.currentMonth = (month - 1);
          self.selectedYear = self.currentYear = year;

          self.updateDayBox();
          self.updateMonthBox();
          self.updateYearBox();
        }
      });
  },
  convertToDate: function (inputString) {
    var results,
      monthIndex;

    // Check if format is DDMMYYYY
    results = inputString.match(/^([0][1-9]|[1|2][0-9]|[3][0|1])([0][1-9]|[1][0-2])([0-9]{4})$/);

    if (results) {
      if (this.isValidDate(results[1], results[2], results[3])) {
        return new Date(results[3], results[2] - 1, results[1]);
      }
    }

    // Check if format is DD [ -/.] MM [ -/.] YYYY or D [ -/.] M [ -/.] YYYY
    results = inputString.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[ -/.]([0]?[1-9]|[1][0-2])[ -/.]([0-9]{4})$/);

    if (results) {
      if (this.isValidDate(results[1], results[2], results[3])) {
        return new Date(results[3], results[2] - 1, results[1]);
      }
    }

    // Check if format is DD [ -/.]? MMM [ -/.]? YYYY or D [ -/.]? MMM [ -/.]? YYYY
    results = inputString.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[ -/.]?([a-zA-Z]{3})[ -/.]?([0-9]{4})$/);

    if (results) {
      monthIndex = $.inArray(results[2], this.monthsShort);

      if (monthIndex !== -1 && this.isValidDate(results[1], monthIndex + 1, results[3])) {
        return new Date(results[3], monthIndex, results[1]);
      }
    }

    // Check if format is DD [ -/.]? MMMM [ -/.]? YYYY
    results = inputString.match(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[ -/.]?([a-zA-Z]+)[ -/.]?([0-9]{4})$/);

    if (results) {
      monthIndex = $.inArray(results[2], this.months);

      if (monthIndex !== -1 && this.isValidDate(results[1], monthIndex + 1, results[3])) {
        return new Date(results[3], monthIndex, results[1]);
      }
    }

    return null;
  },
  formatDate: function (d, m, y) {
    var date = parseInt(d, 10) + ' ' + this.months [parseInt(m, 10) - 1];

    if (y) {
      date += ' ' + y;
    }

    return date;
  },
  formatHiddenDate: function (dateObject) {
    if (dateObject) {
      return dateObject.getDate() + '-' + (dateObject.getMonth() + 1) + '-' + dateObject.getFullYear();
    }

    return null;
  },
  isValidDate: function (d, m, y) {
    var date = new Date(y, m - 1, d);

    if (date.getDate() !== parseInt(d, 10)) {
      return false;
    }

    if (date.getMonth() + 1 !== parseInt(m, 10)) {
      return false;
    }

    if (date.getFullYear() !== parseInt(y, 10) || date.getFullYear() < 1000) {
      return false;
    }

    return true;
  },
  getSuggestions: function (value) {
    var self = this,
      suggestions = [],
      months = this.months;
    value = value || this.$input.val();

    // Strip everything exept whitespace letters and numbers
    value = value.replace(/[^\sa-zA-Z0-9]/g, ' ');

    // Find day, remove leading zero's and strip it
    var day = value.match(/^[0-9]+/);
    day = (day) ? day[0].replace(/^0+/, '') : '';
    value = value.replace(/^[0-9]+/, '');

    // Find month, convert to lowercase and strip it
    var month = value.match(/[a-zA-Z]+/);
    month = (month) ? month[0] : '';
    month = month.toLowerCase();
    value = value.replace(/[a-zA-Z]+/, '');

    if (!month) {
      month = value.match(/[0-9]+/);
      month = (month) ? month[0].replace(/^0+/, '') : '';
    }

    // Set validation year
    var currentDate = new Date(),
      suggestionDate = null,
      monthLength;
    var i, n;

    // Check if months is numeric
    if (month.match(/^\d+$/)) {
      monthLength = month.length;
      for (i = 0, n = months.length; i < n; i++) {
        if (month === (i + 1).toString().substr(0, monthLength)) {
          // Check if day and month are valid
          if (this.isValidDate(day, i + 1, 2000)) {
            suggestionDate = new Date(currentDate.getFullYear(), i, day);

            if (suggestionDate < currentDate) {
              suggestionDate.setFullYear(suggestionDate.getFullYear() + 1);
            }

            suggestions.push(this.formatDate(day, i + 1, suggestionDate.getFullYear()));
          }
        }
      }
    } else {

      monthLength = month.length;
      if (monthLength >= 1) {

        for (i = 0, n = months.length; i < n; i++) {

          if (month === months [i].substr(0, monthLength)) {

            // Check if day and month are valid
            if (this.isValidDate(day, i + 1, 2000)) {

              suggestionDate = new Date(currentDate.getFullYear(), i, day);
              if (suggestionDate < currentDate) {
                suggestionDate.setFullYear(suggestionDate.getFullYear() + 1);
              }

              suggestions.push(this.formatDate(day, i + 1, suggestionDate.getFullYear()));
            }
          }
        }
      }
    }

    $('.date-picker-suggestions', this.$element).remove();
    self.$element.removeClass('is-suggested');

    if (suggestions.length > 0) {
      self.$element.addClass('is-suggested');

      var $suggestionBox = $('<div class="date-picker-suggestions block block--focus-hovered"/>').appendTo($('.custom-text-field', this.$element));

      $('<h6>Bedoelt u</h6>').appendTo($suggestionBox);

      var $suggestionList = $('<ul/>').appendTo($suggestionBox);

      $(suggestions).each(function (n, suggestion) {
        $('<li/>').text(suggestion).appendTo($suggestionList);
      });

      $suggestionList.delegate('li', 'click', function (e) {
        if (self.blurTimeout) {
          clearTimeout(self.blurTimeout);
          self.blurTimeout = undefined;
        }

        var suggestionText = $(this).text();

        e.preventDefault();
        self.$input.focus().val(suggestionText).trigger('change').trigger('refresh');

        $('.date-picker-suggestions', self.$element).remove();
        self.$element.removeClass('is-suggested');
      });
    }
  },
  createPicker: function () {

    this.pickerDialog = new nn.component.Dialog({
      enableShadows: false
    });

    var $dialog = $(this.pickerDialog.content);

    $(this.pickerDialog.window).addClass('dialog--date-picker');
    this.pickerDialog.closeButton.hide();

    if (this.$element.attr('data-date-type') !== 'date of birth') {
      var date = new Date();

      this.currentDay = date.getDate();
      this.currentMonth = date.getMonth();
      this.currentYear = date.getFullYear();

      $(this.pickerDialog.window).addClass('calendar');
      this.updateCalendar();
    }

    if (this.$element.attr('data-date-type') !== 'date of birth') {
      $dialog.append(this.createForwardBackBox());
    } else {
      $(this.pickerDialog.window).addClass('date-of-birth');

      $dialog.append(this.createDaySelect());
      $dialog.append(this.createDayBox());
    }

    $dialog.append(this.createMonthSelect());
    $dialog.append(this.createMonthBox());

    $dialog.append(this.createYearSelect());
    $dialog.append(this.createYearBox());
  },
  createForwardBackBox: function () {
    var self = this;

    this.forwardBackBox = $('<div class="forward-back-box"/>');

    this.forwardButton = $('<span class="forward"/>').appendTo(this.forwardBackBox);

    this.forwardButtonClickHandler = function () {
      self.currentMonth++;

      if (self.currentMonth === 12) {
        self.currentMonth = 0;
        self.currentYear++;
      }

      self.updateForwardBackBox();
      self.updateMonthBox();
      self.updateYearBox();
      self.updateCalendar();
    };

    this.backButton = $('<span class="back"/>').appendTo(this.forwardBackBox);

    this.backButtonClickHandler = function () {
      self.currentMonth--;

      if (self.currentMonth === -1) {
        self.currentMonth = 11;
        self.currentYear--;
      }

      self.updateForwardBackBox();
      self.updateMonthBox();
      self.updateYearBox();
      self.updateCalendar();
    };

    this.updateForwardBackBox();

    return this.forwardBackBox;
  },
  updateForwardBackBox: function () {
    if (this.forwardButton) {
      this.forwardButton.off('click');

      this.forwardButton.on('click', this.forwardButtonClickHandler);
      this.forwardButton.removeClass('disabled');

      if (this.currentYear === this.endYear && this.currentMonth >= this.endMonth) {
        this.forwardButton.off('click');
        this.forwardButton.addClass('disabled');
      }

      if (this.currentYear > this.endYear) {
        this.forwardButton.off('click');
        this.forwardButton.addClass('disabled');
      }
    }

    if (this.backButton) {
      this.backButton.off('click');

      this.backButton.on('click', this.backButtonClickHandler);
      this.backButton.removeClass('disabled');

      if (this.currentYear === this.startYear && this.currentMonth <= this.startMonth) {
        this.backButton.off('click');
        this.backButton.addClass('disabled');
      }

      if (this.currentYear < this.startYear) {
        this.backButton.off('click');
        this.backButton.addClass('disabled');
      }
    }
  },
  createDayBox: function () {
    this.dayBox = $('<div class="day-box"/>');
    this.updateDayBox();

    return this.dayBox;
  },
  updateDayBox: function () {
    // Update daybox text
    if (!this.dayBox) {
      return;
    }
    if (this.currentDay !== undefined) {

      this.dayBox.text(this.currentDay);
    } else {
      this.dayBox.html('<span>Dag</span>');
    }

    this.$daySelect[0].selectedIndex = this.currentDay;
    // Disable days not available in the current month/year
    var options = $('option', this.$daySelect).removeAttr('disabled');
    var n;

    if (this.currentYear !== undefined && this.currentMonth !== undefined) {
      var lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate();

      if (this.startYear && this.currentYear <= this.startYear && this.currentMonth <= this.startMonth) {
        for (n = 0; n < this.startDay; n++) {
          options.eq(n).attr('disabled', true);
        }
      }

      if (this.endYear && this.currentYear >= this.endYear && this.currentMonth >= this.endMonth) {
        for (n = this.endDay + 1; n <= 31; n++) {
          options.eq(n).attr('disabled', true);
        }
      }

      for (n = lastDay + 1; n <= 31; n++) {
        options.eq(n).attr('disabled', true);
      }
    }
  }
  ,
  createMonthBox: function () {
    this.monthsBox = $('<div class="month-box"/>');

    this.updateMonthBox();

    return this.monthsBox;
  },
  updateMonthBox: function () {

    if (!this.monthsBox) {
      return;
    }
    var months = this.months;

    if (this.currentMonth !== undefined) {
      this.monthsBox.text(months [this.currentMonth]);
    } else {
      this.monthsBox.html('<span>Maand</span>');
    }

    //		this.$monthSelect[0].selectedIndex = (this.currentMonth + 1);
    this.$monthSelect.val(this.currentMonth);

    $('option', this.$monthSelect).removeAttr('disabled');

    var n;
    if (this.currentYear === this.startYear) {
      for (n = 0; n < this.startMonth; n++) {
        $('option:eq(' + (n + 1) + ')', this.$monthSelect).attr('disabled', true);
      }
    }

    if (this.currentYear === this.endYear) {
      for (n = 11; n > this.endMonth; n--) {
        $('option:eq(' + (n + 1) + ')', this.$monthSelect).attr('disabled', true);
      }
    }

    if (this.currentYear < this.startYear) {
      $('option', this.$monthSelect).attr('disabled', true);
    }
  },
  createYearBox: function () {
    this.yearBox = $('<div class="year-box"/>');

    this.updateYearBox();

    return this.yearBox;
  },
  updateYearBox: function () {

    if (!this.yearBox) {
      return;
    }

    if (this.currentYear !== undefined) {
      this.yearBox.text(this.currentYear);
    } else {
      this.yearBox.html('<span>Jaar</span>');
    }

    this.$yearSelect.val(this.currentYear);

    this.updateForwardBackBox();
    this.updateMonthBox();
  },
  createDaySelect: function () {

    var self = this,
      months = this.months;

    this.$daySelect = $('<select class="day-select"/>');
    this.$daySelect.append($('<option value="">Dag</option>'));

    for (var n = 1; n <= 31; n++) {
      var $option = $('<option/>').attr('value', n).text(n).appendTo(this.$daySelect);

      if (self.currentDay === n) {
        $option.attr('selected', true);
      }
    }

    this.$daySelect.change(function () {
      var value = $('option:selected', this).attr('value');

      self.currentDay = (value !== '') ? parseInt(value, 10) : undefined;
      self.updateDayBox();
      self.updateCalendar();

      if (self.$element.attr('data-date-type') === 'date of birth') {
        if (self.isValidDate(self.currentDay, self.currentMonth + 1, self.currentYear)) {
          self.$input.val(self.currentDay + ' ' + months [self.currentMonth] + ' ' + self.currentYear).trigger('change').trigger('refresh');
          self.pickerDialog.closeButton.click();
        } else {
          self.$input.val('');
        }
      }
    });

    return this.$daySelect;
  },
  createMonthSelect: function () {
    var self = this,
      months = this.months;

    var $monthSelect = $('<select class="month-select"/>');
    $monthSelect.append($('<option value="">Maand</option>'));

    for (var n = 0; n < months.length; n++) {
      var $option = $('<option/>').attr('value', n).text(months [n]).appendTo($monthSelect);

      if (self.currentMonth === n) {
        $option.attr('selected', true);
      }
    }

    $monthSelect.change(function () {
      var value = $('option:selected', this).attr('value');

      self.currentMonth = (value !== '') ? parseInt(value, 10) : undefined;
      self.updateMonthBox();
      self.updateCalendar();

      if (self.dayBox) {
        self.updateDayBox();
      }

      if (self.$element.attr('data-date-type') === 'date of birth') {
        if (self.isValidDate(self.currentDay, self.currentMonth + 1, self.currentYear)) {
          self.$input.val(self.currentDay + ' ' + months [self.currentMonth] + ' ' + self.currentYear).trigger('change').trigger('refresh');
          self.pickerDialog.closeButton.click();
        } else {
          self.$input.val('');
        }
      }
    });

    this.$monthSelect = $monthSelect;

    return $monthSelect;
  },
  createYearSelect: function () {
    var self = this,
      months = this.months;

    var $yearSelect = $('<select class="year-select"/>');
    $yearSelect.append($('<option value="">Jaar</option>'));

    var n = this.maximumYear;
    for (n; n >= this.minimumYear; n--) {
      var $option = $('<option value="' + n + '"/>').text(n).appendTo($yearSelect);
      if (self.currentYear === n) {
        $option.attr('selected', true);
      }
    }

    $yearSelect.on('change', function () {

      var value = $('option:selected', this).attr('value');
      self.currentYear = (value !== '') ? parseInt(value, 10) : undefined;
      self.updateYearBox();
      self.updateCalendar();

      if (self.dayBox) {
        self.updateDayBox();
      }

      if (self.monthsBox) {
        self.updateMonthBox();
      }

      if (self.$element.attr('data-date-type') === 'date of birth') {
        if (self.isValidDate(self.currentDay, self.currentMonth + 1, self.currentYear)) {
          self.$input.val(self.currentDay + ' ' + months [self.currentMonth] + ' ' + self.currentYear).trigger('change').trigger('refresh');
          self.pickerDialog.closeButton.click();
        } else {
          self.$input.val('');
        }
      }
    });

    this.$yearSelect = $yearSelect;

    return $yearSelect;
  },
  showPicker: function () {

    if (this.pickerDialog.visible) {
      return; // Dialog is already visible.
    }

    nn.globals.glass.show({
      clear: true
    });

    var left = this.$pickerIcon.offset().left - 327;
    var top = this.$pickerIcon.offset().top - 33;

    if (this.$element.data('start-date') && $(document).width() <= nn.settings.mobileBreakPoint) {
      left = 0;
      top = 0;
    }

    this.pickerDialog.show(left, top);
  },
  createCalendar: function (month, year) {
    var self = this,
      months = this.months;

    month = parseInt(month, 10);
    year = parseInt(year, 10);

    var disableWeekends = !!this.$element.attr('data-disable-weekends');
    var disableSundays = !!this.$element.attr('data-disable-sundays');
    var disableSaturdays = !!this.$element.attr('data-disable-saturdays');

    var $calendar = $('<div class="calendar"/>');

    var dayOfTheWeeks = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];

    var n;
    for (n = 0; n < 7; n++) {
      $('<span class="day-of-the-week"/>').text(dayOfTheWeeks [n]).appendTo($calendar);
    }

    var date = new Date(year, month, 1);
    var dayOfTheWeek = (date.getDay() || 7) - 1;

    date = new Date(year, month + 1, 0);
    var lastDay = date.getDate();

    var rest = 7 - (dayOfTheWeek + lastDay) % 7;
    if (dayOfTheWeek) {
      for (n = dayOfTheWeek - 1; n >= 0; n--) {
        date = new Date(year, month, -n);

        $('<span class="day invisible"/>').text('-').appendTo($calendar);
      }
    }

    for (n = 1; n <= lastDay; n++) {
      var $day = $('<span class="day"/>').text(n).appendTo($calendar);

      if (year === this.startYear && month === this.startMonth && n < this.startDay) {
        $day.addClass('disabled');
      }

      if (year === this.startYear && month < this.startMonth) {
        $day.addClass('disabled');
      }

      if (year < this.startYear) {
        $day.addClass('disabled');
      }

      if (year === this.endYear && month === this.endMonth && n > this.endDay) {
        $day.addClass('disabled');
      }

      if (year === this.endYear && month > this.endMonth) {
        $day.addClass('disabled');
      }

      if (year > this.endYear) {
        $day.addClass('disabled');
      }

      if (disableWeekends && dayOfTheWeek % 7 >= 5) {
        $day.addClass('disabled');
      }

      if (disableSaturdays && dayOfTheWeek % 7 === 5) {
        $day.addClass('disabled');
      }

      if (disableSundays && dayOfTheWeek % 7 === 6) {
        $day.addClass('disabled');
      }

      if (!$day.hasClass('disabled')) {
        if (year === this.selectedYear && month === this.selectedMonth && n === this.selectedDay) {
          $day.addClass('selected');
        }

        (function (n) {
          $day.click(function () {
            self.currentDay = n;

            self.$input.val(n + ' ' + months [self.currentMonth] + ' ' + self.currentYear).trigger('change').trigger('refresh');

            self.updateCalendar();

            nn.handlers.dialogs.hide(
              {
                level: self.pickerDialog.level
              });
          });
        })(n);
      }

      dayOfTheWeek++;
    }

    for (n = 1; n <= rest; n++) {
      $('<span class="day invisible"/>').text('-').appendTo($calendar);
    }

    return $calendar;
  },
  updateCalendar: function () {
    if (this.$element.attr('data-date-type') !== 'date of birth') {
      var $calendar = this.createCalendar(this.currentMonth, this.currentYear);
      $('.calendar', this.pickerDialog.content).remove();
      $calendar.appendTo(this.pickerDialog.content);
    }
  }

};
