/**
 * @class DialogExpandable
 * @description Toggles hidden expandable div.
 *
 */

(function (window, $, nn) {

  nn.component.DialogExpandable = function (element) {
    this.element = $(element);
    this.init();
  };

  nn.component.DialogExpandable.prototype = {

    init : function() {

      this.collapsed = true;
      this.elementID = this.element.data('dialog-id');
      this.content = this.element.find('[data-role="dialog-expandable-content"]');
      this.icons = this.element.find('[data-role="dialog-expandable-control"]');
      this.closeBtn = this.element.find('[data-role="dialog-expandable-close-btn"]');
      this.touchExpanded = this.element.data('collapsed-touch');
      this.notouchExpanded = this.element.data('collapsed-notouch');
      this.speed = 0;

      this.initState();
      this.bind();
    },

    bind : function() {

      this.icons.on('click', $.proxy(this.clickHandler, this));
      this.closeBtn.on('click', $.proxy(this.clickHandler, this));
    },
    initState: function() {
      if(nn.globals.storage.get('dialogExpandable.' + this.elementID) !== null) {
        this.collapsed = nn.globals.storage.get('dialogExpandable.' + this.elementID);
      }
      if($(document).width() <= nn.settings.mobileBreakPoint && this.element.attr('data-collapsed-touch')) {
        this.collapsed = this.touchExpanded;
      }
      if($(document).width() >= nn.settings.mobileBreakPoint && this.element.attr('data-collapsed-notouch')) {
        this.collapsed = this.notouchExpanded;
      }

      if (this.collapsed) {
        this.toggleContent(false);
      }

    },
    clickHandler: function(event) {
      event.preventDefault();

      this.collapsed = !this.collapsed;
      if (this.elementID) {
        nn.globals.storage.set('dialogExpandable.' + this.elementID, this.collapsed);
      }
      this.toggleContent(true);
    },
    toggleContent: function(animate) {

      if(animate) {
        this.speed = 300;
      } else {
        this.speed = 0;
      }

      if (this.collapsed === true) {
        this.element.removeClass('expanded');
        this.content.slideUp(this.speed);
      } else {
        this.element.addClass('expanded');
        this.content.slideDown(this.speed);
      }

    }
  };
})(window, jQuery, nn);