(function (window, $, nn) {

  nn.component.PrefillM2 = function (element) {

    this.element = $(element);
    this.converterUrl = this.element.data('converter');
    this.zipcode = $("#" + this.element.data('zipcode'));
    this.housenr = $("#" + this.element.data('housenr'));
    this.nsuffix = $("#" + this.element.data('nsuffix'));
    this.fieldContainer = this.element.closest('div.field');
    this.submitButton = this.element.closest("form.form").find("input[type=submit]");
    this.squareMeterValue = false;
    this.errorMessage = $("p#error_m2_" + this.element.attr("id")).html();

    this.init();
  }

  nn.component.PrefillM2.prototype = {
    init: function () {
      this.zipcode.change($.proxy(this.onChangeEvent, this));
      this.housenr.change($.proxy(this.onChangeEvent, this));
      this.nsuffix.change($.proxy(this.onChangeEvent, this));
      this.fieldContainer.bind('IAF_ShowFormFragment', $.proxy(this.fillM2Field, this));
    },
    onChangeEvent: function () {
      var z = this.zipcode.val(),
        h = parseInt(this.housenr.val(), 10),
        s = this.nsuffix.val(),
        self = this;

      if (!nn.helpers.isZipCode(z) || !nn.helpers.isInt(h)) {
        return;
      }

      this.zipcode.val(z.toUpperCase());
      z = z.toUpperCase().replace(/\s/g, '');

      var postData = {
        postalcode: z,
        number: h
      };
      if (s.length > 0) {
        postData.add = s;
      }

      $.ajax({
        data: postData,
        dataType: 'json',
        url: this.converterUrl
      }).done(function (data) {
        if (data.status && data.status === 'OK') {
          self.squareMeterValue = parseInt(data.value, 10);
          self.fillM2Field();
        } else {
          self.element.val('').trigger('change');
        }
      }).fail(function () {
        self.element.val('').trigger('change');
      });
    },
    fillM2Field: function () {
      var self = this,
        max = parseInt(this.element.closest('.field').find("span.max-unit").html(), 10);
      setTimeout(function () {
        if (self.squareMeterValue !== false) {
          if (self.squareMeterValue > max) {
            self.element
              .val('')
              .trigger('blur')
              .trigger('change');
            self.setError();
          } else {
            self.element
              .val(self.squareMeterValue)
              .trigger('blur')
              .trigger('change');
          }
        } else if (isNaN(self.element.val())) {
          self.element
            .val(0)
            .trigger('change');
        }
      }, 0);
    },
    setError: function () {
      var self = this;

      if (this.element.is('visible')) {
        $("p#error_m2_" + this.element.attr("id")).removeClass('displayNone').html(self.errorMessage.replace("{{residense_size}}", self.squareMeterValue));
        self.element.one('change', function () {
          self.removeError();
        });
      }
    },
    removeError: function () {
      $("p#error_m2_" + this.element.attr("id")).addClass('displayNone');
    }
  }

})(window, jQuery, nn);
