export const a11yDialog = (element) => {
  if(!element.hasAttribute('role') || !element.hasAttribute('aria-modal') || !element.hasAttribute('tabindex')){
    element.setAttribute("role", "dialog")
    element.setAttribute("aria-modal", true)
    element.setAttribute("tabindex",-1)
    addTabStopBehaviour(element);
    element.focus();
  }
}

export const a11yDialogRemove = (element) => {
  element.removeAttribute("aria-modal");
  element.removeAttribute("role");
  element.removeAttribute("tabindex");
  removeTabStopBehaviour(element);
}

const createTabStopElement = (element) => {
  const tabStop = document.createElement('div');
  tabStop.setAttribute('tabindex', 0);
  tabStop.addEventListener('focus', () => {
    element.focus();
  });
  return tabStop;
}

const addTabStopBehaviour = (element) => {
  element.insertAdjacentElement('beforebegin', createTabStopElement(element));
  element.insertAdjacentElement('afterend', createTabStopElement(element));
}

const removeTabStopBehaviour = (element) => {
  if(element.previousElementSibling !== null && element.previousElementSibling.getAttribute('tabindex') === "0"){
    element.parentNode.removeChild(element.previousSibling);
  }

  if(element.nextElementSibling !== null && element.nextElementSibling.getAttribute('tabindex') === "0"){
    element.parentNode.removeChild(element.nextSibling);
  }
}
