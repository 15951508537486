/**
 * @class ResponsiveLinkListBuilder
 * @description
 *    Build a bordered block with a link list
 *    out of a complex set of elements
 *
 *
 * @usage
 *    place the data-component="ResponsiveLinkListBuilder" on the
 *    containing element. Within this element there should be some elements
 *    in order to work.
 *
 *    - The block title : data-role="responsive-link-list-title"
 *    - The single list element : data-role="responsive-link-list-row"
 *    - The title within a single list element : data-role="responsive-link-list-row-title"
 *    - The action within a single list element : data-role="responsive-link-list-row-action"
 *
 *
 */

(function (window, $, nn) {

  nn.component.ResponsiveLinkListBuilder = function (element) {

    this.element = $(element);
    this.rows = this.element.find('[data-role="responsive-link-list-row"]');
    this.init();
  }

  nn.component.ResponsiveLinkListBuilder.prototype = {
    init: function () {
      this.element.addClass('hidden-mobile');
      this.rows.addClass('hidden-mobile');

      this.buildBlock();
      this.bindEvents();
    },
    bindEvents: function () {
      this.blockRowContainer.on('click', 'a', $.proxy(this.clickHandler, this));
    },
    clickHandler: function (event) {
      event.preventDefault();
      var $link = $(event.target),
        index = $link.data('link-row-index'),
        $action = this.element.find('[data-row-index="' + index + '"] a[data-role="responsive-link-list-row-action"]');

      $action.click().change();
    },
    buildBlock: function () {
      this.block = $('<div class="block block--border visible-mobile"></div>');
      this.element.after(this.block);
      this.buildTitle();
      this.buildRows();
    },
    buildTitle: function () {
      var title = this.element.find('[data-role="responsive-link-list-title"]').text();
      this.blockTitle = $(['<h2>', title, '</h2>'].join(''));
      this.block.append(this.blockTitle);
    },
    buildRows: function () {
      this.blockRowContainer = $('<ul class="link-list link-list--responsive"></ul>');
      this.block.append(this.blockRowContainer);
      this.rows.each($.proxy(this.buildRow, this));
    },
    buildRow: function (index, row) {
      var $row = $(row),
        rowTitle = $row.find('[data-role="responsive-link-list-row-title"]').text(),
        rowAction = '#',
        rowLink;

      $row.attr('data-row-index', index);
      rowLink = ['<li>', '<a ',
        'data-link-row-index="', index, '" ',
        'href="', rowAction, '">', rowTitle, '</a>', '</li>'].join('');

      this.blockRowContainer.append($(rowLink));
    }
  };

})(window, jQuery, nn);