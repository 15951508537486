// Plugins

// Polyfills are now done through babel-polyfill as entry-point

require('./jquery/jquery.cycle.min');
require('./jquery/jquery.store.min');
require('./pubsub/pubsub.min');
require('./dom-shims/append');
require('./dom-shims/remove');
require('./dom-shims/matches');

require('../../node_modules/moment/locale/nl');
require('intersection-observer');

/**
 * Check if native Promise.prototype.finally is supported, even after
 * babel-polyfill/core JS has been applied. If not, we need to force the fetch
 * polyfill to override native fetch so it uses the polyfilled Promise object
 * rather than the native one.
 */
if (typeof Response === 'function' && !new Response().text().finally) {
 delete window.fetch;
}
require('./fetch/fetch.min');
/**/
