(function (window, $, nn) {
  nn.component.BreadCrumb = function (element) {
    this.element = $(element);
    this.element.wrap('<div class="breadcrumb"/>');
  };

  /**
   * Resize the breadcrumbs if all list items don't fit.
   * First resize the pills but not the first. Then use the left over width
   * to resize the heading (h1)
   */
  nn.component.BreadCrumb.prototype = {

    bindEvents: function () {

      $(window).on('resize', $.proxy(this.resize, this));
    },

    getListItemsWidth: function () {
      var totalWidths = 0;

      this.listItems.each(function () {
        totalWidths += $(this).width();
      });

      return totalWidths;
    },

    addBreadCrumbState: function () {
      if (this.listItems.size() === 1) {
        this.element.addClass('breadcrumb-heading-only');
      }
    },

    resize: function () {

      var containerWidth = this.element.width(),
        totalPillWidths = this.getPillsWidth();


      if (nn.getViewport() >= nn.settings.mobileBreakPoint) {

        this.lengthenPills();
        this.heading
          .removeClass('inline')
          .css({
            'width': 'auto'
          });

      } else if (this.getListItemsWidth() > containerWidth) {

        var resultWidthLeftForHeading = containerWidth - totalPillWidths,
          widthCorrection = 15;

        if (nn.helpers.browser.msie) {
          widthCorrection = 30;
        }
        if (resultWidthLeftForHeading < (this.heading.width() + widthCorrection)) {
          this.shortenPills();
          this.heading.removeClass('inline');
        } else {
          this.heading
            .addClass('inline')
            .css({
              'width': 'auto'
            });
        }
        var restWidth = (containerWidth - this.getPillsWidth() - this.correction);
        this.heading.css({
          'width': restWidth + 'px'
        });
      } else {
        this.lengthenPills();

        this.heading
          .addClass('inline')
          .css({
            'width': 'auto'
          });
      }
    },

    shortenPills: function () {

      this.pillsOnly.each(function () {
        var $self = $(this);
        if (!$self.hasClass('first')) {
          $self.addClass('short');
        }
      })

    },

    lengthenPills: function () {

      this.pillsOnly.each(function () {
        $(this).removeClass('short');
      })

    },

    getPillsWidth: function () {

      var totalPillWidths = 0;
      this.pillsOnly.each(function () {
        var $self = $(this);
        totalPillWidths += $self.width();
      });

      return totalPillWidths;
    }
  };

})(window, jQuery, nn);
