import details from './details';

const DialogLink = function(element) {

  // Our main container
  var $element = $(element);

  // The dialog instance
  var dialog;

  // The Dossier Details instance
  var dossierDetails;

  // Details rendered callback; method gets called after initialising Dossier Details
  var onDetailsRendered = function() {
    openDialog();
  };

  var config = $element.data("config");
  if (typeof config === "string" && config !== "") {
    config = JSON.parse(config.replace(/'/g, '"'));
  }

  // Render the dialog contents and instantiate our Dossier Details
  var renderDialog = function() {
    dialog = new window.nn.component.Dialog();

    dialog.content.html(generateDialogTemplate());
    dossierDetails = new details(
      dialog.content.find('[data-role="dialogsummary-content"]'),
      config
    );
    dossierDetails.onDetailsRendered(onDetailsRendered);
  };

  // Click listener for our link
  var onClick = function(evt) {
    evt.preventDefault();

    if (typeof dossierDetails !== "object") {
      // First click. Instantiate the Dossier Details and render the dialog contents.
      renderDialog();
    } else {
      openDialog();
    }
    return false;
  };

  // Open our dialog
  var openDialog = function() {
    dialog.show();
  };

  $element.on("click", onClick);

  // Public Method(s)
  return {
    renderDialog: renderDialog
  };
};

const generateDialogTemplate = () => {
  return `<div data-role="dialogsummary-content"></div>`;
}

export default DialogLink;