(function (window, $, nn) {


  nn.component.RequestAddress = function (element) {

    this.element = $(element);

    this.init();
  };

  nn.component.RequestAddress.prototype = {
    init: function () {

      this.servlet = this.element.data('servlet');
      this.findRequestFields();
      this.bindEvents();
      setTimeout($.proxy(this.initializeFormFields, this), 500);
    },
    findRequestFields: function () {

      // input
      this.zipCode = this.element.find('[data-role="zipcode"]')
      this.houseNumber = this.element.find('[data-role="housenumber"]');

      // output
      this.street = this.element.find('[data-role="street"]');
      this.city = this.element.find('[data-role="city"]');

      // message
      this.message = this.element.find('[data-role="request-address-message"]');
    },
    bindEvents: function () {

      this.zipCode.on('change', $.proxy(this.searchAddress, this));
      this.houseNumber.on('change', $.proxy(this.searchAddress, this));
    },
    initializeFormFields: function () {
      var street = this.getField('street'),
        city = this.getField('city');

      if (street.val() !== '') {
        this.makeEditableField(street);
      }

      if (city.val() !== '') {
        this.makeEditableField(city);
      }
    },
    searchAddress: function () {
      // change read only state
      var self = this,
        zipCode = this.zipCode.val(),
        houseNumber = parseInt(this.houseNumber.val(), 10),
        resZip = zipCode.match(/^\d{4}[A-Za-z]{2}/);

      if (!resZip) {
        return;
      }

      if (!houseNumber) {
        return;
      }

      $.ajax({
        url: this.servlet,
        dataType: 'json',
        data: {
          "ax_zip": resZip[0],
          "ax_hnr": houseNumber
        }
      }).done(function (data) {

        var streetValue = '',
          placeValue = '';

        if (data && data.straatnaam) {
          streetValue = data.straatnaam;
        }

        if (data && data.woonplaats) {
          placeValue = data.woonplaats;
        }

        self.updateStreetAndCity(streetValue, placeValue);
      }).fail(function () {
        self.updateStreetAndCity("", "");
      });

    },
    updateStreetAndCity: function (streetValue, cityValue) {
      var street = this.getField('street'),
        city = this.getField('city');

      this.makeEditableField(street);
      street.val(streetValue);
      street.trigger('blur');
      street.trigger('change');

      this.makeEditableField(city);
      city.val(cityValue);
      city.trigger('blur');
      city.trigger('change');

      if (streetValue === '' && cityValue === '') {
        this.message.removeClass('displayNone');
      } else {
        this.message.addClass('displayNone');
      }
    },
    makeEditableField: function (field) {
      if (field.val() === '') {
        field.attr('placeholder', "");
      }
      field.trigger('editable');
    },
    getField: function (fieldName) {
      if (this[fieldName]) {
        return this[fieldName];
      }

      return $('<input />');
    }
  };


})(window, jQuery, nn);