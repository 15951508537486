/**
 * Sitestat is obsolete and has been removed, having been replaced by other
 * analytics packages as abstracted by the data layer. However, we have no way
 * of knowing (at present, that is) whether the old sitestat API is used by
 * other parties. Therefore we've decided to leave a stub in its place. If some
 * piece of code calls a stub method, a console warning is shown.
 */

const stubsByType = {
  "function": () => window.console.warn(
    'Sitestat is obsolete, please remove it from the calling code.'
  ),
  "object": {},
  "string": "",
  "number": 0,
  "boolean": false,
};

const propsByType = {
  "string": [
    "accountUrl",
    "counterSeparator",
    "counterPrefix",
    "softclickTypeClickin",
    "softclickTypeClickout",
    "softclickTypeDownload",
    "softclickCounterNamePrefixClickin",
    "softclickCounterNameImgClickin",
    "softclickCounterNamePrefixClickout",
    "softclickCounterNamePrefixDownload",
    "infoIconCounterNameSuffix",
    "additionalParameters"
  ],
  "object": [
    "pageUrl",
    "pageCounterName"
  ],
  "number": [
    "unNamedObjectCounter"
  ],
  "function": [
    "bindMeasurements",
    "addParameters",
    "measureStandard",
    "measureTechnical",
    "measureSoftclick",
    "measureDeeplink",
    "bindCollapserClickIns",
    "bindClickouts",
    "bindSoftclick",
    "createClickoutCounterName",
    "bindDownloads",
    "createDownloadCounterName",
    "bindClickins",
    "bindAjaxLinks",
    "bindMenuLinks",
    "bindNotificationLinks",
    "bindSubmits",
    "createClickinCounterName",
    "bindInteractiveMenuButtons",
    "createInteractiveMenuButtonName",
    "bindInteractiveBlocks",
    "createInteractiveBlockCounterName",
    "createTabCounterName",
    "createNotificationCenterCounterName",
    "createNotificationCounterName",
    "bindLightbox",
    "createLightboxCounterName",
    "bindForms",
    "bindFormInfoIcons",
    "createInfoIconCounterName",
    "formatCounterName",
    "getQueryParameter",
    "measureAllowed",
    "initializeMeasure",
    "bindMeasurementsHeadAndFoot",
    "bindClickinsBanner",
    "bindSelects",
    "createSelectCounterName",
    "bindSelectChange",
    "formatComscoreLabel",
    "measureSelectChange",
    "getAdvisorNumber"
  ],
  "boolean": [
    "useAdvisorNumber"
  ]
};

export const sitestatService = Object.entries(propsByType)
  .reduce((out, [type, props]) => {
    return props.reduce((out, prop) => {
      out[prop] = stubsByType[type];
      return out;
    }, out);
  }, {});

export const sitestat = stubsByType.function;
export const ns_onclick = stubsByType.function;
export const sitestatMeasureTechnical = stubsByType.function;
export const gxAjaxGeneralCallback = stubsByType.function;
export const ns_API = {
  form: stubsByType.function,
  forms: {
    dispatchSubmitEvent: stubsByType.function,
    dispatchValidationError: stubsByType.function,
  },
};

global.sitestat = sitestat;
global.ns_onclick = ns_onclick;
global.sitestatMeasureTechnical = sitestatMeasureTechnical;
global.sitestatService = sitestatService;
global.gxAjaxGeneralCallback = gxAjaxGeneralCallback;
