(function (window, $, nn) {

  nn.api.uploadField = {
    getReferenceByID: function (id) {
      if (id && nn.references.UploadFields[id]) {
        return nn.references.UploadFields[id];
      }
    },
    showLoadingState: function (id) {
      this.getReferenceByID(id).setStateLoading();
    },
    showDefaultState: function (id) {
      this.getReferenceByID(id).setStateDefault();
    },
    showCompleteState: function (id) {
      this.getReferenceByID(id).setStateComplete();
    },
    showPercentageLoadingState: function (id, percentage) {
      this.getReferenceByID(id).setLoadingPercentage(percentage);
    },
    showErrorState: function (id, message) {
      this.getReferenceByID(id).showError(message);
    },
    hideErrorState: function (id) {
      this.getReferenceByID(id).hideError();
    },
  }

  nn.component.UploadField = function (element) {
    this.element = $(element);
    this.init();
  };

  nn.component.UploadField.prototype = {
    init: function () {
      this.getAndSetOptions();
      this.getAndSetElements();
      this.bindEvents();
      this.setReference();
    },
    getAndSetOptions: function () {
      this.labelLoading = this.element.attr('data-label-loading') || 'Bezig met uploaden...';
      this.labelCancel = this.element.attr('data-label-cancel') || 'Annuleren';
    },
    getAndSetElements: function () {
      this.wrapper = this.element.closest('.upload-field-wrapper');
      if (this.wrapper.length < 1) {
        this.wrapper = this.element.wrap('<div class="upload-field-wrapper"></div>').parent('.upload-field-wrapper');
      }


      this.nameDisplayText = $('.upload-field-filename-text', this.wrapper);
      if (this.nameDisplayText.length < 1) {
        this.nameDisplayText = $('<span class="upload-field-filename-text"></span>').appendTo(this.wrapper);
      }

      this.buttonSelect = $('<button class="button button--light upload-field-button-select">Bestand kiezen</button>').appendTo(this.wrapper);

      this.loadingWrapper = $('.upload-field-loading', this.wrapper);
      if (this.loadingWrapper.length < 1) {
        this.loadingWrapper = $('<div class="upload-field-loading">').appendTo(this.wrapper);
      }

      this.loadingMessage = $('<p class="upload-field-loading-message">' + this.labelLoading + '</p>').appendTo(this.loadingWrapper);
      this.loadingPercentage = $('<p class="upload-field-loading-percentage"></p>').appendTo(this.loadingWrapper);
      this.buttonCancel = $('<button class="button button--light upload-field-button-cancel">' + this.labelCancel + '</button>').appendTo(this.loadingWrapper);
      this.buttonDelete = $('<button class="upload-field-button-delete">Verwijder</button>').appendTo(this.wrapper);

      this.error = this.wrapper.siblings('.error-message');
      if (this.error.length === 0) {
        this.error = $('<p class="error-message"></p>');
      }
      this.warning = $('<div class="upload-field-warning block block--alert"></div>').appendTo(this.wrapper);
    },
    bindEvents: function () {
      this.element.on('change', $.proxy(this.onChangeFieldInput, this));
      this.buttonSelect.get(0).addEventListener('click', this.clickThroughHandler.bind(this));
    },
    clickThroughHandler: function () {
      this.element.click();
    },
    setReference: function () {
      var id = this.element.attr('id');

      if (id) {
        nn.references = nn.references || {};
        nn.references.UploadFields = nn.references.UploadFields || {};

        nn.references.UploadFields[id] = this;
      }
    },
    onChangeFieldInput: function () {
      var filename = this.element.val(),
        rxFilename = (/[/\\]([^/\\]+)$/).exec(filename);

      if (rxFilename && rxFilename[1]) {
        filename = rxFilename[1];
      }

      this.nameDisplayText.text(filename);
    },
    showError: function (message) {
      this.error.appendTo(this.wrapper.parent());
      this.error.html(message);
      this.error.removeClass('displayNone');
      this.wrapper.addClass('upload-field--error');
    },
    hideError: function () {
      this.error.detach();
      this.error.addClass('displayNone');
      this.wrapper.removeClass('upload-field--error');
    },
    showWarning: function (message) {
      this.warning.html('<p>' + message + '</p>');
      this.wrapper.addClass('upload-field--warning');
    },
    hideWarning: function () {
      this.wrapper.removeClass('upload-field--warning');
    },
    setStateDefault: function () {
      this.wrapper.removeClass('upload-field--loading upload-field--complete');
      this.state = 'default';
    },
    setStateLoading: function () {
      this.wrapper.removeClass('upload-field--complete').addClass('upload-field--loading');
      this.state = 'loading';
    },
    setStateComplete: function () {
      this.wrapper.removeClass('upload-field--loading').addClass('upload-field--complete');
      this.state = 'complete';
    },
    setLoadingPercentage: function (percentage) {
      if (this.state !== 'loading') {
        this.setStateLoading();
      }
      if (typeof percentage === "number" && percentage % 1 === 0 && percentage <= 100) {
        this.loadingPercentage.text(percentage + '%');
      }
    }
  };
})(window, jQuery, nn);
