/*jshint unused:false*/
import getNonce from "@nn/psdl-utility/src/nonce";

(function(moduleconfig, window) {
  'use strict';

  var callbacks = [];
  var apiStatus = false;

  window.app = window.app || {};
  window.app.google = window.app.google || {};

  window.app.google.map = function(element) {
    // Parse and/or arrange any given config parameters
    var config = moduleconfig({
      "element": element,
      "defaults": {
        "zoom": 8
      }
    });

    loadApi(config.key);
    addMapCallback(initMap.bind(undefined, element, config.zoom, config.markers));
  };

  // Queues a function 'callback' for execution as soon as the Google Maps API has loaded.
  // In case the API is already fully loaded we simply execute it right away.
  function addMapCallback(callback) {
    if (apiStatus === 'loaded') {
      callback();
    } else {
      callbacks.push(callback);
    }
  }

  // Callback for when the Google Maps API has indicated that it's been fully loaded.
  function apiLoaded(){
    apiStatus = 'loaded';
    for (var i = 0; i < callbacks.length; i++) {
      callbacks[i]();
    }
  }

  // Load the Google Maps API with the given key (it's only loaded once).
  function loadApi(key){
    if (apiStatus === false && !window.initMaps) {
      apiStatus = 'loading';
      window._NN_GoogleMapsApiLoaded = apiLoaded;
      var s = window.document.createElement('script');
      s.src = 'https://maps.googleapis.com/maps/api/js?key='+encodeURIComponent(key)+'&callback=_NN_GoogleMapsApiLoaded';
      s.async = true;
      s.defer = true;
      s.nonce = getNonce();
      window.document.head.appendChild(s);
    }
  }

  // Initialize the map on 'element' with the given options.
  function initMap(element, zoom, markers){
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({'address': markers[0].address}, function(results, status) {
      if (status === 'OK') {
        var map = new window.google.maps.Map(element, {zoom: zoom});
        map.setCenter(results[0].geometry.location);
      } else {
        element.parentNode.removeChild(element);
      }
    });
  }
}(window.app.helper.moduleconfig, window));