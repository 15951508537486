function get(selector, element) {
  const root = element || document;
  return [].slice.call(root.querySelectorAll(selector));
}

function getMetaContent(name) {
  const metaTag = window.document.querySelector(
    "meta[name='" + encodeURIComponent(name) + "']",
  );
  if (null !== metaTag) {
    return metaTag.content;
  }
}

function findAncestor(el, selector) {
  const parent = el.parentElement;

  if (!parent || pfMatches(parent, selector)) {
    return parent;
  }

  return findAncestor(parent, selector);
}

function pfMatches(el, selector) {
  if (el.matches) {
    return el.matches(selector);
  } else if (el.msMatchesSelector) {
    return el.msMatchesSelector(selector);
  }

  return false;
}

export { getMetaContent, get, findAncestor, pfMatches };
