/**
 *
 * Detect the browsers and place a class on the body
 * For IE browsers we use conditional statements so they will not be
 * handled here
 * FIXME remove this browser setup eventually, do feature detection instead we have MOdernizr for this allready
 */
(function (window, $, nn) {
  'use strict';

  var browsers = ['chrome', 'safari', 'mozilla', 'opera'];

  // old function allready made...
  nn.handlers.init.BrowserDetect = function () {
    if (navigator.userAgent.match(/Trident\/7\./)) {
      $('body').addClass('ie' + nn.helpers.browser.version);
    } else if (!nn.helpers.browser.msie) {
      $.each(browsers, function (i, val) {
        if (nn.helpers.browser[val]) {
          $('body').addClass(val);
        }
      });
    } else {
      $('body, html').addClass('ie' + nn.helpers.browser.version);
    }

    if (navigator.userAgent.match(/Android/i)) {
      $('body').addClass('android');
    }

    if (!nn.helpers.isTouchDevice()) {
      $('body').removeClass('touch');
    }
  };
})(window, jQuery, nn);
