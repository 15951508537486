import CMSContent from './cmscontent.js';
import moduleconfig from '../u-moduleconfig/moduleconfig.js';

window.app = window.app || {};
window.app.cmscontent = window.app.cmscontent || {};
window.app.cmscontent.Container = function (element) {
  const container = new CMSContent(element);
  container
    .load(moduleconfig({element}))
    .catch(window.console.warn);

  return container;
};