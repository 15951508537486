/**
 * @class SelectField
 * @description
 *    Replaces regular select boxes with
 *    custom ones.
 */

nn.api.SelectField = {
  enable: function (id) {
    for (var n = 0; n < nn.references.selectFields.length; n++) {
      if ($('select', nn.references.selectFields[n].element).attr('id') === id) {
        nn.references.selectFields[n].enable();
      }
    }
  },
  disable: function (id) {
    for (var n = 0; n < nn.references.selectFields.length; n++) {
      if ($('select', nn.references.selectFields[n].element).attr('id') === id) {
        nn.references.selectFields[n].disable();
      }
    }
  }
};

/** @constructor */
nn.component.SelectField = function (element) {

  var self = this;
  this.element = $(element);
  this.pipeTagSeparation = this.element.data('pipe-tag-separation');
  this.optionHeader = this.element.data('option-header');
  this.optionHeaderArr = String(this.optionHeader).split('|');
  this.touch = nn.helpers.isiPhone();

  // if touch and pipeTagSeparation
  if (this.touch && this.pipeTagSeparation) {
    this.element.find('option').each(function () {
      var temp = $(this).html().replace(/\|/g, ' ');
      $(this).html(temp);
    });
    this.pipeTagSeparation = false;
  }

  this.iafPrefillPersonalisation();

  this.element.addClass('select-hidden');
  this.index = 0;

  var classNames = this.element.attr('class').split(' ');
  var additionalClassName = '';
  $.each(classNames, function (index, item) {
    if (item.indexOf('span') !== -1) {
      additionalClassName = item;
    }
  });

  this.customSelectBox = $('<div class="custom-select-box"></div>').insertAfter(this.element);
  this.currentValue = $('<div class="current-value"></div>').appendTo(this.customSelectBox);
  this.optionDiv = $('<div class="custom-select-box-option-div"/>');


  if (additionalClassName !== '') {
    this.customSelectBox.addClass(additionalClassName);
  }

  this.isLowerIE9 = false;

  if (nn.helpers.browser.msie && parseInt(nn.helpers.browser.version, 10) < 9) {
    this.isLowerIE9 = true;
  }

  this.optionContainer = $('<ul class="custom-select-box-options"></ul>');
  this.optionContainer.appendTo(this.optionDiv);

  this.options = this.element.find('option');
  this.options.each(function (index) {
    self.constructOption($(this), index);
  });

  if (nn.helpers.browser.mozilla && parseInt(nn.helpers.browser.version, 10) >= 5) {
    this.currentValue.css('white-space', 'normal');
  }

  if (this.element.prop('disabled') === true || this.element.prop('disabled') === "true") {
    this.disable();
  }

  var selectedOptions = this.element.find('option:selected');

  if (this.pipeTagSeparation) {
    var selectedOptionIndex = selectedOptions.index(),
      displayIndex = (selectedOptionIndex > 0 ? selectedOptionIndex + 2 : 1);

    this.currentValue.html(this.optionContainer.find('li:nth-child(' + displayIndex + ')').html());
  } else {
    this.currentValue.text(selectedOptions.text());
  }

  if (this.element.attr('data-visible-options')) {
    var numberOfOptions = parseInt(this.element.attr('data-visible-options'), 10);
    this.numberOfVisibleOptions = numberOfOptions;

    this.optionDiv.addClass('scrolling');
    this.optionContainer.height(numberOfOptions * 25);
  }


  this.bindEvents();
};

/**
 * @method expand
 * @descriptions Expands the select box.
 */

nn.component.SelectField.prototype = {
  bindEvents: function () {
    var self = this;

    this.element
      .on('refresh', function () {

        var $selectedOption = self.element.find('option:eq(' + self.element[0].selectedIndex + ')'),
          selectedOptionText = $selectedOption.text();
        $selectedOption.prop('selected', true)


        if ($selectedOption.prop('disabled')) {
          return;
        }

        self.currentValue.text(selectedOptionText);


      })
      .on('focus', function () {
        self.customSelectBox.addClass('focused-custom-select-box');
      })
      .on('blur', function () {
        self.customSelectBox.removeClass('focused-custom-select-box');
      })
      .on('change', function () {

        var $selectedOption = self.element.find('option:eq(' + self.element[0].selectedIndex + ')'),
          selectedOptionText = $selectedOption.text();

        if (self.pipeTagSeparation) {
          selectedOptionText = self.optionParts($selectedOption.text());
        }

        $selectedOption.prop('selected', true)

        if ($selectedOption.prop('disabled')) {
          return;
        }
        if (self.pipeTagSeparation) {
          self.currentValue.html(selectedOptionText);
        } else {
          self.currentValue.text(selectedOptionText);
        }
      })
      .on('keydown', function (e) {

        var selectedIndex = this.selectedIndex;
        switch (e.keyCode) {
        case 40: /* Down */

          e.preventDefault();

          if (selectedIndex < this.options.length - 1) {
            selectedIndex += 1;
          }
          break;
        case 38: /* Up */

          e.preventDefault();

          if (selectedIndex > 0) {
            selectedIndex -= 1;
          }
          break;
        default:
          //return;
          break;
        }

        this.selectedIndex = selectedIndex;
        self.select(selectedIndex);
      });

    // Create a handler for the dialogs.hide event.
    this.customSelectBox
      .on('dialogs.hide', function (e, level) {
        if (self.level >= level) {
          self.collapse();
        }
      })
      .on('click', function (e) {
        if (nn.helpers.isiPad()) {
          if (!$(this).hasClass('disabled')) {
            e.stopPropagation();
            self.expand();
          }
        } else if (!nn.helpers.isiPhone()) {
          if (!$(this).hasClass('disabled')) {
            e.stopPropagation();
            self.element.trigger('focus');
            self.expand();
          }
        } else {
          self.element.trigger('focus');
        }

      });

    $(window)
      .on('Aquima:AjaxCall:Init', function () {
        self.collapse();
      })
  },
  constructOption: function ($optionElement, index) {
    var $option,
      self = this,
      optionParts,
      optionString,
      optionInnerHtml,
      $optionHeaderInnerHtml = "";

    if (this.value === this.element.val()) {
      this.index = index;
    }

    if (this.isLowerIE9 && this.options.length === 1) {
      $option = $('<li class="one-item"><span class="text">' + $optionElement.html() + '</span></li>');
      $('<span class="first-child"></span>').prependTo($option);
    } else if (this.pipeTagSeparation) {
      optionString = $optionElement.html();
      // split string
      optionParts = optionString.split('|');
      // base li
      $option = $('<li class="option-parts-length-' + optionParts.length + '"></li>');
      // generated option parts
      optionInnerHtml = this.optionParts(optionString);
      // create option header
      if (index === 0) {
        $optionHeaderInnerHtml += this.selectHeader();
      }
      $option.append(optionInnerHtml);
    } else {
      $option = $('<li>' + $optionElement.html() + '</li>');
    }

    $option.appendTo(this.optionContainer);

    if (index === 0) {
      this.optionContainer.find('li').first().after($optionHeaderInnerHtml);
    }

    if ($optionElement.attr('disabled')) {
      $option.addClass('disabled');
    }

    if (index === 0) {
      $option.addClass('first-child');
    }

    if (index === self.options.length - 1) {
      $option.addClass('last-child');
    }

    $option
      .on('click', function (e) {
        e.stopPropagation();
        self.select(index);
      })
      .on('mouseover', function () {
        var jThis = $(this);

        jThis.addClass('hover');
        if (jThis.hasClass('first-child')) {
          jThis.addClass('first-child-hover');
        }
        if (jThis.hasClass('last-child')) {
          jThis.addClass('last-child-hover');
        }

      })
      .on('mouseout', function () {
        var jThis = $(this);

        jThis.removeClass('hover');
        if (jThis.hasClass('first-child')) {
          jThis.removeClass('first-child-hover');
        }
        if (jThis.hasClass('last-child')) {
          jThis.removeClass('last-child-hover');
        }
      });

    //select on android not ipad
    if (nn.helpers.isTouchDevice() && !nn.helpers.isiPad()) {
      $option.on('touchend', function () {
        self.select(index);
      });
    }
  },
  expand: function () {
    var self = this;
    var selectedIndex = this.element[0].selectedIndex;

    if (selectedIndex > 0 && this.optionContainer.children('.option-select-header').length > 0) {
      selectedIndex += 1;
    }

    nn.globals.glass.show({
      clear: true
    });

    if (!nn.helpers.browser.msie) {
      this.currentValue.animate({
        backgroundColor: '#ea650d',
        borderTopColor: '#ea650d',
        borderRightColor: '#ea650d',
        borderBottomColor: '#ea650d',
        borderLeftColor: '#ea650d'
      }, nn.settings.fadeDuration);
    }

    //this.optionContainer

    this.optionDiv
      .css('left', 0)
      .css('top', 0)
      .css('visibility', 'hidden')
      .css('display', 'block')
      .appendTo('body');

    this.optionDiv.css('zIndex', 99999);


    // START GX: Support scrolling
    var offset = 0;
    var optionCount = self.element.find('option').length;

    if (optionCount > this.numberOfVisibleOptions) {
      var center = Math.floor(this.numberOfVisibleOptions / 2) - 1;
      if (selectedIndex <= center) {
        offset = selectedIndex * 25;
      } else if (optionCount - selectedIndex < this.numberOfVisibleOptions - center) {
        offset = (this.numberOfVisibleOptions - (optionCount - selectedIndex)) * 25;
      } else {
        offset = center * 25;
      }
    } else {
      offset = $('li:eq(' + selectedIndex + ')', this.optionContainer).position().top;
    }

    var left = this.customSelectBox.offset().left;
    var top = this.customSelectBox.offset().top - offset;

    if (top < 0) {
      top = 0;
    } // prevent custom selectbox to appear outside of screen

    if ($(this.element).attr('data-visible-options')) {
      top = self.customSelectBox.offset().top - 4;
    }

    this.optionDiv
      .css('left', left)
      .css('top', top);

    if (nn.helpers.isResponsivePage() && $(window).width() <= nn.settings.mobileBreakPoint) {
      this.optionDiv.css({
        'width': 'auto',
        'minWidth': this.customSelectBox.width()
      });

    }

    var currentOption = $('li:eq(' + selectedIndex + ')', this.optionContainer);
    currentOption.addClass('hover');

    if (currentOption.hasClass('first-child')) {
      currentOption.addClass('first-child-hover');
    }

    if (currentOption.hasClass('last-child')) {
      currentOption.addClass('last-child-hover');
    }

    this.level = ++nn.globals.dialogLevel;

    this.optionDiv
      .css('display', 'none')
      .css('visibility', 'visible')
      .fadeIn(nn.settings.fadeDuration, function () {
        self.visible = true;
      });

    this.optionContainer.scrollTop(Math.max(0, 25 * selectedIndex - offset));
  },
  /**
   * @method collapse
   * @description Collapses the select box.
   */
  collapse: function () {
    if (!nn.helpers.browser.msie) {
      this.currentValue.animate({
        backgroundColor: '#fff',
        borderTopColor: '#ccc',
        borderRightColor: '#ccc',
        borderBottomColor: '#ccc',
        borderLeftColor: '#ccc'
      }, nn.settings.fadeDuration);
    }

    var self = this;
    //this.optionContainer.fadeOut(nn.settings.fadeDuration, function() {
    this.optionDiv.fadeOut(nn.settings.fadeDuration, function () {
      $('li', self.optionContainer)
        .removeClass('hover')
        .removeClass('first-child-hover')
        .removeClass('last-child-hover');

      self.optionDiv.detach();
      self.visible = false;
    });
  },
  /**
   * @method select
   * @description Selects the specified option.
   *
   * @param {number} index
   *    The index of the option to select.
   */
  select: function (index) {

    var self = this;

    this.index = index;
    this.element[0].selectedIndex = index;

    if (this.visible) {
      nn.handlers.dialogs.hide({
        level: self.level
      });
    }

    this.element.trigger('change');
  },
  /**
   * Enable the select field.
   */
  enable: function () {

    this.element.removeAttr('disabled');
    this.customSelectBox.removeClass('disabled');
  },
  /**
   * Disable the select field.
   */
  disable: function () {

    this.element.prop('disabled', true);
    this.customSelectBox.addClass('disabled');
  },
  /**
   * Disable the select field.
   */
  iafPrefillPersonalisation: function () {

    if (typeof window.FormValidation === 'undefined') {
      return;
    }

    var self = this,
      $containerElement = this.element.closest('[data-iaf-prefill-value]'),
      iafCurrentValue = $containerElement.data('iaf-current-value');

    if (typeof iafCurrentValue !== 'undefined' && iafCurrentValue.toString().length > 0) {
      this.element.val(iafCurrentValue);
      this.element.closest('.nn_form')
        .on('IAF_ClientsideFrameworkLoaded', function () {
          window.FormValidation.changeFragment(self.element, false, true);
        });
    }
  },
  /**
   * Split option select value in parts.
   */
  optionParts: function (optionString) {
    var optionPart = "", optionParts;
    optionParts = optionString.split('|');

    for (var i = 0; i < optionParts.length; i++) {
      optionPart += '<span class="option-part option-part-' + i + '">' + optionParts[i] + '</span>';
    }
    return optionPart;
  },
  /**
   * Add select header
   */
  selectHeader: function () {
    var optionHeader = "";
    optionHeader += '<li class="option-select-header option-parts-length-' + this.optionHeaderArr.length + '">';
    for (var i = 0; i < this.optionHeaderArr.length; i++) {
      optionHeader += '<span class="option-part">' + this.optionHeaderArr[i] + '</span>';
    }
    optionHeader += '</li>';

    return optionHeader;
  }
};
