import { publish } from '../utility/pubsub.js';

function addDropdownToHeader(element) {
  const el = document.createElement("div");
  el.classList.add('c-header-dropdown');

  if (element.querySelector('.c-header__user')) {
    el.classList.add('c-header-dropdown--user');
  }

  const elHtml = el.outerHTML;
  element.insertAdjacentHTML('afterend', elHtml);
  return element.nextSibling;
}

function addDropdownEventListeners(menuItems, menuItemMore, dropdown) {
  let itemsForEventListeners;
  let linkMouseOver = false;
  let dropdownMouseOver = false;

  dropdown.addEventListener('mouseenter', () => {
    dropdownMouseOver = true;
  });

  dropdown.addEventListener('mouseleave', () => {
    dropdownMouseOver = false;
    setTimeout(() => {
      if (!linkMouseOver) {
        hideDropdown(dropdown, linkMouseOver);
      }
    }, 150);
  });

  itemsForEventListeners = [...menuItems];
  itemsForEventListeners.push(menuItemMore);

  itemsForEventListeners.forEach((menuItem) => {
    const subMenu = menuItem.querySelector('.c-header__menu-sub')
    if (subMenu && subMenu.children && subMenu.children.length !== 0) {
      const menuItemLink = menuItem.querySelector('.c-header__menu-link');

      menuItemLink.addEventListener('mouseenter', () => {
        linkMouseOver = true;
        setTimeout(() => {
          if (linkMouseOver) {
            showDropdown(dropdown, menuItem)
          }
        }, 150);
      });

      menuItemLink.addEventListener('mouseleave', () => {
        linkMouseOver = false;
        setTimeout(() => {
          if (!dropdownMouseOver && !linkMouseOver) {
            hideDropdown(dropdown);
          }
        }, 150);
      });
    }
  });
}


function showDropdown(dropdown, menuItem) {
  const subMenuForDropdown = menuItem.querySelector('.c-header__menu-sub').cloneNode(true).innerHTML;
  const dropdownIsOpen = dropdown.classList.contains('c-header-dropdown--open');
  if (!dropdownIsOpen) {
    const headerWidth = document.querySelector('[data-module="header"]').getBoundingClientRect().width;
    dropdown.style.width = `${headerWidth}px`;
    dropdown.classList.add('c-header-dropdown--open');
    dropdown.innerHTML = subMenuForDropdown;
    publish('header:state:dropdown', dropdown);

  } else if (subMenuForDropdown !== dropdown.innerHTML) {
    dropdown.innerHTML = subMenuForDropdown;
  }

}

function hideDropdown(dropdown) {
  if (dropdown.classList.contains('c-header-dropdown--open')) {
    dropdown.classList.remove('c-header-dropdown--open');
    dropdown.innerHTML = "";
  }
}

export { addDropdownToHeader, addDropdownEventListeners }