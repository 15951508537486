/*
Blueriq app element

Blueriq apps are contained in an application div.
We wrap this in a component: the 'BlueriqElement'.

BlueriqElementV1 Application divs have the following structure:

<div
	data-blueriq-scope="<scope>"
	data-blueriq-div="remote"
	data-blueriqjs-url="/<contextroot>/current-web-resources/nn-responsive/js/blueriq.js?scope=<scope>"
	data-start-url="/pfc/doeFunctie?p=49161&f=2000&c=200"
	data-get-url="/<contextroot>/getPage?sessionid=<sessionid>"
	data-active-session-url="/<contextroot>/getObject?type=hasActiveBlueriqSession&p=49161&f=2000&c=200&sessionid=<sessionid>"
	data-session-on-refresh="true|false"
	data-component="BlueriqElement"
></div>

When using Oauth, use BlueriqElementV2 to configure an oauthClientKey
BlueriqElementV2 Application divs have the following structure:

<div
  data-blueriq-config="{'oauthClientKey':'mykey'}"
	data-blueriq-scope="<scope>"
	data-blueriq-div="remote"
	data-blueriqjs-url="/<contextroot>/current-web-resources/nn-responsive/js/blueriq.js?scope=<scope>"
	data-start-url="/pfc/doeFunctie?p=49161&f=2000&c=200"
	data-get-url="/<contextroot>/getPage?sessionid=<sessionid>"
	data-active-session-url="/<contextroot>/getObject?type=hasActiveBlueriqSession&p=49161&f=2000&c=200&sessionid=<sessionid>"
	data-component="BlueriqElement"
></div>

Sub app divs (these are not handled by BlueriqElement):

<div data-blueriq-scope="AppScope1" data-blueriq-div="remote_sub"></div>
*/
import Dialog from '@nn/psdl-modules/src/dialog/dialog.js';

/*
** NOTE: Only relevant for advisor
**
** These are the names of the scopes of the advisor apps that support
** multiple concurrent sessions (different tabs with different sessions/contexts).
*/
var multiSessionApps = [
  'kikoDigitalePostbus',
  'kikoKlantenoverzicht',
  'kikoKlantinzichtProductbezit',
  'AccessManagementVa',
  'kikoLopendeZaken',
  'kikoSearch',
  'kikoKlantinzichtProductoverzicht',
  'suitcasevaportal',
];

/* These are the apps that don't support multisessions, but where we need an exception for.
*/

const exceptionalApps = [
  'levencontractdetails'
];

const whitelist = multiSessionApps.concat(exceptionalApps);

// Check if storage is disabled (e.g. iOS safari incognito mode).
var storageDisabled = false;
try{
  window.app.sessionStorage.setItem('blueriq-storage-test', 'y');
  window.app.localStorage.setItem('blueriq-storage-test', 'y');
}catch(e){
  storageDisabled = true;
}

// Maximum lifetime of a blueriq scope in ms
var scopeLifetime;
if (!storageDisabled) {
  //used for debugging
  scopeLifetime = window.app.localStorage.getItem('blueriq-scope-lifetime');
}

if (!scopeLifetime) {
  scopeLifetime = 1000*60*60*24;
}

/*
** BlueriqElement
** @param {DomNode} element
** @returns {void}
*/
nn.component.BlueriqElement = function (element) {
  this.element = element;
  this.originalScope = element.getAttribute('data-blueriq-scope');
  this.blueriqLoaded = false;
  this.invalidated = false;

  var isAdvisorApp = window.location.host.toLowerCase().indexOf('adviseur') !== -1;
  var isAdvisorMultisessionApp = whitelist.indexOf(this.originalScope) !== -1;
  this.useSessionFix = (isAdvisorApp && !isAdvisorMultisessionApp);

  this.setUid();
  this.setScope();
  this.init();
};

nn.component.BlueriqElement.prototype = {
  // Initialize the app. If relevant, apply
  init: function () {
    if (this.useSessionFix) {
      const urlParams = new URLSearchParams(window.location.search);
      const bqscope = urlParams.get('bqscope');
      var sessionId = nn.helpers.Cookie.get(this.scope+'-blueriqSessionId');

      // Update URL
      var items = window.location.search.substring(1).split('&');
      items = items.filter(function (item) {
        return item.indexOf('bqscope=') !== 0;
      });
      items.push("bqscope=" + this.originalScope);

      var location = '?' + items.join('&') + window.location.hash;
      history.replaceState('replace', "Nationale-Nederlanden", location);

      // Terminate if there's a scope for this session ID
      // and the GET parameter doesn't match.
      // NOTE: we cannot use window[scope].blueriqApi.terminateBlueriqSession,
      // because it doesn't tell us when the AJAX call has completed.
      if (typeof sessionId === 'string' && bqscope !== this.originalScope) {
        var urlBase = this.element.getAttribute('data-active-session-url').split('?').shift();
        var url = urlBase + '?type=terminateBlueriqSession&sessionId=' + sessionId;
        fetch(url, {credentials: 'same-origin'})
          .then(function(response){
            return new Promise(function(resolve, reject){
              if(response.ok) {
                resolve()
              } else {
                reject();
              }
            });
          })
          .then(this.bootstrap.bind(this));
      } else {
        this.bootstrap();
      }
    } else {
      this.bootstrap();
    }
  },

  // Generate a new UID.
  generateUid: function() {
    return String(Date.now()) + ':' + String(Math.random()).split('.').pop();
  },

  // Sets the UID of this tab.
  setUid: function() {
    if (storageDisabled) {
      this.tabUid = this.generateUid();
    } else {
      this.tabUid = window.app.sessionStorage.getItem('blueriq-tabuid');
      if (!this.tabUid) {
        this.tabUid = this.generateUid();
        window.app.sessionStorage.setItem('blueriq-tabuid', this.tabUid);
      }
    }
  },

  /*
	** The scopes of scoped blueriq apps are suffixed with a numeric ID
	** to differentiate them from one another.
	*/
  setScope: function () {
    var scopeId = 0;

    if (!storageDisabled) {
      var scopes = window.app.localStorage.getItem('blueriq-scopes');
      if (!scopes) {
        scopes = {};
      }

      if (!scopes[this.originalScope]) {
        scopes[this.originalScope] = [];
      }

      if (!this.useSessionFix) {
        // If this scope already exists, we reuse it.
        scopeId = scopes[this.originalScope].indexOf(this.tabUid);
        if (scopeId === -1) {
          // If it doesn't exist yet, we check if there's an expired scopeId we can overwrite.
          for (var i = 0; i < scopes[this.originalScope].length; i++) {
            var then = Number(scopes[this.originalScope][i].split(':').shift());
            var now = Date.now();
            if ((then + scopeLifetime) < now) {
              scopeId = i;
              break;
            }
          }

          // If there's no old scopeId, we create a new one.
          if (scopeId === -1) {
            scopeId = scopes[this.originalScope].length;
          }
        }
      }

      scopes[this.originalScope][scopeId] = this.tabUid;
      window.app.localStorage.setItem('blueriq-scopes', scopes);
    }

    this.scope = this.originalScope + String(scopeId);

    var apps = document.querySelectorAll('[data-blueriq-scope="' + this.originalScope + '"]:not([data-scoped="true"])');
    Array.prototype.slice.call(apps).forEach(function(app){
      app.setAttribute('data-blueriq-scope', this.scope);
      app.setAttribute('data-scoped', true);
    }.bind(this));

    nn.globals.blueriqScopes.push(this.scope);
  },

  /*
	** The handler that is triggered when the application-specific
	** blueriq.js asset has been loaded.
	*/
  ready: function (event) {
    if (event.data.scope !== this.scope || this.blueriqLoaded) {
      return;
    }

    this.blueriqLoaded = true;
    if (this.useSessionFix) {
      if (!storageDisabled) {
        window.app.localStorage.setItem(
          'blueriq-scopeuid-'+this.originalScope,
          window.app.sessionStorage.getItem('blueriq-tabuid')
        );
      }

      document.addEventListener('visibilitychange', function(){
        if (document.hidden !== false || this.invalidated) {
          return;
        }

        if (storageDisabled || this.tabUid !== window.app.localStorage.getItem('blueriq-scopeuid-'+this.originalScope)) {
          const dialog = new Dialog({
            themes: ['m'],
            locked: true,
          });
          dialog.setContent('<p>Er is een fout opgetreden. U heeft deze applicatie later voor een andere klant nogmaals geopend. Hierdoor is deze sessie beëindigd.</p>');
          dialog.show();
          this.invalidated = true;
        }
      }.bind(this));
    }
  },

  /*
	** Bootstrap the app. The process differers between scoped and old Aquima apps.
	*/
  bootstrap: function () {
    window.addEventListener('NN:Blueriq:completeLoading', this.ready.bind(this));
    window[this.scope] = {
      blueriqBootstrap: {
        settings: {
          config: this.element.getAttribute('data-blueriq-config'),
          startUrl: this.element.getAttribute('data-start-url'),
          getUrl: this.element.getAttribute('data-get-url'),
          hasActiveBlueriqSession: this.element.getAttribute('data-active-session-url'),
          newBlueriqSessionOnBrowserRefresh: (this.element.getAttribute('data-session-on-refresh') === 'true')
        }
      }
    };

    nn.helpers.libLoader.load(this.element.getAttribute('data-blueriqjs-url') + '?scope=' + encodeURIComponent(this.scope), null, true);
  }
};
