// Possible license plate patterns.
const sets = [
  'XX-99-99',
  '99-99-XX',
  '99-XX-99',
  'XX-99-XX',
  'XX-XX-99',
  '99-XX-XX',
  '99-XXX-9',
  '9-XXX-99',
  'XX-999-X',
  'X-999-XX',
  'XXX-99-X'
];

/**
 * This function takes an unformatted license plate number, strips it of
 * irrelevant characters, formats it by inserting dashes in the right places,
 * then returns it.
 *
 * @param {string} kenteken, unformatted license plate number;
 * @return {string}, the formatted license plate number.
 */
export default function format (kenteken) {
  const original = kenteken;

  // Canonicalize by removing anything that isn't significant.
  kenteken = kenteken
    .toUpperCase()
    .replace(/[^0-9A-Z]/g, '')
    .substring(0, 6);

  if (kenteken.length !== 6) {
    return original.substring(0, 8);
  }

  // Turn our license plate number into a pattern.
  const inputPattern = kenteken
    .replace(/[0-9]/g, '9')
    .replace(/[A-Z]/g, 'X');

  // Find out which set pattern best matches ours so far.
  const matchingSet = sets
    .find(pattern => pattern.replace(/-/g, '').startsWith(inputPattern))

  // If no matching pattern was found we apply dashes evenly as a last resort.
  if (!matchingSet) {
    return kenteken
      .match(/.{1,2}/g)
      .join('-');
  }

  // Get the offsets of the dashes in our matching set.
  const dashOffsets = matchingSet
    .split('')
    .map((glyph, offset) => {
      return glyph === '-' ? offset : -1;
    })
    .filter(offset => offset > 0);

  // We take the matching set and insert dashes at the right offsets. If the
  // offset lies beyond the current length, we ignore it.
  return dashOffsets
    .reduce((kenteken, offset) => {
      if (offset < kenteken.length) {
        return `${kenteken.substr(0, offset)}-${kenteken.substr(offset)}`;
      }
      return kenteken;

    }, kenteken);
}
