import Dialog from './dialog';
import './_c-dialog.scss';

// Themes
import './_c-dialog--context.scss';
import './_c-dialog--datepicker.scss';

window.app = window.app || {};
window.app.Dialog = function(options){
  return new Dialog(options);
}
