(function (window, $, nn) {

  nn.component.Hide = function (element) {
    this.element = $(element);

    this.init();
  };

  nn.component.Hide.prototype = {

    init : function () {
      this.getAndSetOptions();
      this.startTimeout();
    },
    getAndSetOptions : function () {
      this.timeoutSeconds = this.element.attr('data-hide-timeout');
      if (this.timeoutSeconds && this.timeoutSeconds.match(/\d+/)) {
        this.timeoutSeconds = parseInt(this.timeoutSeconds, 10) * 1000;
      } else {
        this.timeoutSeconds = 2000;
      }
    },
    startTimeout: function () {
      this.timeout = window.setTimeout($.proxy(this.onTimeoutReached, this), this.timeoutSeconds);
    },
    onTimeoutReached : function () {
      this.element.slideUp();
    }
  };

})(window, jQuery, nn);