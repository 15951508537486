/**
 *
 * @class StepIndicatorListener
 * @description This class listens to the Aquima dialog and uses
 * its 'currentStep' event to determine in which step the Aquima dialog is.
 * and acts accordingly
 *
 */
nn.component.StepIndicatorListener = function (element) {

  this.element = $(element);
  this.init();

}

nn.component.StepIndicatorListener.prototype = {

  /**
   *
   */
  init: function () {
    this.getAndSetElements();
    this.bindEvents();
  },
  getAndSetElements: function () {
    this.steps = this.element.data('show-at-steps').toString().split(',');
    this.event = this.element.data('event');
    this.valueType = this.element.data('value-type');

    // todo: implement data-value-type="attr"

    if (this.valueType === 'attr') {
      this.ATTR = this.element.data('step-value-selector');
      this.SELECTOR = '[' + this.ATTR + ']';
    } else {
      this.SELECTOR = this.element.data('step-value-selector');
    }
  },
  bindEvents: function () {
    if (typeof aquimaintegration !== 'undefined') {
      aquimaintegration.api.addListener('currentStep', $.proxy(this.currentStepListener, this));
    }

    document.addEventListener(this.event, $.proxy(this.currentStepCustomEventListener, this));
    this.currentStepCustomEventListener();
  },
  currentStepCustomEventListener: function () {

    var identifier = this.getIdentifier(),
      identifierStep = {
        'step': identifier
      };
    this.currentStepListener({}, identifierStep);

  },
  getIdentifier: function () {

    var element = $(this.SELECTOR),
      identifier = null;

    if (element.length === 0) {
      return identifier;
    }

    if (this.valueType === 'attr') {
      identifier = element.attr(this.ATTR);
    } else if (this.valueType === 'value') {
      identifier = element.val();
    } else if (this.valueType === 'html') {
      identifier = element.html();
    }

    return identifier;
  },
  /**
   *
   */
  currentStepListener: function (event, stepInfo) {
    if (this.isCurrentStepPresent(stepInfo.step)) {
      this.element.show();
    } else {
      this.element.hide();
    }
  },

  /**
   *
   */
  isCurrentStepPresent: function (currentStep) {
    return this.steps.indexOf(currentStep) !== -1;
  }
}