/**
 * Bedrag Formatter (social security number)
 *
 * Bij het focussen van het veld worden punten en comma's weggehaald.
 * Bij blurren wordt het bedrag omgezet naar een mooi bedrag
 *
 * Uses the following method
 * $.numberFormat(number, decimals, dec_point, thousands_sep);
 *
 * @param {HTMLNode} element
 * @returns {void}
 */
nn.component.PriceFormatter = function (element) {
  this.element = $(element);
  this.init();
};

nn.component.PriceFormatter.prototype = {
  init: function () {
    this.element
      .on('focus', $.proxy(this.onFocusHandler, this))
      .on('blur', $.proxy(this.onBlurHandler, this))
      .on('keydown', $.proxy(this.onKeydownHandler, this));
  },
  onFocusHandler: function () {

    var step1 = this.element.val().replace('.', '');
    var step2 = step1.replace(',', '.');

    this.element.val(step2);
  },
  onBlurHandler: function () {

    var v = this.element.val().replace(',', '.');
    this.element.val($.numberFormat(v, '2', ',', '.'));
  },
  onKeydownHandler: function (e) {
    var kc_backspace = 8,
      kc_tab = 9,			// key = tab
      kc_delete = 46,		// key = delete
      kc_period = 190,	// key = period
      kc_c = 67,			// key = c
      kc_v = 86,			// key = v
      kc_cmd = 91,		// key = cmd
      kc_ctrl = 17;		// key = ctrl

    // check if ctrl or cmd is pressed
    if (e.keyCode === kc_cmd || e.keyCode === kc_ctrl) {
      this.cmd = true;
    }

    if (e.keyCode === kc_backspace
      || e.keyCode === kc_tab
      || e.keyCode === kc_delete
      || e.keyCode === kc_period
      || e.keyCode === kc_cmd
      || nn.helpers.isArrowKeyCode(e)
      || nn.helpers.isNumericKeyCode(e)
      || (this.cmd === true && e.keyCode === kc_v)
      || (this.cmd === true && e.keyCode === kc_c)) {
      // valid characters or valid key combinations (ctrl-c ctrl-v cmd-c cmd-v)
    } else {
      // invalid character
      e.preventDefault();

    }
  }
};