export default class ChartLegend {
  constructor (element) {
    this.element = element;
    this.sliceStyles = ["graph-type-46","graph-type-67","graph-type-56", "graph-type-47", "graph-type-52", "graph-type-57", "graph-type-48", "graph-type-53", "graph-type-49"];
    this.graphData = [];
    this.rawConfig = this.element.dataset.chartlegendConfig;

    this.constructContainer();
    if (this.rawConfig) {
      this.processConfig(this.rawConfig);
    }
  }

  processConfig (config) {
    config = JSON.parse(config);
    let i, c;
    // if we have a piechart we use slicestyles and labels
    if (config.graphType === 'pie') {
      if (!config.sliceStyles){
        config.sliceStyles = this.sliceStyles;
      }
      for (i = 0, c = config.labels.length; i < c; i++) {
        this.addItem(config.labels[i], config.sliceStyles[i]);
      }
    } else {
      for (i = 0, c = config.data.length; i < c; i++) {
        this.addItem(config.data[i].name, config.data[i].lineStyle);
      }
    }
  }

  constructContainer () {
    if (this.element.offsetParent) {
      this.legendcontainer = document.createElement('ul');
      this.legendcontainer.classList.add('c-chartlegend');
      this.element.appendChild(this.legendcontainer);
    }
  }

  addItem (name, graphType) {
    const li = document.createElement('li');
    li.textContent = name;
    const row = document.createElement('div');
    li.appendChild(row);
    const line = document.createElement('span');
    line.classList.add(graphType)
    row.appendChild(line);
    this.legendcontainer.appendChild(li);
  }
}
