/**
 * @class Reviews
 * @description Applies a width to a rating element.
 */


(function (window, $, nn) {

  nn.component.Reviews = function (element) {

    this.element = $(element);
    this.rows = this.element.find('.review-rating--numeral');
    this.init();

  };

  nn.component.Reviews.prototype = {

    init : function () {

      this.rows.each(function( i, e ) {
        var currentValue = $(e).html();
        var currentPercentage = (currentValue*10) + "%";
        $(e).siblings('.review-rating--current').css('width',currentPercentage);
      });
    }
  };

})(window, jQuery, nn);