nn.component.StickyContact = function (element) {
  this.element = $(element);
  this.init();
};

$.extend(nn.component.StickyContact.prototype, {
  init: function () {
    this.getAndSetElements();
    this.bindEvents();
  },
  getAndSetElements: function () {
    this.titleElement = this.element.find('.block-title');
    this.titleElementHeight = this.titleElement.height();
    this.originalHeight = this.element.height();

    this.onClickListenerDebounced = nn.helpers.debounce($.proxy(this.onClick, this), 200);

  },
  bindEvents: function () {
    this.element.on('StickyPanelTop:StickyChange', $.proxy(this.onStickyChange, this));
    this.titleElement.on('mouseenter', $.proxy(this.onMouseEnter, this));
    this.element.on('mouseleave', $.proxy(this.onMouseLeave, this));
    this.titleElement.on('touchstart', $.proxy(this.onTouchStart, this));
    this.titleElement.on('touchend', $.proxy(this.onTouchEnd, this));
  },
  onTouchStart: function () {
    this.touched = true;
  },
  onTouchEnd: function () {
    this.touched = false;
    if (this.collapsible) {
      if (this.collapsed) {
        this.expandBlock();
      } else {
        this.collapseBlock();
      }
    }
  },
  onMouseEnter: function () {
    if (this.collapsible) {
      if (!this.touched) {
        this.expandBlock();
      }
    }
  },
  onMouseLeave: function () {
    if (this.collapsible) {
      if (!this.touched) {
        this.collapseBlock();
      }
    }
  },
  onStickyChange: function (evt, data) {
    this.setCollapsible(data.sticky);
  },
  setCollapsible: function (collapsible) {
    this.collapsible = !!collapsible;
    if (this.collapsible) {
      this.collapseBlock();
    } else {
      this.expandBlock();
    }
  },
  collapseBlock: function () {
    this.element.stop().animate({'height': this.titleElementHeight}, 400);
    this.collapsed = true;
  },
  expandBlock: function () {
    this.element.stop().animate({'height': this.originalHeight}, 400);
    this.collapsed = false;
  }
});