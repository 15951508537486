import debounce from 'lodash/debounce';

nn.registerModule('scrolltotop', (element) => {
  var the_a = document.createElement('a'),
    the_s = document.createElement('span');
  // duration of animation while going back to top
  var back_duration = 500,
    // scroll position after which 'back to top' button will be visible
    back_offset = 800;
  //injecting the back_button
  the_s.classList.add('c-scroll-to-top', 'c-icon', 'c-icon--chevron-up');
  the_a.appendChild(the_s);
  the_a.setAttribute('href', '#top');
  the_a.setAttribute('aria-label', 'ga naar boven');
  element.appendChild(the_a);
  // store 'back to top' element in variable for easy access
  var back_button = document.querySelector('.c-scroll-to-top');

  function scrollToTop(scrollDuration) {
    var scrolly = window.scrollY,
      cosParameter = scrolly / 2,
      scrollCount = 0,
      oldTimestamp = performance.now();

    function step(newTimestamp) {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI) {
        window.scrollTo(0, 0);
      } else if (scrolly !== 0) {
        window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }
    }
    window.requestAnimationFrame(step);
  }
  // animate back to top
  back_button.addEventListener('click', function () {
    scrollToTop(back_duration);
    back_button.classList.remove('c-scroll-to-top-fadeIn');
  });
  // making button direction aware
  var lastScrollTop = 0;
  var debouncedScroll = debounce(function () {
    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    // show button after offset value
    if (scrollTop > back_offset) {
      back_button.classList.add('c-scroll-to-top-visible');
    } else {
      back_button.classList.remove('c-scroll-to-top-visible');
    }
    // check if scrolling down
    if (scrollTop > lastScrollTop) {
      back_button.classList.remove('c-scroll-to-top-fadeIn');
    } else if (back_button.classList.contains('c-scroll-to-top-visible')) {
      // scrolling up? if yes make it fadeIn
      back_button.classList.add('c-scroll-to-top-fadeIn');
    } else {
      back_button.classList.remove('c-scroll-to-top-fadeIn');
    }

    function getDocHeight() {
      var D = document;
      return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
      );
    }
    //check if at the bottom
    if ((window.innerHeight + window.pageYOffset) >= (getDocHeight() - 100)) {
      back_button.classList.add('c-scroll-to-top-fadeIn');
    }
    lastScrollTop = scrollTop;
  }, 100);
  window.addEventListener('scroll', debouncedScroll);
});
