(function (window, moduleconfig) {
  'use strict';

  window.app = window.app || {};
  window.app.expanderlist = window.app.expanderlist || {};

  window.app.expanderlist.Index = function (element) {

    var config = moduleconfig({
      element: element,
      defaults: {
        behaviour: 'accordion',
        bqstore: true
      }
    });
    var blueriqID;

    if (config.bqstore) {
      for (var el = element; el; el = el.parentElement) {
        blueriqID = el.getAttribute('data-blueriq-component-id');
        if (blueriqID) {
          break;
        }
      }
    }

    // create an array
    var items = Array.prototype.slice.call(element.querySelectorAll('[data-role~="expander"]'));

    items.forEach(function (item, index) {
      item.dataset.index = index;
      var toggle = item.querySelector('[data-role~="toggle"]');
      if (!toggle && item.getAttribute('data-role').split(' ').indexOf('toggle') >= 0) {
        toggle = item;
      }

      if (toggle) {
        toggle.addEventListener('click', function (event) {
          // Do not fire the click handler when clicking on a form field
          if (['select', 'textarea', 'input', 'option'].indexOf(event.target.nodeName.toLowerCase()) === -1) {
            setItemStates(items, item, config.behaviour, blueriqID);
          }
        });
      }
    });

    if (config.bqstore) {
      items.forEach(function (item) {
        setDefaultStateForBQ(blueriqID, item);
      });
    }
  };

  /**
	 *
	 * @param {String} blueriqID
	 * @param {HTMLNode} item
	 * @param {Number} index
	 * @returns {void}
	 */
  function storeStateForBQ(blueriqID, item, expanded) {

    // if there is no BlueriqID we don't want to store anything
    if (!blueriqID) {
      return;
    }

    window.app.localStorage.setItem('expanderlist.' + blueriqID + '-' + item.dataset.index, expanded);
  }

  /**
	 *
	 * @param {String} blueriqID
	 * @param {HTMLNode} item
	 * @param {Number} index
	 * @returns {void}
	 */
  function setDefaultStateForBQ(blueriqID, item) {

    // if there is no BlueriqID we don't want to do anything
    if (!blueriqID) {
      return;
    }

    var expanded = window.app.localStorage.getItem('expanderlist.' + blueriqID + '-' + item.dataset.index);
    if (expanded) {
      item.classList.add('is-expanded');
    }
  }

  function setItemStates(items, clickedItem, behaviour, blueriqID) {
    var clickedItemId = clickedItem.getAttribute('data-expander-id');
    var targetItems = items.filter(function (item) {
      var itemId = item.getAttribute('data-expander-id');
      if (typeof itemId === 'string') {
        return itemId === clickedItemId;
      }
      return item === clickedItem;

    });

    targetItems.forEach(function(targetItem){
      setState(targetItem, !targetItem.classList.contains('is-expanded'), blueriqID);
    });

    if (behaviour === 'collapser') {
      targetItems.forEach(timeout);
    } else {
      items
        .filter(function (item) {
          var itemId = item.getAttribute('data-expander-id');
          if (typeof itemId === 'string') {
            return itemId !== clickedItemId;
          }
          return item !== clickedItem;

        })
        .forEach(function (item) {
          setState(item, false);
          storeStateForBQ(blueriqID, item, false);
        });

      items.forEach(timeout);
    }
  }

  function timeout(item) {
    clearTimeout(item.expanderListTimer);
    item.expanderListTimer = setTimeout(function () {
      var outerContent = item.querySelector('[data-role~="content-outer"]');
      stateUpdated(item, outerContent);
    }, 500);
  }

  // Handles the max-height transition for the content box given by 'outerContent'
  // that belongs to the expander given by 'expander'.
  function stateUpdated(expander, outerContent) {
    if (expander.classList.contains('is-expanding')) {
      expander.classList.add('is-expanded');
      expander.classList.remove('is-expanding');

      if (outerContent) {
        outerContent.style.maxHeight = '';
      }
    } else if (expander.classList.contains('is-contracting')) {
      expander.classList.remove('is-contracting');

      if (outerContent) {
        outerContent.style.maxHeight = '';
      }
    } else {
      expander.classList.remove('is-contracting');
      expander.classList.remove('is-expanded');
    }
  }

  // Updates the state of the expander given by 'expander'.
  // If 'toExpand' evaluates to true, it expands, otherwise it contracts.
  function setState(expander, toExpand, blueriqID) {
    var outerContent = expander.querySelector('[data-role~="content-outer"]');

    var contentHeight = 0;
    var innerContent = expander.querySelector('[data-role~="content-inner"]');
    if (innerContent) {
      contentHeight = innerContent.getBoundingClientRect().height;
    }

    if (toExpand && expander.classList.contains('is-contracting')) {
      if (outerContent) {
        outerContent.style.maxHeight = contentHeight + 'px';
      }

      expander.classList.add('is-expanding');
      expander.classList.remove('is-contracting');

      storeStateForBQ(blueriqID, expander, true);
    } else if (!toExpand && expander.classList.contains('is-expanding')) {
      if (outerContent) {
        outerContent.style.maxHeight = '0px';
      }

      expander.classList.add('is-contracting');
      expander.classList.remove('is-expanding');

      storeStateForBQ(blueriqID, expander, false);
    } else if (!toExpand && expander.classList.contains('is-expanded')) {
      if (outerContent) {
        outerContent.style.maxHeight = contentHeight + 'px';
      }

      setTimeout(function () {
        expander.classList.remove('is-expanded');
        expander.classList.add('is-contracting');

        if (outerContent) {
          outerContent.style.maxHeight = '0px';
        }

        storeStateForBQ(blueriqID, expander, false);
      }, 0);
    } else if (toExpand) {
      expander.classList.add('is-expanding');

      if (outerContent) {
        outerContent.style.maxHeight = contentHeight + 'px';
      }

      storeStateForBQ(blueriqID, expander, true);
    }
  }
}(window, window.app.helper.moduleconfig));
