nn.component.FileField = function (element) {
  var parent = element.parentElement;

  var wrapper = document.createElement('div');
  wrapper.className = 'file-field-wrapper';

  var fakeText = document.createElement('input');
  fakeText.className = 'c-form-text file-field-wrapper__text';
  wrapper.appendChild(fakeText);

  var fakeButton = document.createElement('button');
  fakeButton.type = 'button';
  fakeButton.className = 'file-field-wrapper__button';
  fakeButton.textContent = 'Bestand kiezen';
  wrapper.appendChild(fakeButton);

  parent.insertBefore(wrapper, element);
  wrapper.appendChild(element);

  element.addEventListener('change', function () {
    fakeText.value = element.value.substr(12, 200);
  });
};
