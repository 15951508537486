/**
 * @class TextField
 * @description
 *    Text fields feature additional functions such as value prefixes and sufixes,
 *    inset shadows (even for Internet Explorer) and placeholder texts.
 */

nn.component.TextField = function (element) {
  this.element = $(element); // INPUT element
  this.initialize();
};

nn.component.TextField.prototype = {
  initialize: function () {

    this.container = this.element; // default this is the INPUT element
    this.placeholder = this.element.attr('placeholder');
    this.readOnly = this.element.prop('readonly');
    this.disabled = this.element.prop('disabled');
    this.hasClass = this.element.hasClass('error');
    this.borderFix = false;
    this.modifyStructure();
    this.bindEvents();
    this.updateContainingGridUnit();
  },

  modifyStructure: function () {

    var self = this,
      prefixElement = this.container.siblings('.text-field-prefix'),
      suffixElement = this.container.siblings('.text-field-suffix');

    if (this.readOnly) {
      this.container.addClass('is-readonly');
    }

    if (this.hasClass) {
      this.container.addClass('error');
    }

    if (this.disabled) {
      this.container.addClass('disabled');
    }

    if (prefixElement.length > 0) {

      if (this.disabled) {
        this.container.siblings('.text-field-prefix').css('left', '0');
      }

      var prefixElementWidth,
        prefixElementClone;

      if (!prefixElement.is(':visible')) {
        prefixElementClone = prefixElement.clone().css({"visibility": "hidden"}).appendTo($('body'));
        prefixElementWidth = prefixElementClone.width();
        prefixElementClone.remove();
      } else {
        prefixElementWidth = prefixElement.width();
      }

      var paddingleft = parseInt(prefixElementWidth, 10) + parseInt(prefixElement.css('left'), 10) + parseInt(this.element.css('padding-left'), 10);

      this.element.css('padding-left', paddingleft - 4);

      prefixElement.on('click', function () {
        self.element.focus();
      });

      if (nn.helpers.browser.msie && (9 === nn.helpers.browser.version || 10 === nn.helpers.browser.version)) {
        var curWidth = this.element.outerWidth();
        this.element.css('width', curWidth - 1);
        setTimeout(function () {
          self.element.css('width', '');
        }, 0);
      }
    }

    if (suffixElement.length > 0) {

      var hiddenElement = $('<span>').appendTo(document.body);
      hiddenElement.css({
        'position': 'absolute',
        'top': '-1000px',
        'left': '-1000px',
        'font': self.element.css('font')
      });

      var suffixElementWidth = suffixElement.width();
      var setSuffixLeft = function () {
        var suffixMaxLeft = self.element.outerWidth() - suffixElementWidth;
        hiddenElement.text(self.element.val());
        var contentWidth = hiddenElement.width();
        var suffixLeft = 4 + Math.min(suffixMaxLeft, parseInt(self.element.css('padding-left'), 10) + contentWidth);
        suffixElement.css('left', suffixLeft);
      };

      self.element.css('padding-right', suffixElementWidth + 20);

      suffixElement.on('click', function () {
        self.element.focus();
      });

      this.element.on('keyup', setSuffixLeft);
      setSuffixLeft();

    }
  },

  bindEvents: function () {
    var self = this;
    this.element.on('blur', function () {
      self.container.removeClass('focus');
    }).on('focus', function () {
      self.container.addClass('focus');
    }).on('editable', function () {
      self.container.removeClass('is-readonly');
      self.element.prop('readonly', false);
    }).on('readonly', function () {
      self.container.addClass('is-readonly');
      self.element.prop('readonly', true);
    });
  },

  /**
   * @method updateContainingGridUnit
   * @description Updates this field's parent grid unit
   */
  updateContainingGridUnit: function () {
    var parentGrid = this.element.closest('.grid-unit, .data-table-cell');
    if (parentGrid && parentGrid.children('.dialog--information, .dialog--expandable').length > 0) {
      parentGrid.addClass('grid-unit--info');
    }
  }
};