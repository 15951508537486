// Parse and/or arrange any given config parameters
// Usage:
//
// var config = moduleconfig({
// 	"options": options, // module instance options
// 	"element": element, // native or jQuery element
// 	"defaults": {}
// });

const moduleconfig = function (params) {

  let elementConfig;
  if (params.element) {
    elementConfig = params.element.getAttribute('data-config');
    if (typeof elementConfig === 'string' && elementConfig !== '') {
      elementConfig = JSON.parse(elementConfig.replace(/'/g, '"'));
    }
  }

  const instanceConfig = params.options || {};

  const defaults = params.defaults || {};

  return Object.assign({}, defaults, elementConfig, instanceConfig);
};

window.app = window.app || {};
window.app.helper = window.app.helper || {};

window.app.helper.moduleconfig = moduleconfig;

export default moduleconfig;