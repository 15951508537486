export const IntFormatter = function (element) {
  this.element = element;
  this.init();
};

IntFormatter.prototype = {
  init: function () {
    this.element.addEventListener('change', this.onChangeHandler);
    this.element.addEventListener('keydown', this.onKeydownHandler);
  },
  onChangeHandler: function () {
    this.element.value = this.element.value.replace(/[^0-9]/g, "");
  },
  onKeydownHandler: function (e) {
    const keys = { backspace: 8, tab: 9, delete: 46 };

    const validCharacter = (e.keyCode === keys.backspace
      || e.keyCode === keys.tab
      || e.keyCode === keys.delete
      || isArrowKeyCode(e)
      || (e.shiftKey === false && isNumericKeyCode(e)));

    if (!validCharacter) {
      e.preventDefault();
    }
  }
};

function isArrowKeyCode(evt) {
  return (evt.keyCode >= 37 && evt.keyCode <= 40);
}

function isNumericKeyCode(evt) {
  return (evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105);
}

window.app = window.app || {};
window.app.form = window.app.form || {};
window.app.form.IntFormatter = IntFormatter;

nn.component.IntFormatter = IntFormatter;