import Contextdialog from './contextdialog';
import moduleconfig from "../u-moduleconfig/moduleconfig.js";

window.app = window.app || {};
window.app.contextdialog = window.app.contextdialog || {};
window.app.contextdialog.Index = function (element) {
  return new Contextdialog(element, moduleconfig({element}));
};

// TODO: The old tooltip component now forwards to the new contextdialog.
nn.component.Tooltip = function(element) {
  // window.console.warn('nn.component.Tooltip is deprecated:', element)

  // Clear the content from dialog elemens as it messes with views even if they are not present
  // and move it to it's own contentElement to feed the context dialog
  const contentElement = document.createElement('div');
  while (element.hasChildNodes()) {
    contentElement.appendChild(element.firstChild);
  }

  return new Contextdialog(element, {
    contentElement: contentElement,
    button: element,
    side: 'bottom',
    align: 'right'
  });
};
