import loadscript from '@nn/psdl-modules/src/utility/loadscript'

export default class LibLoader {
  constructor() {
    this.STATE_LOADED = 'loaded'
    this.STATE_LOADING = 'loading'
    this.STATE_FAILED = 'failed'
    this.STATE_NULL = 'null'

    this.init()
  }

  init() {
    this.loadStack = {}
  }

  /**
	 * Load a external JS library. If the library is loaded the callback function is fired.
	 * If a library is allready loaded the library will not be added again, but the callback is
	 * fired. If you want to reload the library set the {forceLoad} to true
	 *
	 * @param {string} libraryURL
	 * @param {function} callback
	 * @param {boolean} forceLoad
	 * @returns {void}
	 */
  load(libraryURL, callback, forceLoad) {

    if (this.hasState(libraryURL, this.STATE_FAILED)) {
      // failed do nothing
      window.console.error('LibLoader: Trying to load library that failed before: ', libraryURL)
      return
    }

    this.addLoadLibraryCallback(libraryURL, callback, forceLoad);
    this.fireCallbacks(libraryURL)

  }

  addLoadLibraryCallback(libraryURL, callback, forceLoad) {

    if (!this.loadStack[libraryURL]) {
      this.loadStack[libraryURL] = {
        'url': libraryURL,
        'callbacks': [],
        'state': this.STATE_NULL,
      }
    }

    if (callback) {
      this.loadStack[libraryURL].callbacks.push(callback)
    }

    if (!forceLoad && this.hasState(libraryURL, this.STATE_LOADED)) {
      return;
    }

    if (!this.hasState(libraryURL, this.STATE_LOADING)) {
      this.loadLibrary(libraryURL, forceLoad)
    }
  }

  async loadLibrary(libraryURL, forceLoad) {
    this.changeState(libraryURL, this.STATE_LOADING)

    try {
      await loadscript(libraryURL, false, forceLoad)
      this.changeState(libraryURL, this.STATE_LOADED)
      this.fireCallbacks(libraryURL);
    } catch(e) {
      window.console.error(`[LIBLOADER] An error occurred (${e}) while loading library`, libraryURL)
      this.changeState(libraryURL, this.STATE_FAILED)
    }
  }

  fireCallbacks(key) {
    if (this.hasState(key, this.STATE_LOADED)) {
      this.loadStack[key].callbacks
        .forEach(cb => cb())

      // clear the callbacks Stack
      this.loadStack[key].callbacks = []
    }
  }

  changeState(key, state) {
    this.loadStack[key].state = state
  }

  hasState(key, state) {
    return (this.loadStack[key] && this.loadStack[key].state === state)
  }
}
