import getNonce from "@nn/psdl-utility/src/nonce";

export function setContent (target, content, initialize=false) {
  if (content instanceof Node) {
    target.textContent = '';
    target.appendChild(content);
  } else if (content instanceof NodeList) {
    [...content].forEach(c => target.appendChild(c));
  } else {
    target.innerHTML = content;
  }

  [...target.querySelectorAll('script')].forEach(originalScript => {
    const newlyAddedScript = document.createElement('script');
    newlyAddedScript.nonce = getNonce();
    newlyAddedScript.innerHTML = originalScript.innerHTML;
    originalScript.remove();
    target.append(newlyAddedScript);
  });

  if (initialize) {
    nn.initializeComponents(target);
  }
  return target;
}

function moveToFragment (nodes, applyBlueriqFix=true) {
  const fragment = document.createDocumentFragment();

  if (!nodes.length) {
    return fragment;
  }

  // NOTE: a fair warning here: Blueriq plays its own games with the DOM,
  // so care needs to be taken not to break the way they handle events. In
  // certain cases, the act of clicking on links/buttons is not properly
  // communicated to Blueriq despite moved nodes retaining their original
  // handlers. More investigation is needed, but for now the go-to solution
  // is to deep clone the nodes to append and manually delegate all clicks so
  // we can apply click handlers and distill the original. It's nasty, but it
  // works... >_<"
  if (applyBlueriqFix) {
    for (const node of nodes) {
      const clone = node.cloneNode(true);
      clone.addEventListener('click', _clonedClick);
      fragment.appendChild(clone);
    }

    for (const elementWithID of fragment.querySelectorAll('[id]')) {
      elementWithID.id += '-delegate';
    }
  } else {
    // Should the fix become irrelevant, the following will suffice:
    fragment.append(...nodes);
  }

  return fragment;
}

export function appendNodes (target, nodes, initialize=false, applyBlueriqFix=true) {
  const fragment = moveToFragment(nodes, applyBlueriqFix);
  target.appendChild(fragment);

  if (initialize) {
    nn.initializeComponents(target);
  }

  return target;
}

function _clonedClick(event) {
  if (event.target && event.target.id && event.target.id.indexOf('-delegate') !== -1) {
    event.preventDefault();

    const idOfOriginalElement = event.target.id.replace('-delegate', '');
    const originalElement = document.querySelector('#' + idOfOriginalElement);
    if (originalElement) {
      originalElement.click();
    }
  }
}
