nn.component.ClosingTimeWarningMessage = function (element) {
  var servletPrefix = '';
  if (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") {
    servletPrefix = '/web';
  }

  this.element = $(element);
  this.checkUrl = servletPrefix + '/wcbservlet/nl.nn.gx.openingstijden.nnopeningstijden.servlet?portalid=';
  this.init();
};

$.extend(nn.component.ClosingTimeWarningMessage.prototype, {
  init: function () {

    this.getAndSetOptions();
    this.getAndSetElements();
    this.bindEvents();
    this.execute();
  },
  getAndSetOptions: function () {
    this.closingWarningMessage = this.element.data("closing-warning-message");
    this.showInfo = this.element.data("show-info-message");
    this.showInterruption = this.element.data("show-interrupted-message");
    this.infoMsg = this.element.data("info-message");
    this.interruptedMsg = this.element.data("interruption-message");
    this.closedmsg = this.element.data("closed-message");
    this.portalId = this.element.data("portalid");
    this.scope = this.element.data("scope-element");
  },
  getAndSetElements: function () {
    this.infoElement = this.element.find("[data-role=info]");
    this.warningElement = this.element.find("[data-role=warning]");
    this.errorElement = this.element.find("[data-role=error]");
  },
  bindEvents: function () {

  },
  execute: function () {
    if (this.showInfo) {
      this.displayInfo(this.infoMsg);
    }
    if (this.showInterruption) {
      this.displayError(this.interruptedMsg, true, this.scope);
    } else {
      this.doCheck(); // initial check
    }
  },
  doCheck: function () {
    $.ajax({
      url: this.checkUrl + this.portalId + '&ts=' + (new Date().getTime()),
      type: 'GET',
      dataType: 'json',
      error: '',
      success: $.proxy(function (data) {
        if (data.error) {
          this.displayError(data.error, null, null);
        } else if (data.isClosed) {
          this.displayError(this.closedmsg, data.hideApp, this.scope);
        } else if (data.showWarning) {
          this.displayWarning(this.parseTime(this.closingWarningMessage, data.minutesBeforeClosing));
        } else {
          this.removeMsg();
        }
      }, this)
    });
  },
  removeMsg: function () {
    this.errorElement.html('');
    this.warningElement.html('');
  },
  displayError: function (msg, hideapp, scope) {
    //only hide application when hideapp is true and scope has a value
    if (hideapp && scope.length) {
      $('*[' + scope + ']').hide();
    }
    if(msg){
      this.errorElement.html("<div class='message--error'>" + msg + "</div>");
    } else {
      this.errorElement.html('');
    }
    this.warningElement.html('');
  },
  displayWarning: function (msg) {
    this.errorElement.html('');
    if(msg){
      this.warningElement.html("<div class='message--warning'>" + msg + "</div>");
    } else {
      this.warningElement.html('');
    }
  },
  displayInfo: function (msg) {
    if(msg){
      this.infoElement.html("<div class='message--info'>" + msg + "</div>");
    } else {
      this.infoElement.html('');
    }
  },
  parseTime: function (msg, minutes) {
    return msg.replace(/\{minutes\}/g, minutes);
  }

});