(function (window) {
  'use strict';

  window.app = window.app || {};
  window.app.premiumcomparison = window.app.premiumcomparison || {};

  /**
	 * Bootstrap Premium Comparison components.
	 *
	 * @param {Node|object} element, the element to bootstrap.
	 */
  window.app.premiumcomparison.Index = function (element) {
    var inputs = Array.prototype.slice.call(element.querySelectorAll('input'));
    var desktopTable = element.querySelector('table');
    var mobileTables = [];

    if (element.dataset.config !== 'wide-columns') {
      element.classList.add("c-premium-comparison-fixed");
    }

    /**
		 * At the time of writing, this component does not support the use of
		 * colspans, because it was deemed very unlikely anyone would want to
		 * use them in this context. Colspans throw off the nth-child etc. CSS
		 * selectors, so supporting them would require a JS solution.
		 */
    if (element.querySelector('tr > [colspan]')) {
      window.console.warn('The premium comparison component does not support the use of the colspan attribute on cells.');
    }

    // Count the number of option columns.
    var optionColumnCount = Array.prototype.slice.call(element.querySelectorAll('tr'))
      .reduce(function (count, tr) {
        return Math.max(count, tr.querySelectorAll('td, th').length - 1);
      }, 0);

    /**
		 * Given an option index,
		 *
		 * @param {Number} optionIndex, the index of the option to select.
		 */
    function selectOption(optionIndex) {
      // Deselect any selected cells.
      deselectCells(element.querySelectorAll('.is-selected'));

      // Select the relevant desktop cells.
      selectCells(desktopTable.querySelectorAll('tr > *:nth-child(' + (optionIndex + 2) + ')'));

      // If there's a corresponding mobile table, mark its only last column as selected.
      if (mobileTables[optionIndex]) {
        selectCells(mobileTables[optionIndex].querySelectorAll('tr > *:last-child'));
      }
    }

    inputs.forEach(function (input, i) {
      if (input.checked) {
        selectOption(i);
      }

      input.addEventListener('change', function () {
        selectOption(i);
      });
    });

    if (optionColumnCount > 1) {
      var fragment = document.createDocumentFragment();
      for (var i = 0; i < optionColumnCount; i++) {
        // We base the mobile tables off of the original.
        var clone = desktopTable.cloneNode(true);

        clearInitializedModules(clone.querySelectorAll('[data-module]'));

        // Since each mobile table corresponds to a single option, the
        // mobile table only needs a single option column.
        var irrelevantCells = clone.querySelectorAll('tr > *:not(:first-child):not(:nth-child(' + (i + 2) + '))');
        removeElements(irrelevantCells);

        // The clones don't need inputs, since the cloned labels refer to the originals.
        removeElements(clone.querySelectorAll('input'));

        // And lastly, if we have a footer we want the option cell to
        // span the full width, hence the colspan.
        var footer = clone.querySelector('tfoot');
        if (footer) {
          removeElements(footer.querySelectorAll('th'));
          footer.querySelector('td').setAttribute('colspan', 2);
        }

        // Mark the table as single-option.
        clone.classList.add('is-single');

        reinitializeModules(clone);

        mobileTables[i] = clone;
        fragment.appendChild(clone);
      }

      element.appendChild(fragment);
      desktopTable.classList.add('is-multi');
    }
  };

  /**
	 * Copied modules don't have correct behavour. So clear initialized state.
	 *
	 * @param {type} modules
	 * @returns {undefined}
	 */
  function clearInitializedModules(modules) {
    Array.prototype.slice.call(modules)
      .forEach(function (module) {
        module.removeAttribute('data-module-initialised');
      });
  }

  /**
	 * Reinitialize the modules within the given clone
	 *
	 * @param {DOMNode} clone
	 * @returns {undefined}
	 */
  function reinitializeModules(clone) {
    nn.initializeComponents(clone);
  }

  /**
	 * Given a NodeList, this function marks them all as unselected.
	 *
	 * @param {NodeList|object} cells, the nodes to unmark.
	 */
  function deselectCells(cells) {
    Array.prototype.slice.call(cells)
      .forEach(function (cell) {
        cell.classList.remove('is-selected');
      });
  }

  /**
	 * Given a NodeList, this function marks them all as selected.
	 *
	 * @param {NodeList|object} cells, the nodes to mark.
	 */
  function selectCells(cells) {
    Array.prototype.slice.call(cells)
      .forEach(function (cell) {
        cell.classList.add('is-selected');
      });
  }

  /**
	 * Removes the elements provided from the DOM.
	 *
	 * NOTE: with ES6 and a proper Node.remove() polyfill we could do this instead:
	 * `[...document.querySelectorAll('.foo')].forEach(el => el.remove());`
	 * because having a helper like this feels a bit derpy...
	 *
	 * @param {NodeList|object} elements, a list of elements to remove from the DOM.
	 */
  function removeElements(elements) {
    Array.prototype.slice.call(elements)
      .forEach(function (element) {
        element.parentNode.removeChild(element);
      });
  }

}(window));