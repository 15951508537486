import { findAncestor } from '@nn/psdl-utility/src/dom-helpers'
import stickyService from '../utility/stickyService.js';
import once from 'lodash/once';
import debounce from 'lodash/debounce';


var wrapper;
var sticky;
var _resize;
var _update;
var _heightUpdate;
var _checkFocus;
var _bindGlobalHandlers = once(bindGlobalHandlers);

/**
 * Sticky footer component initialization method.
 *
 * The sticky footer is a variable content wrapper div containing an inner
 * div. The inner div sticks to the bottom of the window using
 * position: fixed if the wrapper is located below the viewport, i.e. if
 * it's not in view and you'd have to scroll down to see it.
 *
 * Example:
 * <div class="c-sticky-footer" data-module="stickyfooter">
 *     <div class="c-sticky-footer__inner">
 *         ...contents go here...
   *     </div>
   * </div>
   *
   * NOTE: This is a single-instance component (there can only ever be
   * one active sticky footer). If an application contains more than one
   * sticky footer, this is to be treated as a bug in the application.
 *
 * @param {object:Element} element: component element.
 */
nn.registerModule('stickyfooter', (element) => {
  if (!isAttached()) {
    wrapper = element;
    sticky = element.querySelector('[data-role="sticky-section"]');

    _bindGlobalHandlers();
    _update();
    _checkFocus();
  }
});

/**
 * Update the state and size of the sticky in response to a window resize.
 */
function resize() {
  if (isAttached()) {
    wrapper.style.height = sticky.offsetHeight.toString() + 'px';
    wrapper.style.position = 'relative';
  }
}

/**
 * Toggle the stickyness of the inner element based on scroll position.
 */
function update() {
  if (isAttached()) {
    var wrapperBottom = wrapper.getBoundingClientRect().bottom;
    var wrapperTop = wrapper.getBoundingClientRect().top;
    var stickyContainer = findAncestor(wrapper, '[data-module="stickyContainer"]');
    var stickyContainerTop = stickyContainer ? stickyContainer.getBoundingClientRect().top : null;
    if (stickyContainer && stickyContainerTop === wrapperTop && stickyContainerTop - window.innerHeight <= 0 || stickyContainerTop > window.innerHeight) {
      wrapper.classList.remove('is-sticky');
    } else if (wrapperBottom - window.innerHeight <= 0) {
      wrapper.classList.remove('is-sticky');
      wrapper.style.height = 'auto';
    } else if (!wrapper.classList.contains('is-sticky')) {
      resize();
      wrapper.classList.add('is-sticky');
    }
  }
}

/**
 * If any element has focus, check if it's hidden behind the sticky footer.
 * If it is, set the scroll position to the focused element, but only if the
 * element is not contained in the sticky footer itself.
 *
 * @returns {undefined}
 */
function checkFocus() {
  // Stop if document.body has focus (a common default)
  // or if the wrapper is unnattached.
  if (!isAttached() ||
    !document.activeElement ||
    document.activeElement === document.body
  ) {
    return;
  }

  // Stop if the focused element is an ancestor of the wrapper.
  for (var el = document.activeElement; el; el = el.parentElement) {
    if (el === wrapper) {
      return;
    }
  }

  // Check if the focused element appears beneath the sticky footer.
  var stickyPosition = sticky.getBoundingClientRect();
  var focusPosition = document.activeElement.getBoundingClientRect();
  if (stickyPosition.top <= focusPosition.top &&
    stickyPosition.bottom >= focusPosition.bottom &&
    stickyPosition.left <= focusPosition.left &&
    stickyPosition.right >= focusPosition.right) {
    const pixelsToScroll = focusPosition.top - stickyService.getTopOffset() + window.scrollY
    document.body.scrollTop = pixelsToScroll
    document.documentElement.scrollTop = pixelsToScroll; //fix for chrome
  }
}

/**
 * Check if the current sticky footer is attached to the DOM.
 * This would not be the case after a Blueriq partial refresh, hence the
 * decision to make the sticky footer a single-instance component.
 *
 * @returns {boolean}: whether it's attached or not.
 */
function isAttached() {
  if (!wrapper || !sticky) {
    return false;
  }

  for (var el = wrapper; el !== document.body; el = el.parentElement) {
    if (!el) {
      return false;
    }
  }

  return true;
}

/**
 * Bind the global document/window event handlers needed to keep the sticky
 * footer happy.
 *
 * NOTE: These handlers are to be assigned and bound only once. If no
 * sticky footer is present, they aren't needed, but once they've
 * been bound, they can be used for different sticky footers across
 * e.g. Blueriq partial refreshes.
 *
 * TODO: using the click and keyup events to detect height and focus
 * change seems dodgy at best. We should investigate if there's a
 * better way.
 *
 * @returns {undefined}
 */
function bindGlobalHandlers() {
  _resize = debounce(update, 100);
  _update = debounce(update, 80);
  _heightUpdate = debounce(update, 800, { trailing: true });
  _checkFocus = debounce(checkFocus, 80);

  window.addEventListener('resize', _resize, true);
  window.addEventListener('orientationchange', _resize, true);
  document.addEventListener('click', _heightUpdate, true);
  document.addEventListener('keyup', _checkFocus, true);
  window.addEventListener('scroll', _update);
}
