import moment from 'moment';
import { DatepickerDialog } from './datepicker.dialog';
import { DATE_FORMAT, default as dateHelpers, formatDateFromString } from "./date.helpers";
import ElementHelper from "../utility/element.helper";

let uniqueElementid = 1;

window.nn.registerModule('form.datepicker', (element, options) => {
  new DatePicker(element, options);
});

export default class DatePicker {
  constructor(element, options) {
    this.element = element[0]; // transform to base element for pure js
    this.elementId = DatePicker.getOrCreateElementId(this.element);
    this.icon = DatePicker.createIcon(this.element);

    if (this.hasHiddenInput() && this.hiddenInput.value) {
      this.element.value = formatDateFromString(this.hiddenInput.value);
    }

    this.currentDate = DatePicker.getCurrentDate(this.element, options);
    this.config = DatePicker.initConfig(this.currentDate, options);

    if(this.currentDate) {
      this.element.value = this.currentDate.format(DATE_FORMAT);
    }

    this.datepickerDialog = new DatepickerDialog(this.config, this.currentDate, this.onDialogDatePicked.bind(this));

    this.setEventListeners();
  }

  setEventListeners() {
    this.element.addEventListener('change', this.onChange.bind(this));

    if (this.icon) {
      this.icon.addEventListener('click', this.openPicker.bind(this));
    }

    // If opted, prevent textual input
    if (this.config.preventTextInput) {
      setTimeout(() => {
        this.element.addEventListener('focus', () => {
          if (!this.datepickerDialog.dialog.isAttached()) {
            this.renderDatepickerDialog();
          }
          this.element.blur();
        });
      }, 50);
    }

    // redraw on window resize and scroll
    window.addEventListener('resize', this.redrawDatepickerDialog.bind(this));
    window.addEventListener('scroll', this.redrawDatepickerDialog.bind(this));
  }

  openPicker(evt) {
    evt.preventDefault();
    if (!this.element.getAttribute('readonly') && !this.element.getAttribute('disabled')) {
      this.renderDatepickerDialog();
    }
  }

  redrawDatepickerDialog() {
    if (this.datepickerDialog.dialog.isAttached()) {
      this.renderDatepickerDialog();
    }
  }

  renderDatepickerDialog() {
    this.datepickerDialog.render(this.icon);
  }

  hasHiddenInput() {
    // Legacy support: some applications still have a hidden field as actual value and
    // use the datepicker input as dummy.

    if (this.elementId.slice(-6) === '-dummy') {
      this.hiddenInput = document.getElementById(this.elementId.slice(0, -6));
      return !!this.hiddenInput;
    }
    return false;
  }

  onDialogDatePicked(dateString) {
    // Refresh reference to the elements as Blueriq refreshes the DOM after change and therefore
    // we may now be referencing a destroyed DOM element
    this.element = document.getElementById(this.elementId) || this.element;

    ElementHelper.setWithChange(this.element, dateString);
  }

  onChange() {
    let date = dateHelpers.parseFromString(this.element.value);
    if (date) {
      if (!moment.isMoment(date)) {
        date = moment(date);
      }
      if (date.isBefore(this.config.dateStart) || date.isAfter(this.config.dateEnd)) {
        if (date.isBefore(this.config.dateStart)) {
          // Given date lies before the available range. Focus on the first available date.
          this.config.dateFocus = this.config.dateStart;
        } else if (date.isAfter(this.config.dateEnd)) {
          // Given date lies after the available range. Focus on the last available date.
          this.config.dateFocus = this.config.dateEnd;
        }
      } else {
        this.currentDate = date;
        this.config.dateFocus = date; // Select and focus on the given date
      }

      const dateString = moment(date).format(DATE_FORMAT);

      if (!moment(date).isValid()) {
        const currentDateFallback = moment().format(DATE_FORMAT)

        // Sets current date in the dialog (so it won't be blank) when date is invalid (eg. 10-xx-2024)
        this.datepickerDialog.setSelectedDate(currentDateFallback);
      } else {
        this.datepickerDialog.setSelectedDate(dateString);
      }

      ElementHelper.setWithChange(this.datepickerDialog.selectYear, this.config.dateFocus.year());
      ElementHelper.setWithChange(this.datepickerDialog.selectMonth, this.config.dateFocus.month());

      this.element.value = dateString;
    } else {
      // clear selected date
      this.datepickerDialog.setSelectedDate('');
    }

    if (this.hasHiddenInput()) {
      // if there is a legacy hidden input we must update it and send change event
      const dateValue = this.config.valueFormat
        ? moment(dateHelpers.parseFromString(this.element.value)).format(this.config.valueFormat)
        : this.element.value;
      ElementHelper.setWithChange(this.hiddenInput, dateValue);
    }
  }

  static createIcon(element) {
    // The datepicker icon
    let icon;
    if (!element.getAttribute('readonly') && !element.getAttribute('disabled')) {
      icon = document.createElement('a');
      icon.setAttribute('href', '#');
      icon.classList.add('c-datepicker__icon');
      // If element is the last child within its parent element, that's fine, because element.nextSibling will be null
      // and insertBefore handles that case by adding to the end of the list.
      element.parentNode.insertBefore(icon, element.nextSibling);
    }
    return icon;
  }

  static initConfig(currentDate, options) {
    const focusDate = moment();
    const config = {
      dateStart: focusDate.date(1),
      dateEnd: focusDate.date(focusDate.daysInMonth()),
      dateFocus: focusDate,
      disabledWeekDays: [],
      ...options,
    };

    DatePicker.parseConfigDates(config);

    // if a date is already selected it always takes precedence over focusDate
    if (currentDate && currentDate.isValid()) {
      config.dateFocus = currentDate;
    }

    // Final check to ensure focus date is never out of range
    if (config.dateFocus.isBefore(config.dateStart)) {
      config.dateFocus = config.dateStart;
    }
    if (config.dateFocus.isAfter(config.dateEnd)) {
      config.dateFocus = config.dateEnd;
    }

    return config;
  }

  static getOrCreateElementId(element) {
    let elementId = element.getAttribute('id');
    if (!elementId) {
      uniqueElementid += 1;
      elementId = `c-datepicker-${uniqueElementid}`;
      element.setAttribute('id', elementId);
    }

    return elementId;
  }

  static parseConfigDates(config) {
    config.dateStart = dateHelpers.asMomentDate(config.dateStart);
    config.dateEnd = dateHelpers.asMomentDate(config.dateEnd);
    config.dateFocus = dateHelpers.asMomentDate(config.dateFocus);
    config.dateCurrent = dateHelpers.asMomentDate(config.dateCurrent);
  }

  static getCurrentDate(element, options) {
    let currentDate;
    if (options.dateCurrent) {
      if (options.dateCurrent instanceof Date) {
        currentDate = options.dateCurrent;
      } else if (typeof options.dateCurrent === 'string') {
        currentDate = dateHelpers.parseFromString(options.dateCurrent);
      }
    }
    if (!currentDate && element.value) {
      currentDate = dateHelpers.parseFromString(element.value);
    }

    return dateHelpers.asMomentDate(currentDate);
  }
}
