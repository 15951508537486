nn.component.InitialsFormatter = function (element) {

  this.element = $(element);
  this.maxLength = 5;
  this.init();
};

nn.component.InitialsFormatter.prototype = {

  init: function () {

    if (this.element.attr('maxlength')) {

      this.maxLength = Math.floor(parseInt(this.element.attr('maxlength'), 10) / 2);
    }

    this.element
      .on('change', $.proxy(this.onChangeHandler, this))
      .on('keydown', $.proxy(this.onKeyDownHandler, this));
  },

  onChangeHandler: function () {

    var elValue = this.element.val(),
      formattedArray = elValue.replace(' ', '').replace(/\./g, '').split('', this.maxLength),
      formatted = "";

    $.each(formattedArray, function (index, value) {
      formatted += value.toUpperCase() + ".";
    });

    this.element.val(formatted);
  },

  onKeyDownHandler: function (e) {

    if (nn.helpers.isAlphaNumericKeyCode(e)
      && this.element.val().replace(/\./g, '').length === this.maxLength) {
      e.preventDefault();
    }

    var kc_backspace = 8;
    var kc_tab = 9;
    var kc_delete = 46;
    var kc_decimalpoint = 110;
    var kc_period = 190;
    if (e.keyCode === kc_backspace
      || e.keyCode === kc_tab
      || e.keyCode === kc_delete
      || nn.helpers.isArrowKeyCode(e)
      || nn.helpers.isAlphaNumericKeyCode(e)
      || e.keyCode === kc_decimalpoint
      || e.keyCode === kc_period) {

      // valid character
    } else {
      // invalid character
      e.preventDefault();
    }
  }
};