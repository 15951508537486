import indexOf from 'lodash/indexOf';

(function (window, $, nn) {
  var textualMonthsShort = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
  var textualMonthsLong = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

  function convertLegacyDate(str) {
    if (str.match(/^\d{4}-\d{1,2}-\d{1,2}$/)) {
      str = str.split('-').reverse().join('-');
    }

    return str;
  }

  function parseDateString(str) {
    var matches,
      date,
      year,
      month,
      day;

    // Try format DD-MM-YYYY, separator can be a single space or any of these: -/.
    if (!date) {
      matches = str.match(/(\d{1,2})[ /.-](\d{1,2})[ /.-](\d{4})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[3], 10);
        month = parseInt(matches[2], 10) - 1;
        day = parseInt(matches[1], 10);

        date = new Date(year, month, day);
        date.setFullYear(year);
      }
    }

    // Try format YYYY-MM-DD, separator can be a single space or any of these: -/.
    if (!date) {
      matches = str.match(/(\d{4})[ /.-](\d{1,2})[ /.-](\d{1,2})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[1], 10);
        month = parseInt(matches[2], 10) - 1;
        day = parseInt(matches[3], 10);

        date = new Date(year, month, day);
        date.setFullYear(year);
      }
    }

    // Try format DD MMM YYYY or DD MMMM YYYY, separator is a single space
    if (!date) {
      matches = str.match(/(\d{1,2})[ /.-]([a-z]+)[ /.-](\d{4})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[3], 10);
        month = matches[2].length === 3 ? indexOf(textualMonthsShort, matches[2]) : indexOf(textualMonthsLong, matches[2]);
        day = parseInt(matches[1], 10);

        if (month > -1 && month < 12) { // Month int has to be 0-11 for the Date() object
          date = new Date(year, month, day);
          date.setFullYear(year);
        }
      }
    }

    return date || null;
  }

  function formatDateString(date) {
    return [
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear()
    ].join('-');
  }

  nn.component.DatepickerField = function (element) {
    var $element = $(element),
      $input = $('#' + $element.prop('id').replace('-dummy', '')),
      data = $element.data(),
      value = $input.val(),
      config,
      today = new Date();

    today.setHours(0, 0, 0, 0);

    config = {
      dateStart: new Date(today.getFullYear() - 150, today.getMonth(), today.getDate()),
      dateEnd: new Date(today.getFullYear() + 150, today.getMonth(), today.getDate())
    };

    // Handling this part as it converts YYYY-MM-DD to MM-DD-YYYY for the hidden field which is not desired
    // If data-value-format is defined the user/xc template should be responsible to prefill the date in right format
    if (value && !data.valueFormat) {
      $input.val(convertLegacyDate(value));
    }

    if (data.valueFormat) {
      config.valueFormat = data.valueFormat;
    }

    if (data.startDate) {
      config.dateStart = parseDateString(data.startDate);
    }

    if (data.endDate) {
      config.dateEnd = parseDateString(data.endDate);

      if (!data.startDate) {
        config.dateStart = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
        config.dateFocus = new Date(today.getFullYear() - 30, today.getMonth(), today.getDate());
      }
    }

    if (data.focusDate) {
      config.dateFocus = parseDateString(data.focusDate);
    }

    ////

    if (data.endDate && config.dateFocus && (config.dateFocus > config.dateEnd)) {
      config.dateFocus = config.dateEnd;
    }

    if (data.startDate && config.dateFocus && (config.dateFocus < config.dateStart)) {
      config.dateFocus = config.dateStart;
    }

    // Format into D-M-YYYY
    if (config.dateFocus) {
      config.dateFocus = formatDateString(config.dateFocus);
    }
    if (config.dateStart) {
      config.dateStart = formatDateString(config.dateStart);
    }
    if (config.dateEnd) {
      config.dateEnd = formatDateString(config.dateEnd);
    }

    if (data.disableWeekends) {
      config.disabledWeekDays = [6, 0];
    } else {
      if (data.disableSaturdays) {
        config.disabledWeekDays = config.disabledWeekDays || [];
        config.disabledWeekDays.push(6);
      }
      if (data.disableSundays) {
        config.disabledWeekDays = config.disabledWeekDays || [];
        config.disabledWeekDays.push(0);
      }
    }

    if (data.datePickerToggle) {
      config.preventTextInput = true;
    }

    return new window.app.form.datepicker($element, config);
  }
})(window, jQuery, nn);
