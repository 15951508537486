import localStorage from './local-storage';

const LOG_ENABLE_KEY = 'debugLog';

/**
 * For general output of logging information. You may use string substitution and additional arguments with this method.
 */
export function log() {
  if (localStorage.getItem(LOG_ENABLE_KEY)) {
    window.console.log.apply(console, arguments);
  }
}

/**
 * Outputs a warning message. You may use string substitution and additional arguments with this method.
 */
export function warn() {
  window.console.warn.apply(console, arguments);
}

/**
 * Outputs an error message. You may use string substitution and additional arguments with this method.
 */
export function error() {
  if (arguments[0] && arguments[0].message === 'SINON_FAKE') {
    return;
  }
  window.console.error.apply(console, arguments);
}

/**
 * Outputs a stack trace.
 */
export function trace() {
  window.console.trace();
}

/**
 * Creates a new inline group, indenting all following output by another level. To move back out a level, call groupEnd()
 */
export function group() {
  window.console.group();
}

/**
 * Exits the current inline group.
 */
export function groupEnd() {
  window.console.groupEnd();
}

export function enableLogging() {
  localStorage.setItem(LOG_ENABLE_KEY, true);
}

export function disableLogging() {
  localStorage.setItem(LOG_ENABLE_KEY, false);
}
