const scrollHelper = {
  isBodyOverflowing() {
    const rect = document.body.getBoundingClientRect();
    return rect.left + rect.right < window.innerWidth;
  },
  getScrollbarWidth() {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';

    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth
  },
};

window.app = window.app || {};
window.app.scrollHelper = scrollHelper;

export default scrollHelper;