
(function (window, $, nn) {

  nn.component.IbanCopy = function (element) {

    this.element = $(element);
    this.form = this.element.closest("form");
    this.copy = false;
    this.copyBtn = this.form.find('#iban_accountnumber_lightbox_copysource dd a');

    this.init();
  }

  nn.component.IbanCopy.prototype = {

    init : function () {
      var self = this;

      this.copyBtn.on('click', function (e) {
        e.preventDefault();
        self.handleCopy();
      });

      this.form.on('IAFBeforeSubmit', function (e) {
        e.preventDefault();
        self.handleCopy();
      });

      self.handleCopy();
    },

    handleCopy : function () {
      var ibanNumber = $.trim($("div#iban_accountnumber_lightbox_copysource dd").html());


      if (ibanNumber !== "" && ibanNumber !== null && ibanNumber !== "null") {

        $("div#iban_copy_target input").val(ibanNumber);
        $("div#iban_copy_target input").change();
        nn.handlers.dialogs.hide({
          level : 0
        });
      }
    }
  };

})(window, jQuery, nn);