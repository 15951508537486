(function (window, $, nn) {

  nn.component.Notification = function (element) {
    this.element = $(element);
    this.init();
  };

  nn.component.Notification.prototype = {
    init: function () {
      this.getAndSetElements();
      this.bindEvents();
    },
    getAndSetElements: function () {
      this.closeButton = this.element.find('.notification-close-button');
    },
    bindEvents: function () {
      if (this.closeButton.length > 0) {
        this.closeButton.on('click', $.proxy(this.onClosebuttonClick, this));
      }
    },
    onClosebuttonClick: function () {
      this.element.hide(300);
    }
  }
})(window, jQuery, nn);