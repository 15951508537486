/**
 * @class Pagination
 * @description Create a pagination component based on the first unordered list item.
 */


(function (window, $, nn) {

  nn.component.Pagination = function (element) {

    this.element = $(element);
    this.numItems = parseInt(this.element.data('items-per-page'), 10);
    this.paginationItemSelector = this.element.data('pagination-item-selector');
    this.paginationShowDesktopSelector = this.element.data('pagination-show-desktop-selector');
    this.devider = this.element.find('[data-role="devider"]');
    this.paginationItemsMax = 5;
    this.paginationContainer = this.element.find('.pagination');
    this.paginationList = this.element.find('.pagination-list');
    this.length = null;
    this.numPages = null;
    this.dataRole = null;
    this.showTotalLength = this.element.find(this.paginationItemSelector).length;
    this.showDesktopLength = this.element.find(this.paginationShowDesktopSelector).length;
    this.tableDevider = this.element.find('[data-role="row-devider"]');
    this.backward = this.element.find('.button--back');
    this.forward = this.element.find('.button--forward');
    this.jumpBackward = this.element.find('.pagination-jump-backward');
    this.jumpForward = this.element.find('.pagination-jump-forward');
    this.desktopView = false;
    this.curPage = 1;

    // set a fixed width in case of a dialog
    if (this.element.parents('.dialog--ajax').length > 0) {
      this.width = this.element.parents('.dialog--ajax').width();
      if ($(window).width() > 320) {
        // set dialog to fixed width
        this.element.parents('.dialog--ajax').css('width', this.width - 40);
      } else {
        // set max pagination items to 3 in case of mobile
        this.paginationItemsMax = 3;
      }
    }

    this.bind();
    this.build();

  };

  nn.component.Pagination.prototype = {

    bind: function () {
      var self = this;

      // rebuild on resize
      $(window).on('resize', nn.helpers.debounce($.proxy(this.build, this), 500));

      // pagination link items
      this.paginationList.on('click', 'li', function () {
        this.element = $(this);
        var jump;

        if (this.element.hasClass('pagination-jump-backward')) {

          jump = self.curPage - self.paginationItemsMax - 1;
          self.jumpTo(jump);

        } else if (this.element.hasClass('pagination-jump-forward')) {

          jump = self.curPage + self.paginationItemsMax - 1;

          // calculate difference between jump and total pages
          var diff = jump - self.numPages;

          // if diff zero of positive jump to the last page
          if (diff >= 0) {
            jump = self.numPages - 1;
          }

          self.jumpTo(jump);

        } else {

          var curID = $(this).attr('id');
          var splitID = curID.split('-');
          self.jumpTo(splitID[1]);

        }
      });

      // pagination forward button
      this.forward.on('click', function (e) {
        e.preventDefault();

        if (!$(this).hasClass('disabled')) {
          self.jumpTo(self.curPage);
        }

      });

      // pagination back button
      this.backward.on('click', function (e) {
        e.preventDefault();

        if (!$(this).hasClass('disabled')) {
          self.jumpTo(self.curPage - 2);
        }

      });

    },
    build: function () {

      if ($(document).width() > nn.settings.mobileBreakPoint && this.paginationShowDesktopSelector) {
        this.length = this.showDesktopLength;
        this.desktopView = true;
        this.devider.hide();
      } else {
        this.length = this.showTotalLength;
        this.desktopView = false;
      }
      this.numPages = Math.ceil(this.length / this.numItems);

      var pageNav = '<li class="pagination-jump-backward">&hellip;</li>';
      for (var j = 0; j < this.numPages; j++) {
        var pNum = j + 1,
          pGroupNum = Math.ceil(pNum / this.paginationItemsMax);

        pageNav += '<li class="pagination-item pagination-group-' + pGroupNum + '" id="nav-' + j + '">' + pNum + '</li>';

      }
      pageNav += '<li class="pagination-jump-forward">&hellip;</li>';

      // insert pagination into DOM
      this.paginationList.html(pageNav);

      this.jumpTo(0);


    },

    jumpTo: function (id) {
      // Remove all temporary devider rows
      this.element.find('.pagination-temp').remove();

      var fromPage = id * this.numItems,
        toPage = fromPage + this.numItems,
        curElement, curDate, tempElement;

      this.curPage = toPage / this.numItems;

      var i;
      for (i = 0; i <= this.length; i++) {

        if (this.desktopView) {
          curElement = this.element.find(this.paginationShowDesktopSelector + ':eq(' + i + ')');

        } else {
          curElement = this.element.find(this.paginationItemSelector + ':eq(' + i + ')');

          // set dataRole
          this.dataRole = curElement.attr('data-role');
          if (this.dataRole === 'devider') {
            curDate = curElement.html();
            tempElement = '<li class="pagination-temp">' + curDate + '</li>';
          }
        }

        if (i >= fromPage && i < toPage) {

          curElement.show();

          // hide if last row is devider row
          if (i === (toPage - 1) && this.dataRole === 'devider') {
            curElement.hide();
          }
          // Start with a devider row
          if (!this.desktopView && this.dataRole !== 'devider' && i === fromPage) {
            curElement.before(tempElement);
          }
        } else {
          curElement.hide();
        }


      }

      var paginationGroupLength = Math.ceil(this.numPages / this.paginationItemsMax);
      var curPaginationGroup = Math.ceil(this.curPage / this.paginationItemsMax);

      this.setActive(this.curPage);

      // hide all pagination elements
      this.element.find('.pagination-item').hide();
      // show all items of current pagination group
      this.element.find('.pagination-group-' + curPaginationGroup).show();

      this.toggleBackwardForward();
      this.togglePaginationGroups(curPaginationGroup, paginationGroupLength);
      this.togglePagination();

    },

    setActive: function (id) {

      // remove active class
      this.paginationList.find('li').removeClass('active');

      // add active class to active element
      this.paginationList.find('li:eq("' + id + '")').addClass('active');

    },

    toggleBackwardForward: function () {

      // toggle disabled class on forward en backward element
      if (this.curPage === 1) {
        this.backward.addClass('disabled');
      } else {
        this.backward.removeClass('disabled');
      }

      if (this.curPage === this.numPages) {
        this.forward.addClass('disabled');
      } else {
        this.forward.removeClass('disabled');
      }

    },

    togglePaginationGroups: function (cur, total) {

      // set jumpBackward en jumpForward
      var jumpBackward = this.element.find('.pagination-jump-backward');
      var jumpForward = this.element.find('.pagination-jump-forward');

      // toggle visibility of jump items
      if (cur === 1) {
        jumpBackward.hide();
      } else {
        jumpBackward.show();
      }

      if (cur === total) {
        jumpForward.hide();
      } else {
        jumpForward.show();
      }

    },

    togglePagination: function () {

      // don't show pagination if total item length is smaller than number of items per page
      if (this.length <= this.numItems) {
        this.paginationContainer.hide();
      } else {
        this.paginationContainer.show();
      }

    }


  };

})(window, jQuery, nn);