(function (window, $, nn) {

  nn.component.Saml = function () {

  };

  nn.component.Saml.prototype = {

    init: function (SamlAuthUrl) {
      $.ajax({
        'url': SamlAuthUrl,
        'type': 'get'
      })
        .done($.proxy(this.onInitializeDoneHandler, this))
        .fail($.proxy(this.onInitializeFailHandler, this));
    },
    onInitializeDoneHandler: function (data) {
      var url = data.match(/action="(.+?)"/)[1],
        samlRequest = data.match(/value="(.+?)"/)[1];

      this.sendRequest(url, samlRequest);
    },
    onInitializeFailHandler: function (jqXHR, textStatus, errorThrown) {
      var errorObj = {
        message: 'Failed while initializing SAML',
        originalError: errorThrown,
        code: 'SAML001'
      };
      $(window).trigger('SAML:Error', errorObj);
    },
    sendRequest: function (url, requestInfo) {
      //encodeURIComponent?
      $.ajax({
        'url': url,
        'type': 'POST',
        'data': {
          'SAMLRequest': requestInfo
        },
        'xhrFields': {
          'withCredentials': true
        },
        'beforeSend': function (req) {
          req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        },
      })
        .done($.proxy(this.onSendRequestDoneHandler, this))
        .fail($.proxy(this.onSendRequestFailHandler, this));
    },
    onSendRequestDoneHandler: function (data) {
      var errorObj;
      if (data.indexOf("password") !== -1) {

        errorObj = {
          message: 'You\'re not authenticated.',
          originalError: null,
          code: 'SAML002'
        };

        $(window).trigger('SAML:Error', errorObj);
        return;
      }

      var urlMatch = data.match(/action="(.+?)"/),
        responseInfoMatch = data.match(/value="(.+?)"/);

      if (!urlMatch || !responseInfoMatch) {
        errorObj = {
          message: 'Unable to parse response.',
          originalError: null,
          code: 'SAML005'
        };
        $(window).trigger('SAML:Error', errorObj);
        return;
      }

      var url = urlMatch[1].replace(/&#x3a;/g, ":").replace(/&#x2f;/g, "/"), //url decoding? hebben we functie voor toch?
        responseInfo = responseInfoMatch[1];

      this.sendResponse(url, responseInfo);
    },
    onSendRequestFailHandler: function (jqXHR, textStatus, errorThrown) {
      var errorObj = {
        message: 'Failed while requesting authentication with SAML',
        originalError: errorThrown,
        code: 'SAML003'
      };

      $(window).trigger('SAML:Error', errorObj);
    },
    sendResponse: function (url, responseInfo) {
      $.ajax({
        'url': url,
        'type': 'POST',
        'data': {
          'SAMLResponse': responseInfo
        },
        'xhrFields': {
          'withCredentials': true
        },
        'beforeSend': function (req) {
          req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        },
      })
        .done($.proxy(this.onSendResponseDoneHandler, this))
        .fail($.proxy(this.onSendResponseFailHandler, this));
    },
    onSendResponseDoneHandler: function () {
      $(window).trigger('SAML:Auth:OK');
    },
    onSendResponseFailHandler: function (jqXHR, textStatus, errorThrown) {
      var errorObj = {
        message: 'Failed while authenticating with SAML',
        originalError: errorThrown,
        code: 'SAML004'
      };
      $(window).trigger('SAML:Error', errorObj);
    }
  };

})(window, jQuery, nn);