const cookieName = 'ts_currentSession';

const copySession = async () => {
  const getTransmitSession = await sessionStorage.getItem('currentSession');
  if (getTransmitSession) {
    createCookie(getTransmitSession)
  }
};

const createCookie = (session) => {
  document.cookie = `${cookieName}=${session}; path=/;`
}

const retrieveSessionCookie = async () => {
  const cookie = await getCookie();
  setToSession(cookie);
};

const removeSessionCookie = () => {
  document.cookie = `${cookieName}=''; path=/;  expires=Thu, 01 Jan 1970 00:00:00 GMT"`
};

const getCookie = () => {
  if (document && document.cookie) {
    const cookies = document.cookie.split(';');
    const findCookie = cookies.findIndex((cookie) => cookie.trim().indexOf(cookieName) === 0);
    return findCookie > -1 ? cookies[findCookie].trim() : '';
  }
};

const setToSession = (cookie) => {
  if (cookie) {
    const session = cookie.replace(`${cookieName}=`, '');
    sessionStorage.setItem('currentSession', session);
  }
};

window.nn.registerModule('tsSessionManager', () => {
  return window.nn.tsSessionManager;
});

window.nn = window.nn || {};
window.nn.tsSessionManager = {
  copySession,
  retrieveSessionCookie,
  removeSessionCookie
};
