
(function(window, $, nn) {

  nn.component.BustFrame = function(element) {

    this.element = $(element);
    this.init();
  }

  nn.component.BustFrame.prototype = {
    init : function() {
      var allowedLocation = 'bc.nn.nl',
        refLocation = document.referrer;

      if (top !== self && refLocation.indexOf(allowedLocation) === -1) {
        $("#page").hide();
        top.location.replace(self.location.href);
      }
    }
  }

})(window, jQuery, nn);