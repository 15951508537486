const overlay = {
  element: null,
  exists() {
    return !!document.body.getElementsByClassName('overlay').length;
  },
  create(callbackFn, callbackFnReferenceThis) {
    if (this.exists()){
      return;
    }
    this.element = document.createElement('div');
    this.element.classList.add('c-main-navigation');
    this.element.classList.add('overlay');
    this.element.classList.add('is-active');
    this.element.addEventListener('click', () => {
      this.remove();
      if(callbackFn && typeof callbackFn === 'function'){
        callbackFn.apply(callbackFnReferenceThis);
      }
    });

    document.body.appendChild(this.element);
  },
  remove() {
    const element = document.body.getElementsByClassName('overlay')[0];
    if (element) {
      document.body.removeChild(element);
      this.element = null;
    }
  }
};

export default overlay;