nn.component.Select = function (element) {
  this.element = $(element);
  this.init();
};

$.extend(nn.component.Select.prototype, {

  /**
   * Initialize the JobSelectorField compnent
   */
  init: function () {
    this.getAndSetElements();
    this.bindEvents();
  },
  getAndSetElements: function () {
    this.containerElement = this.element.parent('.select');
    // If no container element is present, create it
    if (this.containerElement.length < 1) {
      this.containerElement = this.element.wrap('<div class="select" />').parent('.select');
    }

    // If our select element is disabled, make sure the container knows
    if (this.element.attr('disabled')) {
      this.containerElement.addClass('select--disabled');
    }

    this.containerElement.addClass('select--nopointerevents');
    this.arrow = $('<span class="select-arrow" />').appendTo(this.containerElement);
    this.textOverlay = $('<span class="select-textoverlay" />').appendTo(this.containerElement);
  },
  bindEvents: function () {
    this.element.on('change', $.proxy(this.updateTextOverlay, this));
    this.element.on('focus', $.proxy(this.focus, this));
    this.element.on('blur', $.proxy(this.blur, this));
    this.element.on('keyup', $.proxy(this.updateTextOverlay, this));
    this.updateTextOverlay();
    this.element.on('update', $.proxy(this.updateTextOverlay, this));
  },
  focus: function () {
    this.containerElement.addClass('select--focus');
  },
  blur: function () {
    this.containerElement.removeClass('select--focus');
  },
  updateTextOverlay: function () {
    if (this.textOverlay) {
      this.textOverlay.text(this.element.find(':selected').text());
    }
  }
});