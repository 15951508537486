/*
 * Component DatatableScrollable
 */

(function (window, $, nn) {

  nn.component.DatatableScrollable = function (element) {
    this.element = $(element);
    this.init();
  };

  nn.component.DatatableScrollable.prototype = {
    init: function () {
      this.getAndSetOptions();
      this.getAndSetElements();
      this.bindEvents();
      this.update();
    },
    getAndSetOptions: function () {
      this.breakpoint = parseInt((this.element.attr('data-breakpoint') || '640'), 10);
      this.switched = false;
    },
    getAndSetElements: function () {
      this.tableWrapper = '<div class="data-table--scrollable-wrapper" />';
      this.pinnedWrapper = '<div class="pinned" />';
      this.scrollableWrapper = '<div class="scrollable" />';

      this.element.wrap(this.tableWrapper);
    },
    bindEvents: function () {
      $(window).on('resize', $.proxy(this.update, this));
      if (this.element.hasClass('hyphenate')) {
        $(window).on('NN:Hyphenate:Done', $.proxy(this.update, this));
      }
    },
    update: function () {
      //check if table minimum width doesn't exceed available space
      var wrapperwidth = this.element.closest('.data-table--scrollable-wrapper').width();
      if (wrapperwidth <= this.breakpoint) {
        if (!this.switched) {
          this.splitTable();
          this.switched = true;
        }
        this.calcRowheight(); //update rowheight
      } else if ((wrapperwidth > this.breakpoint) && this.switched) {
        this.unsplitTable();
        this.switched = false;
      }
    },
    splitTable: function () {
      this.element.width(parseInt(this.breakpoint * 0.8, 10)); //20% correction for hidden first column
      this.copy = this.element.clone();
      this.copy.css('width', '');
      this.copy.find('td:not(:first-child), th:not(:first-child)').css('display', 'none');
      this.copy.removeClass('data-table--scrollable').removeAttr("data-component");
      this.element.closest('.data-table--scrollable-wrapper').append(this.copy);
      this.copy.wrap(this.pinnedWrapper);
      this.element.wrap(this.scrollableWrapper);
      this.calcRowheight();
      //this.element.closest('.scrollable').animate({'scrollLeft': 5}, 800);
    },
    unsplitTable: function () {
      this.element.css('width', '');
      this.element.find('tr').css('height', '');
      this.element.closest('.data-table--scrollable-wrapper').find(".pinned").remove();
      this.element.unwrap();
    },
    calcRowheight: function () {
      var rowheight, copyheight, copyrow,
        self = this;

      this.element.find('tr').each(function (index) {
        rowheight = $(this).height();
        copyrow = self.copy.find('tr')[index];
        copyheight = $(copyrow).height();
        rowheight = Math.max(rowheight, copyheight);
        $(this).css('height', rowheight);
        $(copyrow).css('height', rowheight);
      })
    }
  };

})(window, jQuery, nn);