/**
 * The below imported modules are the js files specific for NN.
 * These are all the files that are in
 *     - nn.config.json[scripts.path]
 * but not in
 *     - advisor.config.json[scripts.paths]
 *
 * Any file appearing only in advisor.config.json[scripts.paths] is placed in context.nn-responsive.advisor.js
 * All files appearing both in advisor.config.json[scripts.path] and nn.config.json[scripts.paths] are placed in context.nn-responsive.js
 */

require('@nn/psdl-legacy/src/nn-responsive/global/js/rangeslider-v2.0.2.min.js');
require('@nn/psdl-legacy/src/nn-responsive/technical-component/stickyelement/js/StickyElement.js');
require('@nn/psdl-legacy/src/nn-responsive/technical-component/message-centrum-receiver/js/message-receiver.js');
require('@nn/psdl-legacy/src/nn-responsive/technical-component/xperiencentral/js/XcHandler.js');
