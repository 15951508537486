import http from './http';

export const getAuthSessionId = () => {
  return http.post(`/am/sso/authenticate`, undefined, {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
}

export const refreshAuthSession = () => {
  return http.post('/am/sso/sessions/?_action=refresh', undefined, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
    },
  });
};
