function getMetaElement(metaAttributeSelector) {
  return document.querySelector('meta[' + metaAttributeSelector + ']');
}

function getMetaContent(metaAttributeSelector) {
  const meta = getMetaElement(metaAttributeSelector);
  if (!meta) {
    return;
  }
  return meta.getAttribute('content');
}

function getMetaContentByName(name) {
  return getMetaContent('name=' + name);
}

function getMetaContentByProperty(property) {
  return getMetaContent('property=' + property);
}

export default {
  getMetaElement,
  getMetaContent,
  getMetaContentByName,
  getMetaContentByProperty,
};
