class ButtonTabs {
  constructor(element) {
    this.element = element;
    this.tabButtons = null;
    this.tabPanels = null;

    this.init();
  }

  init() {
    this.tabButtons = [...this.element.querySelectorAll('.c-form-radio-panel__item[role="tab"]')];
    this.tabPanels = [...this.element.querySelectorAll('[role="tabpanel"]')];

    this.tabButtons.forEach(button => {
      button.addEventListener('change', () => {
        this.hideAllPanels();
        this.activatePanel(button.value);
      })
    })
  }

  hideAllPanels() {
    this.tabPanels.forEach(panel => {
      panel.setAttribute('hidden', '');
    })
  }

  activatePanel(panelId) {
    const panel = this.element.querySelector('#' + panelId);
    if (panel) {
      panel.removeAttribute('hidden');
    }
  }
}

export default ButtonTabs;
