import moment from 'moment';

export const DATE_FORMAT = 'DD-MM-YYYY';

const dateHelpers = {

  textualMonthsShort: [
    'jan',
    'feb',
    'mrt',
    'apr',
    'mei',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec'
  ],
  textualMonthsLong: [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
  ],
  format: function (date, format) {
    return moment(date).format(format);
  },
  parseFromString: function (str) {

    const setDate = (year, month, date) => {
      if (date > moment().year(year).month(month).daysInMonth() ||
        month > 11) {
        return null;
      }

      return moment({year, month, date}).toDate();
    };

    let matches,
      date = null,
      year,
      month,
      day;

    if (!str || !str.match) {
      return null;
    }

    // Try format DD-MM-YYYY, separator can be a single space or any of these: -/.
    if (!date) {
      matches = str.match(/(\d{1,2})[ /-](\d{1,2})[ /-](\d{4})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[3], 10);
        month = parseInt(matches[2], 10) - 1;
        day = parseInt(matches[1], 10);

        date = setDate(year, month, day);
      }
    }

    // Try format YYYY-MM-DD, separator can be a single space or any of these: -/.
    if (!date) {
      matches = str.match(/(\d{4})[ /-](\d{1,2})[ /-](\d{1,2})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[1], 10);
        month = parseInt(matches[2], 10) - 1;
        day = parseInt(matches[3], 10);

        date = setDate(year, month, day);
      }
    }

    // Try format DD MMM YYYY or DD MMMM YYYY, separator is a single space
    if (!date) {
      matches = str.match(/(\d{1,2})[ /-]([a-z]+)[ /-](\d{4})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[3], 10);
        const monthSource = matches[2].length === 3 ? window.app.date.textualMonthsShort : window.app.date.textualMonthsLong;
        month = monthSource.findIndex(val => val === matches[2])
        day = parseInt(matches[1], 10);

        date = setDate(year, month, day);
      }
    }

    // Try format DDMMYYYY
    if (!date) {
      matches = str.match(/(\d{2})(\d{2})(\d{4})/);
      if (matches && matches.length > 3) {
        year = parseInt(matches[3], 10);
        month = parseInt(matches[2], 10) - 1;
        day = parseInt(matches[1], 10);

        date = setDate(year, month, day);
      }
    }

    return date || null;
  },
  asMomentDate: (date) => {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      return moment(date, DATE_FORMAT);
    }
    return moment(date);
  }
};

export function formatDateFromString(dateString) {
  return dateHelpers.format(dateHelpers.parseFromString(dateString), DATE_FORMAT);
}

window.app = window.app || {};
window.app.date = dateHelpers;

export default dateHelpers;
