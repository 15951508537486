nn.registerModule('premiumoverview', (element) => {
  var $PREMIUMOVERVIEW = $(element);
  var $PREMIUMDETAILS = $PREMIUMOVERVIEW.find('.c-premium-overview__details');
  var $TOGGLESWITCH = $PREMIUMOVERVIEW.find('.c-premium-overview__detailtoggle');

  var DETAILS_CLOSED = "is-closed";
  var STICKUNTILWIDTH = 640;
  var detailsOpen = false;

  $PREMIUMDETAILS.css({ 'height': '0' });
  $PREMIUMOVERVIEW.addClass(DETAILS_CLOSED);

  function toggleDetails() {
    if (!detailsOpen) {
      detailsOpen = true;
      $PREMIUMDETAILS.css({ 'height': 'auto' });
      $PREMIUMOVERVIEW.removeClass(DETAILS_CLOSED);

      $TOGGLESWITCH.attr('aria-expanded', 'true');
      $PREMIUMDETAILS.attr('aria-hidden', 'false');

      // When in mobile view we scroll premiumoverview into our viewport
      //TODO add aria property changes
      var viewportWidth = $(window).width();
      if (STICKUNTILWIDTH > viewportWidth) {
        var focusOffset = $PREMIUMOVERVIEW.offset().top;

        $('html,body').animate({
          scrollTop: focusOffset
        }, 600);
      }
    } else {
      detailsOpen = false;
      $PREMIUMOVERVIEW.addClass(DETAILS_CLOSED);
      $TOGGLESWITCH.attr('aria-expanded', 'false');
      $PREMIUMDETAILS.attr('aria-hidden', 'true');
    }
  }

  $TOGGLESWITCH.on('click', function(evt) {
    evt.preventDefault();
    toggleDetails();
  });

  new window.app.premiumoverview.Sticky(element);
});
