// Syntax below will expose the exported variables on the global scope.
// The multiple exports for this file are done in the webpack.config.js file using a module rule matching the filename.
const sitestatStub = require('@nn/psdl-legacy/src/nn-responsive/technical-component/sitestat/js/sitestat-stub.js');
module.exports = global.sitestat = sitestatStub.sitestat;
module.exports = global.ns_onclick = sitestatStub.ns_onclick;
module.exports = global.sitestatMeasureTechnical =
  sitestatStub.sitestatMeasureTechnical;
module.exports = global.sitestatService = sitestatStub.sitestatService;
module.exports = global.gxAjaxGeneralCallback =
  sitestatStub.gxAjaxGeneralCallback;

require('@nn/psdl-legacy/src/nn-responsive/index.js');
