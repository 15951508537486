window.app = window.app || {};
window.app.spreadsheet = window.app.spreadsheet || {};

window.app.spreadsheet.Index = function (element, options) {
  return import(/* webpackChunkName: "handsontable" */ './c-spreadsheet').then(Spreadsheet => {
    const spreadsheet = new Spreadsheet.default(element, options);
    // Module interface
    return {
      getTable: () => {
        return spreadsheet.getTable();
      }
    };
  });
};

