/* eslint-disable no-console */
import meta from "@nn/psdl-utility/src/meta.helper";
import getNonce from "@nn/psdl-utility/src/nonce";
const blockedDomains = meta.getMetaContentByName('BLOCKED_DOMAINS');

const knownTagProperties = {
  'script': { urlAttr: 'src' },
  'link': { urlAttr: 'href' },
}

/**
 * Creates a html tag in the document
 * @param {string} tagName Name of the tag to create
 * @param {string} url Url of the resource to load
 * @param {Object} attributes Attributes set to the element. The attribute with the url cannot be override.
 * @param {Object} options Creation options
 * @param {string | undefined} options.urlAttr If the tag is not known, it will use this attribute to set the url to fetch.
 * @param {Boolean | undefined} options.forceLoad If the script already exists, it will remove it and load it again
 * @param {Boolean | undefined} options.allowBlockedDomains If set it will allow creating tags for blocked domains.
 * @returns {Promise<Boolean>}
 */
export const createTag = (tagName, url, attributes = {}, options = null) => {
  return new Promise((resolve, reject) => {
    if (blockedDomains && blockedDomains.split(',').filter(d => url.match(d))) {
      reject('Domain blocked by Meta Tag');
      return;
    }
    const urlAttr = knownTagProperties[tagName] && knownTagProperties[tagName].urlAttr || options.urlAttr;
    if (!urlAttr) {
      reject('Unknown url attribute for tag ', tagName);
      return;
    }
    let tagElement = document.querySelector(`${tagName}[${urlAttr}="${url}"]`);
    if (tagElement && options && options.forceLoad) {
      tagElement.remove();
      tagElement = null;
    }
    if (!tagElement) {
      tagElement = document.createElement(tagName);
      tagElement[urlAttr] = url;
      setAttributes(tagElement, attributes, [urlAttr]);

      tagElement.dataset.loadstate = "loading";

      tagElement.addEventListener('load', () => {
        tagElement.dataset.loadstate = 'loaded';
        resolve(true);
      });

      tagElement.addEventListener('error', error => {
        tagElement.dataset.loadstate = 'error';
        tagElement.dataset.errormsg = error;
        reject(error);
      });

      document.head.appendChild(tagElement);
    }

    if (tagElement.dataset.loadstate === undefined || tagElement.dataset.loadstate === 'loaded') {
      resolve(true);
    } else if (tagElement.dataset.loadstate === 'error') {
      reject(tagElement.dataset.errormsg);
    }
  });
}

function setAttributes(tagElement, attributes, skipAttributes = []) {
  if (attributes) {
    // eslint-disable-next-line no-unused-vars
    Object.keys(attributes).forEach(attrKey => {
      if (skipAttributes.includes(attrKey)) {
        return;
      }

      tagElement.setAttribute(attrKey, attributes[attrKey]);
    });

    // always add nonce if present
    tagElement.setAttribute('nonce', getNonce());
  }
}
