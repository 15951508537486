import overlay from './overlay.js';
const search = {
  init(el) {
    this.el = el;
    this.searchButton = this.el.querySelector('[data-role="enable-nav-search"]');
    const searchForm = this.el.querySelector('[data-role="nav-search"]');
    const resetButton = this.el.querySelector('[data-role="reset-nav-search"]');
    this.inputField = this.el.querySelector('.c-form-text');

    if (!searchForm || !this.searchButton || !resetButton || !this.inputField) {
      return;
    }

    [this.searchButton, resetButton].forEach(el => {
      el.addEventListener('click', () => {
        this.toggleInputWidthAndSearchButton();
        this.toggleOverlay();
        this.toggleFocus();
      });
    });

    searchForm.addEventListener('submit', () => {
      overlay.remove();
    });
  },
  toggleInputWidthAndSearchButton() {
    this.inputField.classList.toggle('has-width');
    this.searchButton.classList.toggle('active');
  },
  toggleFocus() {
    if (this.isSearchActive()) {
      this.inputField.focus();
      addzeroTabIndexTo(this.inputField);
      addMinusOneTabIndexTo(this.searchButton);
    } else {
      this.searchButton.focus();
      addzeroTabIndexTo(this.searchButton);
      addMinusOneTabIndexTo(this.inputField);
    }
  },
  toggleOverlay() {
    if (this.isSearchActive()) {
      overlay.create(this.toggleElementsOnOverlayClick, this);
    } else {
      overlay.remove();
    }
  },
  toggleElementsOnOverlayClick() {
    this.toggleInputWidthAndSearchButton();
    this.toggleFocus();
  },
  isSearchActive() {
    return !!(this.inputField.classList.contains('has-width') || !this.searchButton.classList.contains('active'));
  }
};

const addzeroTabIndexTo = (el) => {
  el.setAttribute('tabindex', '0');
}
const addMinusOneTabIndexTo = (el) => {
  el.setAttribute('tabindex', '-1');
}

export default search;