nn.component.BsnFormatter = function (element) {
  this.element = $(element);
  this.init();
};

nn.component.BsnFormatter.prototype = {
  init: function () {
    this.element
      .on('change', $.proxy(this.onChangeHandler, this))
      .on('keydown', $.proxy(this.onKeydownHandler, this));
  },
  onChangeHandler: function () {
    var v = this.element.val();
    this.element.val(v.replace(/[^0-9]/g, ""));
  },
  onKeydownHandler: function (e) {
    var kc_backspace = 8,
      kc_tab = 9,
      kc_delete = 46;

    if (e.keyCode === kc_backspace
      || e.keyCode === kc_tab
      || e.keyCode === kc_delete
      || nn.helpers.isArrowKeyCode(e)
      || nn.helpers.isNumericKeyCode(e)) {
      // valid character
    } else {
      // invalid character
      e.preventDefault();
    }
  }
};