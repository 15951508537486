/**
 * Parses the Blob or File object provided as a CSV.
 *
 * @param {Blob|File, Object} file, the source representing the CSV file;
 * @returns {Object}, the nested object representing the processed CSV file.
 */
export async function parseCsvBlob (file, fieldSeparator=',') {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = event => resolve(parseCsvString(event.target.result, fieldSeparator));
    reader.onabort = reject;
    reader.onerror = reject;
  });
}

/**
 * Parses the CSV file string provided.
 *
 * @param {String} csv, raw string to parse.
 * @returns {Object}, the nested object representing the processed CSV file.
 */
export function parseCsvString (csv, fieldSeparator=',') {
  if (typeof csv !== 'string') {
    throw null;
  }

  return csv
    .split(/\r?\n/)
    .map(line => line
      .split(fieldSeparator)
      .map(field => field
        .replace(/^\s*"?\s*/, '')
        .replace(/\s*"?\s*$/, '')
      )
    );
}

export function parseCsvData (lines) {
  const headers = lines.shift().map(header => header.trim());

  return lines.map((line) => {
    return line.reduce((line, field, i) => {
      line[headers[i]] = field.trim();
      return line;
    }, {});
  });
}
