let hasLocalStorage = true;
try {
  hasLocalStorage = !!window.localStorage;
} catch (e) {
  // needed because IE Edge has a bug where calling window.localStorage throws an error instead of giving an undefined
  // if the localStorage is not available. Once this error is thrown it stops all other scripts from loading, so
  // catching it here helps to allow the rest to continue.
  hasLocalStorage = false;
}

let uLocalStorage;

if (hasLocalStorage) {
  uLocalStorage = {
    key: localStorage.key.bind(localStorage),
    clear: localStorage.clear.bind(localStorage),
    removeItem: localStorage.removeItem.bind(localStorage),

    getItem: (key) => {
      return JSON.parse(localStorage.getItem(key));
    },

    setItem: (key, value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
  };
}

window.app = window.app || {};
window.app.localStorage = uLocalStorage;
export default uLocalStorage;
