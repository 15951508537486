/**
 * The below imported modules are the common js files between Advisor and NN.
 * These are all the files that appear in both:
 *     - nn.config.json[scripts.paths]
 *     - advisor.config.json[scripts.path]
 *
 * Any file appearing only in nn.config.json[scripts.paths] is placed in context.nn-responsive.nn.js
 * Any file appearing only in advisor.config.json[scripts.path] is placed in context.nn-responsive.advisor.js
 */

require("./typography/copy/css/copy.scss");

require("./js/index");
require("./global/index");
require("./layout/index");
require("./control/index");
require("./component/index");
require("./technical-component/index");

require("./global/css/helper.scss");
require("./global/css/blueriq-corrections.scss");
require("./global/css/xc-corrections.scss");

// End with initializing all components
require("./global/js/init.js");
