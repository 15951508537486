
import instructionsHandler from './instructionsHandler';

export function loader(data) {
  return new Promise(async (resolve, reject) => {
    const $context = $(data.context || window.document);
    const uninitalizedModules = '[data-module]:not([data-module-initialised]):not([data-module-initializing])';
    const instructionsAttr = 'data-module-instructions';
    const unInitializedModulesWithInstructions = [];

    $context.find(uninitalizedModules).addBack(uninitalizedModules).each(function (i, el) {
      if (el.hasAttribute(instructionsAttr) && el.getAttribute(instructionsAttr)) {
        unInitializedModulesWithInstructions.push(el);
        el.setAttribute('data-module-initializing', 'true');
      } else {
        executeModuleApplication(el);
      }
    });

    const uniqueInstructionUrls = unInitializedModulesWithInstructions.reduce((list, el) => {
      const url = el.getAttribute('data-module-instructions');
      const exists = list.find( mod => mod.url === url);
      if (exists) {
        return list;
      }

      return [
        ...list,
        {
          url: el.getAttribute('data-module-instructions'),
        }
      ];
    }, []);

    try {
      const handlerPromises = uniqueInstructionUrls.map(({url}) => {
        return instructionsHandler.load(url).catch(() => {
          unInitializedModulesWithInstructions
            .filter(el => el.getAttribute('data-module-instructions') === url)
            .forEach(el => {
              el.innerHTML = `
                <div class="c-notification c-notification--error">
                  <h2 class="c-title-h3 c-title--secondary c-title--no-margin" aria-labelledby="modalDialogTitle">Onverwacht technisch probleem</h2>
                  <p>Er is een onverwacht technisch probleem opgetreden. Probeert u het later nog eens. Excuses voor het ongemak.</p>
                  <p>Heeft u vragen? Neemt u dan <a href="https://www.nn.nl/nn/show/id=1544422">contact met ons op</a>.</p>
                </div>
              `;
            });
        });
      });

      await Promise.all(handlerPromises);

      unInitializedModulesWithInstructions.forEach(function (el) {
        executeModuleApplication(el);
      });
      resolve();
    } catch (err) {
      reject(err);
    }
  });
}

function executeModuleApplication(el) {
  var $el = $(el),
    module = $el.data('module'),
    part = 'Index';

  if (module.indexOf('.') > 0) {
    var moduleParts = module.split('.');
    module = moduleParts[0];
    part = moduleParts[1];
  }

  if (window.app[module] && typeof window.app[module][part] === 'function') {
    var instance = new window.app[module][part](el);

    el.setAttribute('data-module-initialised', 'true');
    el.removeAttribute('data-module-initializing');

    Promise.resolve(instance).then((instance) => {
      $el.trigger('NN:componentInitialised', {
        'type': module,
        'element': el,
        'instance': instance
      });
    });

  } else {
    window.console.log('Skipped unknown module: ' + module + '.' + part);
  }
}

$('body').on('NN:afterInitializeComponents', (_evt, data) => {
  loader(data);
});