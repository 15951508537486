(function (window, $, nn) {

  nn.component.IbanConverter = function (element) {

    this.element = $(element);
    this.converterUrl = this.element.data('converter');
    this.submitButton = this.element.closest("form.form").find("input[type=submit]");
    this.init();
  }

  nn.component.IbanConverter.prototype = {
    init: function () {
      this.element.on('blur', $.proxy(this.onBlurEvent, this));
    },
    onBlurEvent: function () {
      var v = this.element.val(),
        self = this;
      this.element.val(v.toUpperCase());//turn uppercase

      if (nn.helpers.isIbanNumber(v) || !nn.helpers.isBankAccountNumber(v)) {
        return;
      }

      nn.globals.glass.lock();
      nn.globals.glass.show();

      // Apply formatting
      $.get(this.converterUrl, {
        reknr: v
      }, function (data) {

        if (data.status && data.status === 'OK') {

          self.element.val(data.value);

          var $el = self.element;
          if (self.element.parent().hasClass('text-field-wrapper')) {
            $el = self.element.parent();
          }

          $el.siblings('.warning-message').addClass('displayNone');
          $el.siblings('.success-message').removeClass('displayNone');
        }
      }, 'json').always(function () {

        nn.globals.glass.unlock();
        nn.globals.glass.hide();

      });
    }
  }

})(window, jQuery, nn);
