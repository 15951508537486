export default function scrapeContent(url, asString = true) {
  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then(response => response.text())
    .then(text => {
      const { body } = new DOMParser().parseFromString(text, 'text/html');

      if (asString) {
        return body.innerHTML;
      }
      return body.childNodes;

    });
}
