/**
 * @class Glass
 * @description
 *    Provides a glass pane to disable
 *    and optionally darken the page.
 *
 * @example
 *    var glass = new nn.classes.Glass();
 *    glass.show({
 *       clear: true
 *    });
 */

/**
 * @constructor
 *
 * @property {boolean} visible = false
 *    Keeps track of the pane's visibility.
 * @property {boolean} locked = false
 *    Can the user interact with the pane?
 */

nn.component.Glass = function() {
  var self = this;
  this.visible = false;
  this.locked = false;
  this.defaultZindex = 1000;
  this.importantZindex = 1400;
  // Create the glass pane and attach it to the document.
  this.pane = $('<div id="glass"></div>').appendTo('body');

  this.dialogInFront = null;
  // Redirect the pane's click event to the dialogs.hide handler.
  this.pane.click(function(e) {
    e.stopPropagation();
    if (!self.locked) {
      nn.handlers.dialogs.hide();
    }
  });

  $(document).on('keydown', function (event) {

    if (self.locked === true) {
      return;
    }

    if (event.keyCode === 27) {
      nn.handlers.dialogs.hide({
        level: 0
      });
    }
  });
};
nn.component.Glass.prototype = {
  /**
	 * @method show
	 * @description Shows the glass pane.
	 *
	 * @param {object} [options]
	 *    @param {boolean} [clear = false]
	 *       Makes the glass fully transparent,
	 *       i.e. invisible.
	 */
  show: function(options) {

    options = $.extend({
      clear: false
    }, options);

    this.clear = options.clear;

    if (this.clear) {
      this.pane.css('opacity', 0).show();
    } else {
      this.pane.css('opacity', 0).stop().fadeTo(nn.settings.fadeDuration, 0.4);
    }

    this.visible = true;
  },
  /**
	 * @method hide
	 * @description Hides the glass pane.
	 */
  hide: function() {
    if (this.locked === true) {
      return;
    }

    if (this.clear) {
      this.pane.css('zIndex', this.defaultZindex);
      this.pane.hide();
      this.visible = false;
    } else {
      var self = this;
      self.pane.css('zIndex', this.defaultZindex);
      this.pane.stop().fadeOut(nn.settings.fadeDuration, function() {
        self.visible = false;
      });
    }
  },
  /**
	 * @method lock
	 * @description Locks the pane, disabling user interaction.
	 */
  lock: function() {
    this.locked = true;
  },
  /**
	 * @method unlock
	 * @description Unlocks the pane, (re)enabling user interaction.
	 */
  unlock: function() {
    this.locked = false;
  },
  bringToFront: function(dialog) {
    if (dialog) {
      // also give this
      this.dialogInFront = dialog;
      this.dialogInFront.bringToFront(this);
    }

    this.pane.css('zIndex', this.importantZindex);
  },
  bringToBack: function () {
    this.pane.css('zIndex', this.defaultZindex);
    this.dialogInFront = null;
  }

};
