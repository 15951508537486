import Dialog from '../dialog/dialog.js';
import BlueriqApi from './BlueriqApi.js';
import {disownRemoteOverlay, buildRemoteOverlayHTML} from './helpers.js';

/**
 * NOTE: Some of the communication with Blueriq predates the introduction of the
 * API and still uses the jQuery events below. These cannot be removed just yet
 * for backwards compatibility with existing Blueriq.js deployments.
 *
 * Blueriq also listens to the following events:
 *
 * Aquima:Dialog:Button:Close
 * This tells Blueriq that a dialog has been closed.
 */
export default class BlueriqEventhandler {
  constructor () {
    this.ajaxLoader = null;
    this.dialog = null;
    this.dialogEscapeBound = this.dialogEscape.bind(this);

    // WARNING: There was originally only a single instance of the Blueriq API.
    // Syntactic sugar has been applied in the form of class notation, but there's
    // still only one. Do we want multiple instances?
    this.blueriqApiImplementation = new BlueriqApi();

    // FIXME: this event is triggered by our own loader, so it doesn't strictly need
    // jQuery events; please refactor this whenever we get a chance to work on the
    // loader.
    $(document)
      .on('NN:componentInitialised','[data-blueriq-scope]', this.registerComponentInstance.bind(this));

    // The events below are triggered by the Blueriq.js.
    $(window)
      // Emitted when Blueriq wants you to register a Blueriq API instance.
      .on('Blueriq:RegisterApiImplementation', this.registerApiImplementation.bind(this))
      // Emitted when an ajax call fails, i.e. when Blueriq breaks.
      .on('Aquima:AjaxCall:Error', this.ajaxCallError.bind(this))
      // Emitted when a dialog is to be shown.
      .on('Aquima:Dialog:Init', this.dialogInit.bind(this))
      // Emitted when a dialog should be closed.
      .on('Aquima:Dialog:Hide', this.dialogHide.bind(this))
      // Emitted when Blueriq starts refreshing elements.
      .on('Aquima:Element:Refresh:Init', this.refreshInit.bind(this))
      // Emitted when the refresh has completed.
      .on('Aquima:Element:Refresh', this.refresh.bind(this));
  }

  registerComponentInstance (evt, data) {
    const scope = evt.currentTarget.dataset.blueriqScope;
    window[scope].blueriqApi.registerComponentInstance(data);
  }

  registerApiImplementation (e, blueriqApi) {
    blueriqApi.register(this.blueriqApiImplementation);
  }

  ajaxCallError (e, errorMessage) {
    const dialog = new Dialog({ themes: ['middle']});
    dialog.setContent(errorMessage);
    dialog.show();

    if (this.ajaxLoader) {
      this.ajaxLoader.hide();
    }
  }

  dialogClose () {
    $(window).trigger('Aquima:Dialog:Button:Close');
  }

  dialogInit (e, value) {
    if (!this.dialog) {
      this.dialog = new Dialog({
        themes: ['blueriq', 'ajax', 'l'],
        closeHandler: this.dialogClose.bind(this),
      });
    }

    this.dialog.setContent(buildRemoteOverlayHTML(value.scope, value.dialogHTML));
    this.dialog.show();

    document.removeEventListener('keydown', this.dialogEscapeBound);
    document.addEventListener('keydown', this.dialogEscapeBound);
  }

  dialogEscape (e) {
    if (e.keyCode === 27) {
      $(window).trigger('Aquima:Dialog:Button:Close');
    }
  }

  dialogHide () {
    if (this.dialog) {
      this.dialog.hide();
      disownRemoteOverlay();
      document.removeEventListener('keydown', this.dialogEscapeBound);
    }
  }

  refreshInit (e, element) {
    element.classList.add('aquima-refreshing');
  }

  refresh (e, element) {
    element.classList.remove('aquima-refreshing');
    nn.initializeComponents(element);
  }
}
