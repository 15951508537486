async function bootstrapLazy(lazyPromise, root, config) {
  return (await lazyPromise).default(root, config)
}

window.nn.registerModule('logout', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "customer" */ './logoutForm'), root, config);
});

window.nn.registerModule('logout.advisor', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "customer" */ './logoutFormAdvisor'), root, config);
});
