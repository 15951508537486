/**
 *
 * Specifically made for compatibility with Freestyle
 *
 * @param {obj} element
 * @returns {void}
 *
 */
nn.component.StyleEnhancer = function(element) {
  this.element = $(element);
  this.styleSheet = this.element.data('stylesheet');
  this.styleSheetType = this.element.data('enhance-type');
  this.styleSheetRoot = null;
  this.styleSheetInlineID = this.element.data('enhance-inline-id');
  this.init();

}

nn.component.StyleEnhancer.prototype = {
  init: function() {
    if (this.styleSheetType === 'file') {
      if (typeof this.styleSheet !== 'undefined') {
        this.getStyleSheetRoot();
        this.appendStyleSheetFile();
      }
    } else if (this.styleSheetType === 'inline') {
      this.appendInlineStyle();
    }
  },
  getStyleSheetRoot: function() {

    if ($('head link').attr('href')) {

      var href = $('head link').attr('href').split('/')
      href.pop();

      this.styleSheetRoot = href.join('/');
    }
  },
  appendStyleSheetFile: function() {

    var self = this;
    $(window).on('Iframe:Page:Loaded', function(e, obj) {

      if (obj.element && obj.element.id === self.element.attr('id')) {
        var ss = ['<link rel="stylesheet" href="', self.styleSheetRoot, '/', self.styleSheet, '">'].join('');
        $(obj.element).contents().find("head").append(ss);
      }
    });
  },

  appendInlineStyle: function () {
    var self = this;

    $(window).on('Iframe:Page:Loaded', function(e, obj) {

      if (obj.element && obj.element.id === self.element.attr('id')) {

        self.appendFontStyle(obj);

        var inlineCSS = $('#'+self.styleSheetInlineID).text();
        var cssElement = $('<style type="text/css">'+inlineCSS+'</style>');

        $('#'+self.styleSheetInlineID).remove();
        $(obj.element).contents().find("head").append(cssElement);
      }
    });
  },

  appendFontStyle: function (obj) {

    if (typeof window.designuuid === 'undefined') {
      return;
    }

    var path = '/web2-static/static/design/'+window.designuuid+'/fonts'
    var fontCSSElement = $([
      '<style type="text/css">',
      '@font-face { ',
      'font-family: \'NN Dagny Display\'; ',
      'src: url(\''+path+'/NNDagnyDisplayWebPro.eot\'); ',
      'src: local(\'☺\'), url(\''+path+'/NNDagnyDisplayWebPro.woff\') format(\'woff\'); ',
      'font-weight: normal; ',
      'font-style: normal;',
      '}',
      '</style>'
    ].join(''));

    $(obj.element).contents().find("head").append(fontCSSElement);
  }

}