(function (moduleconfig, window) {
  'use strict';

  window.app = window.app || {};
  window.app.chatdialog = window.app.chatdialog || {};

  window.app.chatdialog.Index = function (element) {

    // CONST
    // The loader DOM reference
    var CHATELEMENT = element;
    var CLOSEBUTTON = CHATELEMENT.querySelector('.js-chat-close');
    var CHATBUTTON = CHATELEMENT.querySelector('.js-chat-button');
    var config = JSON.parse(CHATELEMENT.dataset.config);

    // Display the overlay
    function show() {
      //prevent input by blocking page
      CHATELEMENT.style.display = 'block';
    }

    // Hide the chatbox
    function hide() {
      if (visible()) {
        CHATELEMENT.style.display = 'none';
      }
    }

    // Is our chatbox visible?
    function visible() {
      return CHATELEMENT.classList.contains('is-active');
    }

    // Is our chatbox visible?
    function destroy() {
      CHATELEMENT.parentNode.removeChild(CHATELEMENT);
      CHATELEMENT = '';
    }

    function init() {
      if (!window._laq) {
        window._laq = [];
      }

      window._laq.push(function () {
        window.liveagent.showWhenOnline(config.startChatHash, CHATELEMENT);
        //				window.liveagent.showWhenOffline('5738E000000Cafb', CHATELEMENT);
      });

      window.nn.helpers.libLoader.load(config.api, function () {
        window.liveagent.init(config.init, config.initHash1, config.initHash2);
      });
    }


    // EVENTS
    CLOSEBUTTON.addEventListener("click", function (evt) {
      evt.preventDefault();
      CHATELEMENT.classList.add('is-small');
    });

    CHATBUTTON.addEventListener('click', function () {
      window.liveagent.startChat(config.startChatHash);
    });

    init();

    // The overlay API
    return {
      'show': show,
      'hide': hide,
      'visible': visible,
      'destroy': destroy
    };

  };


}(window.app.helper.moduleconfig, window));
