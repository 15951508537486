nn.component.Checkbox = function (element) {

  // This component has been modified to follow branding conform style/modules/form/c-form-checkbox
  // Can probably be marked for deprecation
  // Only the IAF handlers have been left here for legacy purposes
  this.$checkbox = $(element);
  const $label = this.findLabelNextToCheckbox(this.$checkbox);

  if ($label.length === 1) {
    this.init($label);
  } else {
    this.initLegacy();
  }

  this.iafPrefillPersonalisation();
};

nn.component.Checkbox.prototype = {
  init: function ($label) {
    // Change classes to style/modules/form/c-form-radio
    this.$checkbox.addClass('c-form-checkbox');
    $label.removeClass('field-label--checkbox');

    if ($label.attr('for') !== this.$checkbox.attr('id')) {
      $label.on('click', () => {
        this.$checkbox.click();
      });
    }
    if (!$label.text()) {
      $label.html('&nbsp;');
    }
  },
  initLegacy: function () {
    this.$checkboxCustom = this.$checkbox.wrap('<div class="checkbox--custom" />').parent();

    if (this.$checkboxCustom.siblings().length === 0) {
      this.$checkboxCustom.after('&nbsp;');
    }

    if (this.$checkbox.attr('disabled')) {
      this.$checkboxCustom.addClass('disabled');
    }

    this.checkboxName = this.$checkbox.prop('name');
    this.bindLegacyEvents();
  },
  findLabelNextToCheckbox: function ($checkbox) {
    return $checkbox.nextAll('label');
  },
  bindLegacyEvents: function () {
    var self = this;
    this.$checkbox
      .css('opacity', 0)
      .on('change', function () {

        if (self.$checkbox.prop('checked')) {
          self.$checkboxCustom.addClass('checked');
          self.$checkbox.parents('.data-table-row').addClass('checked');
        } else {
          self.$checkboxCustom.removeClass('checked');
          self.$checkbox.parents('.data-table-row').removeClass('checked');
        }

      }).change();
  },
  iafPrefillPersonalisation: function () {

    if (typeof window.FormValidation === 'undefined') {
      return;
    }

    var $containerElement = this.$checkbox.closest('[data-iaf-prefill-value]'),
      iafCurrentValue = $containerElement.data('iaf-current-value'),
      iafPrefillValue = $containerElement.data('iaf-prefill-value');

    if (typeof iafCurrentValue !== 'undefined' && iafCurrentValue.toString().length === 0) {
      iafCurrentValue = iafPrefillValue;
    }

    if (typeof iafCurrentValue === "undefined") {
      return;
    }

    var $checkedElement = $containerElement.find('input[name="' + this.checkboxName + '"]');
    if (iafCurrentValue.toString().length > 0 && $checkedElement.filter(':checked').length === 0) {

      var $selElement = $checkedElement.filter('[value="' + iafCurrentValue + '"]');
      $selElement.prop('checked', true);
      this.$checkbox.closest('.nn_form')
        .on('IAF_ClientsideFrameworkLoaded', function () {
          window.FormValidation.changeFragment($selElement, false, true);
        });

    }
  }
};
