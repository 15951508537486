function determineSide(dialogElement, side, arrow) {
  const bodyRect = document.body.getBoundingClientRect();
  const dialogRect = dialogElement.getBoundingClientRect();
  const spaceForDialogBottom = bodyRect.height - arrow.y;
  const spaceForDialogRight = bodyRect.width - arrow.x;
  const spaceForDialogTop = arrow.y;
  const spaceForDialogLeft = arrow.x;

  if (side === 'left' && dialogRect.width >= spaceForDialogLeft && spaceForDialogRight >= dialogRect.width) {
    return 'right';
  } else if (side === 'bottom' && dialogRect.height >= spaceForDialogBottom && spaceForDialogTop >= dialogRect.height) {
    return 'top';
  } else if (side === 'top' && dialogRect.height >= spaceForDialogTop && spaceForDialogBottom >= dialogRect.height) {
    return 'bottom';
  } else if (side === 'right' && dialogRect.width >= spaceForDialogRight && spaceForDialogLeft >= dialogRect.width) {
    return 'left';
  }
  return side
}

function updateArrowConfig(element, config) {
  const pos = element.getBoundingClientRect();
  const { arrow: { align }, side } = config;
  // When screen width is smaller than 480px move the dialog towards the
  // bottom and position it without following a specific
  // position of the arrow.
  const isSmallWidth = window.innerWidth < 480;

  if (isSmallWidth || side === 'bottom') {
    config.arrow = {
      side: 'top',
      x: pos.left + (pos.width / 2),
      y: pos.height + pos.top,
      align // with px it's exact otherwise it's relative %
    };
  } else if (side === 'right') {
    config.arrow = {
      side: 'left',
      x: pos.left + pos.width,
      y: (pos.height / 2) + pos.top,
      align // with px it's exact otherwise it's relative %
    };
  } else if (side === 'left') {
    config.arrow = {
      side: 'right',
      x: pos.left,
      y: (pos.height / 2) + pos.top,
      align // with px it's exact otherwise it's relative %
    };
  } else if (side === 'top') {
    config.arrow = {
      side: 'bottom',
      x: pos.left + (pos.width / 2),
      y: pos.top,
      align // with px it's exact otherwise it's relative %
    };
  }
}

export { determineSide, updateArrowConfig}
