/**
 * @class SlideShow
 * @description
 *    The slideshow class can display a.. well, slideshow. From each
 *    element with the class "slideshow-component-slides", a slide
 *    will be created and appended to the navigation for the show.
 */
(function (window, $, nn) {
  nn.component.SlideShow = function (element) {
    this.element = $(element);
    this.slideContainer = this.element.find('.slideshow-component-slides');
    this.slides = this.slideContainer.find('.slide');
    this.init();
  };

  nn.component.SlideShow.prototype = {
    init: function () {
      if (this.slides.size() > 1) {
        this.getAndSetOptions();
        this.startCycle();
      }

      this.enhanceForIE();
    },

    getAndSetOptions: function () {
      this.showNav = this.element.attr('data-slideshow-show-nav') || 'true';
    },

    startCycle: function () {
      var cycleOptions = {
        fx: 'fade',
        slides: '> .slide',
        speed: nn.settings.carrouselFade,
        timeout: nn.settings.carrouselTimeout,
      };

      if (this.showNav === 'true') {
        this.slides.each(function () {
          $(this).attr('data-title', $(this).find('.slide-title').text());
        });

        this.element.append($('<div class="bgnav" />'));
        this.element.append($('<ul class="nav" />'));

        cycleOptions.pager = '.nav';
        cycleOptions.pagerTemplate =
          '<li><a href="#">{{title}}</a><div></div></li>';
      }

      this.slideContainer.cycle(cycleOptions);

      if (nn.isCmsModePreview()) {
        this.slideContainer.cycle('stop');
        // When available, this should be expanded to start and stop on editable events
      }

      var width = Math.floor(100 / this.slides.size());
      this.element.find('.nav li').css('width', width + '%');
    },
  };
})(window, jQuery, nn);
