/**
 *	@class ForceSectionVisibility
 *	@description
 *		A scrollable element with certain content visible by default
 */

nn.component.ForceSectionVisibility = function(element) {
  this.element = $(element);
  this.targetElement = this.element.find('.force-section-visibility-target');
  this.sectionContainer = this.element.find('.force-section-visibility-container');
  this.paddingTop = parseInt(this.sectionContainer.css('padding-top'));
  this.paddingBot = parseInt(this.sectionContainer.css('padding-bottom'));

  this.init();
};

nn.component.ForceSectionVisibility.prototype = {

  init : function () {

    $(window).on('resize', nn.helpers.debounce($.proxy(this.resize, this),500));
    this.resize();

  },

  resize: function () {
    var h = this.targetElement.height() + this.paddingTop + this.paddingBot;
    this.sectionContainer.css('height',h);
  }

};