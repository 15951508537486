import lodashDelay from 'lodash/delay';
import assign from 'lodash/assign';

(function(moduleconfig, window) {
  // The loader DOM reference
  var loaderElement;

  window.app = window.app || {};

  window.app.Loadingoverlay = function(options) {
    var showDelayed;
    var delayTimer;

    // Parse and/or arrange any given config parameters
    var config = moduleconfig({
      'options': options,
      'defaults': {
        'delay': 0,
        'dialogContent': ''
      }
    });


    if (!loaderElement) {
      //Prepare data to fill our template
      var dialogContent ;
      if (typeof options !== 'undefined') {
        dialogContent = options.dialogContent ;
      }

      // Marker to prevent multiple calls to show from messing up when show is delayed
      showDelayed = false;

      var templateData = {
        'dialogContent' :  dialogContent || config.dialogContent
      };

      // Render our template into a HTML fragment, that we can append to the body
      var overlayFragment = createDocumentFragment(getLoadingOverlayTemplate(templateData));

      // Append the fragment to the body
      window.document.querySelector('body').appendChild(overlayFragment);

      // Since we used a disposable HTML fragment, we cannot reference it.
      // Make a reference to the overlay now.
      loaderElement = window.document.querySelector('body > .c-loading-overlay');
    }


    // Display the overlay
    function show(options) {

      var delay ;

      //prevent input by blocking page
      loaderElement.style.display = 'block';

      if (typeof options !== 'undefined') {

        if ((!options.force && showDelayed) || visible()){
          // We are waiting for delayed show execution, or overlay is already shown.
          // Nothing to do here.
          return;
        }

        delay = parseInt(options.delay,10) ;
      }

      // This function itself accepts a delay. Overrule the upper one.
      if (delay === undefined ) {
        delay = parseInt(config.delay,10);
      }

      if (delay > 0) {
        // Delay execution. Pass any given options and make sure that final execution has 0 delay.
        var delayedOptions = assign(options, {delay: 0, force: true});
        delayTimer = lodashDelay(show, delay, delayedOptions);
        // Prevent repeat calls from messing things up.
        showDelayed = true;
      } else {
        // No delay. Do what needs to be done.
        loaderElement.classList.add('is-active');
        // It's safe to call show again
        showDelayed = false;
      }

    }

    // prefixer helper function
    var pfx = ["webkit", "moz", "MS", "o", ""];
    function prefixedEventListener(element, type, callback) {
      for (var p = 0; p < pfx.length; p++) {
        if (!pfx[p]) {
          type = type.toLowerCase();
        }
        element.addEventListener(pfx[p]+type, callback, false);
      }
    }


    // Hide the overlay
    function hide() {
      // Prevent delayed execution of show
      clearTimeout(delayTimer);

      if (visible()){

        loaderElement.classList.remove('is-active');
        // Listen for animation end of css animation
        prefixedEventListener(loaderElement,"TransitionEnd",function(){
          loaderElement.style.display = 'none';
        });
      } else {
        loaderElement.classList.remove('is-active');
        loaderElement.style.display = 'none';
      }
    }

    // Is our overlay visible?
    function visible() {
      return loaderElement.classList.contains('is-active');
    }

    // Is our overlay visible?
    function destroy() {
      loaderElement.parentNode.removeChild(loaderElement);
      loaderElement = '';
      // prevent delayed execution of show
      clearTimeout(delayTimer);
    }

    // Serve the overlay element
    function element() {
      return loaderElement;
    }

    // The overlay API
    return {
      'show': show,
      'hide': hide,
      'visible': visible,
      'destroy': destroy,
      'element': element
    };
  };

  function createDocumentFragment(htmlStr) {
    var frag = document.createDocumentFragment(),
      temp = document.createElement('div');
    temp.innerHTML = htmlStr;
    while (temp.firstChild) {
      frag.appendChild(temp.firstChild);
    }
    return frag;
  }

}(window.app.helper.moduleconfig, window));

const getLoadingOverlayTemplate = ({dialogContent}) => {
  return `<div class="c-loading-overlay">
	<div class="c-loading-overlay__content">
    ${dialogContent ? `<div class="c-loading-overlay__content__dialog">
				<div class="o-media">
					<div class="o-media__figure c-spinner">Bezig met laden...</div>
	 				<div class="o-media__body">${dialogContent}</div>
				</div>
      </div>` : `<div class="c-spinner">Bezig met laden...</div>`}
	 </div></div>`;
}
