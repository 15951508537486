/**
 * @class FlippableComponent
 * @description
 * A flippable component can be clicked on to reveal the other side,
 * using a fancy transition between the two pages.
 *
 * @property [Node] element - The element which this instance will control.
 * @property [boolean] flipped - Is the back-face visible?
 * @property [boolean] animating - Is the component currently being animated?
 * @property [Object] layers - An object with the front- and rear layers.
 * @property [Object] cache - Cached variables, lengths and sizes, for performance.
 */
/**
 * @constructor
 */
nn.component.FlippableComponent = function (element) {
  this.element = element;
  this.$element = $(this.element);
  this.flipped = false;
  this.animating = false;
  this.cache = {};
  this.layers =
    {
      front: this.$element.find('.component-front-side'),
      back: this.$element.find('.component-back-side'),
      cover: null,
      panel: null,
      panelFront: null
    };

  this.$element.find('.component-back-side').hide();

  /** check component empty links (#), used as handler to flip the component */
  this.$element.find('a[href="#"]').on('click', function (e) {
    e.preventDefault();
  });

  var self = this;
  /** Determine the tallest side of the flippable component */
  this.$element.css('height', Math.max(this.layers.front.outerHeight(), this.layers.back.outerHeight()));
  /** Bind the required events to elements within the component **/
  this.$element.find('.flip-link a:not(.more), .flip-link .submit-button').click(function () {
    self.flip();
    return false;
  });
  if (this.$element.find('.flip-link .submit-button').size() === 0) {
    this.$element.click(function () {
      self.flip();
    });
  }
};
/**
 * @method flip
 * @description
 * Flip the component around. If we're facing the front-side, the
 * back-side will be shown, and vica versa.
 */
nn.component.FlippableComponent.prototype = {
  flip: function () {
    /** If we're already being animated, ignore this click **/
    if (this.animating === true) {
      return;
    }
    this.animating = true;
    /** Set the animation options depending on the direction **/
    var options = {};
    if (this.flipped === false) { // front-to-back
      options.panelDirection = 'right';
      options.panelReverse = 'left';
      options.panelFadeOut = 500;
      options.activeLayer = this.layers.front;
      options.updateLayer = this.layers.back;
    } else { // back-to-front
      options.panelDirection = 'left';
      options.panelReverse = 'right';
      options.panelFadeOut = 400;
      options.activeLayer = this.layers.back;
      options.updateLayer = this.layers.front;
    }
    /** Initialize the panel, and set it up for the f-to-b direction **/
    this.createPanel(options.panelDirection);
    /** Start fading the elements **/
    this.layers.cover.fadeOut(0).fadeIn(300);
    this.layers.panel.fadeOut(0).fadeIn(400);
    options.activeLayer.fadeOut(300);
    var self = this;
    var duration = 500,
      halfPi = (Math.PI / 2),
      stepFragment = (1 / duration),
      animationStart = new Date().getTime(),
      animationEnd = animationStart + duration,
      stepProgress = 0,
      fadedPanel = false,
      fadedElement = false;
    /** Run the animation in its own interval, running at 33fps **/
    var interval = setInterval(function () {
      var currentTime = new Date().getTime();
      if (currentTime < animationEnd) {
        stepProgress = ((currentTime - animationStart) * stepFragment);
        self.updatePanel(
          options.panelDirection,
          Math.round(Math.cos(halfPi * stepProgress) * 100),
          Math.round(Math.sin(halfPi * stepProgress) * 100)
        );
      } else {
        self.updatePanel(options.panelDirection, 0, 100);
        clearInterval(interval);
        /** Set the properties to their correct values for the second iteration **/
        duration = 600;
        stepFragment = 1 / duration;
        animationStart = currentTime;
        animationEnd = animationStart + duration;
        /** And process the second part of the animation, again at 33fps **/
        interval = setInterval(function () {
          currentTime = new Date().getTime();
          if (fadedElement === false && (currentTime + 250) > animationEnd) {
            options.updateLayer.fadeIn(450);
            self.layers.cover.fadeOut(450);
            fadedElement = true;
          }
          if (fadedPanel === false && (currentTime + options.panelFadeOut) > animationEnd) {
            self.layers.panel.fadeOut(400);
            fadedPanel = true;
          }
          if (currentTime < animationEnd) {
            stepProgress = ((currentTime - animationStart) * stepFragment);
            self.updatePanel(
              options.panelReverse,
              Math.round(Math.sin(halfPi * stepProgress) * 100),
              Math.round(Math.cos(halfPi * stepProgress) * 100)
            );
          } else {
            clearInterval(interval);
            self.destroyPanel();
            self.animating = false;
            self.flipped = !self.flipped;
          }
        }, 30);
      }
    }, 30);
  },
  /**
   * @method updatePanel
   * @description
   * Updating a panel for a certain transition on the page, may be done
   * by invoking this method. That's exactly what the intervals for the
   * actual flipping method(s) will do.
   */
  updatePanel: function (direction, stepX, stepY) {
    var width = Math.round(stepX / (100 / this.cache.sizes.width)),
      heightDelta = Math.round(stepY / 5);

    this.layers.panel.css(
      {
        left: this.cache.offset.left + Math.round((this.cache.sizes.width - width) / 2) - 3,
        top: this.cache.offset.top - heightDelta,
        width: width + 6,
        height: this.cache.sizes.height + (heightDelta * 2)
      });
    this.layers.panel [0].className = 'flip-panel ' + direction + '-flip-panel';
    this.layers.panelFront.css('border-' + direction + '-width', (width > 6 ? width - 6 : 0));
    this.layers.panelFront.css(
      {
        'border-top-width': heightDelta * 2,
        'border-bottom-width': heightDelta * 2,
        'height': this.cache.sizes.height - (heightDelta * 2)
      });
  },
  /**
   * @method createPanel
   * @description
   * Creating the panels used in the animation will be done by this
   * method, which will also register certain caching variables.
   */
  createPanel: function (direction) {
    this.cache.offset = this.$element.offset();
    this.cache.sizes =
      {
        width: this.$element.outerWidth(),
        height: this.$element.outerHeight()
      };
    this.layers.panel = $('<div><div class="front" /><div class="side" /></div>');
    this.layers.cover = $('<div class="cover" />');
    this.layers.cover.css(
      {
        left: this.cache.offset.left,
        top: this.cache.offset.top,
        width: this.cache.sizes.width,
        height: this.cache.sizes.height
      });
    this.layers.panelFront = this.layers.panel.find('.front');
    this.layers.back.css('visibility', 'visible');
    this.layers.back.fadeOut(0);
    $('body').append(this.layers.panel).append(this.layers.cover);
    this.updatePanel(direction, 100, 0);
  },
  /**
   * @method destroyPanel
   * @description
   * After the animation has finished, we need to remove our mess and
   * get reset the internal state.
   */
  destroyPanel: function () {
    this.layers.panel.remove();
    this.layers.cover.remove();
    this.layers.cover = null;
    this.layers.panel = null;
    this.layers.panelFront = null;
  }

}
/**
 * @class GenderSelectionComponent
 * @description
 * Selecting one's gender requires a minimal amount of custom
 * handling, considering we want the selected gender to stay
 * selected.
 */