// This is a formatting function that takes a Number and formats it
// to display 'precision' decimal digits (zeros are used for padding),
// using 'decimalMark' to separate the integral and fractional parts if precision is nonzero,
// and grouping digits using 'thousandsSep'.
// Returns the formatted string upon success, false upon failure.
// NOTE: This is purely a formatting function!
// Don't rely on the precision of the output in further calculations since it rounds off and/or pads with zeroes.
const number_format = function (number, precision, decimalMark, thousandsSep) {
  precision = !isFinite(precision) ? 0 : Math.abs(precision);
  thousandsSep = (typeof thousandsSep === 'string') ? thousandsSep : ',';
  decimalMark = (typeof decimalMark === 'string') ? decimalMark : '.';
  // Perform some sanity checks and canonicalize the number.
  if (typeof number === 'number') {
    if (isNaN(number)) {
      return false;
    }
    number = String(number).replace('.', decimalMark);
  } else if (typeof number !== 'string') {
    return false;
  }

  number = flipDecimalAndThousandSeparatorIfNeeded(number, thousandsSep, decimalMark);

  // Remove any thousands separators and split the string into integral and decimal parts:
  number = number.split(thousandsSep).join('').split(decimalMark, 2);

  // Purge all non-digits from the integral and fractional parts, then concatenate:
  number = number.map(function (part) {
    return part.replace(/\D/g, '');
  }).join('.');

  // Check if the value is usable as a float:
  number = parseFloat(number);
  if (isNaN(number)) {
    return false;
  }

  // Round off (previous code comments cautioned against toFixed() due to cross-browser issues, so be it):
  var k = Math.pow(10, precision);
  number = String(Math.round(number * k) / k);

  // Split into integral and fractional parts again:
  number = number.split('.', 2);

  // Group digits in the integral part using the thousands separator:
  var integralPart = number[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);

  // Truncate/pad the fractional part to the required precision:
  var fractionalPart = (typeof number[1] === 'string') ? number[1].substr(0, precision) : '';
  for (var i = 0, len = precision - fractionalPart.length; i < len; i++) {
    fractionalPart += '0';
  }

  // Put the integral part and, if relevant, fractional part back together:
  return precision ? (integralPart + decimalMark + fractionalPart) : integralPart;
};

const flipDecimalAndThousandSeparatorIfNeeded = (number, thousandsSep, decimalMark) => {
  const lastIndexOfThousandSepPos = number.lastIndexOf(thousandsSep);
  const lastIndexOfDecimalPos = number.lastIndexOf(decimalMark);
  const thousandSeperatorUsedAsDecimal = lastIndexOfThousandSepPos !== -1 && number.substring(lastIndexOfThousandSepPos + 1).length === 2
  const decimalSeperatorUsedAsThousand = lastIndexOfDecimalPos !== -1 && number.substring(lastIndexOfDecimalPos + 1).length > 2

  if (thousandSeperatorUsedAsDecimal || decimalSeperatorUsedAsThousand) {
    return number.split('').map(c => {
      if (c === thousandsSep) {
        return decimalMark;
      }
      if (c === decimalMark) {
        return thousandsSep;
      }
      return c;
    }).join('');
  }
  return number
};

window.app = window.app || {};
window.app.helper = window.app.helper || {};
window.app.helper.number_format = number_format;

export default number_format;
