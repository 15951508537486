/**
 * @class Repeater
 * @description Component to add more form fields in the view
 */


(function(window, $, nn) {

  /**
	 *
	 * @param HTMLNode element
	 * @returns void
	 */
  nn.component.Repeater = function(element) {

    this.element = $(element);
    this.init();
  };

  nn.component.Repeater.prototype = {
    /**
		 *
		 * @returns {void}
		 */
    init: function() {
      this.counter = this.element.find('[data-role="repeater-counter"]');
      this.rows = this.element.find('[data-role="repeater-row"]');
      this.addBtn = this.element.find('[data-role="repeater-row-adder"]');
      this.rowInstances = [];
      this.activeRows = [];
      this.inactiveRows = [];

      this.rows.each($.proxy(this.initRows, this));

      this.changeRemoverSate();
      this.bindEvents();
    },
    /**
		 *
		 * @param {int} index
		 * @param {HTMLNode} row
		 * @returns {void}
		 */
    initRows: function(index, row) {
      var rowInstance = new nn.component.RepeaterRow(row, this, index);

      if (index < this.counter.val()) {
        rowInstance.activate();
      }

      if (rowInstance.isActive()) {
        this.activeRows.push(rowInstance);
        rowInstance.show();
      } else {
        this.inactiveRows.push(rowInstance);
        rowInstance.hide();
      }

      this.rowInstances.push(rowInstance);
    },
    /**
		 *
		 * @returns {void}
		 */
    bindEvents: function() {
      this.addBtn.on('click', $.proxy(this.addBtnHandler, this));
    },
    /**
		 *
		 * @param {Event} event
		 * @returns {void}
		 */
    addBtnHandler: function (event) {
      event.preventDefault();
      this.showRepeaterRow();
    },
    /**
		 *
		 * @param {Event} event
		 * @returns {void}
		 */
    showRepeaterRow: function() {
      var rowInstance = this.inactiveRows.shift();
      rowInstance.activate();
      this.activeRows.push(rowInstance);
      this.counter.val(parseInt(this.counter.val(), 10)+1);
      this.counter.trigger('change');

      this.changeRemoverSate();
    },
    hideLastRepeaterRow: function () {
      var lastActiveRow = this.activeRows.pop();
      lastActiveRow.deactivate();
      this.inactiveRows.unshift(lastActiveRow); // prepend
      this.counter.val(parseInt(this.counter.val(), 10)-1);
      this.counter.trigger('change');

      this.changeRemoverSate();
    },
    /**
		 *
		 * @param {nn.component.RepeaterRow} dactivatedRow
		 * @returns {void}
		 */
    dactivateRow: function(dactivatedRow) {

      var currentActiveRowCount = this.activeRows.length;
      var rowIndexToDeactivate = currentActiveRowCount;
      for (var i = 0; i < currentActiveRowCount; i++) {
        if (dactivatedRow.equals(this.activeRows[i])) {
          rowIndexToDeactivate = i;
        }
      }

      if (this.activeRows.length === 1) {
        // do nothing
      } else if (rowIndexToDeactivate === (this.activeRows.length - 1)) { // if its the last one.. keep it simple
        this.rowInstances[rowIndexToDeactivate].deactivate(); // clear content also
        this.hideLastRepeaterRow();
      } else { // if it is not the last one than reorder the content
        // get data from next row
        this.moveRowContent(rowIndexToDeactivate);
        this.hideLastRepeaterRow();
      }
    },
    moveRowContent: function(baseRowIndex) {

      var nextRowIndex = baseRowIndex + 1;
      if (this.rowInstances[nextRowIndex]) {
        this.rowInstances[nextRowIndex].moveContentTo(this.rowInstances[baseRowIndex]);

        this.moveRowContent(nextRowIndex);
      }
    },
    changeRemoverSate: function () {

      if (this.activeRows.length > 1) {
        this.rowInstances[0].enableRemover();
      } else {
        this.rowInstances[0].disableRemover();
      }

      if (this.inactiveRows.length === 0) {
        this.addBtn.hide();
      } else {
        this.addBtn.show();
      }
    }
  };


  /**
	 *
	 * @param {HTMLNode}
	 * @param {nn.component.Repeater} repeater
	 * @param {int} index
	 * @returns {void}
	 */
  nn.component.RepeaterRow = function(element, repeater, index) {
    this.element = $(element);
    this.repeater = repeater;
    this.index = index;

    this.init();
  };

  nn.component.RepeaterRow.prototype = {
    /**
		 *
		 * @returns {void}
		 */
    init: function() {
      this.state = this.element.attr('data-state');
      this.removeBtn = this.element.find('[data-role="repeater-row-remover"]');
      this.content = this.element.find('[data-role="repeater-row-content"]');
      this.removable = false;
      this.contentInstance = new nn.component.RepeaterRowContent(this);

      this.bindEvents();
    },
    /**
		 *
		 * @returns {void}
		 */
    bindEvents: function() {
      this.removeBtn.on('click', $.proxy(this.removeBtnClickHandler, this));
    },
    /**
		 *
		 * @param {Event} event
		 * @returns {void}
		 */
    removeBtnClickHandler: function(event) {
      event.preventDefault();
      if (this.removable === false) {
        this.repeater.dactivateRow(this);
      }
    },
    /**
		 *
		 * @param {nn.component.RepeaterRow} repeaterRow
		 * @returns {void}
		 */
    moveContentTo: function(repeaterRow) {
      if (repeaterRow.contentInstance) {
        this.contentInstance.moveContentValuesTo(repeaterRow.contentInstance);
      }
    },
    enableRemover: function () {
      this.removable = false;
      this.removeBtn.removeClass('disabled');
    },
    disableRemover: function () {
      this.removable = true;
      this.removeBtn.addClass('disabled');
    },
    /**
		 *
		 * @param {Event} event
		 * @returns {void}
		 */
    deactivate: function() {

      this.state = 'inactive';
      this.element.attr('data-state', this.state);
      this.contentInstance.clearContentValues();

      this.hide();
    },
    /**
		 *
		 * @returns {void}
		 */
    activate: function() {
      this.state = 'active';
      this.element.attr('data-state', this.state);

      this.show();
    },
    /**
		 *
		 * @returns {void}
		 */
    hide: function() {
      this.element.addClass('displayNone');
    },
    /**
		 *
		 * @returns {void}
		 */
    show: function() {
      this.element.removeClass('displayNone');
    },
    /**
		 *
		 * @returns {Boolean}
		 */
    isActive: function() {
      return (this.state === 'active');
    },
    /**
		 *
		 * @param {nn.component.RepeaterRow} repeaterRow
		 * @returns {Boolean}
		 */
    equals: function(repeaterRow) {

      if (this.element.get(0) === repeaterRow.element.get(0)) {
        return true;
      }

      return false;
    }
  };

  /**
	 *
	 * @param {nn.component.RepeaterRow} row
	 * @returns {void}
	 */
  nn.component.RepeaterRowContent = function(row) {
    this.row = row;
    this.init();
  };

  nn.component.RepeaterRowContent.prototype = {
    init: function() {
      this.content = $(this.row.content);
      this.fields = {};
      this.content.find(':input').each($.proxy(this.exploreFields, this));
      this.content.find('.field').removeClass('displayNone');
    },
    exploreFields: function (index, field) {
      var $field = $(field);
      var fieldName = $field.attr('name').split('.').pop();
      this.fields[fieldName] = $field;
    },
    moveContentValuesTo: function(otherContent) {
      $.each(this.fields, $.proxy(otherContent.putContentValue, otherContent));
    },
    putContentValue: function (name, $field) {
      if (this.fields[name].attr('type') !== 'file') {
        this.fields[name].val($field.val());
      }
      this.fields[name].trigger('refresh');
    },
    clearContentValues: function() {
      $.each(this.fields, function () {
        var $field = $(this);
        $field.val('')
        $field.on('trigger');
      });
    }
  };

})(window, jQuery, nn);