import portalMenu from './portal-menu.js';
import stickyMenu from './sticky-menu.js';
import {findAncestor} from '@nn/psdl-utility/src/dom-helpers.js';
import scrollHelper from "../utility/scroll.helper";
import search from './search-form';
import overlay from './overlay';
import getNonce from "@nn/psdl-utility/src/nonce";

const mainNavClass = 'c-main-navigation';

export default class mainNavigation {
  constructor(element) {
    this.element = element;
    this.overlayExtended = false;
  }

  onDropdownButtonClick(dropdownButton){
    const dropdownButtonParent = findAncestor(dropdownButton.srcElement, 'LI');
    const isSelectedDropdownOpen = dropdownButtonParent.classList.contains(mainNavClass + '__expanded');
    if(!isSelectedDropdownOpen){
      this.closeAllDropdownMenus();
      if(!this.overlayExtended){
        this.toggleOverlay();
      }
    } else {
      this.toggleOverlay();
    }

    dropdownButtonParent.classList.toggle(mainNavClass + '__expanded');
  }

  toggleOverlay(){
    if(!this.overlayExtended){
      overlay.create(this.closeAllDropdownMenus, this);
      this.overlayExtended = true;
    } else {
      overlay.remove();
      this.overlayExtended = false;
    }
  }

  closeAllDropdownMenus(){
    [...this.element.querySelectorAll('.' + mainNavClass + '__expanded')].forEach(expandedMenu => {
      expandedMenu.classList.remove(mainNavClass + '__expanded');
    });
    this.toggleOverlay();
  }

  initSubmenuTogglers() {
    this.submenuTogglers = [];
    [...this.element.querySelectorAll('[data-toggle=submenu]')].forEach(dropdownButton => {
      dropdownButton.addEventListener('click', (dropdownButton) => {
        this.onDropdownButtonClick(dropdownButton)
      });
    });

    [...this.element.querySelectorAll('.' + mainNavClass + ' a')].forEach(navigationLink => {
      navigationLink.addEventListener('click', () => {
        if(this.overlayExtended){
          this.closeAllDropdownMenus();
        }
      });
    });
  }

  adjustWidthToCompensateForScrollbarRemoval(){
    const css = 'body.is-modal-open header .c-main-navigation__main{width: calc(100% - '+scrollHelper.getScrollbarWidth()+'px);}';
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.nonce = getNonce();
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }

  init() {
    portalMenu.init(this.element);
    stickyMenu.init(this.element);
    this.initSubmenuTogglers();
    this.adjustWidthToCompensateForScrollbarRemoval();
    search.init(this.element);
    return this;
  }
}
