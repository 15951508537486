const classMenuItem = "c-header__menu-item";
const classMenuItemHide = "c-header__menu-item--hidden";
const classMenuItemMore = "c-header__menu-item--more";
const classMenuLink = "c-header__menu-link";
const classMenuLinkMore = "c-header__menu-link--more";

function getMenuItems(element) {
  const menu = element.querySelector(".c-header__menu");
  const menuItems = [...element.querySelectorAll(`.${classMenuItem}:not(${classMenuItemMore})`)];
  return { menu, menuItems }
}

function createMoreMenu(menu, subMenu) {
  const menuItemMore = document.createElement("li");
  const menuItemMoreLink = document.createElement("a");

  menuItemMore.classList.add(classMenuItem); // ie11 doesn't support classlist.add() with multiple classes
  menuItemMore.classList.add(classMenuItemMore);
  menuItemMoreLink.classList.add(classMenuLink);// ie11 doesn't support classlist.add() with multiple classes
  menuItemMoreLink.classList.add(classMenuLinkMore);

  menuItemMoreLink.href = "#";
  menuItemMoreLink.innerHTML += "Meer...";

  menuItemMore.appendChild(menuItemMoreLink);
  menuItemMore.appendChild(subMenu);
  menu.appendChild(menuItemMore);

  menuItemMore.style.display = "none";
  return menuItemMore;
}

function createSubMenu() {
  const subMenu = document.createElement("div");
  const subMenuGrid = document.createElement("div");
  const subMenuList = document.createElement("ul");

  subMenu.classList.add("c-header__menu-sub");
  subMenuList.classList.add("c-list");
  subMenuGrid.classList.add("u-grid", "u-grid__indent");

  subMenu.appendChild(subMenuGrid);
  subMenuGrid.appendChild(subMenuList);

  return { subMenu, subMenuList }
}

function setMoreMenu(menu, menuItems, menuItemMore, subMenuList) {
  const menuItemMijnn = menu.querySelector(".c-header__login")
  const menuItemMijnnnWidth = menuItemMijnn ? menuItemMijnn.offsetWidth : 0;
  const menuWidth = menu.offsetWidth;
  const menuItemMoreWidth = menuItemMore.offsetWidth;
  let extraspace = 0;
  if (menuItemMoreWidth === 0) {
    extraspace = 46
  }
  let maxWidthMenuItems = menuWidth - menuItemMijnnnWidth - menuItemMoreWidth - extraspace;
  let totalWidthMenuItems = 0;

  [...subMenuList.children].forEach((el) => {
    el.parentNode.removeChild(el);
  })

  menuItems.forEach((menuItem) => {
    menuItem.classList.remove(classMenuItemHide);

    const menuItemWidth = menuItem.offsetWidth;
    totalWidthMenuItems += menuItemWidth;

    if (totalWidthMenuItems > maxWidthMenuItems) {
      menuItem.classList.add(classMenuItemHide);

      const subMenuListItem = document.createElement("li");
      const cLink = document.createElement("a");

      subMenuListItem.classList.add("c-list__item");
      cLink.classList.add("c-link");
      cLink.href = menuItem.getElementsByTagName("a")[0].href;
      cLink.innerHTML = menuItem.getElementsByTagName("a")[0].innerHTML;

      subMenuListItem.appendChild(cLink);
      subMenuList.appendChild(subMenuListItem);
    }
  });

  if (menuItemMore.getElementsByTagName("li").length !== 0) {
    menuItemMore.style.display = "block";
    const header = document.querySelector('[data-module="header"]')
    const padding = menu.querySelector(`.${classMenuLinkMore}`).getBoundingClientRect().left - header.getBoundingClientRect().left;
    subMenuList.style.padding = `0 0 0 ${padding}px`
  } else {
    menuItemMore.style.display = "none";
  }
}


export { getMenuItems, createMoreMenu, createSubMenu, setMoreMenu };