window.nn.registerModule('chatbotTrigger', (element, config) => {
  Object.keys(behaviours).forEach(key => {
    if (element.tagName === key) {
      behaviours[key](element, config);
    }
  });
})

const behaviours = {
  'A': (element, config) => {
    element.addEventListener('click', (e) => {
      let inputMsg = e.target.dataset.message;
      triggerChatbot(inputMsg, config);
    });
  },
  'BUTTON': (element, config) => {
    element.addEventListener('click', (e) => {
      let inputMsg = e.target.dataset.message;
      triggerChatbot(inputMsg, config);
    });
  },
  'FORM': (element) => {
    element.addEventListener('submit', (e) => {
      e.preventDefault();
      let textArray = e.target.getElementsByTagName('input');
      let text = [];
      Array.prototype.forEach.call(textArray, (element) => {
        text.push(element.value);
      })
      triggerChatbot(text.join(' '));
    });
  },
}

const triggerChatbot = (text, conversation) => {
  if (window.nnchatbot && window.nnchatbot.version) {
    if (text) {
      window.nnchatbot.createConversationWithMessage(text);
    } else if (Object.keys(conversation).length > 0) {
      window.nnchatbot.createConversation(conversation);
    } else {
      window.nnchatbot.createConversation();
    }
  }
}
