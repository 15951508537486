import moduleconfig from '../u-moduleconfig/moduleconfig';

import './_c-sidepanel.scss';
import SidePanel from './sidepanel';

window.app = window.app || {};
window.app.sidepanel = window.app.sidepanel || {};


window.app.sidepanel.button = function (element) {
  return SidePanel.fromShowButton(element, moduleconfig({element}));
};

window.app.sidepanel.ajaxButton = function (element) {
  return SidePanel.fromAjaxButton(element, moduleconfig({element}));
}
