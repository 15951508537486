import moduleconfig from "../u-moduleconfig/moduleconfig";

window.app = window.app || {};
window.app.form = window.app.form || {};

window.app.form.formpart = function (element, options) {

  // Our main container / element
  const $element = $(element);

  // Parse and/or arrange any given config parameters
  const config = moduleconfig({
    "options": options,
    "element": element,
    "defaults": {
      "max-filesize": 0,
      "errorfilesizebefore": "De totale bestandsgrootte mag maximaal",
      "errorfilesizeafter": "zijn"
    }
  });

  // Error container provided by GX/XC
  const errorContainer = $element.parent().find('#generalerrormessage');

  // Total file field size
  let totalFileSize = 0;

  // Change listener
  function onChange() {
    updateFileSize();
  }

  // Set change listener
  element.addEventListener("change", onChange);

  // Update total file size for upload fields in this form (part)
  function updateFileSize() {
    totalFileSize = 0;
    const inputFiles = element.querySelectorAll('input[type="file"]');
    for (let i = inputFiles.length - 1; i >= 0; i--) {
      totalFileSize += window.app.form.inputfile.getInputFilesize(inputFiles[i]);
    }

    // Mark the form part if max file size is exceeded
    // TODO: please give this a js-cms-totalfilesizetroep  name
    if (config["max-filesize"] > 0 && totalFileSize > config["max-filesize"]) {
      $element.addClass('c-form__formpart--filesize-exceeded');
      errorContainer.text(config.errorfilesizebefore + ' ' + bytesToSize(config["max-filesize"]) + ' ' + config.errorfilesizeafter);
    } else {
      $element.removeClass('c-form__formpart--filesize-exceeded');
      errorContainer.text('');
    }
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Bytes';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
};
