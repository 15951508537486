async function bootstrapLazy(lazyPromise, root, config) {
  return (await lazyPromise).default(root, config)
}

window.nn.registerModule('login', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "customer" */ './loginForm'), root, config);
});

window.nn.registerModule('login.advisor', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "customer" */ './loginFormAdvisor'), root, config);
});

window.nn.registerModule('login.mfa', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "customer" */ './loginMfa'), root, config);
});

window.nn.registerModule('login.particulier', function (root, config) {
  return bootstrapLazy(import(/* webpackChunkName: "customer" */ './loginParticulier'), root, config);
});
