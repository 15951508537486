// FIXME: the Blueriq JS TabManager component interacts  with the remote
// overlay. It checks for its presence, and if it's still there (e.g. the dialog
// it's in is closing already, but the process takes a while because is's
// animated), it locks the focus to the soon-to-be nonexistent remote div. The
// following solution feels rather suboptimal.
export function disownRemoteOverlay () {
  const scopedRemoteOverlay = document.querySelector('[data-blueriq-scope][data-blueriq-div="remote_overlay"]');
  if (scopedRemoteOverlay) {
    scopedRemoteOverlay.removeAttribute('data-blueriq-div');
    scopedRemoteOverlay.removeAttribute('data-blueriq-scope');
  }
}

export function buildRemoteOverlayHTML (scope, html) {
  return `<div data-blueriq-scope="${encodeURIComponent(scope)}" data-blueriq-div="remote_overlay">${html}</div>`;
}
