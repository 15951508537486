/**
 *
 * @param {HTMLNode} element
 * @returns {void}
 */
nn.component.LastnameFormatter = function (element) {
  this.element = $(element);
  this.init();
};

nn.component.LastnameFormatter.prototype = {
  init: function () {
    this.element
      .on('change', $.proxy(this.onChangeHandler, this));
  },
  onChangeHandler: function () {
    var v = this.element.val();
    this.element.val(v.charAt(0).toUpperCase() + v.slice(1));
  }
};