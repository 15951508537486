import Hypher from 'hypher';
import nl from 'hyphenation.nl';

export const initHyphenate = () => {
  const loadHyphenationAndHyphenate = async () => {
    const h = new Hypher(nl);

    const checkChildNodes = (childNodes) => {
      if (!childNodes || !childNodes.length) {
        return;
      }

      for (let i = 0; i < childNodes.length; i++) {
        const child = childNodes[i];
        hyphenateElement(child);
        checkChildNodes(child.childNodes);
      }
    }

    const isInAllowedParentContainer = (parentTagName) => {
      return ['TEXTAREA'].indexOf(parentTagName) === -1;
    };

    const hyphenateElement = (el) => {
      if (el.nodeType === 3 && el.textContent && isInAllowedParentContainer(el.parentNode.tagName)) {
        el.textContent = h.hyphenateText(el.textContent);
      }
    };

    var hyphenatorhasrun = false;
    [...document.querySelectorAll('.hyphenate')].forEach((el) => {
      hyphenateElement(el);
      checkChildNodes(el.childNodes);
      el.classList.remove('hyphenate');
      hyphenatorhasrun = true;
    });

    if (hyphenatorhasrun) {
      $("body").trigger('NN:Hyphenate:Done');
    }
  }

  $(window).on('NN:afterInitializeComponents', loadHyphenationAndHyphenate);
  $(window).on('Aquima:Element:Refresh', loadHyphenationAndHyphenate);
}

if(process.env.NODE_ENV !== 'testing') {
  initHyphenate();
}
