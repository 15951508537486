nn.component.Collapser = function (element) {
  // Set root element
  this.element = $(element);
  // Set properties
  this.collapsed = false;
  this.traversalMethod = null;
  this.traversalSelector = null;
  this.contentMethod = null;
  this.contentSelector = null;
  this.animateCollapse = false;
  this.collapserId = 'collapser';
  this.contentContainer = null;
  this.content = null;
  this.initialOpen = false;
  this.enabled = true;
  this.enableDeeplink = false;

  // Get and set options
  this.getAndSetOptions();

  // Bind events
  this.bindEvents();

  // If in XC edit mode:
  if (!nn.isCmsModePreview()) {
    // Hide content without animation on initialize
    this.initToggleContent(false);
    // Show or hide toggler depending on if content is found
    this.showOrHideTogglerOnContent();
  }
};

nn.component.Collapser.prototype = {
  getAndSetOptions: function () {

    // FIXME: USE DATA once, don't ask for attr over and over
    var data = this.element.data();

    // OLD
    if (this.element.attr('data-traversalMethod')) {
      this.traversalMethod = this.element.attr('data-traversalMethod');
    }

    if (this.element.attr('data-traversalSelector')) {
      this.traversalSelector = this.element.attr('data-traversalSelector');
    }

    if (this.element.attr('data-contentMethod')) {
      this.contentMethod = this.element.attr('data-contentMethod');
    }

    if (this.element.attr('data-contentSelector')) {
      this.contentSelector = this.element.attr('data-contentSelector');
    }

    if (this.element.attr('data-animateCollapse') === "true") {
      this.animateCollapse = true;
    }

    if (this.element.attr('data-deeplink') === "1") {
      this.enableDeeplink = true;
    }

    //refill it with the first content id if possible
    this.collapserId = this.findContent().find("[id]:first").attr("id") || this.element.data('collapserId');

    this.collapserNostorage = this.element.attr('data-collapser-nostorage') === "true";


    var isInitialOpen = data.initialOpen ? data.initialOpen : data.initialopen;

    // data-initial-open is advised (no uppercase data attributes html5) and data-initialOpen was used, this should work for both but then again should be tested cross browser
    if (isInitialOpen || this.element.attr('data-expandoninit') === "true" || this.element.attr('data-expandOnInit') === "true" /* Advisor legacy */) {
      this.initialOpen = true;
    }


    this.preventContainerActiveClass = this.element.attr('data-prevent-container-active-class') === "true";

    if (this.element.attr('data-collapser-emptystring')) {
      this.emptyString = this.element.attr('data-collapser-emptystring');
    } else {
      this.emptyString = "";
    }

    // Set active class for current SSC item;
    if (parseInt(nn.helpers.getQueryVariable("et_rsid"), 10) === parseInt(this.collapserId, 10)) {
      this.element.addClass('color--orange');
    }

  },
  initToggleContent: function (animate) {

    var toggle = this.initialOpen;

    if (!this.collapserNostorage && nn.globals.storage.get('collapser.' + this.collapserId) !== null) {
      toggle = nn.globals.storage.get('collapser.' + this.collapserId);
    }

    if (this.enableDeeplink && !toggle && this.hasHashElement()) {
      toggle = true;
    }

    if (toggle) {
      this.expandContent(animate);

      if (this.collapserId && window.location.hash === '#' + this.collapserId) {
        $('html, body').animate({scrollTop: this.element.offset().top});
      }
    } else {
      this.collapseContent(animate);
    }
  },
  bindEvents: function () {

    if (!nn.isCmsModePreview()) {
      this.hideTogglerContent();
    }

    this.element.on('click', $.proxy(this.onClickElement, this));
  },
  hideTogglerContent: function () {
    this.element.find(this.contentSelector).hide();
  },
  hasHashElement: function () {
    return this.getHashElement();
  },
  getHashElement: function () {
    var hash = window.location.hash.substr(1);
    var hashElement;

    if (hash && hash.indexOf(':') === -1) {
      hashElement = this.findContent().find('#' + hash + ',[name="' + hash + '"]');

      if (hashElement.length < 1 && this.element.is('#' + hash + ',[name="' + hash + '"]')) {
        hashElement = this.element;
      }
    }

    this.hashElement = (hashElement && hashElement.length > 0 ? hashElement : null);

    return this.hashElement;
  },
  onClickElement: function (e) {

    // IF the content is empty reset the emptystring to empty, because there's nothing to expand anyway;
    if ($.trim(this.findContent().html()) === '') {
      this.emptyString = "";
    }

    if ($.trim(this.findContent().html()) !== this.emptyString) {
      e.preventDefault();
      if (!this.enabled) {
        return;
      }
      this.element.trigger("Collapser:Click", {collapser: this});
      this.toggleContent(this.animateCollapse);
      if (!this.collapserNostorage) {
        nn.globals.storage.set('collapser.' + this.collapserId, !this.collapsed);
      }
      if (this.enableDeeplink && window.history.replaceState) {
        window.history.replaceState({}, '', '#' + this.collapserId);
      }
    }
  },
  toggleContent: function (animate) {

    this.element.trigger("Collapser:Toggle", {collapser: this});

    animate = animate || this.animateCollapse;

    if (this.collapsed) {
      this.expandContent(animate);
    } else {
      this.collapseContent(animate);
    }
  },
  expandContent: function (animate) {
    animate = animate || this.animateCollapse;
    var duration = animate ? 300 : 0,
      container = this.findContainer(),
      content = this.findContent(),
      self = this;

    this.element.attr('data-collapser-state', 'expanded');

    if (!this.preventContainerActiveClass) {
      container.addClass('active');
    }

    content.slideDown(duration, function () {
      self.element.trigger("Collapser:Expand", {collapser: self});
    });

    this.showTogglerExpanded();

    this.collapsed = false;
  },
  collapseContent: function (animate) {
    var duration = animate ? 300 : 0,
      container = this.findContainer(),
      content = this.findContent(),
      self = this;

    this.element.attr('data-collapser-state', 'collapsed');

    if (!this.preventContainerActiveClass) {
      container.removeClass('active');
    }

    content.slideUp(duration, function () {
      self.element.trigger("Collapser:Collapse", {collapser: self});
    });

    this.showTogglerCollapsed();

    this.collapsed = true;
  },
  findContainer: function () {
    var element = this.element;

    if (null !== this.contentContainer) {
      return this.contentContainer;
    }

    this.contentContainer = $(document);

    if (this.traversalMethod) {
      if ($.isFunction(element[this.traversalMethod])) {
        if (this.traversalSelector === null) {
          this.contentContainer = element[this.traversalMethod]();
        } else {
          this.contentContainer = element[this.traversalMethod](this.traversalSelector);
        }
      }
    }

    return this.contentContainer;
  },
  findContent: function () {
    var content = this.findContainer(),
      contentMethod = 'find';

    if (null !== this.content) {
      return this.content;
    }

    this.content = content;

    if (this.contentMethod !== null) {
      contentMethod = this.contentMethod;
    }

    if ($.isFunction(content[contentMethod])) {
      if (this.contentSelector === null) {
        this.content = content[contentMethod]();
      } else {
        this.content = content[contentMethod](this.contentSelector);
      }
    }

    // if the collapser is empty or if the collapser's html matches the data-attr, then act and look like a normal link;
    if (this.content.html() === "" || $.trim(this.content.html()) === this.emptyString) {
      $(this.element).addClass('empty');
    }

    return this.content;
  },
  hasContent: function () {
    return (this.findContent().length > 0);
  },
  showOrHideTogglerOnContent: function () {
    if (this.hasContent()) {
      this.showToggler();
    } else {
      this.hideToggler();
    }
  },
  showToggler: function () {
    this.element.show();
  },
  hideToggler: function () {
    this.element.hide();
  },
  showTogglerExpanded: function () {
    this.element.addClass('collapse');
  },
  showTogglerCollapsed: function () {
    this.element.removeClass('collapse');
  },
  enable: function () {
    this.enabled = true;
  },
  disable: function () {
    this.enabled = false;
  }
};
