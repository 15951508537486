/** ----------------~ C H A R A C T E R  C O U N T  ~----------------- */

/**
 * Initializes a new character count component.
 *
 * @param {element} The component's root element.
 */

nn.component.Charactercount = function (element) {
  this.element = $(element);

  // Initialize
  this.init();
};

$.extend(nn.component.Charactercount.prototype, {

  /**
   * Initialize the Charactercount component
   */
  init: function () {
    this.getAndSetOptions();

    // maxlength needs to be a positive integer. Abort if otherwise.
    if (!this.characterLimit) {
      return;
    }

    this.getAndSetElements();
    this.getElementData();
    this.bindEvents();
    this.updateCounter();
  },
  getAndSetOptions: function () {
    this.characterLimit = parseInt(this.element.attr("maxlength"), 10);
    this.containerClass = this.element.attr("data-charactercount-containerclass") || "";
    this.counterPrefix = this.element.attr("data-charactercount-prefix") || "";
    this.counterPostfix = this.element.attr("data-charactercount-postfix") || "";
    this.counterMessage = this.element.attr("data-charactercount-message") || "";
  },
  getAndSetElements: function () {
    this.containerElement = this.element.parent('.charactercount');
    // If no container element is present, create it
    if (this.containerElement.length < 1) {
      this.containerElement = this.element.wrap('<span class="charactercount" />').parent('.charactercount');
    }

    if (this.containerClass) {
      this.containerElement.addClass(this.containerClass);
    }

    this.counterElement = this.containerElement.find('.charactercount-counter');
    if (this.counterElement.length < 1) {
      this.counterElement = $('<span class="charactercount-counter"></span>').appendTo(this.containerElement);
    }

    if (this.counterMessage !== "") {
      this.counterElement.html(this.counterMessage.replace(/{{charactersleft}}/g, '<span class="charactercount-counter-amount"></span>'));
    } else if (this.counterPrefix !== "" || this.counterPostfix !== "") {
      this.counterElement.html(this.counterPrefix + '<span class="charactercount-counter-amount"></span>' + this.counterPostfix);
    }

    this.counter = this.counterElement.find('.charactercount-counter-amount');
  },
  getElementData: function () {

  },
  bindEvents: function () {
    this.element.on('keyup', $.proxy(this.onUpdate, this));
    this.element.on('blur', $.proxy(this.onUpdate, this));
  },
  onUpdate: function () {
    this.updateCounter();
  },
  updateCounter: function () {
    var value = this.element.val();

    //There is a bug/difference in Chrome, counting Returns as 2 chars in maxlength
    this.counter.text(this.characterLimit - value.length);

    // Enforce the limit.
    // Makes up for maxlength not working on textareas in older browsers
    if (value.length > this.characterLimit) {
      this.element.val(value.substr(0, this.characterLimit));
    }
  }
});