(function (window, $, nn) {

  nn.component.FeedForward = function (element) {

    this.form = $(element);
    this.submitButton = this.form.find("input[type=submit]");
    this.rows = [];
    this.init();
  }

  nn.component.FeedForward.prototype = {
    init: function () {

      this.getFieldRows();
      this.form.addClass('form--feedforward');

      var self = this;
      // Disable the button onLoad/DOMready
      $(function () {
        self.submitButton.trigger('disable');
      });

      $(document.body).on('IAF_ClientsideFrameworkLoaded', function () {
        self.submitButton.trigger('disable');
      });


    },
    getFieldRows: function () {

      var self = this;
      this.form.find('div.field').each(function () {
        self.rows.push(new nn.component.FeedForwardRow(this, self));
      });

    },
    checkValidRows: function () {

      var allRowsValid = true;

      for (var i = 0; i < this.rows.length; i++) {
        if (this.rows[i].isValid() === false) {
          allRowsValid = false;
          break;
        }
      }

      if (allRowsValid) {
        this.submitButton.trigger('enable');
      } else {
        this.submitButton.trigger('disable');
      }
    }
  }

  nn.component.FeedForwardRow = function (row, parent) {

    this.NOT_VALIDATED = 0;
    this.VALIDATING = 1;
    this.VALIDATED = 2;

    this.parent = parent; // FeedForward Class

    this.row = $(row);
    this.stateIcon = this.row.find('.icon-ffwd');
    this.mandatory = this.row.hasClass('mandatory'); // check if the row is mandatory

    this.validateState = this.NOT_VALIDATED; // this.NOT_VALIDATED | this.VALIDATING | this.VALIDATED
    this.valid = false;

    this.mainFormField = null;
    this.formFields = [];

    this.init();
  }

  nn.component.FeedForwardRow.prototype = {
    init: function () {
      var forcedValidationFields = this.row.find('input.forcevalidation'); // Prefer this field above others to validate


      // If forced validation fields are found use only those
      if (forcedValidationFields.length > 0) {
        forcedValidationFields.each($.proxy(this.exploreField, this));
      } else {
        this.row.find(':input').each($.proxy(this.exploreField, this));
      }

    },
    // @TODO modify this
    exploreField: function (index, element) {
      if (this.isDummyField(element)) {
        //find the real element
        var elName = element.name.replace("-dummy", "");
        $("form input[name='" + elName + "']").each(function () {
          element = this;
        });
      }
      var $field = $(element),
        linkValidation = $field.data('linkvalidation');

      // check to be sure if the found field is not a dummy field
      if (!this.isDummyField(element)) {
        this.formFields.push($field);
        this.saveFieldState(element, 'notvalid');
        this.bindFormFieldEvents($field);
      }

      if (linkValidation) {
        var linkedFormFieldNames = linkValidation.split(',');

        for (var i = 0; i < linkedFormFieldNames.length; i++) {
          var $additionalFormField = this.parent.form.find(':input[name="' + linkedFormFieldNames[i] + '"]');
          this.formFields.push($additionalFormField);
          this.saveFieldState($additionalFormField.get(0), 'notvalid');
          this.bindFormFieldEvents($additionalFormField);
        }
      }
    },
    bindFormFieldEvents: function ($field) {

      if ($field.data('event-binded')) {
        return;
      }

      $field
        .on('IAF_HideError', $.proxy(this.onFieldHideError, this))
        .on('IAF_ShowError', $.proxy(this.onFieldShowError, this))
        .on('change', $.proxy(this.onFieldChange, this));

      $field.data('event-binded', '1');
    },
    onFieldHideError: function (e) {

      this.saveFieldState(e.target, 'valid');
      this.checkValid();
    },
    onFieldShowError: function (e) {

      this.saveFieldState(e.target, 'notvalid');
      this.checkValid();
    },
    onFieldChange: function (e) {

      var $field = $(e.target);
      if (this.isDummyField(e.target)) {
        return; // don't validate dummy fields
      }

      // if the value is filled hide the error
      // let IAF do the validation
      if ($field.val().length > 0) {

        this.valid = false;
        this.validateState = this.VALIDATING;
        this.saveFieldState(e.target, 'notvalid');

        // invalidate linkedFIELDs
        this.stateIcon
          .addClass('loading-message-ffwd')
          .removeClass('displayNone success-message-ffwd');

        $field.trigger('IAF_HideError');
      } else {

        if ($field.hasClass('forcevalidation')) {
          if (this.VALIDATED === this.validateState) {
            $field.trigger('IAF_ShowError');
          }
          return;
        }

        if ($field.is(':visible')) {
          $field.trigger('IAF_ShowError');
        } else if ($field.closest('.field.mandatory').length === 0) {
          // field is hidden. We have to check if the field is mandatory.
          // if not mandatory mark as valid
          $field.trigger('IAF_HideError');
        }
      }
    },
    isDummyField: function (formField) {
      var $field = $(formField);

      if (typeof $field.attr('name') === "undefined") {
        return true;
      }

      return $field.attr('name').indexOf('dummy') !== -1;
    },
    isValid: function () {

      if (!this.mandatory) {
        return true;
      }

      return this.valid;
    },
    checkValid: function () {

      var self = this,
        allFieldsValid = true;

      $.each(this.formFields, function (index, formField) {
        if (self.getFieldState(formField) === 'notvalid') {
          allFieldsValid = false;
        }
      });

      if (allFieldsValid === true) {
        this.valid = true;
        this.validateState = this.VALIDATED;
        this.stateIcon
          .removeClass('loading-message-ffwd')
          .addClass('success-message-ffwd');

      } else {
        this.valid = false;

        if (this.VALIDATED === this.validateState) {
          this.stateIcon
            .removeClass('loading-message-ffwd success-message-ffwd')
            .addClass('displayNone');
        }

      }

      this.parent.checkValidRows();
      // check all linked items if they are valid
      // we have to keep the state of all form fields
    },
    saveFieldState: function (formField, state) {
      $(formField).data('is-valid', state);
    },
    getFieldState: function (formField) {
      return $(formField).data('is-valid');
    },
    inValidateFields: function ($field) {
      var self = this;

      if (this.mainFormField !== $field) {
        return;
      }

      $.each(this.formFields, function (index, formField) {
        self.saveFieldState(formField, 'notvalid');
      });
    }
  }

})(window, jQuery, nn);