
(function (window, $, nn) {

  nn.component.FullWidthCentered = function (element) {
    this.element = $(element);

    this.init();
  };

  nn.component.FullWidthCentered.prototype = {
    init: function () {
      this.getAndSetOptions();
      this.bindEvents();
      this.onWindowResize();
    },
    getAndSetOptions: function () {
      this.paddingLeft = parseInt(this.element.css('padding-left'),10);
      this.paddingRight = parseInt(this.element.css('padding-right'),10);
      this.paddingLeftParent = parseInt(this.element.parent().css('padding-left'),10);
    },
    bindEvents: function () {
      if (window.matchMedia && window.matchMedia('(max-device-width: '+nn.settings.tabletBreakPoint+'px)').matches) {
        // do nothing with these kind of devices
      } else {
        var resizeCallback = $.proxy(this.onWindowResize, this);
        resizeCallback = nn.helpers.debounce(resizeCallback,50);
        $(window).on('resize', resizeCallback);
      }
      $(window).on('orientationchange', $.proxy(this.onWindowResize, this));
    },
    onWindowResize: function () {
      this.viewportWidth = parseInt($('body').width(), 10);
      this.centerElement();
    },
    centerElement: function () {
      var notResponsivePageWidth = 990,
        pageWidth = $('#page').width(),
        viewportWidth = Math.min(this.viewportWidth, 1280), // maximum viewport width that can be used is 1280
        leftPosition = Math.floor((pageWidth - viewportWidth) / 2),
        css;

      if (notResponsivePageWidth >= this.viewportWidth) {
        leftPosition = 0;
      }

      if (!nn.helpers.isResponsivePage() && notResponsivePageWidth >= this.viewportWidth) {
        viewportWidth = notResponsivePageWidth;
      }

      if (this.paddingLeftParent > 0) {
        leftPosition = leftPosition - this.paddingLeftParent;
      }

      css = {
        'width': viewportWidth,
        'left': leftPosition
      };

      if (this.paddingLeft > 0) {
        css['padding-left'] = (this.paddingLeft + (0 - leftPosition)) + 'px';
      }

      if (this.paddingRight > 0) {
        css['padding-right'] = (this.paddingRight + (0 - leftPosition)) + 'px';
      }

      this.element.css(css);
    }
  };
})(window, jQuery, nn);