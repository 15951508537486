import indexOf from 'lodash/indexOf';

(function($, moduleconfig, window) {
  // Listen to certain events on the given element, and

  window.app = window.app || {};
  window.app.helper = window.app.helper || {};

  window.app.helper.eventlistener = function(element,options) {

    function navigateLink() {
      window.location.href = $element.prop('href');
    }

    function navigateUrl() {
      if (config.action.afterUrl) {
        var currentUrl = document.createElement('a');
        currentUrl.href = window.location.href;

        var newUrl = document.createElement('a');
        newUrl.href = config.action.afterUrl;

        window.location.href = config.action.afterUrl;

        if (currentUrl.baseURI === newUrl.baseURI) {
          // We are navigating to the exact same page. Force a reload.
          window.location.reload();
        }
      }
    }

    var $element = $(element);

    var eventHandler; // Place the event handler method in the module's upper scope

    function eventListener(evt) {
      evt.preventDefault();
      if (!eventTriggered) {
        eventTriggered = true;
        eventHandler(evt);
      }
    }

    // Parse and/or arrange any given config parameters
    var config = moduleconfig({
      "options": options,
      "element": element,
      "defaults": {}
    });

    var eventTriggered = false;

    // Set up AJAX request
    if (config.action.type === 'ajax') {

      // Set the request method
      if (config.action.method && indexOf(['get','post','put','delete','head'],config.action.method.toLowerCase()) > -1) {
        config.action.method = config.action.method.toLowerCase();
      } else {
        config.action.method = 'GET';
      }

      eventHandler = function() {
        if (config.action.type === 'ajax') {
          var ajaxOptions = {
            'method': config.action.method
          };
          if (config.action.data) {
            ajaxOptions.data = config.action.data;
          } else if ($element.prop("tagName") === 'SELECT') {
            var selectedOptionData = $element.find('>option:selected').data('eventlistenerData');
            if (selectedOptionData) {
              ajaxOptions.data = JSON.parse(selectedOptionData.replace(/'/g,'"'));
            }
          }

          var ajaxRequest = $.ajax(config.action.url,ajaxOptions);

          switch (config.action.after) {
          case 'navigateLink':
            ajaxRequest.always(navigateLink);
            break;
          case 'navigateUrl':
            ajaxRequest.always(navigateUrl);
            break;
          }
        }
      };

      $element.on(config.event, eventListener);

    }

  };

}(jQuery, window.app.helper.moduleconfig, window));