const parseConfig = require('./parse-config.js').default;

if (typeof window.console === 'undefined') {
  window.console = {};
  window.console.log =
    window.console.info =
    window.console.warn =
    window.console.error =
      function () {};
}

/**
 * @section Namespaces
 */
var nn = (function () {
  var globals = {
      dialogLevel: 0,
      invalidateLevel: 0,
      activePhotoPanel: false,
      glass: false,
      ieQuirks: false,
      storage: null,
      blueriqScopes: [],
      freestyleDesignPath: null,
      cms: {
        previewModeClass: 'xc-incontext',
        editModeClass: 'wm_not_selectable',
        isModePreview: false,
        isModeEdit: false,
      },
    },
    component = {},
    helpers = {},
    handlers = {
      dialogs: {},
      compat: {},
      init: {},
    },
    settings = {
      mobileBreakPoint: 479,
      tabmobBreakPoint: 610,
      tabletBreakPoint: 820,
      currentView: null,
      fadeDuration: 200,
      slideDuration: 500,
      carrouselFade: 1500,
      carrouselTimeout: 4000, //timeout for slideshow before advancing to next slide
      ajaxTimeout: 30000, //set timeout to 30 seconds for slow pages
      invalidationStep: 75,
    },
    references = {
      expandableComponents: [],
      selectFields: [],
      dialogs: [],
    },
    objectInstance = {},
    object = {};

  function _initializeInitHandlers() {
    $.each(handlers.init, function () {
      var fn = this;
      fn();
    });
  }

  function _initializeGlobals() {
    if (component.Glass) {
      globals.glass = new component.Glass();
    }

    if (window.designuuid) {
      globals.freestyleDesignPath = $(
        'script[src*="' + window.designuuid + '"]',
      )
        .prop('src')
        .replace(/\/js.*/, '');
    }
  }

  var _initializeIEEnhancements = function () {};

  var _initComponent = function (element, componentName) {
    var componentDefinition,
      spin = false;
    if (nn.spin && nn.spin.component[componentName]) {
      componentDefinition = nn.spin.component[componentName];
      spin = true;
    } else if (component[componentName]) {
      componentDefinition = component[componentName];
    }

    if (!(element instanceof jQuery)) {
      element = $(element);
    }

    if (componentDefinition) {
      var instance = new componentDefinition(element[0]),
        instanceID = element.attr('id') || '_noid_' + componentName;

      element.attr('data-initialized', 'true');

      if (typeof objectInstance[instanceID] !== 'undefined') {
        var counter;
        for (
          counter = 0;
          typeof objectInstance[instanceID + '_' + counter] !== 'undefined';
          counter++
        ) {
          // Empty block
        }
        instanceID = instanceID + '_' + counter;
      }

      object[instanceID] = objectInstance[instanceID] = instance;

      if (spin === true && nn.spin) {
        nn.spin.object[instanceID] = instance;
      }

      return instance;
    }
  };

  var _initializeComponents = function (context) {
    if (!context) {
      context = document;
    } else if (context instanceof jQuery) {
      context.toArray().forEach(_initializeComponents);
      return;
    }

    const selector = '[data-component]:not([data-initialized])';
    const uninitialized = [...context.querySelectorAll(selector)];
    if (context !== document && context.matches(selector)) {
      uninitialized.push(context);
    }

    uninitialized.forEach((element) => {
      element.dataset.component
        .split(',')
        .forEach((componentName) =>
          _initComponent(element, componentName.trim()),
        );
    });

    $('body').trigger('NN:afterInitializeComponents', {
      context: context,
    });
  };

  var _registerModule = function (moduleName, cb) {
    var segments = moduleName.split('.');

    if (segments.length > 2) {
      throw new Error('Cannot register module with more than 1 level');
    }

    window.app = window.app || {};

    if (segments[0] in window.app) {
      if (segments.length > 1) {
        if (segments[1] in window.app[segments[0]]) {
          let errorMessage =
            'Cannot register the module with name ' +
            segments[0] +
            '.' +
            segments[1] +
            ', name already in use. Please change the name of your module.';
          throw new Error(errorMessage);
        }
      } else {
        let errorMessage =
          'Cannot register the module with name ' +
          segments[0] +
          ', name already in use. Please change the name of your module.';
        throw new Error(errorMessage);
      }
    }

    window.app[segments[0]] = window.app[segments[0]] || {};

    var applyConfig = async function (root, config) {
      config = config || parseConfig({ element: root });
      return await cb(root, config);
    };

    if (segments.length === 1) {
      window.app[segments[0]].Index = applyConfig;
    } else {
      window.app[segments[0]][segments[1]] = applyConfig;
    }
  };

  var _initializeEvents = function () {
    var getView = function (width) {
      width = parseInt(width, 10);
      if (width <= nn.settings.mobileBreakPoint) {
        return 'mobile';
      } else if (width <= nn.settings.tabmobBreakPoint) {
        return 'tabmob';
      } else if (width <= nn.settings.tabletBreakPoint) {
        return 'tablet';
      }
      return 'desktop';
    };

    nn.settings.currentView = getView($(window).width());

    $(window).resize(function () {
      if (nn.settings.currentView !== getView($(window).width())) {
        nn.settings.currentView = getView($(window).width());
        // console.log(nn.settings.currentView);
        $('body').trigger(nn.settings.currentView);
        // REV: only one consumer?????????
        $('body').trigger('Viewport:Change', nn.settings.currentView);
      }
    });
  };

  var _getViewportWidth = function () {
    return $('#page').width();
  };

  var _isPsdlSuitcase = function () {
    return $('body.is-psdlsuitcase').length > 0;
  };

  var _isCmsModePreview = function () {
    return nn.globals.cms.isModePreview;
  };

  var _isCmsModeEdit = function () {
    return nn.globals.cms.isModeEdit;
  };

  // return browser and version
  var uaMatch = function (ua) {
    ua = ua.toLowerCase();

    var match =
      /(chrome)[ /]([\w.]+)/.exec(ua) ||
      /(webkit)[ /]([\w.]+)/.exec(ua) ||
      /(opera)(?:.*version|)[ /]([\w.]+)/.exec(ua) ||
      /(msie) ([\w.]+)/.exec(ua) ||
      (ua.indexOf('compatible') < 0 &&
        /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua)) ||
      [];

    return {
      browser: match[1] || '',
      version: match[2] || '0',
    };
  };

  var _matched = uaMatch(navigator.userAgent);
  var browser = {};

  if (_matched.browser) {
    browser[_matched.browser] = true;
    browser.version = parseInt(_matched.version, 10);
    browser.name = _matched.browser;
  }

  // Chrome is Webkit, but Webkit is also Safari.
  if (browser.chrome) {
    browser.webkit = true;
  } else if (browser.webkit) {
    browser.safari = true;
  }

  // return browser object
  helpers.browser = browser;

  var _init = function () {
    _initializeGlobals();
    _initializeInitHandlers();
    _initializeEvents();
    _initializeComponents();
  };

  return {
    globals: globals,
    handlers: handlers,
    component: component,
    settings: settings,
    helpers: helpers,
    object: object,
    objectInstance: objectInstance,
    references: references,
    initComponent: _initComponent,
    initializeComponents: _initializeComponents,
    getViewport: _getViewportWidth,
    isPsdlSuitcase: _isPsdlSuitcase,
    isCmsModePreview: _isCmsModePreview,
    isCmsModeEdit: _isCmsModeEdit,
    enhanceForIE: _initializeIEEnhancements,
    api: {},
    init: _init,
    registerModule: _registerModule,
    version: process.env.VERSION,
  };
})();

module.exports = global.nn = nn;
