import CMSContentMultimedia from "./multimedia";

// The default, hardcoded, XC endpoint from which to request CMS content.
const CMS_CONTENT_ENDPOINT = '/Json-pagina.htm';

export default class CMSContent{
  constructor (element) {
    this.element = element;
  }

  /**
     * Initializes CMS content.
     */
  initializeContent (data) {
    switch (data.multimedia[0].contentType) {
    case 'nnsitemediaitemsmultimedia':
      this.content = new CMSContentMultimedia(this.element, data.multimedia[0]);
      break;
    }
  }

  /**
     * Load content from an XC endpoint.
     */
  load (config) {
    config = Object.assign({
      endpoint: CMS_CONTENT_ENDPOINT
    }, config);

    return fetch(
      `${config.endpoint}?type=${config.type}&gx_context_id=${config.gx_context_id}`,
      { credentials: 'same-origin' }
    )
      .then(response => response.json())
      .then(data => this.initializeContent(data));
  }
}